import { Expression } from './../../ms/ms.types';
import {
  ShapeDescriptor,
  ShapeConstructor,
  GeneralShapeDescriptor,
  TypeDef,
} from './types/shape.type';
import { Resource } from './resource';

export type ResourceIRI = string;
export type Predicate = string;

export interface ResourceDescriptor<T = string | Expression> {
  inputs: Record<string, TypeDef>;
  exprs: Record<string, T>;
  elements: Record<string, ResourceDescriptor<T>>;
}

export type RawResourceDescriptor = ResourceDescriptor<string>;
export type CompiledResourceDescriptor = ResourceDescriptor<Expression>;

export interface ResourceData<T = GeneralShapeDescriptor, C = {}> {
  type?: string;
  IRI?: ResourceIRI;
  label?: string;
  literals?: Record<string, any> & { descriptor?: T };
  relationships?: Record<
    string,
    string | string[] | ResourceData | ResourceData[] | Record<string, boolean>
  >;
  relatedResourceDataMap?: any;
  // TODO - remove descriptor key
  // descriptor?: T;
  config?: C;
}

export interface ResourceDataObject {
  resourceData: ResourceData;
  included?: Record<string, ResourceData>;
}

export interface ResourceOperation {
  key: string;
  index?: number;
  type?: OperationType;
  child?: boolean;
}

export enum OperationType {
  Equal = 'equal',
  Push = 'push',
}

// TODO - clear that
export type Relationships = Record<
  string,
  string | string[] | ResourceData | ResourceData[]
>;

// export type TypeDef =
//   | Type
//   | {
//       type: Type;
//       optional?: boolean;
//     };

export type Type = LiteralType | ResourceType;

export enum LiteralType {
  String = 'string',
  Number = 'number',
  Boolean = 'boolean',
}

export enum ObjectLiteral {
  Point = 'Point',
  Vector = 'Vector',
}

export enum ResourceType {
  Point = 'point',
  Vector = 'vector',
  Resource = 'resource',
  GeneralShape = 'generalShape',
  GeneralPath = 'generalPath',
  PathController = 'pc',
  CurvePoint = 'CurvePoint',
  TextElement = 'TextElement',
  PathShape = 'PathShape',
  PathSection = 'PathSection',
  GeneralPathNext = 'GeneralPathNext',
  ImageElement = 'ImageElement',
  ImportedImageElement = 'ImportedImageElement',
  ImportedImagePreview = 'ImageElementPreview',
  ContainerShape = 'ContainerShape',
  RectangleShape = 'RectangleShape',
  TrajectoryShape = 'TrajectoryShape',
  CircleShape = 'CircleShape',
  GroupShape = 'GroupShape',
}

export interface GeneralShapeInput {
  descriptor: ShapeDescriptor;
  constructor: ShapeConstructor;
}

export interface PatchRequest {
  resource: Resource;
  body: any;
}

export interface PostRequest {
  key: string;
  type: ResourceType;
  body: any;
}

export interface FurtherRequests {
  patch: PatchRequest[];
  post: PostRequest[];
}

export interface PatchResponse {
  patches?: { resource: Resource; object?: any }[];
}
