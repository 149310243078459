<div
  class="arm-shape-attribute-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Arm Config"
    [attributeState]="attributeState"
    key="config"
    [noDelete]="true"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-2"
  >
    <div class="row-space-between">
      <div class="label">fixed-length</div>
      <nw-boolean-input
        [value]="attributeState.value.fixedLength || false"
        (changed)="updateSubAttributeValue('fixedLength', $event)"
      ></nw-boolean-input>
    </div>
    <div class="row-space-between">
      <nw-number-input
        key="r1"
        [value]="attributeState.value.r1"
        (changed)="updateSubAttributeValue('r1', $event)"
      ></nw-number-input>
      <nw-number-input
        key="r2"
        [value]="attributeState.value.r2"
        (changed)="updateSubAttributeValue('r2', $event)"
      ></nw-number-input>
    </div>
    <div class="row-space-between"></div>
    <div class="row-space-between">
      <nw-number-input
        key="d1"
        [value]="attributeState.value.d1"
        [step]="0.1"
        (changed)="updateSubAttributeValue('d1', $event)"
      ></nw-number-input>
      <nw-number-input
        key="d2"
        [value]="attributeState.value.d2"
        [step]="0.1"
        (changed)="updateSubAttributeValue('d2', $event)"
      ></nw-number-input>
    </div>
    <div class="row-space-between">
      <nw-boolean-input
        key="flat1"
        [value]="attributeState.value.flat1"
        (changed)="updateSubAttributeValue('flat1', $event)"
      ></nw-boolean-input>
      <nw-boolean-input
        key="flat2"
        [value]="attributeState.value.flat2"
        (changed)="updateSubAttributeValue('flat2', $event)"
      ></nw-boolean-input>
    </div>
    <div class="flex-row gap-6">
      <div>stroke</div>
      <nw-color-select
        [colors]="attributeState.value.strokeColor"
        (changed)="updateSubAttributeValue('strokeColor', $event)"
      ></nw-color-select>
    </div>
    <div class="flex-row gap-6">
      <div>stroke-width</div>
      <nw-number-input
        [value]="attributeState.value.strokeWidth"
        (changed)="updateSubAttributeValue('strokeWidth', $event)"
      ></nw-number-input>
    </div>
    <div class="flex-row gap-4">
      <div class="fs-11">center</div>
      <input
        type="checkbox"
        [checked]="attributeState.value.center"
        (change)="changeStrokeSection('center', $event)"
      />
    </div>
    <div class="flex-row gap-6" *ngIf="attributeState.value.center">
      <nw-number-input
        key="start"
        [value]="[attributeState.value.center?.[0].start]"
        [step]="0.1"
        (changed)="changeSectionInterval('center', 'start', $event)"
      ></nw-number-input>
      <div class="delimiter"></div>
      <nw-number-input
        key="end"
        [value]="[attributeState.value.center?.[0].end]"
        [step]="0.1"
        (changed)="changeSectionInterval('center', 'end', $event)"
      ></nw-number-input>
    </div>
    <div class="flex-row gap-4">
      <div class="fs-11">top</div>
      <input
        type="checkbox"
        [checked]="attributeState.value.top"
        (change)="changeStrokeSection('top', $event)"
      />
    </div>
    <div class="flex-row gap-6" *ngIf="attributeState.value.top">
      <nw-number-input
        key="start"
        [value]="[attributeState.value.top?.[0].start]"
        [step]="0.1"
        (changed)="changeSectionInterval('top', 'start', $event)"
      ></nw-number-input>
      <div class="delimiter"></div>
      <nw-number-input
        key="end"
        [value]="[attributeState.value.top?.[0].end]"
        [step]="0.1"
        (changed)="changeSectionInterval('top', 'end', $event)"
      ></nw-number-input>
    </div>
    <div class="flex-row gap-4">
      <div class="fs-11">bottom</div>
      <input
        type="checkbox"
        [checked]="attributeState.value.bottom"
        (change)="changeStrokeSection('bottom', $event)"
      />
    </div>
    <div class="flex-row gap-6" *ngIf="attributeState.value.bottom">
      <nw-number-input
        key="start"
        [value]="[attributeState.value.bottom?.[0].start]"
        [step]="0.1"
        (changed)="changeSectionInterval('bottom', 'start', $event)"
      ></nw-number-input>
      <div class="delimiter"></div>
      <nw-number-input
        key="end"
        [value]="[attributeState.value.bottom?.[0].end]"
        [step]="0.1"
        (changed)="changeSectionInterval('bottom', 'end', $event)"
      ></nw-number-input>
    </div>
    <div class="flex-row gap-4">
      <div class="fs-11">start</div>
      <input
        type="checkbox"
        [checked]="attributeState.value.start"
        (change)="changeStrokeSection('start', $event)"
      />
    </div>
    <div class="flex-row gap-6" *ngIf="attributeState.value.start">
      <nw-number-input
        key="start"
        [value]="[attributeState.value.start?.[0].start]"
        [step]="0.1"
        (changed)="changeSectionInterval('start', 'start', $event)"
      ></nw-number-input>
      <div class="delimiter"></div>
      <nw-number-input
        key="end"
        [value]="[attributeState.value.start?.[0].end]"
        [step]="0.1"
        (changed)="changeSectionInterval('start', 'end', $event)"
      ></nw-number-input>
    </div>
    <div class="flex-row gap-4">
      <div class="fs-11">end</div>
      <input
        type="checkbox"
        [checked]="attributeState.value.end"
        (change)="changeStrokeSection('end', $event)"
      />
    </div>
    <div class="flex-row gap-6" *ngIf="attributeState.value.end">
      <nw-number-input
        key="end"
        [value]="[attributeState.value.end?.[0].end]"
        [step]="0.1"
        (changed)="changeSectionInterval('end', 'end', $event)"
      ></nw-number-input>
      <div class="delimiter"></div>
      <nw-number-input
        key="end"
        [value]="[attributeState.value.end?.[0].end]"
        [step]="0.1"
        (changed)="changeSectionInterval('end', 'end', $event)"
      ></nw-number-input>
    </div>
  </div>
</div>
