import { exhaustMap, map, switchMap } from 'rxjs/operators';
import { setOrganisations } from '../organisation/organisation.actions';
import { setProjects } from '../projects/project.actions';
import { HomeService } from './home.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadHome } from './home.actions';

@Injectable()
export class HomeEffects {
  readonly loadHomeData$: Observable<Action>;

  constructor(
    private readonly actions$: Actions,
    private readonly homeService: HomeService,
  ) {
    this.loadHomeData$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadHome),
        exhaustMap(() => this.homeService.getHome()),
        switchMap(organisations => [setOrganisations({ organisations })]),
      ),
    );
  }
}
