import { StoreService } from '../../store/store.service';
import { CanvasService } from '../../services/canvas/canvas.service';
import { ResourceData } from '../../elements/resource/resource.types';
import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  ElementRef,
  NgZone,
  Input,
} from '@angular/core';
import {
  ShapeDescriptor,
  ContainerShapeDescriptor,
  Coords,
} from '../../elements/resource/types/shape.type';
import { clone as _cloneDeep, omit as _omit, uniq } from 'lodash';
import { safeDump, safeLoad } from 'js-yaml';

import { PathShape } from '../shape/shapes/path-shape/path-shape';
import { AnimationFrame } from '../animation/components/animation-frame/animation.types';
import { RootShape } from '../shape/shapes/general/root/root-shape';
import { ViewportRuler } from '@angular/cdk/scrolling';
import { UntilDestroy } from '@ngneat/until-destroy';
import { GeneralShape } from '../shape/shapes/general/general-shape';
import { ShapeService } from '../shape/shape.service';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  isFileLoading,
  isPatchLoading,
  showComponentSearch,
} from '../store/selector/editor.selector';
import { filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SVGParseService } from '../svg/svg-parse.service';

@UntilDestroy()
@Component({
  selector: 'iw-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit {
  @ViewChild('canvas') canvas: ElementRef;

  @ViewChild('container') container: ElementRef;

  @Input() recordMode = false;

  isPatchLoading$: Observable<boolean>;
  isFileLoading$: Observable<boolean>;

  codeMirrorOptions = {
    mode: 'javascript',
    lineNumbers: true,
  };

  newTab = null;
  yaml: string;
  time: number;

  currentId: string;
  localStore: Record<string, { resource: ResourceData }> = {};
  command = false;
  forceFetch = false;
  newDescriptor: ShapeDescriptor;

  dragState: { x: number; y: number };
  dragRectangle: { x: number; y: number; width: number; height: number };

  get resourceIRI() {
    return this.cs.currentResourceData.IRI;
  }

  get showCodeEditor() {
    if (!this.cs.previewShape) {
      return false;
    }
    return this.cs.selectedShapes.length == 1;
  }

  showComponentSearch$: Observable<boolean>;

  get previewShape() {
    return this.cs.previewShape;
  }

  set previewShape(shape: RootShape) {
    this.cs.previewShape = shape;
  }

  get translated() {
    if (!this.previewShape?.currentMatrix) {
      return { x: undefined, y: undefined };
    }
    const [x, y] = [
      this.previewShape.currentMatrix.e,
      this.previewShape.currentMatrix.f,
    ];
    const scale = this.previewShape.currentMatrix.a;
    return {
      x: (this.cs.canvasX - x) / scale,
      y: (this.cs.canvasY - y) / scale,
      cx: this.cs.canvasX,
      cy: this.cs.canvasY,
      tx: x,
      ty: y,
      scale: scale,
    };
  }

  get calculated() {
    return this.cs.getAbsoluteCoords(this.cs.canvasX, this.cs.canvasY, true);
  }

  get currentResourceData() {
    return this.cs.currentResourceData;
  }

  get label() {
    return this.currentResourceData.literals.label;
  }

  get componentType() {
    return 'element-editor';
  }

  set descriptor(value: ShapeDescriptor) {
    if (!this.localStore[this.currentId]) {
      return;
    }
    this.localStore[this.currentId].resource.literals.descriptor = value;
  }

  get descriptor() {
    return this.currentResourceData.literals.descriptor;
  }

  get shapes() {
    return this.previewShape?.ref('shapes') || [];
  }

  get selectedShapes() {
    return this.shapes.filter((ps: PathShape) => ps.selected);
  }

  imageUploadState: string;

  @Input()
  fixCanvas = false;
  async onFileDropped(event: any) {
    this.imageUploadState = 'in-progress';
    const { files, coords } = event;
    const file = files[0];
    console.log('onFileDropped', file);
    console.log('file.type', file.type);
    if (file.type.endsWith('svg+xml')) {
      return this.svgParseService.parse(file);
    }

    if (this.cs.previewShape) {
      const { data: base64, error: e1 } = await this.cs.toBase64(file);

      if (e1) {
        throw new Error(
          `Base64 conversion of the uploaded file resulted an error: ${e1.message}`,
        );
      }

      // const { data: s3Id, error: e2 } = await this.cs.saveImageToS3(base64);
      // if (e2) {
      //   throw new Error(`Image could not be saved to S3: ${e1.message}`);
      // }

      this.cs.previewShape.addImageShape(null, coords, base64);

      // const is = new ImageShapeByFile(
      //   this.cs,
      //   {
      //     literals: {
      //       label: 'abc',
      //       descriptor: {
      //         position: {
      //           x: 100,
      //           y: 100,
      //         },
      //         s3Id: null,
      //       },
      //     },
      //   },
      //   file
      // );
      // this.cs.previewShape.containerForChildren.addChild(is.container);
    }
  }

  onRightClick(event: MouseEvent) {
    // -- // -- //
    event.stopPropagation();
    event.preventDefault();

    if (this.cs.isControlPressed) {
      return;
    }
    console.log('right-click', this.cs.draggedSVGElement, {
      isControlPressed: this.cs.isControlPressed,
    });

    if (
      this.shapeService.areThereSelectedShapes() ||
      this.cs.draggedSVGElement
    ) {
      const clickedShape = this.cs.draggedSVGElement?.parent as GeneralShape;

      // TODO - RC's and PC's config should ha a shape parent
      const shapesInScope = this.shapeService.selectedShapes;
      // clickedShape?.selected
      // ? this.shapeService.selectedShapes
      // : [clickedShape];
      this.cs.clickAbsorbed = true;
      this.cs.draggedSVGElement?.stopDrag();
      this.cs.draggedSVGElement = null;
      this.cs.rightClickMenu = {
        x: event.clientX,
        y: event.clientY,
        options: [
          { label: 'Mask shapes', shortcut: 'Shift + m', id: 'mask' },
          { label: 'Create layer from selection', id: 'layer' },
          { label: 'Create component', shortcut: 'Shift + g', id: 'layer' },
        ],
        handler: (id: string) => {
          switch (id) {
            case 'mask':
              console.log('yooo mask', shapesInScope);
              this.cs.notification = 'Please select a shape for the masking!';
              this.shapeService.shapesTobeMasked = shapesInScope;
              break;
            case 'layer':
              console.log('yooo layer', shapesInScope);
              //this.cs.notification = 'Please select a shape for the masking!';
              // this.shapeService.shapesTobeMasked = shapesInScope;
              break;
            case 'component':
              this.shapeService.createImportedShapeFromSelection();
              break;
          }
          this.cs.rightClickMenu = null;
        },
      };
    } else {
      // -- //
    }
  }

  constructor(
    public readonly cs: CanvasService,
    private readonly viewportRuler: ViewportRuler,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    private readonly ngZone: NgZone,
    public readonly shapeService: ShapeService,
    private readonly svgParseService: SVGParseService,
  ) {
    this.isFileLoading$ = this.store.select(isFileLoading);

    this.viewportRuler
      .change(50)
      .subscribe(() => this.ngZone.run(() => this.resizeCanvas()));

    this.cs.generalEventSubscribe('refresh', () => this.reInit());
    // this.cs.keyEventSubscribe(
    //   p'Shift+r',
    //   () => {
    //     this.cs.consumeKeyEvent('r');
    //     this.reInit();
    //   },
    //   2,
    // );

    this.cs.keyEventSubscribe('Escape', () => {
      this.shapeService.selectorRectangle?.remove();
      this.dragState = null;
    });

    this.route.params
      .pipe(filter(({ fileID }) => !!fileID))
      .subscribe(async ({ fileID }) => {
        this.cs.currentFileID = fileID;
      });

    this.isPatchLoading$ = this.cs.store.select(isPatchLoading);

    // this.cs.generalEventSubscribe('start-recording', () => this.record());
  }

  ngOnInit() {
    this.showComponentSearch$ = this.store.select(showComponentSearch);
  }

  scaleBufferCnt = 0;
  scaleBuffer = 0;

  mouseover() {
    // console.log('cc > mouse-over');
  }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent) {
    event.preventDefault();
    /* console.log(
      'deltaX',
      event.deltaX,
      'deltaY',
      event.deltaY,
      'deltaMode',
      event.deltaMode,
    ); */

    if (this.cs.isSpacePressed) {
      this.cs.consumeKeyEvent('Space');
    }

    const { deltaX, deltaY, offsetX, offsetY } = event;
    // console.log({ deltaX, deltaY });
    const { abs } = Math;
    if (abs(deltaX) === 0 || abs(deltaY) === 0) {
      // that is the mouse operation
      if (this.cs.isMetaPressed || deltaX % 1 != 0 || deltaY % 1 != 0) {
        // console.log('meta', { deltaX, deltaY });
        // if the shift is pressed then the deltaX and the deltaY is reveresed
        return this.cs.xscale(offsetX, offsetY, -deltaY);
      }

      return this.cs.isSpacePressed
        ? this.cs.translateCanvas(-deltaY, -deltaX)
        : this.cs.translateCanvas(-deltaX, -deltaY);
    }

    if (deltaX % 1 == 0 && deltaY % 1 == 0) {
      return this.cs.translateCanvas(
        (abs(deltaX) > abs(deltaY) ? -2 * deltaX : 0) * this.cs.canvasScale,
        (abs(deltaY) > abs(deltaX) ? -2 * deltaY : 0) * this.cs._canvasScale,
      );
    }

    this.scaleBufferCnt++;
    this.scaleBuffer += deltaY;
    // if (this.scaleBufferCnt < 2) {
    //   return;
    // }

    // console.log('deltaY', deltaY);

    this.cs.xscale(offsetX, offsetY, -2 * this.scaleBuffer);
    this.scaleBuffer = 0;
    this.scaleBufferCnt = 0;
    return;
    if (
      (abs(deltaX) < 1 && abs(deltaX) != 0) ||
      (abs(deltaY) < 1 && abs(deltaY) != 0)
    ) {
      this.cs.xscale(offsetX, offsetY, -deltaY);
    } else {
      // if (this.cs.isMetaPressed) {
      //   this.cs.xscale(offsetX, offsetY, -deltaY * 10);
      // } else if (this.cs.isSpacePressed) {
      //   return this.cs.translateCanvas(-deltaY / 2, 0);
      // } else {
      //   return this.cs.translateCanvas(0, -deltaY / 2);
      // }
    }

    // console.log('wheel', event.offsetX, event.offsetY, event.deltaY);
  }

  // @HostListener('gesturestart', ['$event'])
  // @HostListener('gesturechange', ['$event'])
  // @HostListener('gestureend', ['$event'])
  // onGesture(event: any) {
  //   // this.zoomChange.emit(event.scale);
  //   event.preventDefault();
  // }
  // }
  initialTouchPoints: any;
  initialDistance: number;
  initialAngle: number;

  private calculateDistance(points) {
    const [p1, p2] = points;
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  private calculateAngle(points) {
    const [p1, p2] = points;
    return Math.atan2(p2.y - p1.y, p2.x - p1.x) * (180 / Math.PI);
  }

  initializedShapeByDrag: GeneralShape;

  @HostListener('mousedown', ['$event'])
  mouseDown(event: MouseEvent) {
    if (event.button === 2) {
      return;
    }
    if (this.cs.mouseDownAbsorbed) {
      this.cs.mouseDownAbsorbed = false;
      return;
    }

    this.cs.mouseDownFree = true;

    if (this.cs.draggedSVGElement && !this.cs.isPressed('Alt')) {
      return;
    }

    // if (this.cs.isPressed('Alt')) {
    //   this.cs.draggedSVGElement = null;
    //   return;
    // }

    if (this.cs.isPressed('Alt')) {
      this.cs.consumeKeyEvent('Alt');
    }

    if (this.cs.isPressed(['a', 's', 'd'])) {
      if (this.cs.draggedSVGElement) {
        return;
      }

      const { offsetX, offsetY } = event;
      this._offsetX = offsetX;
      this._offsetY = offsetY;
      const { x, y } = this.cs.getAbsoluteCoords(offsetX, offsetY);
      this.initializedShapeByDrag = this.shapeService.addPathShape(x, y);
      return;
    }

    if (this.cs.shapeAddMode) {
      this.newShapeResizeDims = null;
      const { offsetX, offsetY } = event;
      this._offsetX = offsetX;
      this._offsetY = offsetY;
      const { x, y } = this.cs.getAbsoluteCoords(offsetX, offsetY);
      switch (this.cs.shapeAddMode) {
        case 'rectangle':
          this.initializedShapeByDrag = this.shapeService.addRectangleShape(
            x,
            y,
          );
          break;
        case 'circle':
          this.initializedShapeByDrag = this.shapeService.addCircleShape(x, y);
          break;
        case 'path':
          this.initializedShapeByDrag = this.shapeService.addPathShape(x, y);
          break;
        case 'text':
          this.initializedShapeByDrag = this.shapeService.addTextShape(x, y);
          break;
      }

      return;
    }

    // @ts-ignore
    if (event.target.parentElement.getAttribute('id') == '_canvas') {
      // console.log('------- ee:starting-dragstate -----------');
      this.dragState = { x: event.offsetX, y: event.offsetY };
      this.shapeService.startSelectorRectangle(event.offsetX, event.offsetY);
    }
  }

  // @HostListener('mouseup', ['$event'])
  // mouseup(event: MouseEvent) {
  //   // @ts-ignore
  // }

  _offsetX = 0;
  _offsetY = 0;

  currentPos: Coords;

  newShapeResizeDims: Coords;

  @HostListener('mousemove', ['$event'])
  onMousemove({ offsetX, offsetY }: MouseEvent) {
    this.currentPos = [offsetX, offsetY];

    if (this.initializedShapeByDrag && !this.cs.isPressed('Alt')) {
      const [width, height] = [
        offsetX - this._offsetX,
        offsetY - this._offsetY,
      ];
      this.newShapeResizeDims = [width, height];
      this.initializedShapeByDrag._resize(
        width / this.cs.canvasScale,
        height / this.cs.canvasScale,
      );
      return;
    }
    if (this.dragState) {
      // console.log('--- mouse-move ---', offsetX, offsetY); // -- // -- //
      let { x, y } = this.dragState;
      const [width, height] = [offsetX - x, offsetY - y];
      if (width < 0) {
        x = x + width;
      }
      if (height < 0) {
        y = y + height;
      }
      this.shapeService.dragSelectorRectangle(x, y, width, height);
    }

    // This broadcasts back the moving to the SVG elements that are dragging
    this.cs.mouseMoveEvent(offsetX, offsetY);
  }

  @HostListener('click', ['$event'])
  async click(e: MouseEvent) {
    const log = false;
    if (log) {
      console.log('canvas.comp > clicked', {
        clickAbsorbed: this.cs.clickAbsorbed,
        initializedShapeByDrag: this.initializedShapeByDrag,
        draggingPathSection: this.cs.draggingPathSection,
        draggedSVGElement: this.cs.draggedSVGElement,
        dragState: this.dragState,
      });
    }

    // if (this.shapeService.groupRCDragMode) {
    //   this.shapeService.groupRCDragMode = false;
    //   return;
    // }
    this.cs.rightClickMenu = null;
    this.cs.mouseDownFree = false;

    // if (this.cs.clickAbsorbed) {
    //   this.cs.clickAbsorbed = false;
    //   this.cs.draggedSVGElement?.endDragHandler();
    //   this.cs.draggedSVGElement = null;
    //   console.log('return > 1');
    //   this.dragState = null;
    //   return;
    // }

    // TODO - maybe it is not the most elegant place for it //

    if (this.initializedShapeByDrag) {
      this.initializedShapeByDrag.finishInit(!this.newShapeResizeDims);
      this.initializedShapeByDrag = null;
      this.cs.shapeAddMode = null;
      this.cs.draggedSVGElement?.endDragHandler();
      // console.log('return > 2');
      return;
    }

    if (this.cs.draggingPathSection && !this.cs.isPressed('Alt')) {
      // As we finish the dragging initial drag session of a path-section another svg element
      // may have been clicked and its drag process would go on. We eliminate it stopDrag
      // #notsupercleancode
      console.log('ee:draggingPathSection-return');
      this.cs.draggedSVGElement?.stopDrag();
      this.cs.draggedSVGElement?.endDragHandler();
      this.cs.draggedSVGElement = null;
      this.cs.draggingPathSection?.clickHandler();

      // This is bit dirty but necessary.
      // If the initial drag session is over, then we will click one other element which
      // causes a next drag session to be created. The next line eliminates that
      // console.log('return > 3');
      return;
    }

    if (this.cs.draggedSVGElement) {
      // this is the case when there's for instance an image //

      this.cs.draggedSVGElement.endDragHandler();
      this.cs.draggedSVGElement = null;

      this.cs._hoveredControlPoint?.draggedOnto(
        this.cs.draggedPointController?.config?.controlPoint,
      );
      this.cs.draggedPointController = null;
      // console.log('return > 4'); //
      return;
    }

    // if (this.cs.clickAbsorbed) {
    //   this.cs.clickAbsorbed = false;
    //   this.dragState = null;
    //   return;
    // }
    if (this.dragState) {
      this.shapeService.removeSelectorRectangle();
      this.dragState = null;
      return;
    }
  }

  @HostListener('window:keydown', ['$event'])
  keyEventDown(event: KeyboardEvent) {
    if (event.key === 'Shift') {
      this.command = true;
    }
  }

  ngAfterViewInit() {
    this.cs.canvasNativeElement = this.canvas.nativeElement;
    this.canvas.nativeElement.appendChild(this.cs.app.view);
    this.cs.canvas = this.canvas;
    // -- // -- // this.resizeCanvas(); // -- // -- //
    console.log('canvas-component > ngAfterViewInit');
  }

  resizeCanvas() {
    const { scrollWidth, scrollHeight } = this.container.nativeElement;
    // TODO - check if it necessary
    console.log('resize', { scrollWidth, scrollHeight });
    this.cs.resizeCanvas(scrollWidth, scrollHeight);
  }

  // This routine is called when a file is opened at the side-bar

  resourceLoaded: string;

  async reInit() {}

  envChange({ key, value }: { key: string; value: any }) {
    this.cs.previewShape.descriptor.env ||= {};
    this.cs.previewShape.descriptor.env[key] = value;
    this.cs.previewShape.save();
  }

  currentAnimation: AnimationFrame;

  /*********************************** UNUSED FOR NOW *******************************/

  tabChanged({ id }: { id: string }) {
    this.currentId = id;
    this.yaml = safeDump(this.descriptor);
    // this.initPreviewshape(); //
  }

  initTab() {
    this.newDescriptor = null;
    this.newTab = {
      id: this.currentId,
      name: this.currentResourceData.literals.label,
    };
  }

  initYaml(resource: ResourceData) {
    try {
      this.descriptor = resource.literals?.descriptor;
    } catch {
      this.descriptor = {};
    }
    this.yaml = safeDump(this.descriptor);
  }
}
