import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from '../material.module';
import { AnimationControlerPanelComponent } from '../element-editor/control-panel/control-panel-component/animation-control-panel/animation-panel.component';
import { DataEditorComponent } from './data-editor/data-editor.component';
import { DynamicMenuComponent } from './dynamic-menu/dynamic-menu.component';
import { FilePreviewComponent } from './file-preview/file-preview.component';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { GeneralComponent } from './general-component/general-component';
import { MsEditorComponent } from './ms-editor/ms-editor.component';
import { RootComponent } from './root/root.component';
import { TabItemComponent } from './tabs/tab-item/tab-item.component';
import { TabsComponent } from './tabs/tabs.component';
import { UtilComponentModule } from './util/util-component.module';
import { DirectivesModule } from '../directives/directives.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { StateEditorComponent } from './state-editor/state-editor.component';
import { ListEditorComponent } from './list-editor/list-editor.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { OrganisationModule } from '../organisation/organisation.module';

@NgModule({
  declarations: [
    DataEditorComponent,
    GeneralComponent,
    MsEditorComponent,
    DynamicMenuComponent,
    RootComponent,
    FileUploaderComponent,
    TabsComponent,
    TabItemComponent,
    FilePreviewComponent,
    AnimationControlerPanelComponent,
    StateEditorComponent,
    ListEditorComponent,
    HomeComponent,
  ],
  imports: [
    OrganisationModule,
    ColorPickerModule,
    BrowserAnimationsModule,
    BrowserModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    UtilComponentModule,
    DirectivesModule,
    RouterModule,
  ],
  exports: [
    DataEditorComponent,
    GeneralComponent,
    MsEditorComponent,
    DynamicMenuComponent,
    RootComponent,
    FileUploaderComponent,
    TabsComponent,
    TabItemComponent,
    FilePreviewComponent,
    AnimationControlerPanelComponent,
    StateEditorComponent,
    ListEditorComponent,
    HomeComponent,
  ],
})
export class ComponentsModule {}
