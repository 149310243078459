<div
  class="float-effect-attribute-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Rotation"
    [attributeState]="attributeState"
    key="rotateEffect"
    [initialValue]="{ speed: 5 }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-4 fs-12"
  >
    <div class="flex-row space-between">
      <div>speed</div>
      <nw-number-input
        [value]="attributeState.value['speed']"
        (changed)="updateSubAttributeValue('speed', $event)"
      >
      </nw-number-input>
    </div>
  </div>
</div>
