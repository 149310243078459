<div
  class="control-panel-component editor-container"
  fxLayoutAlign="space-between center"
  fxLayout="row"
  (click)="closeMenus()"
>
  <div class="flex-row gap-12 mh-12">
    <div class="main-menu-item">
      <div
        class="menu-item-title"
        (click)="$event.stopPropagation(); flipMenu($event, 'file')"
      >
        File
      </div>
      <nw-floating [top]="8" *ngIf="menuOpen.file">
        <div class="menu-element-container">
          <div
            class="submenu-element disabled"
            (click)="action('file', 'save')"
          >
            Save
          </div>
          <div
            class="submenu-element"
            [ngClass]="{ 'iw-disabled': !(isSaveActive$ | async) }"
            (click)="editorService.openStateEditor()"
          >
            <nw-icon type="steppers" [size]="14" [outlined]="true"></nw-icon>
            <div class="submenu-text">Set states</div>
          </div>
          <div
            class="submenu-element"
            [ngClass]="{ 'iw-disabled': !cs.previewShape }"
          >
            <nw-icon type="dataset" [size]="14" [outlined]="true"></nw-icon>
            <div class="submenu-text">Set inputs</div>
          </div>
          <div
            *ngIf="cs.previewShape?.currentState"
            class="submenu-element"
            [ngClass]="{ 'iw-disabled': !cs.previewShape }"
            (click)="cs.previewShape.saveStagePosition()"
          >
            <nw-icon type="bookmark" [size]="14" [outlined]="true"></nw-icon>
            <div class="submenu-text">Save stage state</div>
          </div>
          <!-- 
            <div class="submenu-element">Open</div>
            <div class="submenu-element">Delete</div>
            <div class="submenu-element">Add state</div> 
          -->
        </div>
      </nw-floating>
    </div>
    <div class="main-menu-item">
      <div
        class="menu-item-title"
        (click)="$event.stopPropagation(); flipMenu($event, 'shape')"
      >
        Shape
      </div>
      <nw-floating [top]="8" *ngIf="menuOpen.shape">
        <div class="menu-element-container">
          <div
            class="submenu-element"
            (click)="shapeService.setAsLightShadow()"
          >
            <nw-icon type="sunny" [size]="14"></nw-icon>
            <div class="submenu-text">Set as light shadow -</div>
          </div>
          <div class="submenu-element" (click)="shapeService.setAsDarkShadow()">
            <nw-icon type="contrast" [size]="14"></nw-icon>
            <div class="submenu-text">Set as dark shadow -</div>
          </div>
          <div class="submenu-element" (click)="shapeService.verticalAlign()">
            <nw-icon type="align_vertical_center" [size]="14"></nw-icon>
            <div class="submenu-text">Vertical align - center -</div>
          </div>
          <div class="submenu-element" (click)="shapeService.horizontalAlign()">
            <nw-icon type="align_horizontal_center" [size]="14"></nw-icon>
            <div class="submenu-text">Horizontal align - center -</div>
          </div>
          <div
            class="submenu-element"
            (click)="shapeService.concentricCircles()"
          >
            <nw-icon type="target" [outlined]="true" [size]="14"></nw-icon>
            <div class="submenu-text">Make circles concentric -</div>
          </div>
          <div
            class="submenu-element"
            [ngClass]="{ 'iw-disabled': !cs.previewShape }"
            (click)="cs.copyDescriptor()"
          >
            <nw-icon type="content_copy" [size]="14"></nw-icon>
            <div class="submenu-text">Copy descriptor</div>
          </div>
          <div
            class="submenu-element"
            [ngClass]="{ 'iw-disabled': !cs.previewShape }"
            (click)="cs.importDescriptor()"
          >
            <nw-icon type="drive_folder_upload" [size]="14"></nw-icon>
            <div class="submenu-text">Import descriptor</div>
          </div>
          <div
            class="submenu-element"
            [ngClass]="{ 'iw-disabled': !cs.previewShape }"
            (click)="cs.flipAuxMode()"
          >
            <nw-icon type="show_chart" [size]="14"></nw-icon>
            <div class="submenu-text">Flip aux mode</div>
          </div>

          <div
            class="submenu-element"
            [ngClass]="{ 'iw-disabled': !cs.previewShape }"
            (click)="cs.defineInputs()"
          >
            <nw-icon type="input" [size]="14"></nw-icon>
            <div class="submenu-text">Define input</div>
          </div>

          <div class="submenu-element" (click)="flipPathShapeDirection()">
            Flip path-shape direction
          </div>
          <!-- <div class="submenu-element">Add path-shape</div>
          <div class="submenu-element">Toggle path-shape extend mode</div>
          <div class="submenu-element">Add circle</div>
          <div class="submenu-element">Add text</div>
          <div class="submenu-element">Equalize distance</div>
          <div class="submenu-element">New control point</div>
          <div class="submenu-element">Close path shape</div>
          <div class="submenu-element">Path shape extend mode </div>  -->
        </div>
      </nw-floating>
    </div>
    <div class="main-menu-item">
      <div
        class="menu-item-title"
        (click)="$event.stopPropagation(); flipMenu($event, 'animation')"
      >
        Animation
      </div>
      <nw-floating [top]="8" *ngIf="menuOpen.animation">
        <div class="menu-element-container">
          <div
            class="row-space-between"
            [ngClass]="{ 'iw-disabled': !cs.previewShape }"
            (click)="cs.flipZoomMode()"
          >
            <div class="flex-row gap-12">
              <nw-icon type="zoom_in_map" [size]="14"></nw-icon>
              <div class="submenu-text">
                {{ cs.zoomMode ? 'Disable zoom' : 'Enable zoom' }}
              </div>
            </div>
            <div style="float: right; opacity: 0.6">(Z)</div>
          </div>

          <div
            class="submenu-element"
            [ngClass]="{ 'iw-disabled': !cs.currentAnimation }"
            (click)="shapeService.addTrajectoryAppearAnimation()"
          >
            <nw-icon type="line_end" [outlined]="true" [size]="14"></nw-icon>
            <div class="submenu-text">Add trajectory-appear animation</div>
          </div>
          <div
            class="submenu-element"
            (click)="cs.setAnimationFrameMoveMode('set-prev')"
          >
            <nw-icon type="line_end" [outlined]="true" [size]="14"></nw-icon>
            <div class="submenu-text">Set 'set-prev' move mode</div>
          </div>
          <div
            class="submenu-element"
            (click)="cs.setAnimationFrameMoveMode('move-up')"
          >
            <nw-icon type="line_end" [outlined]="true" [size]="14"></nw-icon>
            <div class="submenu-text">Set 'move-up' move mode</div>
          </div>

          <!-- <div class="submenu-element">Canvas zoom mode</div>
          <div class="submenu-element - shape.addTrajectoryAnimation">
            Add trajectory animation
          </div>
          <div class="submenu-element - shape.addLoopTrajectoryTransform">
            Add loop trajectory transform
          </div>
          <div class="submenu-element - shape.addLoopTrajectoryTransform">
            Add rotation
          </div> -->
        </div>
      </nw-floating>
    </div>
  </div>
  <div
    *ngIf="boundingRect$ | async as boundingRect"
    class="flex-row gap-2 fs-11"
  >
    <div>{{ boundingRect.x?.toFixed(1) }} -</div>
    <div>{{ boundingRect.y?.toFixed(1) }} -</div>
    <div>{{ (boundingRect.x + boundingRect.width).toFixed(1) }} -</div>
    <div>{{ (boundingRect.y + boundingRect.height).toFixed(1) }}</div>
  </div>
  <div class="mode-switch flex-row fs-12 gap-4">
    <div
      class="mode-item"
      [class.active-mode]="!(isAnimationMode$ | async)"
      (click)="setStaticMode()"
    >
      static
    </div>
    <ng-container *ngIf="currentAnimationId$ | async">
      <div class="mode-delimiter"></div>
      <div
        class="mode-item"
        [class.active-mode]="isAnimationMode$ | async"
        (click)="resetStaticMode()"
      >
        animation
      </div>
    </ng-container>
  </div>

  <div class="flex-row gap-10">
    <ng-container *ngIf="isFile$ | async">
      <nw-utility-control-panel></nw-utility-control-panel>
      <nw-base-shape-control-panel></nw-base-shape-control-panel>
    </ng-container>

    <!-- *ngIf="(languages$ | async).length" -->
    <div class="flex-row gap-4" style="margin-right: 6px">
      <nw-icon type="language" [size]="14"></nw-icon>
      <select
        [(ngModel)]="currentLanguage"
        (change)="changeLang($event)"
        id="abcdefg"
      >
        <option value="">default</option>
        <option *ngFor="let option of languages$ | async" [value]="option">
          {{ langMap[option] }}
        </option>
      </select>
    </div>

    <!-- <nw-control-element
      matTooltip="Show bounding rectangle"
      *ngIf="cs.previewShape"
    >
      <nw-icon
        type="crop_16_9"
        [size]="16"
        [outlined]="true"
        (click)="cs.showBoundingRectangle()"
      ></nw-icon>
    </nw-control-element> -->

    <!-- <nw-state-controller></nw-state-controller> -->
  </div>
</div>
