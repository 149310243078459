<div class="recorder-component w-100-h-100 flex-column">
  <div *ngIf="state == 'idle'" class="header fs-16" style="margin: 20px">
    Export Video
  </div>
  <div
    *ngIf="state == 'load-resources'"
    class="header fs-16 flex-row gap-10"
    style="margin: 20px"
  >
    <div>Resources are being loaded</div>
    <div class="spinner-x"></div>
  </div>
  <div
    *ngIf="state == 'recording'"
    class="header fs-16 flex-row gap-10"
    style="margin: 20px"
  >
    <div>Video is being exported...</div>
    <div class="spinner-x"></div>
  </div>

  <div *ngIf="state == 'finished'" class="header fs-16" style="margin: 20px">
    Video export has been completed!
  </div>
  <div class="editor-container pos-rel">
    <iw-canvas [recordMode]="true"> </iw-canvas>
    <img
      *ngIf="state == 'idle'"
      src="assets/play_record.svg"
      alt=""
      class="center-image"
      (click)="record()"
    />
    <img
      *ngIf="state == 'recording'"
      src="assets/play_record.svg"
      alt=""
      class="center-image"
      (click)="stopAnimation()"
    />

    <!-- <nw-floating [top]="-100">
    </nw-floating> -->
  </div>
  <div></div>
</div>
