import { RootShape } from '../../../element-editor/shape/shapes/general/root/root-shape';
import { StoryBoardItem, StoryBoardItemDescriptor } from './story-board-item';

export interface StoryBoardDescriptor {
  items: StoryBoardItemDescriptor[];
}

export class StoryBoardController {
  // -- // -- // -- // -- // -- //

  items: StoryBoardItem[];

  get cs() {
    return this.rootShape.cs;
  }

  constructor(public readonly rootShape: RootShape) {}

  delete() {}

  set(descriptor: StoryBoardDescriptor) {
    // -- // -- //
    // -- // -- //
    this.items?.map(item => item.delete());

    const [w, h] = [500, 200];
    this.items = descriptor.items.map(
      (item, i) => new StoryBoardItem(this, item, [i * (w + 20), h], [w, h]),
    );
  }
}
