import { _SVGAttributes } from '../../../../elements/resource/types/shape.type';
import { IncrementController } from '../../../animation/frame/increment/controller/increment.controller';
import { PathElement } from '../primitive/path-element';
import { PathShape } from './path-shape';

export class DashController {
  // -- //
  dashMoveInterval: number;
  dashMoveElements: PathElement[];

  dashMovePositions: Array<{
    start: number;
    end: number;
  }> = [];

  get endLength() {
    return this.pathShape.endLength;
  }

  get pathSections() {
    return this.pathShape.pathSections;
  }

  get container() {
    return this.pathShape.container;
  }

  get resolvedSVGAttributes() {
    return this.pathShape.resolvedSVGAttributes;
  }

  constructor(
    private readonly pathShape: PathShape,
    private readonly length: number,
    private readonly gap: number,
  ) {
    // -- //
    let start = 0;
    let end = length;

    this.dashMoveInterval = length + gap;
    // -- // -- // const endLength = this.endLength; // -- // -- //

    while (start < pathShape.endLength) {
      this.dashMovePositions.push({
        start,
        end: Math.min(end, this.endLength),
      });

      start += length + gap;
      end = start + length;
    }

    this.dashMoveElements = this.dashMovePositions.map(({ start, end }) => {
      const elements = this.pathSections
        .map(ps => ps.getPathItem(start, end))
        .flat()
        .filter(val => !!val);

      return new PathElement(this.pathShape, this.pathShape.container, {
        position: {
          x: 0,
          y: 0,
        },
        elements,
        ...this.resolvedSVGAttributes,
        'fill-opacity': 0,
      });
    });
  }

  remove() {
    this.dashMoveElements?.map(e => e.remove());
  }

  applyInterval(startLimit: number, endLimit: number) {
    // -- // -- //

    this.dashMovePositions.map(({ start, end }, index) => {
      const pathElement = this.dashMoveElements[index];

      if (end < startLimit) {
        pathElement.hide();
        return;
      }

      if (endLimit < start) {
        pathElement.hide();
        return;
      }

      pathElement.show();
      const elements = this.pathSections
        .map(ps =>
          ps.getPathItem(Math.max(start, startLimit), Math.min(end, endLimit)),
        )
        .flat()
        .filter(val => !!val);

      pathElement.patch({ elements });
    });
  }
  dashMoveLimitController: IncrementController;
  dashMoveLimit: number;
  currentDashMove: { length: number; gap: number };
  moveSpeed: number;
  prepareMove(speed: number) {
    this.moveSpeed = speed;
    // -- // -- // -- //
    this.currentDashMove = {
      length: this.length,
      gap: this.gap,
    };

    this.dashMovePositions.unshift({
      start: -(this.length + this.gap),
      end: -this.gap,
    });

    this.dashMoveInterval = this.length + this.gap;

    this.dashMoveElements.unshift(
      new PathElement(this.pathShape, this.pathShape.container, {
        position: {
          x: 0,
          y: 0,
        },
        elements: [],
        ...this.resolvedSVGAttributes,
        'fill-opacity': 0,
      }),
    );
  }

  patch(attrs: _SVGAttributes) {
    this.dashMoveElements.map(e => e.patch(attrs));
  }
  move(increment: number) {
    let incrementDashMove = true;
    if (this.dashMoveLimitController) {
      this.dashMoveLimit = this.dashMoveLimitController.increment(increment);

      if (this.dashMoveLimit < this.endLength) {
        incrementDashMove = false;
        // console.log('increment-dash-move > false', this.dashMoveLimit, this.endLength)
      }
    }

    if (incrementDashMove) {
      // console.log('increment-dash-move', this.dashMoveLimit, this.endLength)

      increment *= this.moveSpeed || 1;

      this.dashMovePositions = this.dashMovePositions.map(({ start, end }) => ({
        start: start + increment,
        end: Math.min(end + increment, this.endLength),
      }));
      // -- // -- // -- // -- //
    }

    const { start } = this.dashMovePositions[this.dashMovePositions.length - 1];

    if (start >= this.endLength) {
      const { start: firstStart } = this.dashMovePositions[0];
      // -- // -- //

      this.dashMovePositions.pop();

      const { length, gap } = this.currentDashMove;
      this.dashMovePositions.unshift({
        start: firstStart - (length + gap),
        end: firstStart - gap,
      });
      // -- // -- //
    }

    const hideIndexes = [];
    this.dashMovePositions.map(({ start, end }, index) => {
      if (this.dashMoveLimit) {
        if (this.dashMoveLimit <= start) {
          this.dashMoveElements[index].hide();
          hideIndexes.push(index);
          return;
        }

        if (this.dashMoveLimit < end) {
          end = this.dashMoveLimit;
        }
      }

      const elements = this.pathSections
        .map(ps => ps.getPathItem(Math.max(0, start), end))
        .flat()
        .filter(val => !!val);

      this.dashMoveElements[index].patch(
        {
          elements,
        },
        true,
      );
    });
  }
}
