import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { Subscription } from 'rxjs';
import { setLibraryValue } from '../../../projects/project.actions';
import { selectNumberVariables } from '../../../projects/project.selector';
import { ShapeService } from '../../../element-editor/shape/shape.service';

@Component({
  selector: 'nw-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
})
export class NumberInputComponent implements OnInit, OnDestroy {
  @Input()
  step = 1;

  @Input()
  key: string;

  array: (number | string)[];
  inputValue: number | number[] | string | string[];
  @Input()
  set value(value: number | number[] | string | string[]) {
    this.inputValue = value;
    this.array = Array.isArray(value) ? value : [value];
    this.updateOptions();
  }

  get isMixed() {
    return this.array?.length > 1;
  }

  @Output()
  changed = new EventEmitter<number | string>();

  hovered = false;
  focused = false;

  get _value(): number {
    const val = this.array[0];
    if (typeof val == 'number') {
      return val;
    }
    return this.shapeService.resolveNumber(val);
  }

  get isVariable() {
    return typeof this.array[0] === 'string' && this.array[0].startsWith?.('$');
  }

  get variableName() {
    return (this.array[0] as string).slice(2);
  }

  subs: Subscription[];

  selectorOptions = [];
  variables: Record<string, number> = {};
  constructor(
    public readonly cs: CanvasService,
    private readonly shapeService: ShapeService,
  ) {}

  ngOnInit(): void {
    this.subs = [
      this.cs.generalEventSubscribe('globalClick', () => {
        this.selectorOpen = false;
        this.newVariableOpen = false;
      }),
      this.cs.store.select(selectNumberVariables).subscribe(val => {
        this.variables = val;
        this.updateOptions();
      }),
    ];
  }
  ngOnDestroy(): void {
    this.subs.map(sub => sub.unsubscribe());
  }

  selectorOpen = false;
  newVariableOpen = false;

  get selectAbleVariables() {
    return Object.keys(this.variables || {})
      .filter(key => (this.isVariable ? this.variableName !== key : true))
      .map(key => ({
        id: key,
        label: key,
      }));
  }

  updateOptions() {
    this.selectorOptions = [];
    if (this.isMixed) {
      this.selectorOptions = this.array.map(val => {
        return {
          label: typeof val == 'string' ? val : val.toFixed(2),
          id: val,
        };
      });
    } else if (this.isVariable) {
      this.selectorOptions = [
        {
          label: this.variableName,
          id: 'current',
          delimiter: this.selectAbleVariables.length,
        },
      ];
    } else {
      this.selectorOptions = [
        {
          label: 'Save',
          id: 'save',
          delimiter: this.selectAbleVariables.length,
        },
      ];
    }

    this.selectorOptions.push(...this.selectAbleVariables);
  }

  openSelector(event: Event) {
    console.log('open-selector');
    event.stopPropagation();
    this.selectorOpen = true;
  }

  inputChanged(event: Event) {
    if (this.isVariable) {
      this.cs.generalEventEmit('variable-changed', {
        key: 'number',
        varName: this.variableName,
        value: +(event.target as HTMLInputElement).value,
      });
    } else {
      this.changed.emit(+(event.target as HTMLInputElement).value);
    }
  }

  selectorClicked({ id }: { id: string }) {
    this.selectorOpen = false;
    switch (id) {
      case 'save':
        this.newVariableOpen = true;
        break;
      case 'current':
        this.changed.emit(this._value);
        break;
      default:
        if (typeof id == 'string') {
          this.changed.emit('$.' + id);
        } else {
          this.changed.emit(id);
        }
        this.selectorOpen = false;
    }
  }

  saveVariable(varName: string) {
    console.log('save-variable', varName);
    this.changed.emit('$.' + varName);
    this.newVariableOpen = false;
    this.cs.store.dispatch(
      setLibraryValue({
        key: 'number',
        varName,
        value: this._value,
      }),
    );
  }
}
