import { createAction, props } from '@ngrx/store';
import {
  AnimationInnerKey,
  AnimationItem,
  AnimationKeys,
  Translate,
  Rotation,
  Scale,
  ShapeIRI,
  __Animation,
} from '../../../elements/resource/types/shape.type';
import { AnimationFrame } from '../components/animation-frame/animation.types';
import { AnimationByStart, FrameCollection } from '../animation.service';
import { AnimationId } from '../frame/animation-frame-object';
import {
  AnimationControlPanelState,
  AnimationFunctions,
  AnimationsByFrame,
  AnimationsByShape,
} from './animation.reducer';
import { Easing } from '../frame/increment/controller/increment.controller';
import { ResourceData } from '../../../elements/resource/resource.types';

export const setAnimationControlPanelState = createAction(
  'animation:set-control-panel-state',
  props<{
    state: AnimationControlPanelState;
  }>(),
);

export const requestSpeechFileFromText = createAction(
  'animation:request-speech-file-from-text',
  props<{
    id: string;
    text: string;
  }>(),
);

export const requestSpeechFileFromTextRequest = createAction(
  'animation:request-speech-file-from-text-request',
  props<{
    id: string;
    text: string;
  }>(),
);

export const addAnimationItemAction = createAction(
  'animation:add-animation-item',
  props<{
    IRIs?: string[];
    IRI?: string;
    item: AnimationItem;
  }>(),
);

export const removeAnimationItemAction = createAction(
  'animation:remove-animation-item',
  props<{
    key: AnimationKeys;
    IRIs?: string[];
    IRI?: string;
  }>(),
);

export const addAnimationFunction = createAction(
  'animation:add-animation-function',
  props<{
    name: string;
    item: AnimationItem;
  }>(),
);

export const setAnimationFunctions = createAction(
  'animation:set-animation-functions',
  props<{
    functions: AnimationFunctions;
  }>(),
);

export const setAnimationsByFrame = createAction(
  'animation:set-animations-by-frame',
  props<{
    animationsByFrame: AnimationsByFrame;
  }>(),
);

export const setAnimationsByShape = createAction(
  'animation:set-animations-by-shape',
  props<{
    animationsByShape: AnimationsByShape;
  }>(),
);

export const setAnimationSubValue = createAction(
  'animation:set-animation-sub-value',
  props<{
    IRIs?: string[];
    IRI?: string;
    animationKey: AnimationKeys;
    innerKey: AnimationInnerKey;
    value: any;
  }>(),
);

export const setTranslateAnimation = createAction(
  'animation:set-translate-animation',
  props<{
    IRI: string;
    animationId: AnimationId;
    translate: Translate;
  }>(),
);

export const setScaleAnimation = createAction(
  'animation:set-scale-animation',
  props<{
    IRI: string;
    animationId: AnimationId;
    scale: Scale;
  }>(),
);

export const setRotationAnimation = createAction(
  'animation:set-rotation-animation',
  props<{
    IRI: string;
    animationId: AnimationId;
    rotation: Rotation;
  }>(),
);

export const addAnimationByShapeBaseAction = createAction(
  'animation:add-animation-by-shape-base-action',
  props<{
    shapeIRI: string;
    animationId: AnimationId;
    item: AnimationItem;
  }>(),
);

export const resetAnimationAction = createAction(
  'animation:reset-animation',
  props<{
    IRIs: string[];
    key: AnimationKeys;
  }>(),
);

export const setAnimationEase = createAction(
  'animation:set-animation-ease',
  props<{
    shapeIRI: string;
    key: AnimationKeys;
    ease: Easing;
  }>(),
);

export const setAnimationEaseBase = createAction(
  'animation:set-animatio-ease-base',
  props<{
    shapeIRI: string;
    key: AnimationKeys;
    ease: Easing;
    animationId: string;
  }>(),
);

export const setFrameCollection = createAction(
  'animation:set-frame-collection',
  props<{
    value: FrameCollection;
  }>(),
);

export const setAnimationsOfFileAction = createAction(
  'animation:set-animations-of-file',
  props<{
    animations: Record<string, Record<string, AnimationItem[]>>;
  }>(),
);

export const setCurrentAnimationId = createAction(
  'animation:set-current-animation-id',
  props<{
    id: string;
  }>(),
);

export const setCurrentAnimationIdBase = createAction(
  'animation:set-current-animation-id-base',
  props<{
    id: string;
  }>(),
);

export const resetCurrentAnimationId = createAction(
  'animation:reset-current-animation-id',
);

export const setCurrentAnimationIdLocal = createAction(
  'animation:set-current-animation-id-local',
  props<{
    id: string;
  }>(),
);

export const deleteAnimationItemAction = createAction(
  'animation:delete-animation-editor',
  props<{
    animationKey: AnimationKeys;
  }>(),
);

export const setMainAnimationFrame = createAction(
  'animation:set-main-animation-frame',
  props<{
    frame: AnimationFrame;
  }>(),
);

export const setMainAnimationFrameBase = createAction(
  'animation:set-main-animation-frame-base',
  props<{
    frame: AnimationFrame;
  }>(),
);

export const setAnimationFramesBySceneBase = createAction(
  'animation:set-animation-frames-by-scene-base',
  props<{
    frames: Record<string, AnimationFrame>;
  }>(),
);

export const setAnimationFrameForScene = createAction(
  'animation:set-animation-frame-for-scene',
  props<{
    frame: AnimationFrame;
    scene: string;
  }>(),
);

export const setAnimationChanged = createAction(
  'animation:set-animation-changed',
  props<{
    value: boolean;
  }>(),
);

export const setAnimationsByStart = createAction(
  'animation:set-main-animations-by-start',
  props<{
    value: AnimationByStart[];
  }>(),
);

export const setAnimations = createAction(
  'animation:set-animations-2',
  props<{
    IRI: string;
    animations: Record<string, Animation>;
  }>(),
);

export const initAnimationsOfFile = createAction(
  'animation:init-animations-of-shapes',
  props<{
    file: ResourceData;
    shapes: ResourceData[];
  }>(),
);

export const addAnimationsOfShapesToStoreBase = createAction(
  'animation:add-animations-of-shapes-to-store-base',
  props<{
    animations: Array<{
      IRI: string;
      animationsById: Record<string, __Animation>;
    }>;
  }>(),
);

export const removeAnimations = createAction(
  'animation:remove-animation',
  props<{
    key: AnimationKeys;
    IRIs: string[];
    animationId: string;
  }>(),
);

export const addAnimationItemBaseAction = createAction(
  'animation:add-animation-item-base',
  props<{
    animationId: AnimationId;
    shapeIRI: ShapeIRI;
    item: AnimationItem;
  }>(),
);

export const removeAnimationItemBaseAction = createAction(
  'animation:remove-animation-base-action',
  props<{
    animationId: AnimationId;
    shapeIRI: ShapeIRI;
    key: AnimationKeys;
  }>(),
);

export const testAnimationAction = createAction('animation:test');
