import { ResourceData } from '../../../../elements/resource/resource.types';
import {
  AnimationItem,
  AnimationKeys,
  DropShadowConfig,
  FillConfig,
  GeneralShapeDescriptor,
  SVGAttributes,
  ShapeConfig,
  StrokeConfig,
} from '../../../../elements/resource/types/shape.type';
import { GeneralShape } from '../general/general-shape';
import { SVG, SVGConfig } from './svg-element';
import { ImportedShape } from '../general/imported/imported-shape';
import { RectangleElement } from './rectangle-element';
import { ShapeService } from '../../shape.service';
import { ColorIncrementController } from '../../../animation/frame/increment/controller/color-increment.controller';
import {
  baseSVGAttributesByIRI,
  currentSVGAttributesByIRI,
  dropShadowByIRI,
} from '../../../store/selector/editor.selector';
import { omit as _omit, cloneDeep, isEqual } from 'lodash';
import { IncrementController } from '../../../animation/frame/increment/controller/increment.controller';
import { skip } from 'rxjs/operators';
import { ColorBlinkAnimation } from './primitive.types';
import { BulkUpdateItems } from '../../../store/reducer/editor.reducer';

export class PrimitiveShape<
  E extends SVG = SVG,
  T extends GeneralShapeDescriptor = GeneralShapeDescriptor,
  C extends ShapeConfig = ShapeConfig,
> extends GeneralShape<T, C> {
  element: E;
  baseLine: E;

  maskElement: E;

  strokeWidthIncrement: number;
  fillOpacityIncrement: number;
  currentFillOpacity: number;

  get elementAttributes() {
    return {};
  }
  get resolvedSVGAttributes() {
    const { fill, stroke, opacity } = this.svgAttributes;
    const { color, width, dash } = stroke
      ? {
          ...stroke,
          color: this.service.resolveColor(stroke.color),
        }
      : { color: undefined, width: undefined, dash: undefined };

    const {
      color: fillColor,
      gradient,
      gradientColor,
      gradientDirection,
    } = fill || ({} as Partial<FillConfig>);

    return {
      fill: this.service.resolveColor(fillColor),
      stroke: color,
      'stroke-width': this.service.resolveNumber(width),
      dash,
      opacity,
      gradient,
      gradientColor,
      gradientDirection,
    };
  }
  svgAttributes: SVGAttributes;
  baseSvgAttributes: SVGAttributes;
  converStroke(config: StrokeConfig) {
    const { color: stroke, width: strokeWidth } = config || {};
    return {
      stroke,
      'stroke-width': strokeWidth,
    };
  }
  finishInit(defaultSize?: boolean) {
    // -- // -- //
    super.finishInit(defaultSize);
    this.saveTranslate();
    this.saveScale(this.scale);
  }

  constructor(service: ShapeService, data: ResourceData<T>, config?: C) {
    super(service, data, config);

    this.svgAttributes = this.descriptor.svgAttributes || {};
    this.baseSvgAttributes = this.svgAttributes;
    // -- // -- //
    // subaru //

    if (this.config?.isRoot) {
      this.subscriptions.push(
        ...[
          this.store
            .select(baseSVGAttributesByIRI(this.IRI))
            .subscribe((svgAttributes: SVGAttributes) => {
              this.baseSvgAttributes = svgAttributes;
            }),
          this.store
            .select(currentSVGAttributesByIRI(this.IRI))
            // .pipe(skip(1))
            .subscribe((svgAttributes: SVGAttributes) => {
              if (Object.keys(svgAttributes || {}).length == 0) {
                return;
              }

              if (!isEqual(this.svgAttributes, svgAttributes)) {
                // this.svgAttributes = { ...this.svgAttributes, ...svgAttributes };
                this.svgAttributes = cloneDeep(svgAttributes);
                this.refreshElement();
              }
            }),
        ],
      );

      this.store.select(dropShadowByIRI(this.IRI)).subscribe(dropShadow => {
        this.initDropShadowElement(dropShadow);
      });
    }

    // if (this.descriptor.hidden) {
    //   this._hide();
    // }
  }

  get copyDescriptor() {
    return {
      ...super.copyDescriptor,
      svgAttributes: this.svgAttributes,
    };
  }

  resetBaseState(): void {
    super.resetBaseState();
    this.svgAttributes = this.baseSvgAttributes;
    this.refreshElement();
  }

  afterInit(): void {
    super.afterInit();
    if (this.dropShadow) {
      this.initDropShadowElement(this.dropShadow);
    }
    this.redraw();
    this.refreshElement();
  }

  applyAnimationByTime(time: number, directApply = false): BulkUpdateItems {
    if (time == undefined) {
      return;
    }

    let changed = false;
    const updates = super.applyAnimationByTime(time, directApply);
    Object.entries(this.consequtiveAnimationsByKey || {}).map(
      ([animationKey]) => {
        switch (animationKey) {
          case 'fill':
            const fill = this.animationService.getSVGAttributeTillTime(
              time,
              this.IRI,
              this.baseSvgAttributes[animationKey],
              animationKey,
            );
            if (directApply) {
              changed = true;
              this.svgAttributes[animationKey] = fill as FillConfig;
            } else {
              updates[animationKey] = { value: fill };
            }
            break;
          case 'stroke':
            const stroke = this.animationService.getSVGAttributeTillTime(
              time,
              this.IRI,
              this.baseSvgAttributes[animationKey],
              animationKey,
            );

            if (directApply) {
              changed = true;
              this.svgAttributes[animationKey] = stroke as StrokeConfig;
            } else {
              updates[animationKey] = { value: stroke };
            }
            break;
        }
      },
    );

    if (changed) {
      this.refreshElement();
    }

    return updates;
  }

  initDropShadowElement(config: DropShadowConfig) {}

  updateSVGValue<T extends string | number>(key: string, value: T) {
    // this[key] = value as T;
    this.patch(`svgAttributes.${key}`, value, true);
    this.attrubutesRefresh();
    this.multipliedShapesArray.map(shape => {
      shape.patch(`svgAttributes.${key}`, value, true);
      (shape as PrimitiveShape).attrubutesRefresh();
    });
  }

  transformation(
    scaleX?: number,
    scaleY?: number,
    dx = 0,
    dy = 0,
    noRefresElement = false,
  ): void {
    if (!noRefresElement) {
      this.refreshElement();
    }

    super.transformation(scaleX, scaleY, dx, dy);
  }

  updateOpacity(opacity: number) {
    this.opacity = opacity;
  }

  fillColorIncrement: ColorIncrementController;

  strokeColorIncrement: ColorIncrementController;
  strokeWidthIncrementController: IncrementController;

  colorBlinkUp: ColorIncrementController;
  colorBlinkDown: ColorIncrementController;
  blinkCnt: number;
  blinkDivision: number;
  prepareKeyValueAnimation(
    animation: AnimationItem,
    division: number,
    duration: number,
    inverse = false,
  ) {
    super.prepareKeyValueAnimation(animation, division, duration, inverse);
    const { key, value } = animation;

    // TOOD - check key casting
    switch (key as string) {
      case 'color-blink':
        // TODO - consider count as well

        const { color: color1 } = value as ColorBlinkAnimation;
        this.colorBlinkUp = new ColorIncrementController(
          this.fill?.color || '#ffffff',
          this.service.resolveColor(color1),
          division / 2,
        );
        this.colorBlinkDown = new ColorIncrementController(
          this.service.resolveColor(color1),
          this.fill?.color || '#ffffff',
          division / 2,
        );
        this.blinkDivision = division / 2;
        this.blinkCnt = 0;

        break;
      case 'fill':
        const [start, end] = [
          this.fill?.color || '#ffffff',
          typeof value == 'string' ? value : (value as FillConfig).color,
        ].map(val => this.service.resolveColor(val));
        this.fillColorIncrement = new ColorIncrementController(
          start,
          end,
          division,
        );
        break;

      case 'stroke':
        // TODO - implement

        const { stroke } = this.svgAttributes;
        const { color, width } = stroke || { color: '#ffffff', width: 0 };
        const { color: targetColor, width: targetWidth } =
          value as StrokeConfig;
        if (color !== targetColor) {
          const [start, end] = [color, targetColor].map(val =>
            this.service.resolveColor(val),
          );
          this.strokeColorIncrement = new ColorIncrementController(
            start,
            end,
            division,
          );
        }
        if (width !== targetWidth) {
          this.strokeWidthIncrementController = new IncrementController(
            width,
            targetWidth,
            division,
          );
        }
        break;
      case 'opacity':
        // TODO - implement
        break;
    }
  }

  incrementAnimation(
    increment: number,
    id?: string,
    maxIncrement?: number,
  ): void {
    super.incrementAnimation(increment, id, maxIncrement);
    // if (this.strokeWidthIncrement) {
    //   this.strokeWidth += this.strokeWidthIncrement * increment;
    //   // console.log('stroke-width', this.strokeWidth);
    //   this.refreshElement();
    // }
    if (this.fillOpacityIncrement) {
      this.currentFillOpacity += this.fillOpacityIncrement;
      this.updateFillOpacity(this.currentFillOpacity);
    }

    const updateObject = {} as Partial<SVGConfig>;

    if (this.fillColorIncrement) {
      updateObject.fill = this.fillColorIncrement.increment(increment);
    }

    if (this.strokeWidthIncrementController) {
      updateObject['stroke-width'] =
        this.strokeWidthIncrementController.increment(increment);
    }

    if (this.strokeColorIncrement) {
      updateObject.stroke = this.handleStrokeAnimation(increment);
    }

    if (Object.keys(updateObject).length) {
      this.element?.patch(updateObject);
    }
  }

  handleStrokeAnimation(increment: number) {
    return this.strokeColorIncrement.increment(increment);
  }

  incrementAnimationByKey(key: AnimationKeys, increment: number) {
    super.incrementAnimationByKey(key, increment);

    switch (key) {
      case 'color-blink':
        this.blinkCnt += increment;
        let fill: string;
        if (this.blinkCnt < this.blinkDivision) {
          fill = this.colorBlinkUp.increment(increment);
        } else {
          fill = this.colorBlinkDown.increment(increment);
        }
        this.element?.patch({ fill });

        break;
    }
  }

  hideDragControllers() {
    super.hideDragControllers();
    this.baseLine?.hide();
  }

  updateFillOpacity(currentFillOpacity: number) {
    this.svgAttributes['fill-opacity'] = currentFillOpacity;
    this.refreshElement();
  }

  endAnimationByKeyValue(key: string, value: any, inverse = false): void {
    super.endAnimationByKeyValue(key, value, inverse);
    switch (key) {
      case 'r':
        this.strokeWidthIncrement = value as number;
        this.refreshElement();
        break;
      case 'stroke':
        this.strokeWidthIncrementController = null;
        this.strokeColorIncrement = null;
        this.svgAttributes.stroke = cloneDeep(value);
        break;
      case 'fill':
        this.fillColorIncrement = null;
        break;
    }
  }

  // patch(key: string, value: any, norefresh?: boolean): void {
  //   super.patch(key, value, norefresh);
  //   if (key?.startsWith('svgAttr')) {
  //     key = key.split('.')[1];
  //     if (typeof value === 'string' && value.startsWith('#')) {
  //       value = this.convertHexToNumber(value as string);
  //     }
  //     this.setSVGAttribute(key, value);
  //   }
  // }

  // setSVGAttribute(key: string, value: string | number) {
  //   this.element.patch({ [key]: value });
  // }

  refresh(): void {
    this.refreshElement();
  }

  refreshElement() {
    // TODO - check the terminology // -- //
    this.element?.patch({
      ...this.elementAttributes,
      ...this.resolvedSVGAttributes,
    });
  }

  attrubutesRefresh() {
    this.element.patch(this.resolvedSVGAttributes);
  }
}
