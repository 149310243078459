import {
  Coords,
  DragConstraintInstance,
  PathShapeDescriptor,
} from '../../../../../elements/resource/types/shape.type';
import { PathShape, PathShapeConfig } from '../path-shape';
import { ArcItem, CurveItem, PathItem } from '../path-shape.types';
import { selectArmShapeConfig } from '../../../../store/selector/editor.selector';
import { ShapeService } from '../../../shape.service';
import { ResourceData } from '../../../../../elements/resource/resource.types';
import { PathElement } from '../../primitive/path-element';
import { BaseVector, Vector } from '../../../../../elements/base/vector/vector';
import { PointController } from '../../../../../elements/util/point-controller/point-controller';
import { cloneDeep, isEqual } from 'lodash';
import { filter } from 'rxjs/operators';
import { IncrementController } from '../../../../animation/frame/increment/controller/increment.controller';
import { ArcSection } from '../path-sections/arc/arc-section';
import { inv } from 'mathjs';
import { BulkUpdateItems } from '../../../../store/reducer/editor.reducer';
import { setDescriptorValue } from '../../../../store/editor.crud.actions';

export interface ArmShapeConfig {
  flat1?: boolean;
  flat2?: boolean;
  r1: number;
  r2: number;
  flat?: boolean;
  d1?: number;
  d2?: number;
  x?: number;
  y?: number;
  strokeColor?: string;
  strokeWidth?: number;
  start?: { start: number; end: number };
  top?: { start: number; end: number };
  bottom?: { start: number; end: number };
  end?: { start: number; end: number };
  center?: { start: number; end: number };
  fixedLength?: boolean;
}

export interface ArmShapeDescriptor extends PathShapeDescriptor {
  config: ArmShapeConfig;
}

const { PI, sin, cos, asin, pow, sqrt } = Math;

export class ArmShapeNext extends PathShape<ArmShapeDescriptor> {
  get flat1() {
    return this.armConfig?.flat1;
  }
  get flat2() {
    return this.armConfig?.flat2;
  }

  get d1() {
    return this.armConfig?.d1 || 0;
  }
  get d2() {
    return this.armConfig?.d2 || 0;
  }
  get fixedLength() {
    return this.armConfig.fixedLength;
  }
  get r1() {
    return this.armConfig?.r1;
  }

  set r1(val: number) {
    this.armConfig.r1 = val;
  }

  get r2() {
    return this.armConfig?.r2;
  }

  set r2(val: number) {
    this.armConfig.r2 = val;
  }

  get X() {
    return this.armConfig?.x;
  }

  set X(val: number) {
    this.armConfig.x = val;
  }

  get Y() {
    return this.armConfig?.y;
  }

  set Y(val: number) {
    this.armConfig.y = val;
  }

  getType(): string {
    return 'arm-shape';
  }

  get copyDescriptor() {
    return {
      ...super.copyDescriptor,
      type: 'arm-shape',
      config: cloneDeep(this.armConfig),
    };
  }

  armConfig: ArmShapeConfig;
  originalConfig: ArmShapeConfig;

  get pcs() {
    return [this.pc, this.endPc, this.r1Pc, this.r2Pc];
  }
  constructor(
    service: ShapeService,
    data: ResourceData<ArmShapeDescriptor>,
    config: PathShapeConfig,
  ) {
    super(service, data, config);

    this.strokeElements = [];

    this.armConfig = cloneDeep(this.descriptor.config);
    if (config.isRoot) {
      this.store
        .select(selectArmShapeConfig(this.IRI))
        .pipe(filter(val => !!val))
        .subscribe(config => {
          if (!isEqual(this.armConfig, config)) {
            this.armConfig = cloneDeep(config);
            // console.log('arm-config', this.armConfig);
            this.refreshElement();
          }
        });
    }
  }

  saveConfig() {
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'config',
        value: cloneDeep(this.armConfig),
      }),
    );
  }

  arc1Start: Coords;
  arc1StartAngle: number;
  arc1End: Coords;
  arc1EndAngle: number;

  arc2Start: Coords;
  arc2End: Coords;
  arc2StartAngle: number;
  arc2EndAngle: number;

  pc: PointController;
  endPc: PointController;

  r1Pc: PointController;
  r2Pc: PointController;

  centerStroke: PathElement;
  topStroke: PathElement;
  bottomStroke: PathElement;
  startStroke: PathElement;
  endStroke: PathElement;

  afterInit() {
    this.armConfig = cloneDeep(this.descriptor.config);
    this.originalConfig = cloneDeep(this.descriptor.config);
    super.afterInit();

    if (this.config?.isRoot) {
      this.pc = new PointController(
        this,
        {
          p: [0, 0],
          controlPoint: {
            shapeIRI: this.IRI,
            name: 'start',
          },
        },
        this.circleContainer,
        this.auxCircleContainer,
      );

      this.r1Pc = new PointController(
        this,
        {
          p: [0, 0],
          d: this.r1,
          angle: 0,
          start: () => {
            // this.startBaseDrag();
            console.log('start-rc-drag');
          },
          drag: ({ d }) => {
            this.r1 = d;
            this.refreshElement();
          },
          end: () => {
            this.saveConfig();
          },
        },
        this.circleContainer,
        this.auxCircleContainer,
      );

      this.r2Pc = new PointController(
        this,
        {
          p: [this.X, this.Y],
          d: this.r2,
          angle: 0,
          start: () => {
            // this.startBaseDrag();
          },
          drag: ({ d }) => {
            this.r2 = d;
            this.refreshElement();
          },
          end: () => {
            this.saveConfig();
          },
        },
        this.circleContainer,
        this.auxCircleContainer,
      );
      // this.turnPC.hide(); //

      this.endPc = new PointController(
        this,
        {
          // d: sqrt(pow(this.X, 2) + pow(this.Y, 2)),
          // angle: new BaseVector([this.X, this.Y]).getAngle(),
          fix: this.fixedLength ? 'length' : undefined,
          p: [this.X, this.Y],
          // rotation: new BaseVector([this.X, this.Y]).getAngle(),
          controlPoint: {
            shapeIRI: this.IRI,
            name: 'end',
          },
        },
        this.circleContainer,
        this.auxCircleContainer,
      );

      this.pcs.map(pc => pc.hide());
    }
  }

  applyAnimationByTime(time: number, directApply = false): BulkUpdateItems {
    if (time == undefined) {
      return;
    }

    let changed = false;
    const updates = super.applyAnimationByTime(time, directApply);
    Object.entries(this.consequtiveAnimationsByKey || {}).map(
      ([animationKey]) => {
        switch (animationKey) {
          case 'config':
            const config =
              this.animationService.getShapeAttributeTillTime<ArmShapeConfig>(
                time,
                this.IRI,
                this.originalConfig,
                'config',
              );
            if (directApply) {
              changed = true;
              this.armConfig = config;
            }
            updates.config = { value: config };
            break;
        }
      },
    );

    if (changed) {
      this.refreshElement();
    }

    return updates;
  }

  getAbsCoordsOfControlPoint(pointId: string) {
    switch (pointId) {
      case 'start':
        return [this.x, this.y];
      case 'end':
        return [this.x + this.X, this.y + this.Y];
    }
    return super.getAbsCoordsOfControlPoint(pointId);
  }

  d1StartVector: Vector;
  d1EndVector: Vector;
  d2StartVector: Vector;
  d2EndVector: Vector;

  calcAngles() {
    this.endPc?.patch({
      fix: this.fixedLength ? 'length' : undefined,
      p: [this.X, this.Y],
    });

    const vector = new BaseVector([this.X, this.Y]);
    const length = vector.length;
    const diffAngle = asin((this.r2 - this.r1) / length);
    vector.reScale(this.r1);

    // POINT 1
    if (this.flat1) {
      vector.rotate(PI / 2);
    } else {
      vector.rotate(PI / 2 + diffAngle + this.d2);
    }

    // -- // -- // -- // -- //

    if (this.d2) {
      this.d2EndVector = new Vector(
        {
          x: vector.x,
          y: vector.y,
        },
        {
          x: 2 * vector.x,
          y: 2 * vector.y,
        },
      );
      this.d2EndVector.reScale(length / 3);
      if (this.flat1) {
        this.d2EndVector.rotate(-PI / 2 + this.d1);
      } else {
        // If not fle
        this.d2EndVector.rotate(-PI / 2);
      }
    }

    this.arc1StartAngle = vector.getAngle();
    this.arc1Start = vector.coords;

    // POINT 2
    if (this.flat1) {
      vector.rotate(PI);
    } else {
      vector.rotate(PI - 2 * diffAngle - this.d1 - this.d2);
    }

    if (this.d1) {
      this.d1StartVector = new Vector(
        {
          x: vector.x,
          y: vector.y,
        },
        {
          x: 2 * vector.x,
          y: 2 * vector.y,
        },
      );
      this.d1StartVector.reScale(length / 3);
      if (this.flat1) {
        this.d1StartVector.rotate(PI / 2 - this.d1);
      } else {
        this.d1StartVector.rotate(PI / 2);
      }
    }

    this.arc1End = vector.coords;
    this.arc1EndAngle = vector.getAngle();

    this.r1Pc?.patch({
      angle: this.arc1EndAngle,
      d: this.r1,
    });

    let vector2 = new Vector({ x: this.X, y: this.Y }, { x: 0, y: 0 }).reScale(
      this.r2,
    );

    // POINT 3
    if (this.flat2) {
      vector2.rotate(PI / 2);
    } else {
      vector2.rotate(PI / 2 - diffAngle + this.d1);
    }

    if (this.d1) {
      this.d1EndVector = new Vector(
        {
          x: vector2.x,
          y: vector2.y,
        },
        {
          x: 2 * vector2.x,
          y: 2 * vector2.y,
        },
      );
      this.d1EndVector.reScale(length / 3);
      if (this.flat2) {
        this.d1EndVector.rotate(-PI / 2 + this.d1);
      } else {
        this.d1EndVector.rotate(-PI / 2);
      }
    }

    this.arc2Start = vector2.coords;
    this.arc2StartAngle = vector2.getAngle();
    this.r2Pc?.patch({
      p: [this.X, this.Y],
      angle: this.arc2StartAngle,
      d: this.r2,
    });

    // POINT 4
    if (this.flat2) {
      vector2.rotate(PI);
    } else {
      vector2 = new Vector({ x: this.X, y: this.Y }, { x: 0, y: 0 }).reScale(
        this.r2,
      );
      vector2.rotate(-PI / 2 + diffAngle - this.d2);
    }

    if (this.d2) {
      this.d2StartVector = new Vector(
        {
          x: vector2.x,
          y: vector2.y,
        },
        {
          x: 2 * vector2.x,
          y: 2 * vector2.y,
        },
      );
      this.d2StartVector.reScale(length / 3);
      if (this.flat2) {
        this.d2StartVector.rotate(PI / 2 - this.d2);
      } else {
        this.d2StartVector.rotate(PI / 2);
      }
    }

    // vector2.rotate(PI);
    this.arc2End = vector2.coords;
    this.arc2EndAngle = vector2.getAngle();
  }

  get startElement() {
    return this.flat1
      ? ({
          type: 'line',
          offset: this.arc1Start,
          // x: this.arc1End[0] - this.arc1Start[0],
          // y: this.arc1End[1] - this.arc1Start[1],
          X: this.arc1End[0],
          Y: this.arc1End[1],
        } as PathItem)
      : ({
          type: 'arc',
          a1: this.arc1StartAngle,
          a2: this.arc1EndAngle,
          offset: this.arc1Start,
          CX: 0,
          CY: 0,
          X: this.arc1End[0],
          Y: this.arc1End[1],
          r: this.r1,
        } as ArcItem);
  }

  get topElement() {
    return this.d1
      ? ({
          type: 'curve',
          bx: this.d1StartVector.x,
          by: this.d1StartVector.y,
          cx: this.d1EndVector.x,
          cy: this.d1EndVector.y,
          X: this.arc2Start[0],
          Y: this.arc2Start[1],
        } as CurveItem)
      : ({
          type: 'line',
          X: this.arc2Start[0],
          Y: this.arc2Start[1],
        } as PathItem);
  }

  get endElement() {
    return this.flat2
      ? ({
          type: 'line',
          x: this.arc2End[0] - this.arc2Start[0],
          y: this.arc2End[1] - this.arc2Start[1],
        } as PathItem)
      : ({
          type: 'arc',
          a1: this.arc2StartAngle,
          a2: this.arc2EndAngle,
          CX: this.X,
          CY: this.Y,
          x: this.arc2End[0] - this.arc2Start[0],
          y: this.arc2End[1] - this.arc2Start[1],
          r: this.r2,
        } as ArcItem);
  }

  get bottomElement() {
    return this.d2
      ? ({
          type: 'curve',
          bx: this.d2StartVector.x,
          by: this.d2StartVector.y,
          cx: this.d2EndVector.x,
          cy: this.d2EndVector.y,
          X: this.arc1Start[0],
          Y: this.arc1Start[1],
        } as CurveItem)
      : ({
          type: 'line',
          X: this.arc1Start[0],
          Y: this.arc1Start[1],
        } as PathItem);
  }

  get _elements(): PathItem[] {
    // -- //
    this.calcAngles();

    return [
      this.startElement,
      this.topElement,
      this.endElement,
      this.bottomElement,
    ];
  }

  cxIncrement: IncrementController;
  cyIncrement: IncrementController;

  lastConfig: ArmShapeConfig;
  startAnimation(
    id: string,
    division: number,
    inverse?: boolean,
    duration?: number,
  ): void {
    super.startAnimation(id, division, inverse, duration);
    this.getAnimationsById(id).map(({ key, value }) => {
      switch (key) {
        case 'config':
          const { x, y } = value as ArmShapeConfig;
          if (inverse) {
            const { x: xl, y: yl } = this.lastConfig;
            this.cxIncrement = new IncrementController(x, xl, division);
            this.cyIncrement = new IncrementController(y, yl, division);
          } else {
            this.lastConfig = cloneDeep(this.armConfig);
            this.cxIncrement = new IncrementController(this.X, x, division);
            this.cyIncrement = new IncrementController(this.Y, y, division);
          }
          break;
      }
    });
  }

  incrementAnimation(increment: number, id?: string, percent?: number): void {
    super.incrementAnimation(increment, id, percent);
    this.getAnimationsById(id).map(({ key }) => {
      switch (key) {
        case 'config':
          if (this.cxIncrement && this.cyIncrement) {
            this.X = this.cxIncrement.increment(increment);
            this.Y = this.cyIncrement.increment(increment);
          }
          this.refreshElement();
          break;
      }
    });
  }

  endAnimation(id: string, inverse?: boolean): void {
    super.endAnimation(id, inverse);
    this.getAnimationsById(id).map(({ key }) => {
      switch (key) {
        case 'config':
          this.X = this.cxIncrement.end;
          this.Y = this.cyIncrement.end;
          this.cxIncrement = null;
          this.cyIncrement = null;
          this.refreshElement();
          break;
      }
    });
  }

  hoverSelect() {
    super.hoverSelect();
    this.rc?.show();
    this.endPc?.show();
  }

  completeSelect() {
    this.showDragControllers();
  }

  baseConfigs: Coords[];

  firstSectionDrag = false;
  dragSectionStartIndex: number;
  dragSectionEndIndex: number;

  startDragBy(
    key: string,
    shapeStore?: Record<string, boolean>,
  ): DragConstraintInstance[] {
    shapeStore ||= {};
    shapeStore[this.IRI] = true;

    const furtherConstraints = this.getConstraintsByPoint(key, shapeStore);
    if (key == 'start') {
      furtherConstraints.push(...this.getConstraintsByPoint('end', shapeStore));
    }

    switch (key) {
      case 'start':
        this.dragBase = [this.x, this.y];
        return [
          {
            shape: this,
            point: key,
          },
          ...furtherConstraints,
        ];
      case 'end':
        this.dragBase = [this.X, this.Y];
        return [
          {
            shape: this,
            point: key,
            limit: this.fixedLength
              ? {
                  x: this.X,
                  y: this.Y,
                  l: sqrt(pow(this.X, 2) + pow(this.Y, 2)),
                  external: true,
                }
              : undefined,
          },
          ...furtherConstraints,
        ];
    }
    return super.startDragBy(key, shapeStore);
  }

  dragBy(point: string, [dx, dy]: Coords) {
    const [x, y] = this.dragBase;
    switch (point) {
      case 'start':
        this.applyTranslate({
          x: x + dx,
          y: y + dy,
        });
        this.refreshElement();
        break;
      case 'end':
        this.X = x + dx;
        this.Y = y + dy;

        this.refreshElement();
        break;
    }
    super.dragBy(point, [dx, dy]);
  }

  endDragBy(key: string, [dx, dy]: Coords) {
    switch (key) {
      case 'start':
        this.pc.patch({ p: [0, 0] });
        this.saveTranslate(dx, dy);
        break;
      case 'end':
        this.endPc.patch({
          p: [this.X, this.Y],
        });
        this.saveConfig();
        break;
    }
  }

  _drag(x: number, y: number, dx: number, dy: number) {
    // this.constrainedShapes.map(shape => shape._drag(x, y, dx, dy));
    // if (this.dragSectionStartIndex == undefined) {
    //   this.firstHandSection.pc.patch({
    //     p: [0, 0],
    //   });
    //   this.drag(dx, dy);
    //   return;
    // }
    // if (this.firstSectionDrag) {
    //   this.drag(dx, dy);
    // }
    // this.handSections
    //   .slice(this.dragSectionStartIndex, this.dragSectionEndIndex + 1)
    //   .map((hs, i) => {
    //     const [x, y] = this.baseConfigs[i];
    //     hs.x = x + (this.firstSectionDrag ? -dx : dx);
    //     hs.y = y + (this.firstSectionDrag ? -dy : dy);
    //     hs.refreshPC();
    //   });
    // this.refreshElement();
  }

  _endDrag() {
    this.endDrag();
    // if (this.dragSectionStartIndex == undefined) {
    //   this.endDrag();
    // }
    this.firstSectionDrag = false;
    this.dragSectionStartIndex = undefined;
    this.dragSectionEndIndex = undefined;
    this.saveSections();
  }

  strokeElement: PathElement;
  strokeElements: PathElement[] = [];

  showDragControllers(): void {
    // DragPC can be removed
    this.pcs.map(pc => pc.show());
  }

  hideDragControllers(): void {
    this.pcs.map(pc => pc.hide());
  }

  dragOntoHandler() {
    // -- // -- // -- //
  }

  refreshElement(start?: number, end?: number): void {
    // -- // -- // -- //
    super.refreshElement();
    const strokeConfig = {
      stroke: this.armConfig?.strokeColor || '#000000',
      'stroke-width': this.armConfig?.strokeWidth || 1,
    };

    if (this.armConfig.center) {
      const { start, end } = this.armConfig.center;

      const vector = new BaseVector([this.X, this.Y]);
      vector.scale(start);
      const position = {
        x: vector.x,
        y: vector.y,
      };

      const vector1 = new BaseVector([this.X, this.Y]);
      vector1.scale(end);
      this.centerStroke ||= new PathElement(this, this.container, {});
      this.centerStroke.patch({
        position,
        x: vector1.x,
        y: vector1.y,
        ...strokeConfig,
      });
    } else {
      this.centerStroke?.remove();
      this.centerStroke = null;
    }
    if (this.armConfig.top) {
      // TODO - implement interval
      const { start, end } = this.armConfig.top;

      const v = new Vector(
        {
          x: this.arc1End[0],
          y: this.arc1End[1],
        },
        {
          x: this.arc2Start[0],
          y: this.arc2Start[1],
        },
      );

      if (start !== 0) {
        v.scale(start);
      }

      this.topStroke ||= new PathElement(this, this.container, {});
      this.topStroke.patch({
        position: {
          x: v.end.x,
          y: v.end.y,
        },
        elements: [this.topElement],
        ...strokeConfig,
      });
    } else {
      this.topStroke?.remove();
      this.topStroke = null;
    }

    if (this.armConfig.bottom) {
      // TODO - implement interval
      // const { start, end } = this.armConfig.bottom;
      // this.bottomStroke = new PathElement(this, this.container, {});

      this.bottomStroke ||= new PathElement(this, this.container, {});

      this.bottomStroke.patch({
        position: {
          x: this.arc2End[0],
          y: this.arc2End[1],
        },
        elements: [this.bottomElement],
        ...strokeConfig,
      });
    } else {
      this.bottomStroke?.remove();
      this.bottomStroke = null;
    }

    if (this.armConfig.end) {
      // TODO - implement interval
      // const { start, end } = this.armConfig.end;

      this.endStroke ||= new PathElement(this, this.container, {});
      this.endStroke.patch({
        position: {
          x: this.arc2Start[0],
          y: this.arc2Start[1],
        },
        elements: [this.endElement],
        ...strokeConfig,
      });
    } else {
      this.endStroke?.remove();
      this.endStroke = null;
    }

    if (this.armConfig.start) {
      // TODO - implement interval
      const { start, end } = this.armConfig.start;
      const { a1, a2 } = this.startElement as ArcSection;

      this.startStroke ||= new PathElement(this, this.container, {});

      try {
        this.startStroke.patch({
          position: {
            x: this.arc1Start[0],
            y: this.arc1Start[1],
          },
          elements: [
            {
              ...this.startElement,
              a1,
              a2: a1 + (a2 - a1) * end,
            } as ArcItem,
          ],
          ...strokeConfig,
        });
      } catch (e) {
        console.log('--- error ---', e);
      }
    } else {
      this.startStroke?.remove();
      this.startStroke = null;
    }
  }

  refresh() {
    // -- // -- //
  }

  __elements: PathItem[];

  _getElements(start?: number, end?: number) {
    return this._elements;
  }

  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }
    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return PI - angle;
    }
    if (x < 0 && y < 0) {
      return PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? PI / 2 : (3 * PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : PI;
    }
  }
}
