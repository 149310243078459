import { createAction, props } from '@ngrx/store';
import { ResourceData } from '../../elements/resource/resource.types';
import {
  AnimationInnerKey,
  AnimationKeys,
  BBox,
  GeneralShapeDescriptor,
  Translate,
  LiteralValue,
  SVGAttributes,
  ShapeIRI,
  ShapeTransform,
  AnimationValue,
  CanvasOrientation,
  Scene,
  ControlPointDefinition,
} from '../../elements/resource/types/shape.type';
import { BulkUpdates, CanvasPosition } from './reducer/editor.reducer';

export const EditorFeature = 'editor';

function createSetterAction<T>(name: string) {
  return createAction(EditorFeature + ':' + name, props<{ value: T }>());
}

export const setFileChanged = createSetterAction<boolean>('set-file-changed');

export const saveSceneRequestComplete = createAction(
  'editor:save-scene-request-complete',
);

export const setComponents = createAction(
  'editor:set-components',
  props<{
    components: Record<string, ResourceData>;
  }>(),
);

export const setCurrentFile = createAction(
  'editor:set-current-file',
  props<{
    file: ResourceData;
  }>(),
);

export const addFile = createAction(
  'editor:add-file',
  props<{
    file: ResourceData;
  }>(),
);

export const setFiles = createAction(
  'editor:set-files',
  props<{ files: Record<string, ResourceData> }>(),
);

export const createNewScene = createAction(
  'editor:save-scene-request',
  props<{
    scene: string;
    fileID: string;
    index: number;
  }>(),
);
export const assignSceneToFile = createAction(
  'editor:assign-scene-to-file',
  props<{
    mainFile: string;
    fileID: string;
    index: number;
  }>(),
);

export const saveSceneRequest = createAction(
  'editor:save-scene-request',
  props<{
    scene: string;
  }>(),
);

export const setLiteralValue = createAction(
  'editor:set-literal-value',
  props<{
    IRI: string;
    key: string;
    value: LiteralValue;
  }>(),
);
export const bulkUpdateFinish = createAction('editor:shape-update-finish');
export const clearBulkUpdates = createAction('editor:clear-bulk-updates');
export const setBulkUpdateItem = createAction(
  'editor:bulk-shape-update',
  props<{
    item?: {
      IRI: string;
      key: AnimationKeys;
      value: AnimationValue;
      innerKey?: AnimationInnerKey;
      base?: boolean;
    };
    items?: Array<{
      IRI: string;
      key: AnimationKeys;
      value: AnimationValue;
      innerKey?: AnimationInnerKey;
      base?: boolean;
    }>;
  }>(),
);

export const applyBulkShapeUpdates = createAction(
  'editor:bulk-shape-update-base',
  props<{
    data: BulkUpdates;
  }>(),
);

export const removeSceneRequest = createAction(
  'editor:remove-scene-request',
  props<{
    scene: string;
  }>(),
);

export const removeAllScenesRequest = createAction(
  'editor:remove-all-scene-request',
);

export const setCanvasBackgroundColor = createAction(
  'editor:set-canvas-background-color',
  props<{ color: string }>(),
);

export const setCanvasOrientation = createAction(
  'editor:set-canvas-orientation',
  props<{ value: CanvasOrientation }>(),
);

export const addFileAction = createAction(
  'editor:add-file',
  props<{ name: string }>(),
);

export const setIfAttributes = createAction(
  'editor:set-if-attribute',
  props<{
    key: string;
    value: boolean;
  }>(),
);

export const setLoadedScenes = createAction(
  'editor:set-loaded-scenes',
  props<{
    value: Record<string, boolean>;
  }>(),
);

export const addLoadedScene = createAction(
  'editor:add-loaded-scenes',
  props<{
    key: string;
  }>(),
);

export const loadFileAction = createAction(
  'editor:load-file',
  props<{
    ID: string;
    mode: 'main-file' | 'imported-shape' | 'subscene-load';
  }>(),
);

export const loadFileRequestAction = createAction(
  'editor:load-file-request',
  props<{
    ID: string;
    subSceneLoad?: boolean;
  }>(),
);

export const setSubsceneLoaded = createAction(
  'editor:set-sub-scene-loaded',
  props<{
    value: boolean;
  }>(),
);

export const resetBaseState = createAction('editor:reset-base-state');
export const setAttributeByState = createAction(
  'editor:set-attribute-state',
  props<{
    IRIs: string[];
    state: string;
    key?: AnimationKeys;
    innerKey?: AnimationInnerKey;
    value: AnimationValue;
    keys?: string[];
  }>(),
);

export const setShowComponentSearch = createAction(
  'editor:set-show-component-search',
  props<{
    value: boolean;
  }>(),
);

export const setFileLoading = createSetterAction<boolean>('set-file-loading');

export const setEditorFile = createAction(
  'editor:set-show-component-search',
  props<{
    value: boolean;
  }>(),
);
export const saveChangedShapes = createAction(
  'editor:save-changed-shape',
  props<{
    IRI?: ShapeIRI;
    IRIs?: ShapeIRI[];
  }>(),
);

export const setShapeParent = createAction(
  'editor:set-shape-parent',
  props<{
    IRI: ShapeIRI;
    fileIRI: string;
  }>(),
);

export const setcompleteShapeDescriptor = createAction(
  'editor:set-complete-shape-descriptor',
  props<{
    IRI: string;
    descriptor: GeneralShapeDescriptor;
  }>(),
);

export const setBaseShapeTransform = createAction(
  'editor:set-base-shape-transform',
  props<{
    IRI: ShapeIRI;
    transform: Partial<ShapeTransform>;
  }>(),
);

export const setShapeOriginalTransform = createAction(
  'editor:set-original-shape-transform',
  props<{
    IRI: ShapeIRI;
    transform: Partial<ShapeTransform>;
  }>(),
);

export const setShapeTranslateBase = createAction(
  'editor:set-shape-translate-base',
  props<{
    IRI: ShapeIRI;
    translate: Translate;
  }>(),
);

export const setCurrentShapeTranslate = createAction(
  'editor:set-current-shape-translate',
  props<{
    IRI: ShapeIRI;
    translate: Translate;
  }>(),
);

export const saveResourcesRequestAction = createAction(
  'editor:save-shapes-request',
  props<{
    posts: ResourceData[];
    patches: ResourceData[];
    deletes: ResourceData[];
  }>(),
);

export const saveResourcesAction = createAction(
  'editor:save-resources-request',
);

export const setCanvasShapes = createAction(
  'editor:set-canvas-shapes',
  props<{
    shapes: ResourceData[];
    merge?: boolean;
  }>(),
);

export const setShapes = createAction(
  'editor:set-shapes',
  props<{
    shapes: Record<string, ResourceData>;
  }>(),
);

export const setShape = createAction(
  'editor:set-shape',
  props<{
    shape: ResourceData;
  }>(),
);

export const setShapeDescriptors = createAction(
  'editor:set-shape-descriptors',
  props<{
    shapes: ResourceData[];
  }>(),
);

export const addShapesAction = createAction(
  'editor:add-shapes-action',
  props<{
    file: ResourceData;
    merge?: boolean;
  }>(),
);

export const resetPatchedShapes = createAction('editor:reset-patched-shapes');

export const cleanFile = createAction('editor:clean-file');

export const setSelectedShapes = createAction(
  'editor:set-selected-shapes',
  props<{ shapes: Record<string, boolean> }>(),
);

export const selectShape = createAction(
  'editor:select-shape',
  props<{ IRI: string; shift?: boolean }>(),
);

export const deselectShape = createAction(
  'editor:deselect-shape',
  props<{ IRI: string }>(),
);

export const selectAllShapes = createAction('editor:select-all-shapes');
export const deselectAllShapes = createAction('editor:deselect-all-shapes');

export const setOriginalSVGAttributes = createAction(
  'editor:set-original-svg-attributes',
  props<{
    IRI: string;
    svgAttributes: SVGAttributes;
  }>(),
);

export const saveSubScenePosition = createAction(
  'editor:save-sub-scene-position',
  props<{
    scene: string;
    subScene: string;
    position: {
      x: number;
      y: number;
      scale: {
        x: number;
        y: number;
      };
    };
  }>(),
);
export const saveScenePosition = createAction(
  'editor:save-scene-position',
  props<{
    scene: string;
    position: {
      x: number;
      y: number;
      scale: {
        x: number;
        y: number;
      };
    };
  }>(),
);

export const updateDescriptorOfShapeBaseAction = createAction(
  'editor:update-descriptor-of-shape-base-action',
  props<{
    IRI: string;
    descriptor?: Partial<GeneralShapeDescriptor>;
    original?: boolean;
  }>(),
);

export const updateCurrentDescriptorBaseAction = createAction(
  'editor:update-current-descriptor-of-shape-base-action',
  props<{
    IRI: string;
    descriptor?: Partial<GeneralShapeDescriptor>;
  }>(),
);

export const setShapeToBeSaved = createAction(
  'editor:set-shape-to-be-saved',
  props<{ IRI: string }>(),
);

export const setCurrentSVGAttributesOfShape = createAction(
  'editor:set-current-svg-attribute-of-shape',
  props<{ shapeIRI: string; svgAttributes: SVGAttributes }>(),
);

export const setSVGAttributesOfShape = createAction(
  'editor:set-svg-attribute-of-shape',
  props<{ shapeIRI: string; svgAttributes: SVGAttributes }>(),
);

export const setSVGSubAttribute = createAction(
  'editor:set-svg-sub-attribute',
  props<{
    IRI?: string;
    IRIs?: string[];
    key: keyof SVGAttributes;
    innerKey: AnimationInnerKey;
    value: string | number | boolean;
  }>(),
);

export const setCurrentSVGSubAttribute = createAction(
  'editor:set-svg-current-sub-attribute',
  props<{
    IRI: string;
    key: keyof SVGAttributes;
    innerKey: AnimationInnerKey;
    value: string | number | boolean;
  }>(),
);

export const setCurrentScenes = createAction(
  'editor:set-current-scenes',
  props<{
    scenes: string[];
  }>(),
);

export const selectSubScene = createAction(
  'editor:select-sub-scene',
  props<{
    name: string;
  }>(),
);

export const selectSubSceneBase = createAction(
  'editor:select-sub-scene-base',
  props<{
    name: string;
  }>(),
);

export const setCanvasPosition = createAction(
  'editor:set-canvas-position',
  props<{ position: CanvasPosition }>(),
);

export const setCurrentScene = createAction(
  'editor:set-current-scene',
  props<{
    scene: string;
  }>(),
);

export const addNewSceneBase = createAction(
  'editor:add-new-scene-base',
  props<{
    name: string;
  }>(),
);

export const addSubScene = createAction(
  'editor:add-sub-scene',
  props<{
    parentIndex: number;
    name: string;
    index: number;
  }>(),
);

export const openFile = createAction(
  'editor:open-file',
  props<{
    ID?: string;
    IRI?: string;
    importedShape?: boolean;
  }>(),
);

export const deleteComponent = createAction(
  'editor:delete-component',
  props<{
    IRI?: string;
  }>(),
);

export const addFileToStore = createAction(
  'editor:add-file-to-store',
  props<{
    file: ResourceData;
    shapes: Record<string, ResourceData>;
  }>(),
);

export const setSubSceneName = createAction(
  'editor:set-sub-scene-name',
  props<{
    parentIndex: number;
    index: number;
    name: string;
  }>(),
);

export const setScenes = createAction(
  'editor:set-scenes',
  props<{
    scenes: Scene[];
  }>(),
);

export const setSubScenePosition = createAction(
  'editor:add-sub-scene',
  props<{
    parentIndex: number;
    name: string;
    index: number;
  }>(),
);

export const setTransformSubAttribute = createAction(
  'editor:set-transform-sub-attribute',
  props<{
    IRI?: string;
    IRIs?: string[];
    key: keyof ShapeTransform;
    innerKey: 'x' | 'y' | 'relative';
    value: number | boolean;
  }>(),
);

export const setDescriptorSubAttribute = createAction(
  'editor:set-descriptor-sub-attribute',
  props<{
    shapeIRI: string;
    key: 'sections' | 'index' | 'label' | 'opacity';
    innerKey?: AnimationInnerKey;
    value: string | number | boolean;
  }>(),
);

export const setCurrentDescriptorSubAttribute = createAction(
  'editor:set-current-descriptor-sub-attribute',
  props<{
    shapeIRI: string;
    key: 'sections' | 'index' | 'label' | 'opacity';
    innerKey?: AnimationInnerKey;
    value: string | number | boolean;
  }>(),
);

export const setPatchLoading = createAction(
  'editor:set-patch-loading',
  props<{
    value: boolean;
  }>(),
);

export const setProjectLoaded =
  createSetterAction<boolean>('set-project-loaded');

export const addNewState = createAction(
  'editor:add-new-state',
  props<{
    name: string;
    index?: number;
  }>(),
);

export const addToFileVisitStack = createAction(
  'editor:add-to-visit-stack',
  props<{
    ID: string;
  }>(),
);

export const popVisitStack = createAction('editor:pop-visit-stack');
export const moveBack = createAction('editor:move-back-on-stack');
export const removeState = createAction(
  'editor:remove-state',
  props<{
    name: string;
  }>(),
);

export const setCurrentState = createAction(
  'editor:set-current-state',
  props<{
    name: string;
  }>(),
);

export const addNewShapeAction = createAction(
  'editor:add-new-shape',
  props<{
    data: ResourceData;
  }>(),
);

export const setNewShapeAction = createAction(
  'editor:set-new-shape',
  props<{
    data: ResourceData;
  }>(),
);

export const setShapeLabel = createAction(
  'editor:set-shape-label',
  props<{ IRI: string; label: string }>(),
);

export const toggleSelectAction = createAction(
  'editor:toggle-select-action',
  props<{ IRI: string }>(),
);

export const setShapeDescriptor = createAction(
  'editor:set-descriptor',
  props<{ IRI: string; descriptor: GeneralShapeDescriptor }>(),
);

export const setCurrentDescriptor = createAction(
  'editor:set-current-descriptor',
  props<{ IRI: string; descriptor: GeneralShapeDescriptor }>(),
);

export const setOriginalDescriptor = createAction(
  'editor:set-original-descriptor',
  props<{ IRI: string; descriptor: GeneralShapeDescriptor }>(),
);

export const addShapeToStore = createAction(
  'editor:add-shape-to-store',
  props<{ data: ResourceData<GeneralShapeDescriptor> }>(),
);

export const setResourcePatch = createAction(
  'editor:set-shape-resource-patch',
  props<{
    IRI?: string;
    IRIs?: string[];
    resourceType: 'nw:Shape' | 'nw:File';
    field: 'literals' | 'relationships';
    key: string;
  }>(),
);

export const setResourcePost = createAction(
  'editor:set-shape-resource-post',
  props<{
    IRI?: string;
    IRIs?: string[];
    resourceType: 'nw:Shape' | 'nw:File';
  }>(),
);

export const setResourceDelete = createAction(
  'editor:set-resource-delete',
  props<{
    IRI?: string;
    IRIs?: string[];
    resourceType: 'nw:Shape' | 'nw:File';
  }>(),
);

export const saveFileRequestComplete = createAction(
  'editor:save-file-request-complete',
);

export const setBBoxOfShape = createAction(
  'editor:set-bbox-of-shape',
  props<{ IRI: string; bBox: BBox }>(),
);

export const setRelationship = createAction(
  'editor:set-relationship',
  props<{
    subject: string;
    relationship: string;
    object?: string;
    objects?: string[];
  }>(),
);

export const incrementRadius = createAction(
  'editor:increment-radius',
  props<{
    increment: number;
  }>(),
);

export const setRelationshipBase = createAction(
  'editor:set-relationship-base',
  props<{
    subject: string;
    subjectType: 'nw:Shape' | 'nw:File';
    relationship: string;
    object: string;
    oneToMany?: boolean;
  }>(),
);

export const removeRelationshipBase = createAction(
  'editor:remove-relationship-base',
  props<{
    subject: string;
    relationship: string;
    subjectType: 'nw:Shape' | 'nw:File';
    object?: string;
  }>(),
);

export const setNewFile = createAction(
  'editor:set-new-file',
  props<{ fileIRI: string }>(),
);

export const clearNewFiles = createAction('editor:clear-new-files');

export const fetchShape = createAction(
  'editor:fetch-shape-action',
  props<{ IRI: string }>(),
);

export const addState = createAction(
  'editor:add-state',
  props<{ name: string }>(),
);

export const selectState = createAction(
  'editor:select-state',
  props<{ name: string }>(),
);

export const setNoAnimationMode = createAction(
  'editor:set-no-animation-mode',
  props<{
    value: boolean;
  }>(),
);

export const setFontLoaded = createAction(
  'editor:set-font-loaded',
  props<{
    value: boolean;
  }>(),
);

export const createNewComponentAction = createAction(
  'editor:create-new-component',
  props<{
    IRI: string;
    label: string;
    descriptor: any;
  }>(),
);

export const saveNewComponentRequestAction = createAction(
  'editor:save-new-comp-request',
  props<{
    data: ResourceData;
  }>(),
);
