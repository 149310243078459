<div class="hand-shape-section-component flex-column gap-2">
  <div class="flex-row space-between" *ngIf="!amIFirst">
    <div class="flex-row">
      <div>d1</div>
      <nw-number-select
        [values]="[section.d1]"
        [step]="0.1"
        (valueChange)="setD1($event)"
      ></nw-number-select>
    </div>
    <div class="flex-row">
      <div>d2</div>
      <nw-number-select
        [values]="[section.d2]"
        [step]="0.1"
        (valueChange)="setD2($event)"
      ></nw-number-select>
    </div>
  </div>

  <div class="flex-row gap-8" *ngIf="amIFirst || amIlast; else inner">
    <div>end</div>
    <select name="" [value]="section.end" (change)="setEnd($event)">
      <option value="arc">arc</option>
      <option value="flat">flat</option>
      <option value="half-arc">half-arc</option>
      <option value="half-arc">half-arc inv</option>
    </select>
  </div>
  <ng-template #inner>
    <div class="flex-row gap-8">
      <div>joint</div>
      <select
        name=""
        [value]="section.joint || 'symm'"
        (change)="setJoint($event)"
      >
        <option value="symm">symm</option>
        <option value="assym">assym</option>
        <option value="assym-inverse">assym-inverse</option>
      </select>
    </div>
  </ng-template>

  <div class="row-space-between">
    <div style="font-size: 12px">stroke</div>
    <nw-icon
      *ngIf="!section.stroke"
      (click)="addStroke()"
      type="add"
      [size]="12"
    >
    </nw-icon>
    <nw-icon
      *ngIf="section.stroke"
      (click)="removeStroke()"
      type="remove"
      [size]="12"
    >
    </nw-icon>
  </div>
  <div
    *ngIf="section.stroke as stroke"
    class="flex-column flex-align-end gap-2"
  >
    <nw-color-select
      [colors]="[stroke.color]"
      (changed)="strokeColorChange($event)"
    ></nw-color-select>

    <nw-number-select
      [values]="[stroke.width]"
      (valueChange)="strokeWidthChange($event)"
    ></nw-number-select>

    <div class="flex-column gap-6">
      <div class="row-space-between" *ngIf="stroke.top as top">
        <input
          type="checkbox"
          [checked]="!!stroke.top"
          (change)="changeStrokeSection('top', $event)"
        />
        <div class="flex-row gap-6">
          <div class="fs-11">top</div>
          <div class="flex-row">
            <input
              [value]="stroke.top?.start"
              (change)="changeSectionInterval('top', 'start', $event)"
              placeholder="start"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
            <div class="delimiter"></div>
            <input
              [value]="stroke.top?.end"
              (change)="changeSectionInterval('top', 'end', $event)"
              placeholder="end"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
          </div>
        </div>
      </div>
      <!-- <div class="row-space-between" *ngIf="!amIFirst"> -->
      <div class="row-space-between">
        <input
          type="checkbox"
          [checked]="!!stroke.side"
          (change)="changeStrokeSection('side', $event)"
        />
        <div class="flex-row gap-6">
          <div class="fs-11">side</div>
          <div class="flex-row">
            <input
              [value]="stroke.side?.start"
              (change)="changeSectionInterval('side', 'start', $event)"
              placeholder="start"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
            <div class="delimiter"></div>
            <input
              [value]="stroke.side?.end"
              (change)="changeSectionInterval('side', 'end', $event)"
              placeholder="end"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
          </div>
        </div>
      </div>
      <!-- <div class="row-space-between" *ngIf="!closed && index == 1"> -->
      <div class="row-space-between">
        <input
          type="checkbox"
          [checked]="!!stroke.bottom"
          (change)="changeStrokeSection('bottom', $event)"
        />
        <div class="flex-row gap-6">
          <div class="fs-11">bottom</div>
          <div class="flex-row">
            <input
              [value]="stroke.bottom?.start"
              (change)="changeSectionInterval('bottom', 'start', $event)"
              placeholder="start"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
            <div class="delimiter"></div>
            <input
              [value]="stroke.bottom?.end"
              (change)="changeSectionInterval('bottom', 'end', $event)"
              placeholder="end"
              type="number"
              (keyup)="$event.stopPropagation()"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div (click)="remove()">Remove</div>
</div>
