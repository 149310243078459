import { AnimationFrame } from '../components/animation-frame/animation.types';
import {
  pick as _pick,
  cloneDeep as _cloneDeep,
  omit as _omit,
  set as _set,
} from 'lodash';
import { GeneralShape } from '../../shape/shapes/general/general-shape';
import {
  AnimationKeys,
  SelectParams,
  __Animation,
} from '../../../elements/resource/types/shape.type';
import {
  AnimationFrameObject,
  AnimationFrameRelationships,
} from './animation-frame-object';
import { setMainAnimationFrame } from '../store/animation.actions';
// import { SubSceneTransitionFrameObject } from './subscene-transition.frame';
import { FunctionAnimationFrameObject } from './function/function-animation-frame';
import { LocalSoundAnimationFrameObject } from './local-sound-animation-frame';
import { AnimationEnvironment } from '../../../services/animation/animation.types';

export class MainAnimationFrameObject extends AnimationFrameObject {
  isSoundSelected = false;

  functionDuration = 0;

  functionInstances: Record<
    string,
    Record<string, FunctionAnimationFrameObject>
  > = {};

  get shape() {
    return this.fcnParent.shape;
  }

  get isSound() {
    return false;
  }

  get store() {
    return this._store;
  }

  get rootMainFrame(): RootAnimationFrame {
    return this.fcnParent?.rootMainFrame;
  }

  get frameObject() {
    return this.firstFrame.frameObject;
  }

  // get fullId() {
  //   return this.roo
  // }

  soundEditMode = false;
  _width = 0;

  set width(val: number) {
    this._width = val;
  }

  get width() {
    return this._width;
  }

  get mainFrame(): MainAnimationFrameObject {
    return this;
  }

  _store: Record<string, AnimationFrameObject> = {};
  _rows = {};
  dependecies: Record<string, boolean> = {};
  frameOccupation: Array<Record<string, boolean>> = [];
  soundFrameOccupation: Array<Record<string, boolean>> = [];

  get lastEnd() {
    let end = 0;
    Object.values(this.store).map(frame => {
      if (isNaN(frame._end)) {
        return;
      }
      end = Math.max(end, frame._end);
    });
    return end;
  }
  constructor(
    public frame: AnimationFrame,
    public readonly functionParent?:
      | MainAnimationFrameObject
      | FunctionAnimationFrameObject,
  ) {
    super(frame, { functionParent });
    this.setFunctionDependencies();

    // Object.entries(frame.localFunctions || {}).map(([key, value]) => {
    //   this.mainFrame.localFunctions[key] = new MainAnimationFrameObject(
    //     this.shape,
    //     value
    //   );
    // });
  }
  firstFrame: AnimationFrameObject;

  getFrameById(id: string) {
    const [main, ...rest] = id.split('_');

    const frame = this.store[main] as FunctionAnimationFrameObject;

    if (!frame) {
      throw new Error(`Frame could not be found with id: ${main}`);
    }

    if (rest.length > 0) {
      return frame.functionInstance.getFrameById(rest.join('_'));
    }

    return frame;
  }

  init(relationships?: AnimationFrameRelationships) {
    this.firstFrame = this.service.initFrameObject(this.frame, {
      ...(relationships || {}),
      functionParent: this,
    });
  }

  calcFcnDuration() {
    this.functionDuration = Math.max(
      ...Object.values(this.store).map(frame => frame._end),
    );
    // -
  }

  calcPositions() {
    this.firstFrame.calcIntervals();
    this.calcRowIndexes();
  }

  findFrameById(id: string) {
    if (id.includes('_')) {
      const [firstId, ...rest] = id.split('_');
      if (!this.store[firstId]) {
        const [_firstId, ..._rest] = rest;
        return this.store[_firstId]?.findFrameById(rest.join('_'));
      }
      return this.store[firstId]?.findFrameById(rest.join('_'));
    }
    return this.store[id];
  }

  updateFunctions(frameId: string, fcnName: string, newFrame: AnimationFrame) {
    if (!this.rootMainFrame.frame.functions) {
      return;
    }
    this.rootMainFrame.frame.functions = this.rootMainFrame.frame.functions.map(
      ({ name, frame }) => {
        if (fcnName == name) {
          return {
            name,
            frame: newFrame,
          };
        }

        return { name, frame };
      },
    );
    //
    Object.entries(this.functionInstances[fcnName] || {})
      .filter(([id]) => id !== frameId)
      .map(([, functionInstance]) => functionInstance.updateFunction(newFrame));

    this.firstFrame.calcIntervals();
    this.calcRowIndexes();
  }

  calcRowIndexes() {
    // console.log('calc-row-indexes', mode); //
    this.frameOccupation = [];
    if (!this.firstFrame) {
      return;
    }
    this.depthFirstTree = this.firstFrame.getBreathFirstTree();
    this.depthFirstTree.map(frame => this.insertToFrameOccupation(frame));
  }

  /**
   * Traverses the original frame descriptor for functions and the puts their name
   * into the this.dependencies record. It is used in circular dependency checking
   * and in calculation order of the function durations.
   */
  setFunctionDependencies() {
    return;
    const addToFrames = (frames: AnimationFrame[], frame: AnimationFrame) => {
      frames.push(_omit(frame, ['next', 'parallel']));
      if (frame.next) {
        frames.push(...addToFrames(frames, frame.next));
      }
      if (frame.paralell) {
        frames.push(...addToFrames(frames, frame.paralell));
      }
      return frames;
    };

    const frames = addToFrames([], this.frame);
    this.dependecies = frames.reduce(
      (object, frame) => {
        if (frame.function) {
          object[frame.function] = true;
        }
        return object;
      },
      {} as Record<string, boolean>,
    );
  }

  async animate(env: AnimationEnvironment): Promise<void> {
    return this.firstFrame.animate(env);
  }

  insertToFrameOccupation(frame: AnimationFrameObject, log = false) {
    const [start, end] = frame.interval;

    // if (frame.prev) {
    //   // That is the tricky part
    //   // Since the breath-first propagation I don't think we need that
    //   minRow = Math.max(frame.prev.rowIndex, minRow);
    // }

    let currentRowIndex = 0;

    if (frame.parent) {
      currentRowIndex =
        frame.parent.rowIndex !== undefined ? frame.parent.rowIndex + 1 : 0;
    } else if (frame.prev) {
      currentRowIndex = frame.prev.rowIndex || 0;
    }

    let rowFound = false;

    while (!rowFound) {
      if (this.frameOccupation[currentRowIndex]) {
        let isThereOverlap = false;

        for (
          let index = currentRowIndex;
          index < currentRowIndex + frame.height;
          index++
        ) {
          const frames = Object.keys(this.frameOccupation[index] || {})
            .map(id => this.store[id])
            .filter(f => !!f);

          isThereOverlap = !!frames.find(({ _start, _end }) => {
            return (
              (start < _start && _start < end) ||
              (_start <= start && start < _end)
            );
          });
          if (isThereOverlap) {
            // This just breaks the for loop
            break;
          }
        }

        if (!isThereOverlap) {
          for (
            let index = currentRowIndex;
            index < currentRowIndex + frame.height;
            index++
          ) {
            this.frameOccupation[index] ||= {};
            this.frameOccupation[index][frame.id] = true;
          }
          frame.rowIndex = currentRowIndex;
          rowFound = true;
        } else {
          currentRowIndex++;
        }
      } else {
        this.frameOccupation[currentRowIndex] = {
          [frame.id]: true,
        };
        frame.rowIndex = currentRowIndex;
        rowFound = true;
      }
    }

    this.height = Math.max(this.height, frame.rowIndex + frame.height);
    this.width = Math.max(this.width, frame._end);
    let prev = frame.prev;

    while (prev) {
      prev.rowIndex = frame.rowIndex;
      prev = prev.prev;
    }
  }

  save() {
    if (this.fcnParent) {
      // this.fcnParent.upd
    }

    this.rootMainFrame.save();
  }

  select(params?: SelectParams) {
    return this.firstFrame?.select(params);
  }

  remove() {
    if (this.next && this.child) {
      return alert(`You cannot delete this node!`);
    }

    if (this.next) {
      delete this.store[this.next.id];
      this.frame = {
        ...this.next.frame,
        id: this.frame.id,
      };
      if (this.next.next) {
        this.next.next.prev = this;
      }
      if (this.next.child) {
        this.next.child.parent = this;
      }
      this.child = this.next.child;
      this.next = this.next.next;
    }
  }

  selectNext() {
    if (this.selectedObject) {
      this.selectedObject.next?.select();
    } else {
      this.select();
    }
  }

  selectParalell() {
    if (this.selectedObject) {
      this.selectedObject.child?.select();
    } else {
      // this.select();
    }
  }

  selectMainFrame() {
    if (!(this as MainAnimationFrameObject).fcnParent && !this.selectedObject) {
      // this.setSelected(this.mainFrame);
    }
    this.selectedObject?.select();
  }
}

export class SoundMainAnimationFrame extends MainAnimationFrameObject {
  get rootMainFrame() {
    return super.rootMainFrame.soundRootFrame;
  }
  get store() {
    return this.rootMainFrame._store;
  }

  get isSound() {
    return true;
  }

  save() {
    this.soundParent.save();
  }

  init(relationships?: AnimationFrameRelationships) {
    super.init(relationships);
    this.soundParent = relationships.soundParent;
  }

  calcIntervals(log?: boolean): void {
    // console.log('firstFrame-calcIntervals', this);
    this._start = this.fcnParent._start || 0;
    this.firstFrame?.calcIntervals();
    // this.rootMainFrame.fcnParent.calcIntervals(); //
  }
}

export class RootAnimationFrame extends MainAnimationFrameObject {
  get mainFrame(): MainAnimationFrameObject {
    return this;
  }

  get fullId() {
    return this.shape.IRI + '_' + super.fullId;
  }

  get frameObject() {
    return this.firstFrame.frameObject;
  }

  get rootMainFrame(): RootAnimationFrame {
    return this;
  }

  get shape() {
    return this.parentShape;
  }

  get type() {
    return 'root';
  }

  get isOpen() {
    return true;
  }

  get soundHeight() {
    return this.soundMaxRowIndex + 1;
  }
  get soundStore(): Record<string, AnimationFrameObject> {
    return Object.values(this.store).reduce((prev, curr) => {
      // -- //
      if (curr.soundAnimationFrame) {
        prev = {
          ...prev,
          ...curr.soundAnimationFrame.store,
        };
      }

      return prev;
    }, {});
  }

  soundMaxRowIndex = 0;

  soundRootFrame: RootAnimationFrame;

  backgroundMusicFrame: LocalSoundAnimationFrameObject;
  constructor(
    public readonly parentShape: GeneralShape,
    frame: AnimationFrame,
    functionParent?: MainAnimationFrameObject | FunctionAnimationFrameObject,
    root = true,
    offset?: number,
  ) {
    super(frame, functionParent);

    this.offset = offset || 0;

    // if (this.frame.functions?.length) {
    //   this.functions = this.frame.functions.map(({ name, frame }) => ({
    //     name,
    //     frame: new MainAnimationFrameObject(frame, name, this),
    //   }));
    //   this.initFunctionByDepencyTree();
    // }
    if (root) {
      this.soundRootFrame = new SoundRootAnimationFrame(
        parentShape,
        {
          id: '1234',
        },
        this,
        false,
      );

      this.init();
      this.calcPositions();
      this.updateFrameCollection();
    }

    // console.log('-- root > rameOccupation --', this.frameOccupation); //
    // console.log('-- root > rameOccupation --', this.soundFrameOccupation); //
    // console.log('-- root > store --', this.store); //
  }

  save() {
    // -- // -- // -- //
    this.updateFrameCollection();
    // TODO - clean the code

    this.cs.store.dispatch(
      setMainAnimationFrame({ frame: _cloneDeep(this.frameObject) }),
    );

    // (this.shape as RootShape).updateAnimationsByStart(
    //   animationsByStart,
    //   this.selectedObject._end,
    // );
  }

  async animate(env: AnimationEnvironment = {}): Promise<void> {
    await Promise.all([
      this.backgroundMusicFrame?._animate(env),
      super.animate(env),
    ]);
  }

  updateFrameCollection() {
    // const frameCollection = this.getFrameCollection();
    // this.shape.store.dispatch(setFrameCollection({ value: frameCollection }));
  }

  initFunctionByDepencyTree() {
    // TODO - reimplement
    // const processedFunctions = {};
    // while (Object.keys(processedFunctions).length < this.functions.length) {
    //   const processableFunction = this.functions.find(({ frame }) => {
    //     const notProcessedDeps = Object.keys(frame.dependecies).filter(dep => !processedFunctions[dep]);
    //     return notProcessedDeps.length == 0;
    //   })
    //   if (!processableFunction) {
    //     throw new Error(`Circular dependency has been found`);
    //   }
    //   processableFunction.frame.init();
    //   processableFunction.frame.calcPositions();
    //   processedFunctions[processableFunction.name] = true;
    // }
  }

  calcRowIndexes(): void {
    super.calcRowIndexes();
    const soundFrames = this.depthFirstTree.reduce((prev, curr) => {
      if (curr.soundAnimationFrame) {
        prev.push(...curr.soundAnimationFrame.firstFrame.getBreathFirstTree());
      }
      return prev;
    }, []);
    if (!this.soundRootFrame) {
      return;
    }
    this.soundRootFrame.maxRowIndex = 0;
    this.soundRootFrame.frameOccupation = [];
    soundFrames.map(frame =>
      this.soundRootFrame.insertToFrameOccupation(frame),
    );

    // console.log('------ soundRootFrame ----', this.soundRootFrame.store);
    // console.log('depthFirstTree > sound-animation-frames', soundFrames);
  }

  updateSubSceneTransitionFrames(
    mainScene: string,
    subScene: string,
    name: string,
  ) {
    const isThereChanged = false;
    // Object.values(this.store)
    //   .filter(frame => frame instanceof SubSceneTransitionFrameObject)
    //   .filter(
    //     (frame: SubSceneTransitionFrameObject) =>
    //       frame.mainScene == mainScene && frame.targetSubScene == subScene,
    //   )
    //   .map((frame: SubSceneTransitionFrameObject) => {
    //     isThereChanged = true;
    //     frame.targetSubScene = name;
    //   });

    if (isThereChanged) {
      this.mainFrame.save();
    }
  }
}

export class MSAnimationFrame extends MainAnimationFrameObject {
  constructor(
    shape: GeneralShape,
    frame: AnimationFrame,
    fcnParent?: MainAnimationFrameObject,
  ) {
    super(frame, fcnParent);
    this.shapesToAnimate = [shape];
  }

  get targetShape() {
    return this.shapesToAnimate[0];
  }

  get baseAction() {
    return this.frame.baseAction;
  }
}

export class SoundRootAnimationFrame extends RootAnimationFrame {
  init() {
    // super.init();
  }

  calcPositions(log?: boolean): void {
    // the intervals are calculated already by the root-frame's calc interval routine
    this.calcRowIndexes();
  }
}
