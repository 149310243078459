import { RectangleElement } from '../../../element-editor/shape/shapes/primitive/rectangle-element';
import { Coords } from '../../../elements/resource/types/shape.type';
import { StoryBoardController } from './story-board-controller';

export interface StoryBoardItemDescriptor {
  color?: string;
  notes: string[];
}

export class StoryBoardItem {
  // -- // -- // -- // -- // -- // -- //
  rectangle: RectangleElement;
  get cs() {
    return this.controller.cs;
  }

  constructor(
    private readonly controller: StoryBoardController,
    private readonly descriptor: StoryBoardItemDescriptor,
    offset: Coords,
    dims: Coords,
  ) {
    const [x, y] = offset;
    const [width, height] = dims;

    console.log('x', x, 'y', y);
    this.rectangle = new RectangleElement(
      this,
      this.controller.rootShape.rootContainer,
      {
        x,
        y,
        position: { x, y },
        width,
        height,
        fill: this.descriptor.color,
        stroke: '#ff0000',
        'stroke-width': 2,
      },
      0,
    );
  }

  delete() {
    this.rectangle.remove();
  }
}
