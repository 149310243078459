import { NgModule } from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { UtilComponentModule } from '../../../components/util/util-component.module';
import { AnimationControllerComponent } from './animation-controller/animation-controller.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AnimationActionComponent } from './animation-action/animation-action.component';
import { ShowHideAnimationComponent } from './animation-action/show-hide-animation/show-hide-animation.component';
import { GeneralAttributeHeaderModule } from '../general-attribute-header/general-attribute-header.module';
import { FloatEffectAttributeComponent } from './animation-action/float-effect-attribute/float-effect-attribute.component';
import { TrajectoryAnimationComponent } from './animation-action/trajectory-animation/trajectory-animation.component';
import { PathAnimationComponent } from './animation-action/path-animation/path-animation.component';
import { DashMoveAnimationComponent } from './animation-action/dash-move-animation/dash-move-animation.component';
import { PathAnimationComponentModule } from './animation-action/path/path-animation-component.module';
import { PrimitiveAnimationComponentModule } from './animation-action/primitive/color-blink/primitive-animation-component.module';
import { RotateEffectAttributeComponent } from './animation-action/rotate-effect-attribute/rotate-effect-attribute.component';
import { AnimationControllerComponentModule } from './animation-controller/animation-controller.module';
import { TranslateEffectComponent } from './animation-action/translate-effect/translate-effect.component';

@NgModule({
  providers: [CanvasService],
  imports: [
    CommonModule,
    FormsModule,
    UtilComponentModule,
    MatProgressSpinnerModule,
    GeneralAttributeHeaderModule,
    PathAnimationComponentModule,
    PrimitiveAnimationComponentModule,
    AnimationControllerComponentModule,
  ],
  declarations: [
    AnimationActionComponent,
    FloatEffectAttributeComponent,
    RotateEffectAttributeComponent,
    ShowHideAnimationComponent,
    TrajectoryAnimationComponent,
    PathAnimationComponent,
    DashMoveAnimationComponent,
    TranslateEffectComponent,
  ],
  exports: [AnimationControllerComponent, AnimationActionComponent],
})
export class AnimationAttributeModule {}
