/* These are the actions that are called due to the actions of the users.*/

import { createAction, props } from '@ngrx/store';
import {
  AnimationKeys,
  AnimationInnerKey,
} from '../../elements/resource/types/shape.type';

export const deleteShapeAction = createAction(
  'editor:delete-shape',
  props<{
    IRI: string;
  }>(),
);

export const deleteShapeFromFileAction = createAction(
  'editor:delete-shape-from-file',
  props<{
    IRI: string;
  }>(),
);

export const incrementStrokeWidth = createAction(
  'editor:increment-stroke-width',
  props<{
    increment: number;
  }>(),
);

export const setDescriptorValue = createAction(
  'editor:set-attribute-subvalue',
  props<{
    key: AnimationKeys;
    innerKey?: AnimationInnerKey;
    value: unknown;
    IRI?: string;
  }>(),
);
