import { Component } from '@angular/core';
import { AnimationService } from '../../../animation/animation.service';
import { Store } from '@ngrx/store';
import {
  requestSpeechFileFromText,
  setAnimationControlPanelState,
} from '../../../animation/store/animation.actions';
import {
  AnimationFrame,
  CanvasTransformFrame,
  SceneTransitionFrame,
  SceneTransitionType,
  TextAnimationFrame,
} from '../../../animation/components/animation-frame/animation.types';
import { CanvasService } from '../../../../services/canvas/canvas.service';
import { FunctionAnimationFrameObject } from '../../../animation/frame/function/function-animation-frame';
import { ImportedShape } from '../../../shape/shapes/general/imported/imported-shape';
import { ShapeService } from '../../../shape/shape.service';
import { SceneTransitionFrameObject } from '../../../animation/frame/scene-transition.frame';
import { HttpService } from '../../../../store/http/http.service';
import { TextAnimationFrameObject } from '../../../animation/frame/text-animation-frame';
import { SubSceneTransitionFrameObject } from '../../../animation/frame/subscene-transition.frame';
import { CanvasTransformFrameObject } from '../../../animation/frame/canvas-transform-frame';

@Component({
  selector: 'iw-animation-controller',
  templateUrl: './animation-controller.component.html',
  styleUrls: ['./animation-controller.component.scss'],
})
export class AnimationControllerComponent {
  get mainFrame() {
    // -- // -- // -- //
    return this.animationService.mainFrame;
  }

  get selectedImportedShape(): ImportedShape {
    return this.shapeService.selectedShapes[0]?.getType() == 'is'
      ? (this.shapeService.selectedShapes[0] as ImportedShape)
      : undefined;
  }

  get currentFrame() {
    return this.mainFrame?.selectedObject;
  }

  get soundEditMode() {
    return this.mainFrame.soundEditMode;
  }

  get selectedObject() {
    if (!this.mainFrame) {
      return;
    }

    if (this.mainFrame.soundEditMode) {
      return this.mainFrame.soundRootFrame.selectedObject;
    }

    return this.mainFrame.selectedObject;
  }
  get selectedSubSceneTransitionFrame() {
    return this.mainFrame.selectedObject as SubSceneTransitionFrameObject;
  }

  get selectedCanvasTransformFrame() {
    return this.mainFrame.selectedObject as CanvasTransformFrameObject;
  }

  get selectedSceneTransitionObject() {
    return this.mainFrame.selectedObject as SceneTransitionFrameObject;
  }

  get selectedSoundFrameText() {
    return (this.selectedObject.frame as TextAnimationFrame).text;
  }

  get textFrame() {
    return this.selectedObject.frame as TextAnimationFrame;
  }

  get textFrameObject() {
    return this.selectedObject as TextAnimationFrameObject;
  }

  get functionFrameObject() {
    return this.selectedObject as FunctionAnimationFrameObject;
  }

  changeText(event: Event) {
    const value: string = (event.target as HTMLInputElement).value;
    (this.selectedObject.frame as TextAnimationFrame).text = value;
    this.mainFrame.save();
  }

  get functions() {
    if (this.selectedImportedShape) {
      return (
        this.selectedImportedShape.baseShapeDescriptor.animationFrame?.functions?.map(
          ({ name }) => name,
        ) || []
      );
    }
    return this.mainFrame?.frame.functions?.map(({ name }) => name) || [];
  }

  currentSoundFileName: string;
  addStateChangeFrameMode = false;

  constructor(
    private readonly animationService: AnimationService,
    private readonly cs: CanvasService,
    private readonly shapeService: ShapeService,
    private readonly http: HttpService,
    private readonly store: Store,
  ) {}
  addStateChangeFrame() {
    this.addStateChangeFrameMode = true;
  }

  subtitleChecked = false;
  speechChecked = false;

  deleteFunction(index: number) {
    this.mainFrame.frame.functions.splice(index, 1);
    this.mainFrame.save();
  }

  update() {
    this.mainFrame.calcPositions();
    this.mainFrame.save();
  }

  addSceneTransition() {
    console.log('scenes', this.shapeService.previewShape.scenes);
    console.log('scenes', this.cs.currentScene);

    // const scenes =
    //   this.shapeService.previewShape.scenes?.map(({ name }) => name) || [];

    // if (!scenes.length) {
    //   return alert('No scenes have been found!');
    // }

    // const currentScene = this.cs.currentScene || scenes[0];

    // const index = scenes.findIndex(name => name == currentScene);

    // const next = scenes[index + 1];

    // if (!next) {
    //   return alert('No next scene was found after: ' + currentScene);
    // }

    const frame =
      this.animationService.selectedFrame.insertNext<FunctionAnimationFrameObject>(
        {
          duration: 1,
          type: 'scene-transition',
          mode: SceneTransitionType.FloatRight,
        } as SceneTransitionFrame,
      );
    frame.select();
    this.update();
  }

  addFunction(fcn: string) {
    const frame: AnimationFrame = {
      duration: 1,
      function: fcn,
    };

    if (this.selectedImportedShape) {
      frame.functionTarget = {
        IRI: this.selectedImportedShape.IRI,
      };
    }

    let fcnFrame: FunctionAnimationFrameObject;
    if (this.cs.isShiftPressed) {
      fcnFrame =
        this.currentFrame.insertParalell<FunctionAnimationFrameObject>(frame);
    } else {
      fcnFrame =
        this.currentFrame.insertNext<FunctionAnimationFrameObject>(frame);
    }

    this.update();
    this.currentFrame.deselect();
    fcnFrame.select();
  }

  addFrame() {
    if (!this.currentFrame) {
      console.log('add-first-frame');
      return this.animationService.addFirstFrame();
    }
    this.currentFrame.insertNext();
    this.update();
  }

  addInverseFrame() {
    this.animationService.selectedFrame.insertNext({
      inverse: true,
    });
    this.update();
  }

  textToSpeechText = '';

  addTextToSpeech() {
    this.store.dispatch(
      setAnimationControlPanelState({
        state: {
          mode: 'text-frame-form',
        },
      }),
    );
  }

  closeTextToSpeech() {
    this.store.dispatch(
      setAnimationControlPanelState({
        state: {
          mode: 'idle',
        },
      }),
    );
  }

  get isTextInvalid() {
    return this.textToSpeechText.length <= 3;
  }

  setTransitionParameter(
    key: 'hide' | 'show' | 'transform' | 'backgroundColor',
    val: boolean,
  ) {
    // this.selectedSceneTransitionObject.frame[key] = val;
    (this.selectedObject as SceneTransitionFrameObject).frame[key] = val;
    this.mainFrame.save();
    // -- // -- // -- // -- // -- //
    console.log('set-transition-parameter', key, val);
  }
  insertLocalAnimation() {
    // -- // -- //
  }

  insertTextAnimation() {
    this.insertAnimation({
      type: 'text',
      text: 'Please define text',
    } as TextAnimationFrame);
  }

  insertLocalSound() {
    this.insertAnimation({
      type: 'local-sound',
      audioDuration: 1,
      duration: 1,
    } as TextAnimationFrame);
  }

  insertAnimation(frame: AnimationFrame) {
    // const text = this.textToSpeechText.replace(/'/g, `\\'`); //

    if (this.selectedObject.mainFrame.isSound) {
      this.selectedObject.insertNext(frame);
      this.animationService.mainFrame.calcPositions();
      this.animationService.mainFrame.save();
    } else {
      console.log('yooo - insertTextAnimation -');
      this.animationService.selectedFrame.insertTextAnimation(
        frame as TextAnimationFrame,
      );
    }
  }

  // _insertAnimation() {
  //   // -- // -- //
  //   // const text = this.textToSpeechText.replace(/'/g, `\\'`);

  //   if (this.selectedObject.mainFrame.isSound) {
  //     this.selectedObject.insertNext({
  //       soundFileUrl: this.speechChecked
  //         ? this.panelState.speechFile.filename
  //         : undefined,
  //       // duration: this.currentDuration, //
  //       type: 'text',
  //       audioDuration: 1,
  //       duration: 1,
  //       subtitle: true,
  //       text,
  //     } as TextAnimationFrame);
  //     this.animationService.mainFrame.calcPositions();
  //     this.animationService.mainFrame.save();
  //   } else {
  //     this.animationService.selectedFrame.insertTextAnimation({
  //       soundFileUrl: this.speechChecked
  //         ? this.panelState.speechFile.filename
  //         : undefined,
  //       // duration: this.currentDuration, //
  //       type: 'text',
  //       audioDuration: 1,
  //       duration: 1,
  //       subtitle: true,
  //       text,
  //     });
  //   }
  // }

  requestSpeechFile() {
    this.store.dispatch(
      requestSpeechFileFromText({
        text: this.textToSpeechText,
        id: this.selectedObject.id,
      }),
    );
  }

  speechChange(event: Event) {
    const val = (event.target as HTMLInputElement).checked;
    console.log('speechChange', val, 'textToSpeechText', this.textToSpeechText);
    if (val) {
      this.requestSpeechFile();
    }
  }

  addCanvasTransform() {
    // -- // -- // -- //
    this.mainFrame.selectedObject.insertNext({
      type: 'canvas-transform',
      translate: [
        this.cs.previewShape.translateX,
        this.cs.previewShape.translateY,
      ],
      scale: this.cs.previewShape.scaleX,
    } as CanvasTransformFrame);
    this.update();
  }

  addDelay() {}

  addNoise() {}

  addMusic() {}

  addText() {}
}
