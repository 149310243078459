import { Injectable } from '@angular/core';
import { BufferLoader } from './frame/buffer-loader';

@Injectable()
export class AudioService {
  audioContext = new Promise<AudioContext>(resolve => {
    let context: AudioContext;
    const subscription = async () => {
      // console.log('moved');
      if (context) {
        await context.resume();
      } else {
        context = new AudioContext();
        resolve(context);
      }
    };

    document.addEventListener('mousemove', subscription);
  });

  constructor() {
    // this.registerBackgroundMusic();
  }

  audioDestination: Promise<MediaStreamAudioDestinationNode> =
    this.audioContext.then(context => context.createMediaStreamDestination());

  bufferLoaders: Record<string, BufferLoader> = {};
  buffers: Record<string, any> = {};
  sources: Record<string, AudioBufferSourceNode> = {};
  durations: Record<string, number> = {};

  fileNames?: Record<string, string> = {};

  async initSoundBuffer(fileName: string, id?: string): Promise<number> {
    // console.log('init-sound-buffer', fileName);
    const context = await this.audioContext;
    // console.log('context is there');
    id ||= fileName;
    this.fileNames[id] = fileName;

    const promise = new Promise<number>(resolve => {
      this.bufferLoaders[id] = new BufferLoader(
        context,
        `https://s3.amazonaws.com/audio.inwedio.com/${fileName}`,
        async audioBuffer => {
          this.buffers[id] = audioBuffer;
          this.durations[id] = audioBuffer.length / audioBuffer.sampleRate;
          resolve(audioBuffer.duration);
        },
      );
      this.bufferLoaders[id].load();
    });

    this.audioFilePromises.push(promise);

    return promise;
  }

  async waitForAudioFiles() {
    return;
    console.log('wait-for-audio-files > ', this.audioFilePromises.length);
    await Promise.all(this.audioFilePromises);
  }

  async initSoundBufferByBlob(audioBlob: Blob, id: string) {
    // -- // -- // -- // -- //

    // -- // -- // -- // -- //
    const audioContext = new (window.AudioContext ||
      (window as any).webkitAudioContext)();
    const audioData = await audioBlob.arrayBuffer();

    audioContext.decodeAudioData(audioData, buffer => {
      const source = audioContext.createBufferSource();

      source.buffer = buffer;
      source.connect(audioContext.destination);
      // source.start(0); // Start playing
      this.sources[id] = source;
      this.buffers[id] = buffer;
    });
  }

  // async playSample(fileName: string) {
  //   if (this.buffers[fileName]) {
  //     return this.playSound(fileName);
  //   }

  //   this.bufferLoader = new BufferLoader(
  //     await this.animationService.audioContext,
  //     `https://s3.amazonaws.com/audio.inwedio.com/${this.frame.soundFileUrl}`,
  //     async audioBuffer => {
  //       // -- // -- // -- //
  //       this.buffer = audioBuffer;
  //       console.log('playSample > audioBuffer', audioBuffer);
  //       await this.playSound();
  //     },
  //   );
  //   this.bufferLoader.load();
  // }

  clearSound(id: string) {
    delete this.buffers[id];
  }

  async playSound(id: string, fileName?: string) {
    if (!this.buffers[id]) {
      await this.initSoundBuffer(fileName, id);
    }

    await this.initBufferSource(id);
    this.sources[id].start(0);
    await new Promise(res => setTimeout(res, this.durations[id] * 1_000));
  }

  async initBufferSource(id: string) {
    const context = await this.audioContext;
    const destination = await this.audioDestination;

    const source = context.createBufferSource();
    source.buffer = this.buffers[id];
    source.connect(destination); // recorder
    source.connect(context.destination); // speaker
    // source.buffer.duration;

    this.sources[id] = source;
  }

  async pause(id: string) {
    this.sources[id].stop();
  }

  async restart(id: string, from: number) {
    await this.initBufferSource(id);
    console.log('------- restart --------', from);
    this.sources[id].start(0, from);
  }

  bgBufferLoader: BufferLoader;

  audioFilePromises = [];

  async registerBackgroundMusic() {
    // -- //
    // async () => {
    const context = await this.audioContext;
    // const destination = await this.audioDestination;

    this.audioFilePromises.push(
      new Promise<void>(res => {
        this.bgBufferLoader = new BufferLoader(
          context,
          `http://localhost:4200/assets/sounds/mixkit-driving-ambition-32.mp3`,
          // `http://localhost:4200/assets/sounds/ES_Humanoid-AvaLow-0000-13343.wav`,
          // `http://localhost:4200/assets/sounds/${this.soundUrl}`,
          audioBuffer => {
            this.bgMusicBuffer = audioBuffer;
            res();
            // console.log('background-music loaded successfully');
          },
        );
      }),
    );

    this.bgBufferLoader.load();
    // };
  }

  bgMusicSource: AudioBufferSourceNode;
  bgMusicBuffer: AudioBuffer;

  bgMusicPausedAt: number = 0; // Time at which the music was paused
  bgMusicStartTime: number = 0; // Time when the music was started or resumed
  async startBackgroundMusic() {
    // .. // .. // .. //
    this.bgMusicStopped = false;
    await this.initBgMusicSource();
    console.log('------- init-start-bg-music -----');
    this.bgMusicSource.start(0);
  }

  async initBgMusicSource() {
    const context = await this.audioContext;
    const destination = await this.audioDestination;
    const gainNode = context.createGain();

    // Set the gain value (volume level)
    // 1.0 is the original volume, lower it to decrease the volume (e.g., 0.5 for 50% volume)
    gainNode.gain.value = 0.1; // Set this value to control the volume level
    this.bgMusicSource = context.createBufferSource();
    this.bgMusicSource.loop = true;
    this.bgMusicSource.buffer = this.bgMusicBuffer;

    this.bgMusicSource.connect(gainNode);

    gainNode.connect(destination); // recorder
    gainNode.connect(context.destination); // speaker
  }

  async pauseBackgroundMusic() {
    return;
    const context = await this.audioContext;
    this.bgMusicPausedAt = context.currentTime;
    this.bgMusicSource.stop();
  }

  async restartBackgroundMusic() {
    // -- //
    return;
    await this.initBgMusicSource();
    this.bgMusicSource.start(0, this.bgMusicPausedAt - this.bgMusicStartTime);
  }

  bgMusicStopped = false;
  stopBackgroundMusic() {
    this.bgMusicStopped = true;
    this.bgMusicSource?.stop();
  }
}

// switch (this.soundUrl) {
//   case 'beep':
//     fileName = 'beep.wav';
//     break;
//   case 'noise':
//     fileName = 'noise.wav';
//     break;
//   case 'inwedio':
//     fileName = 'test.mp3';
//     break;
//   // `http://localhost:4200/assets/${fileName}`,
// }
