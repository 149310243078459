import {
  _Scale,
  AnimationItem,
  AnimationKeys,
  CenterScaleAnimation,
  Coords,
  DropShadowConfig,
  GeneralShapeConfig,
  ImageShapeDescriptor,
  IncrementState,
  Translate,
  Scale,
  ShapePosition,
} from '../../../../elements/resource/types/shape.type';
import { GeneralShape } from '../general/general-shape';
import { RectangleController } from '../../../../elements/util/rectangle-controller/rectangle-controller';
import { cloneDeep } from 'lodash';
import { APIRespone } from '../../../../store/http/http.service';
import { BlurFilter, Container } from 'pixi.js';
import { RectangleElement } from '../primitive/rectangle-element';
import { environment } from '../../../../../environments/environment';
import { ShapeService } from '../../shape.service';
import {
  ResourceData,
  ResourceType,
} from '../../../../elements/resource/resource.types';

export interface ImageShapeConfig extends GeneralShapeConfig {
  base64?: string;
}

export class ImageShape extends GeneralShape<
  ImageShapeDescriptor,
  ImageShapeConfig
> {
  get s3Id() {
    return this.descriptor.s3Id;
  }

  set s3Id(val: string) {
    this.descriptor.s3Id = val;
  }

  getType(): string {
    return 'image-shape';
  }

  get rWidth() {
    return this._width * this._scale.x;
  }

  get rHeight() {
    return this._height * this._scale.y;
  }

  get base64() {
    return this.config?.base64;
  }

  constructor(
    service: ShapeService,
    data: ResourceData<ImageShapeDescriptor>,
    config?: ImageShapeConfig,
  ) {
    super(service, data, config);
    this.type = ResourceType.ImportedImageElement;
    this.initialised = false;
    this.init();
  }

  async saveImageToS3() {
    let s3Id = null;
    let i = 0;
    while (i < 5) {
      const { data, error } = (await Promise.race([
        this.cs.httpService.saveImageToS3(this.base64),
        new Promise(res => setTimeout(() => res({ error: 'timeout' }), 6_000)),
      ])) as APIRespone;

      if (data) {
        s3Id = data;
        break;
      }
      if (error == 'timeout') {
        i++;
      } else {
        throw new Error(`Unexpected error happended`);
      }
    }

    if (!s3Id) {
      throw new Error(`File could not be saved`);
    }
    this.s3Id = s3Id as string;
    console.log('s3Id', this.s3Id);
  }

  async init() {
    super.init();

    if (this.parent.getType() !== 'is') {
      if (this.config?.base64 && !this.descriptor.s3Id) {
        console.log('save-image-to-s2', this.config.base64);
        this.cs.httpService.saveImageToS3(this.config.base64).then(response => {
          // console.log('s3-save-response', response);
          if (response.data) {
            this.s3Id = response.data;
            console.log('s3Id', this.s3Id);
            this.saveDescriptorKey('s3Id', this.s3Id);
          }
        });
      }
    }

    if (this.config?.base64) {
      // return this.initImageByBase64(this.config.base64.split(',')[1]);
      return this.initImageByBase64(this.config.base64, null, true);
    }

    if (!this.s3Id) {
      return;
    }

    // const base64 = await
    // this.cs.httpService.loadImage(this.s3Id).then(base64 => {
    this.initImageByBase64(
      `https://s3.eu-central-1.amazonaws.com/nowords-vlog.illustrations/${this.s3Id}`,
      null,
      true,
    );
    // })
  }

  // startTransformation(dx?: number, dy?: number): void {
  //   super.startTransformation(dx, dy);
  //   this._scaleX = this.scaleX;
  //   this._scaleY = this.scaleY;
  // }

  // transformation(scaleX: number, scaleY: number, dx?: number, dy?: number) {
  //   super.transformation(scaleX, scaleY, dx, dy);
  //   this.scaleX = this._scaleX * scaleX;
  //   this.scaleY = this._scaleY * scaleY;
  //   this.redraw();
  // }

  set_BBox() {
    const { width, height } = this.container.getBounds();
    this._bBox = [width, height];
    console.log('is', this._bBox);
  }

  get hasFrameRectangle() {
    return true;
  }

  centerScaleIncrementState: IncrementState<{ width: number; height: number }>;
  _bBox: [number, number];

  prepareKeyValueAnimation(
    animation: AnimationItem<AnimationKeys>,
    division: number,
    duration: number,
  ): void {
    super.prepareKeyValueAnimation(animation, division, duration);
    const { key, value } = animation;
    switch (key) {
      case 'center-scale':
        //
        this.set_BBox();
        const { ratio } = value as CenterScaleAnimation;
        // const { width, height } = this.container.getBounds();
        const [width, height] = this._bBox;
        console.log('prepare-center-scale', { width, height });
        this.centerScaleIncrementState = {
          current: 1,
          increment: (ratio - 1) / division,
          data: { width, height },
        };
        break;
    }
  }

  incrementAnimation(
    increment: number,
    id?: string,
    maxIncrement?: number,
  ): void {
    super.incrementAnimation(increment, id, maxIncrement);
    let current;
    this.getAnimationsById(id).map(({ key }) => {
      switch (key) {
        case 'center-scale':
          // -- // -- // -- //
          current = this.incrementState(
            this.centerScaleIncrementState,
            increment,
          );
          const { width: originalWidth, height: originalHeight } =
            this.centerScaleIncrementState.data;

          this.centerScale([originalWidth, originalHeight], current);
          break;
      }
    });
  }

  centerScale(bBox: Coords, ratio: number): void {
    // super.centerScale(bBox, ratio);
    const [width, height] = bBox;
    const [currentWidth, currentHeight] = [width, height].map(
      val => val * ratio,
    );

    // const [width, height] = bBox;
    // const [currentWidth, currentHeight] = [width, height].map(
    //   val => val * ratio
    // );

    if (this.importedShapeParent) {
      const { x, y } = this.importedShapeParent.scale as _Scale;
      this._redraw({
        x: this.x - (currentWidth - width) / (2 * x),
        y: this.y - (currentHeight - height) / (2 * y),
        scale: { x: this._scale.x * ratio, y: this._scale.y * ratio },
      });
    } else {
      this._redraw({
        x: this.x - (currentWidth - width) / 2,
        y: this.y - (currentHeight - height) / 2,
        scale: { x: this._scale.x * ratio, y: this._scale.y * ratio },
      });
    }

    (this.dropShadowElement as RectangleElement)?.patch({
      width: currentWidth,
      height: currentHeight,
    });
  }

  initRect() {
    if (!this.editable) {
      return;
    }

    // this.sprite.interactive = true;
    // this.sprite.on('click', () => this.clicked());

    this.rc = new RectangleController(
      this,
      {
        offset: [0, 0],
        width: this.rWidth,
        height: this.rHeight,
        drag: ({ x, y, width: w, height: h }) => {
          this.x = x;
          this.y = y;
          this._scale.x = w / this._width;
          this._scale.y = h / this._height;

          this.redraw();
          this.scaleImage();
        },
        endDrag: () => {
          this.rc.patch({
            width: cloneDeep(this.rWidth),
            height: cloneDeep(this.rHeight),
          });
          console.log('end-drag', this.x, this.y);
          this.saveTransform();
        },
        mouseout: () => {
          if (!this.cs.shapeAddMode) {
            this.deselect({ onHover: true });
          }
        },
        clicked: () => this.clicked(),
      },
      this.circleContainer,
      this.auxCircleContainer,
    );
    this.rc.hide();
  }

  initDropShadowElement(config: DropShadowConfig) {
    this.blurContainer?.destroy();

    console.log('init-drop-shadow-element', config);

    const { strength, margin, color } = config;
    this.blurContainer = new Container();

    const { width, height } = this.container.getBounds();

    this.dropShadowElement = new RectangleElement(this, this.blurContainer, {
      width: width + 2 * margin,
      height: height + 2 * margin,
      fill: this.getColorValue(color),
    });

    this.container.addChildAt(this.blurContainer, 0);

    this.blurContainer.setTransform(-margin, -margin);
    const filter = new BlurFilter(strength);
    this.dropShadowElement.element.filters = [filter];
  }

  redraw() {
    super.redraw();
    this.container.alpha = this.opacity || 1;
  }

  updateOpacity(opacity: number): void {
    this.sprite.alpha = opacity;
  }

  scaleImage() {
    this.sprite?.setTransform(0, 0, this._scale.x, this._scale.y);
  }

  _redraw(position: ShapePosition): void {
    super._redraw(position);
    if (!position.scale) {
      return;
    }
    console.log('image-shape > _redraw', position.scale.x, position.scale.y);
    this.applyScale(position.scale as _Scale);
  }

  get href() {
    return `${environment.backend.url}${this.s3Id}`;
  }

  _scaleX: number;
  _scaleY: number;

  startTransformation(dx?: number, dy?: number): void {
    super.startTransformation(dx, dy);
    this._scaleX = this._scale.x;
    this._scaleY = this._scale.y;
  }

  transformation(scaleX: number, scaleY: number, dx?: number, dy?: number) {
    super.transformation(scaleX, scaleY, dx, dy);
    this.scaleX = this._scaleX * scaleX;
    this.scaleY = this._scaleY * scaleY;
    this.applyScale({
      x: this.scaleX,
      y: this.scaleY,
    });
  }
  applyScale(scale: _Scale) {
    this.sprite?.setTransform(0, 0, scale.x, scale.y);
  }
  endTransformation(): void {
    super.endTransformation();
    this.scale = {
      x: this.scaleX,
      y: this.scaleY,
    };
    this.saveScale(this.scale);
  }
}
