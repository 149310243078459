import { Scene } from '../../../elements/resource/types/shape.type';
import { _screenX } from '../../../services/canvas/canvas.service';
import { GeneralShape } from '../../shape/shapes/general/general-shape';
import { RootShape } from '../../shape/shapes/general/root/root-shape';
import { SceneTransitionFrame } from '../components/animation-frame/animation.types';
import { AnimationFrameObject } from './animation-frame-object';
import { ColorIncrementController } from './increment/controller/color-increment.controller';
import {
  Easing,
  IncrementController,
} from './increment/controller/increment.controller';
import { pick as _pick } from 'lodash';
export class SceneTransitionFrameObject extends AnimationFrameObject<SceneTransitionFrame> {
  // -- // -- // -- //
  get to() {
    return this.frame.stateTransition!.to;
  }
  get from() {
    return this.frame.stateTransition!.from;
  }
  get minimalFrameObject() {
    return {
      ...super.minimalFrameObject,
      ..._pick(this.frame, ['hide', 'show', 'transform', 'backgroundColor']),
    };
  }
  select() {
    super.select();
    // if (!this.cs.isShiftPressed) {
    //   this.service.store.dispatch(
    //     setCurrentScenes({
    //       scenes: [this.to],
    //     }),
    //   );
    // }
  }
  backgroundChangeController: ColorIncrementController;
  opacityController1: IncrementController;
  opacityController2: IncrementController;
  fromShapes: GeneralShape[];
  toShapes: GeneralShape[];
  halfTime: number;
  secondStarted = false;
  targetSceneShape: RootShape;
  xIncrement: IncrementController;
  yIncrement: IncrementController;
  async executeAnimation(timeScale?: number): Promise<void> {
    const numberOfBatches = Math.floor(
      this.duration * timeScale * this.cs.batchPerSecond,
    );

    const nextSceneShape = this.shapeService.getNextSceneShape(
      this.shape as RootShape,
    );

    console.log('scene-transition', { nextSceneShape });
    if (!nextSceneShape) {
      return;
    }

    const third = numberOfBatches / 3;
    this.halfTime = numberOfBatches / 2;

    this.cs.canvasScale = 1;

    // console.log('frame', this.frame);
    // nextSceneShape.opacity = 0;

    this.opacityController1 = new IncrementController(
      1,
      0,
      this.halfTime,
      Easing.LINEAR,
    );
    this.opacityController2 = new IncrementController(
      0.5,
      1,
      this.halfTime,
      Easing.LINEAR,
    );

    // -- // -- // console.log('duration', this.duration); // -- // -- //

    // switch (
    //  (SceneTransitionType.FloatRight)
    // case SceneTransitionType.ShowHide:

    //   break;

    // case SceneTransitionType.FloatRight:
    //   const [x, y] = this.shapeService.animationState.canvasPosition;

    //   console.log('adding scene to canvas', [x + _screenX, y]);
    //   // this.cs.addSceneToCanvas(nextSceneShape, [x + _screenX, y]);
    //   console.log('duration', this.duration);

    //   this.xIncrement = new IncrementController(
    //     x + _screenX,
    //     x,
    //     this.duration * this.cs.batchPerSecond,
    //     Easing.LINEAR,
    //   );

    //   this.yIncrement = new IncrementController(
    //     y,
    //     y,
    //     this.duration * this.cs.batchPerSecond,
    //     Easing.LINEAR,
    //   );
    //   break;
    // {
    // }

    // -- // this.toShapes.map(shape => shape._show()); // -- //
    this.currentIncrement = 0;

    // if (this.shape.descriptor.backgroundColorByScene) {
    //   const bgColor = this.shape.descriptor.backgroundColor;
    //   const bgByScene = this.shape.descriptor.backgroundColorByScene;
    //   const [fromColor, toColor] = [
    //     this.from == 'main'
    //       ? bgByScene[this.from] || bgColor
    //       : bgByScene[this.from],
    //     bgByScene[this.to],
    //   ];

    //   if (fromColor && toColor && (backgroundColor || all)) {
    //     this.backgroundChangeController = new ColorIncrementController(
    //       this.shapeService.resolveColor(fromColor),
    //       this.shapeService.resolveColor(toColor),
    //       numberOfBatches,
    //     );
    //   }
    // }
    let nextStarted = false;
    nextSceneShape.__setPreAnimationState();
    await Promise.all([
      nextSceneShape.animationFrame?.animate(),
      new Promise<void>(resolve => {
        this.service.animationFrames[this.id] = increment => {
          this.currentIncrement += increment;
          let end = false;

          if (this.xIncrement) {
            // const x = this.xIncrement.increment(increment);
            // nextSceneShape.rootContainer.position = {
            //   x,
            //   y: this.yIncrement.increment(increment),
            // };
            // this.cs.canvasContainer.setTransform(
            //   x,
            //   this.yIncrement.increment(increment),
            // );
            // this.shapeService.previewShape.applyShapeTranslate({
            //   x: this.xIncrement.increment(increment),
            //   y: this.yIncrement.increment(increment),
            // });
          }

          // if (this.backgroundChangeController) {
          //   this.cs.app.renderer.background.color =
          //     this.backgroundChangeController.increment(increment);
          // }

          if (this.currentIncrement >= numberOfBatches) {
            end = true;
            // Occasional overflow is eliminated //
            increment -= this.currentIncrement - numberOfBatches;
          }

          // if (this.currentIncrement > 2 * third && !) {
          //   nextSceneShape.animationFrame.animate();
          // }

          if (this.currentIncrement < this.halfTime) {
            const opacity = this.opacityController1.increment(increment);
            this.shape.opacity = opacity;
          } else {
            if (!nextStarted) {
              nextStarted = true;
              this.cs.addSceneToCanvas(nextSceneShape, [0, 0]);
              delete this.service.animationFrames[this.id];
              resolve();
            }
            // const opacity = this.opacityController2.increment(increment);
            // nextSceneShape.opacity = opacity;
            // end = true;
          }

          if (end) {
            // resolve();
          }
        };
      }),
    ]);

    // await nextSceneShape.animationFrame.animate();
  }

  getPosition(scenes: Scene[], mainScene: string, subScene: string) {
    const mainSceneIndex = scenes.findIndex(s => s.name == mainScene);
    const subSceneIndex = scenes[mainSceneIndex].subscenes.findIndex(
      s => s.name == subScene,
    );
    return scenes[mainSceneIndex].subscenes[subSceneIndex].position;
  }
}
