import {
  Coords,
  HandShapeConfig,
  HandShapeNewDescriptor,
  ShapeControlPointPosition,
} from '../../../../../elements/resource/types/shape.type';
import { PathShape, PathShapeConfig } from '../path-shape';
import { PathItem } from '../path-shape.types';
import { selectHandShapeConfig } from '../../../../store/selector/editor.selector';
import { cloneDeep, isEqual } from 'lodash';
import { GeneralShape } from '../../general/general-shape';
import { ShapeService } from '../../../shape.service';
import { ResourceData } from '../../../../../elements/resource/resource.types';
import { HandSectionNext } from './hand-section-next';
import { PathElement } from '../../primitive/path-element';
import { currentAnimationId } from '../../../../animation/store/animation.selector';
import { setDescriptorValue } from '../../../../store/editor.crud.actions';

export interface HandShapeSectionDescriptorNew {
  x: number;
  y: number;
  r: number;
  flat?: boolean;
  corner?: boolean;
  d1?: number;
  d2?: number;
  cornerRadius?: number;
  end?: 'flat' | 'arc' | 'half-arc' | 'half-arc-inverse';
  joint?: 'symm' | 'assym' | 'assym-inverse';
  stroke?: {
    color: string;
    width: number;
    top?: { start: number; end: number };
    side?: { start: number; end: number };
    bottom?: { start: number; end: number };
  };
  curve?: number;
}

const { PI, sin, cos, pow, sqrt } = Math;

export class HandShapeNext extends PathShape<HandShapeNewDescriptor> {
  get isClosed() {
    return this.handIsClosed;
  }
  handConfig: HandShapeConfig;
  originalConfig: HandShapeConfig;
  handIsClosed: boolean;
  handSections: HandSectionNext[];
  getType(): string {
    return 'hand-shape-next';
  }
  get lastSectionIndex() {
    return this.handSections.length - 1;
  }
  get lastHandSection() {
    return this.handSections[this.lastSectionIndex];
  }

  get firstHandSection() {
    return this.handSections[0];
  }

  get lastButOneHandSection() {
    return this.handSections[this.lastSectionIndex - 1];
  }

  constrainedShape: GeneralShape;
  constraintPoint: ShapeControlPointPosition;
  handSectionDescriptors: HandShapeSectionDescriptorNew[];
  curve: number;

  _config: HandShapeConfig;

  constructor(
    service: ShapeService,
    data: ResourceData<HandShapeNewDescriptor>,
    config: PathShapeConfig,
  ) {
    super(service, data, config);
    this.strokeElements = [];
    // console.log('hand-shape-next', this.descriptor.config);
    if (!this.descriptor.config) {
      return;
    }

    this.handIsClosed = !!this.descriptor.config.closed;
    this.curve = this.descriptor.config.curve;
    this.originalConfig = this.descriptor.config;
    this.handSectionDescriptors = this.descriptor.config.handSections;

    this.store
      .select(currentAnimationId)
      .subscribe(id => (this.currentAnimationId = id));
    this.store.select(selectHandShapeConfig(this.IRI)).subscribe(config => {
      if (!isEqual(this.handConfig, config)) {
        this.handConfig = config;

        const { handSections, closed, curve } = config || {};
        if (!handSections?.length) {
          return;
        }

        if (
          !isEqual(this.handSectionDescriptors, handSections) ||
          this.curve !== curve ||
          this.handIsClosed !== closed
        ) {
          if (!this.currentAnimationId) {
            this.handIsClosed = closed;
            this.curve = curve;
          }
          this.handSectionDescriptors = cloneDeep(handSections);
          this.initHandSections();
          this.showDragControllers();
          this.refreshElement();
        }

        if (!this.currentAnimationId) {
          this.handIsClosed = closed;
          this.curve = curve;
        }
      }
    });

    if (this.editable) {
      this.cs.keyDownEventSubscribe('c', () => {
        if (this.selected) {
          return;
        }
        this.handSections?.map(hs => hs.pc.show());
      });

      this.cs.keyEventSubscribe('c', () => {
        if (this.selected) {
          return;
        }
        this.handSections?.map(hs => hs.pc.hide());
      });
    }

    this.initHandSections();
  }

  hoverSelect() {
    this.baseLine.show();
    this.handSections.map(hs => hs.pc.show());
  }

  hoverDeselect(): void {
    if (this.selected) {
      return;
    }
    this.baseLine.hide();
    this.handSections.map(hs => hs.pc.hide());
  }

  applyAnimationByTime(time: number, directApply = false) {
    if (time == undefined) {
      return;
    }

    let changed = false;
    const updates = super.applyAnimationByTime(time, directApply);
    Object.entries(this.consequtiveAnimationsByKey || {}).map(
      ([animationKey]) => {
        switch (animationKey) {
          case 'config':
            const config =
              this.animationService.getShapeAttributeTillTime<HandShapeConfig>(
                time,
                this.IRI,
                this.originalConfig,
                'config',
              );
            if (directApply) {
              changed = true;
              this.handConfig = config;
            }
            updates.config = { value: config };
            break;
        }
      },
    );

    if (changed) {
      this.refreshElement();
    }

    return updates;
  }

  startAnimation(
    id: string,
    division: number,
    inverse?: boolean,
    duration?: number,
  ): void {
    super.startAnimation(id, division, inverse, duration);
    this.getAnimationsById(id).map(({ key, value }) => {
      switch (key) {
        case 'config':
          const { handSections } = value as HandShapeConfig;
          this.handSections.map((hs, i) =>
            hs.startAnimation(handSections[i], division),
          );
          break;
      }
    });
  }

  firstAdjustMode = false;

  rotateDrag = false;

  startDragBy(
    point: string,
    shapeStore: Record<string, boolean> = {},
    { rotate, direct }: { rotate?: boolean; direct?: boolean } = {},
  ) {
    if (!point) {
      return [];
    }

    shapeStore[this.IRI] = true;

    this.rotateDrag = rotate;

    const index = +point.split('.')[1];

    this.dragBase = [this.x, this.y];
    switch (index) {
      case 0:
        const furtherConstraints = direct
          ? this.getConstraintsByPoint('section.0', shapeStore, { rotate })
          : [];

        if (this.cs.isShiftPressed && direct) {
          this.firstAdjustMode = true;
          return [
            {
              shape: this,
              point,
            },
            ...furtherConstraints,
          ];
        }

        if (!this.rotateDrag && furtherConstraints.find(c => !c.limit.l)) {
          this.rotateDrag = true;
        } else {
          // base drag mode
          this.handSections.slice(1).map(section => {
            furtherConstraints.push(
              ...this.getConstraintsByPoint(section.id, shapeStore, { rotate }),
            );
          });
        }

        if (this.rotateDrag) {
          // -- // -- //
          this.handSections[0].startDrag(shapeStore, true);
        }

        return [
          {
            shape: this,
            point,
          },
          ...furtherConstraints,
        ];

      // for (let i = 1; i < this.handSections.length; i++) {
      //   this.handSections[i].startDrag(shapeStore);
      // }

      default:
        const section = this.handSections[index];
        section.startDrag(shapeStore, true);

        // this.handSections
        //   .filter((hs, i) => i > index)
        //   .map((hs, i) => {
        //     hs.startDrag();
        //   });

        // console.log(
        //   'default',
        //   point,
        //   this.getConstraintsByPoint(point, shapeStore),
        // );
        // const furtherConstraints = this.getConstraintsByPoint(
        //   point,
        //   shapeStore,
        // );

        return [
          {
            shape: this,
            point,
            limit: this.cs.isShiftPressed
              ? undefined
              : {
                  x: section.x,
                  y: section.y,
                  l: sqrt(pow(section.x, 2) + pow(section.y, 2)),
                  external: true,
                },
          },
          // ...furtherConstraints,
          // ...this.getConstraintsByPoint(point, shapeStore),
          // ...(index == this.handSections.length - 1
          //   ? []
          //   : this.startDragBy(`section.${index + 1}`, shapeStore)
          // ).map(constraint => ({
          //   ...constraint,
          //   limit: undefined,
          // })),
        ].filter(v => !!v);
    }
  }

  dragBy(point: string, [dx, dy]: Coords, dAngle?: number): void {
    const index = +point.split('.')[1];
    const [x, y] = this.dragBase;

    this._diffs = [dx, dy];
    if (index == 0) {
      // -- // -- //
      // console.log('hs > dragBy 0', this.IRI, dAngle);
    }

    switch (index) {
      case 0:
        if (!this.firstAdjustMode) {
          this.applyTranslate({
            x: x + dx,
            y: y + dy,
          });

          // -- // -- // -- // -- // -- //
          if (this.rotateDrag || dAngle !== undefined) {
            this.handSections[0].dragByAngle(dAngle);
          }

          this.refreshElement();
          return;
        }

        for (let i = 1; i < this.handSections.length; i++) {
          this.handSections[i].drag(-dx, -dy, dAngle);
        }
        // -- // -- //
        this.refreshElement();
        this.applyTranslate({
          x: x + dx,
          y: y + dy,
        });
        break;
      default:
        this.handSections[index].drag(dx, dy);
        this.refreshElement();
        break;
    }
  }

  endDragBy(point, [dx, dy] = this._diffs) {
    const index = +point.split('.')[1];
    if (index == 0 || this.firstAdjustMode) {
      this.saveTranslate(dx, dy);
    }
    this.firstAdjustMode = false;
    this.rotateDrag = false;
    this.saveSections();

    // console.log('endDragBy - index', index, 'handSections', this.handSections);
    this.handSections[index].endDrag();
  }

  incrementAnimation(increment: number, id?: string, percent?: number): void {
    super.incrementAnimation(increment, id, percent);
    this.getAnimationsById(id).map(({ key }) => {
      switch (key) {
        case 'config':
          this.handSections.map(hs => hs.incrementAnimation(increment));
          this.refreshElement();
          break;
      }
    });
  }

  initHandSections() {
    // console.log('init-hand-sections', this.handSectionDescriptors);
    this.handSections?.map(hs => hs.remove());
    this.handSections = this.handSectionDescriptors?.map(
      (config, i) => new HandSectionNext(this, config, i),
    );

    this.handSections.map(hs => hs.afterInit());
  }

  getAbsCoordsOfControlPoint(pointId: string): number[] {
    if (pointId.startsWith('section')) {
      const [, index] = pointId.split('.');
      const section = this.handSections[index];

      return [this.x + section.absX, this.y + section.absY];
    }
    return super.getAbsCoordsOfControlPoint(pointId);
  }

  updateSection(index: number, section: HandShapeSectionDescriptorNew) {
    this.handSectionDescriptors[index] = section;
    this.saveSections();
  }
  baseConfigs: Coords[];

  firstSectionDrag = false;
  dragSectionStartIndex: number;
  dragSectionEndIndex: number;

  _startDrag(key: string, sourceShapeIRI?: string): void {
    if (!this.cs.isPressed('n')) {
      this.startConstraintDrag(key, sourceShapeIRI || this.IRI);
    }

    if (!this.isClosed && key == 'section.0') {
      return this.startBaseDrag(key, { [sourceShapeIRI || this.IRI]: true });
    }

    const index = +key.split('.')[1];
    if (this.isClosed) {
      if (index == 0) {
        this.startBaseDrag(key, { [sourceShapeIRI || this.IRI]: true }, true);
        this.firstSectionDrag = true;
        this.dragSectionStartIndex = 1;
        this.dragSectionEndIndex = 1000;
      } else {
        this.dragSectionStartIndex = index;
        this.dragSectionEndIndex = index;
      }
    } else {
      this.dragSectionStartIndex = index;
      this.dragSectionEndIndex = index + 1;
    }

    this.baseConfigs = this.handSections
      .slice(this.dragSectionStartIndex, this.dragSectionEndIndex + 1)
      .map(hs => [hs.x, hs.y]);

    if (!this.isClosed && index == 1 && !this.cs.isPressed('n')) {
      this.startConstraintDrag('section.2', sourceShapeIRI || this.IRI);
    }
  }
  _drag(x: number, y: number, dx: number, dy: number) {
    this.constrainedShapes.map(shape => shape._drag(x, y, dx, dy));

    if (this.dragSectionStartIndex == undefined) {
      this.firstHandSection.pc.patch({
        p: [0, 0],
      });
      this.drag(dx, dy);
      return;
    }

    if (this.firstSectionDrag) {
      this.drag(dx, dy);
    }

    this.handSections
      .slice(this.dragSectionStartIndex, this.dragSectionEndIndex + 1)
      .map((hs, i) => {
        const [x, y] = this.baseConfigs[i];
        hs.x = x + (this.firstSectionDrag ? -dx : dx);
        hs.y = y + (this.firstSectionDrag ? -dy : dy);
        hs.refreshPCs();
      });

    this.handSections.map(hs => hs.refreshPCs());
    this.refreshElement();
  }

  _endDrag() {
    this.endDrag();
    // if (this.dragSectionStartIndex == undefined) {
    //   this.endDrag();
    // }
    this.firstSectionDrag = false;
    this.dragSectionStartIndex = undefined;
    this.dragSectionEndIndex = undefined;
    this.saveSections();
  }

  saveSections() {
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'config',
        innerKey: 'handSections',
        value: cloneDeep(this.handSectionDescriptors),
      }),
    );
  }

  strokeElement: PathElement;
  strokeElements: PathElement[] = [];

  afterInit() {
    super.afterInit();
    // this._config = this.descriptor._config;

    // if (this._config.constraintTo) {
    //   const { shapeIRI, point } = this._config.constraintTo;
    //   this.constrainedShape = this.service.getShapeByIRI(shapeIRI);
    //   this.constraintPoint = point;
    //   if (this.constrainedShape) {
    //     this.constrainedShape.constrainedBy = point;

    //     if (this.constrainedShape instanceof HandShapeNextNext) {
    //       const [_x, _y] = this.xy;

    //       this.constrainedShape.applyShapeTranslate({
    //         x: this.x + _x,
    //         y: this.y + _y,
    //       });
    //     }
    //   }
    // }

    // this.turnPC.hide(); //
    this.refreshElement();
  }

  completeSelect(): void {
    this.handSections.map(hs => hs.showRC());
    this.baseLine.hide();
  }

  showDragControllers(): void {
    // DragPC can be removed
    this.handSections.map(hs => hs.showRC());
  }

  hideDragControllers(): void {
    this.handSections.map(hs => hs.hideRC());
  }

  dragOntoHandler() {
    // -- // -- // -- //
  }

  refreshElement(start?: number, end?: number): void {
    // -- // -- // -- //
    super.refreshElement();
    // -- // -- // -- //

    this.handSections?.map(hs => hs.initStrokeElements());
    // if (this.handSections) {
    //   const strokeSections = [];
    //   this.handSections.map((_hs, index) => {
    //     strokeSections.push([index, 'side']);
    //     strokeSections.push([index, 'bottom']);
    //   });

    //   if (!this.handIsClosed) {
    //     for (let i = this.handSections.length - 1; 0 < i; i--) {
    //       strokeSections.push([i, 'top']);
    //     }
    //   }

    //   this.strokeElements?.map(se => se.remove());
    //   this.strokeElements = [];
    // }
  }

  _getElements(start?: number, end?: number) {
    return this._elements;
  }

  get _elements(): PathItem[] {
    const _elements = [];

    if (!this.handSections) {
      return [];
    }

    this.handSections.map(section => section.calcAngles());
    this.handSections.map(section =>
      _elements.push(...section.getForwardElements()),
    );

    if (!this.isClosed) {
      this.handSections
        .reduce((array, section) => {
          array.unshift(section);
          return array;
        }, [])
        .map(section => _elements.push(...section.getBackwardElements()));
    }

    // console.log('_element', _elements);
    return _elements;
  }

  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }
    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return PI - angle;
    }
    if (x < 0 && y < 0) {
      return PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? PI / 2 : (3 * PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : PI;
    }
  }
}
