import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  loadFileAction,
  resetPatchedShapes,
  saveResourcesRequestAction,
  setPatchLoading,
  saveSceneRequest,
  saveSceneRequestComplete,
  removeSceneRequest,
  removeAllScenesRequest,
  saveNewComponentRequestAction,
  addFileToStore,
  setShapes,
  setFiles,
  deleteComponent,
  createNewScene,
  setSubsceneLoaded,
  assignSceneToFile,
  setFileLoading,
} from '../editor.actions';
import {
  selectFile,
  selectedShapes,
  selectCurrentFileResourceData,
} from '../selector/editor.selector';
import { HttpService } from '../../../store/http/http.service';
import { RequestType } from '../../../store/actions';
import { ResourceData } from '../../../elements/resource/resource.types';

import { omit as _omit, cloneDeep, omit } from 'lodash';
import {
  DeprecatedGeneralShapeDescriptor,
  GeneralShapeDescriptor,
  ImportedShapeDescriptor,
  SVGAttributes,
  ShapeTransform,
  StrokeConfig,
  _SVGAttributes,
  __Animation,
} from '../../../elements/resource/types/shape.type';
import { EditorService } from '../../editor.service';
import { RelationshipPatches } from '../reducer/editor.reducer';
import { currentOrganisationID } from '../../../organisation/organisation.selector';
import { IdToIRI, IRIToId } from '../../../util/iri';
import { getCurrentProjectID } from '../../../projects/project.selector';

@Injectable()
export class EditorRequestEffects {
  addShapeToStore$: Observable<Action>;
  newShape$: Observable<Action>;
  loadFile$: Observable<Action>;
  loadScene$: Observable<Action>;
  loadSceneRequest$: Observable<Action>;
  saveShapes$: Observable<Action>;
  saveShapesRequest$: Observable<Action>;
  setShapeAttributeAction$: Observable<Action>;
  updateShapeDescriptorAction$: Observable<Action>;
  addFileRequest$: Observable<Action>;
  saveSceneRequest$: Observable<Action>;
  createNewScene$: Observable<Action>;
  assignSceneToFile$: Observable<Action>;
  removeSceneRequest$: Observable<Action>;
  removeAllScenesRequest$: Observable<Action>;
  saveNewComponent$: Observable<Action>;
  deleteComponent$: Observable<Action>;
  saveResources$: Observable<Action>;
  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly http: HttpService,
    private readonly editorService: EditorService,
  ) {
    this.deleteComponent$ = createEffect(() =>
      this.actions$.pipe(
        ofType(deleteComponent),
        withLatestFrom(this.store.select(currentOrganisationID)),
        switchMap(([{ IRI }]) => {
          return this.editorService.deleteComponent(IRIToId(IRI));
        }),
        switchMap(() => []),
      ),
    );

    this.loadFile$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(loadFileAction),
        switchMap(({ ID, mode }) => {
          return this.editorService.loadFiles(ID, mode == 'subscene-load').pipe(
            map(res => ({
              ...res,
              mode,
              ID,
            })),
          );
        }),
        switchMap(({ shapes, files, mode, ID }) => {
          const actions = [
            setShapes({ shapes }),
            setFiles({
              files,
            }),
            setFileLoading({ value: false }),
          ];

          console.log('mode', mode);
          switch (mode) {
            case 'main-file':
              return [
                ...actions,
                addFileToStore({
                  file: files[IdToIRI(ID)],
                  shapes,
                }),
              ];
            case 'imported-shape':
              return actions;
            case 'subscene-load':
              return [...actions, setSubsceneLoaded({ value: true })];
          }
        }),
      );
    });

    // this.saveResources$ = createEffect(() => {
    //   this.actions$.pipe(
    //     ofType(saveResourcesAction),
    //     withLatestFrom(this.store.select(selectResourcePatches)),
    //     switchMap(([{ data: body }]) => {
    //       // ------ // ------ // -- //
    //       // return this.http.requestCall({
    //       //   type: RequestType.POST,
    //       //   path: 'shape-v2/component',
    //       //   body,
    //       // });
    //     }),
    //     switchMap(() => []),
    //   );
    // });

    this.saveNewComponent$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveNewComponentRequestAction),
        withLatestFrom(this.store.select(selectedShapes)),
        switchMap(([{ data: body }]) => {
          // ------ // ------ // -- //
          return this.http.requestCall({
            type: RequestType.POST,
            path: 'shape-v2/component',
            body,
          });
        }),
        switchMap(() => []),
      ),
    );

    this.createNewScene$ = createEffect(() =>
      this.actions$.pipe(
        ofType(createNewScene),
        withLatestFrom(this.store.select(getCurrentProjectID)),
        switchMap(([{ scene, fileID, index }, projectID]) =>
          this.http.requestCall({
            type: RequestType.POST,
            path: 'shape-v2/scene',
            body: {
              scene,
              fileID,
              projectID,
              index,
            },
          }),
        ),
        switchMap(() => [saveSceneRequestComplete()]),
      ),
    );

    this.assignSceneToFile$ = createEffect(() =>
      this.actions$.pipe(
        ofType(assignSceneToFile),
        switchMap(({ mainFile, fileID, index }) =>
          this.http.requestCall({
            type: RequestType.POST,
            path: 'shape-v2/scene/assign',
            body: {
              mainFile,
              fileID,
              index,
            },
          }),
        ),
        switchMap(() => [saveSceneRequestComplete()]),
      ),
    );

    this.saveSceneRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveSceneRequest),
        withLatestFrom(this.store.select(selectedShapes)),
        switchMap(([{ scene }, selectedShapes]) =>
          this.http.requestCall({
            type: RequestType.PATCH,
            path: 'shape-v2/scene',
            body: selectedShapes.map(shapeIRI => ({ shapeIRI, scene })),
          }),
        ),
        switchMap(() => [saveSceneRequestComplete()]),
      ),
    );

    this.removeSceneRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(removeSceneRequest),
        withLatestFrom(this.store.select(selectedShapes)),
        switchMap(([{ scene }, selectedShapes]) =>
          this.http.requestCall({
            type: RequestType.DELETE,
            path: 'shape-v2/scene',
            body: selectedShapes.map(shapeIRI => ({ shapeIRI, scene })),
          }),
        ),
        switchMap(() => [saveSceneRequestComplete()]),
      ),
    );

    this.removeAllScenesRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(removeAllScenesRequest),
        withLatestFrom(this.store.select(selectedShapes)),
        switchMap(([, selectedShapes]) =>
          this.http.requestCall({
            type: RequestType.DELETE,
            path: 'shape-v2/scene-all',
            body: selectedShapes,
          }),
        ),
        switchMap(() => [saveSceneRequestComplete()]),
      ),
    );

    // this.addFileRequest$ = createEffect() => {
    //   ofType(addFileActionRequest)
    //   withLatestFrom(this.store.select(getCurrentProjectID))
    //   exhaustMap(([{ name }, currentProjectID]) => {
    //     return this.http.requestCall({
    //       type: RequestType.POST,
    //       path: 'editor/file',
    //       body: {
    //         type: 'nw:File,',
    //         literals: {
    //            label: name,
    //         },
    //         relationships: {
    //           project: { IRI: `nw:${currentProjectID}` },
    //         },
    //       }
    //     })
    //   }),
    //   switchMap(( { }))
    // }

    this.saveShapesRequest$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(saveResourcesRequestAction),
        withLatestFrom(this.store.select(selectCurrentFileResourceData)),
        switchMap(([{ posts, patches, deletes }, file]) => {
          console.log('--------', { posts, patches, deletes });
          return this.http.requestCall({
            type: RequestType.PATCH,
            path: 'shape',
            body: {
              file,
              posts,
              patches,
              deletes,
            },
          });
        }),
        switchMap(() => {
          return [setPatchLoading({ value: false }), resetPatchedShapes()];
        }),
        catchError(error => {
          console.error('saveShapesRequest', error.message);
          return of(error.message);
        }),
      );
    });
  }

  mapDescriptor(
    descriptor: DeprecatedGeneralShapeDescriptor | GeneralShapeDescriptor,
  ): GeneralShapeDescriptor {
    if (descriptor.version == 2) {
      return descriptor as GeneralShapeDescriptor;
    }

    let { position, svgAttributes, animations } = descriptor;
    const { _animationsByKey } = descriptor;

    animations = animations
      ? animations
      : Object.entries(_animationsByKey || {}).reduce((object, [id, value]) => {
          object[id] = value.reduce((obj, val) => {
            obj[val.key] = omit(val, 'key');
            return obj;
          }, {});

          return object;
        }, {});

    svgAttributes = this.getSVGAttributes(svgAttributes || {});
    position = {
      translate: position.translate || {
        x: position.x,
        y: position.y,
      },
      scale: position.scale,
      rotation: position.rotation,
    };

    delete descriptor._animationsByKey;

    // if ((descriptor as TextShapeDescriptor)?.textConfig) {
    //   // this is already the new structure
    //   return descriptor;
    // }
    // return {
    //   ...descriptor,
    //   textConfig: (descriptor as TextShapeDescriptor)?.text
    //     ? _pick(descriptor as TextShapeDescriptor, [
    //         'text',
    //         'fontSize',
    //         'align',
    //         'fontStyle',
    //         'fontWeight',
    //         'wordWrap',
    //         'wordWrapWidth',
    //         'letterSpacing',
    //         'fontFamily',
    //       ])
    //     : undefined,
    // };

    return {
      ...descriptor,
      position,
      svgAttributes,
      animations,
    };
  }

  getSVGAttributes(
    svgAttributes: _SVGAttributes | SVGAttributes,
  ): SVGAttributes {
    const strokeWidth = svgAttributes?.['stroke-width'];
    return {
      stroke: svgAttributes?.stroke
        ? ({
            color: svgAttributes.stroke,
            width: strokeWidth,
            dash: (svgAttributes as _SVGAttributes).dash,
          } as StrokeConfig)
        : undefined,
      fill: svgAttributes?.fill
        ? {
            color: svgAttributes.fill,
            gradient: (svgAttributes as _SVGAttributes).gradient,
            gradientDirection: (svgAttributes as _SVGAttributes)
              .gradientDirection,
            gradientColor: (svgAttributes as _SVGAttributes).gradientColor,
          }
        : undefined,
    } as SVGAttributes;
  }

  getShapeResourceData(
    IRI: string,
    transforms: Record<string, ShapeTransform>,
    descriptors: Record<string, GeneralShapeDescriptor>,
    svgAttributes: Record<string, SVGAttributes>,
    shapes: Record<string, ResourceData>,
    updatesByState: Record<string, any>,
    animations: any,
    relationshipPatches: RelationshipPatches,
  ): any {
    let override = {};
    const relationships: Record<string, any> = {};
    const { x, y } = transforms[IRI].scale || { x: 0, y: 0 };
    if (!descriptors[IRI]) {
      return;
    }
    switch (descriptors[IRI].type) {
      case 'rectangle-shape':
        override = {
          width: x,
          height: y,
        };
        break;
      case 'circle-shape':
        override = {
          rx: (x as number) / 2,
          ry: (y as number) / 2,
        };
        break;
      // case 'imported-shape':
      //   relationships.instanceOf = (
      //     descriptors[IRI] as ImportedShapeDescriptor
      //   ).shapeIRI;
      //   break;
    }
    return {
      ...shapes[IRI],
      type: 'nw:Shape',
      literals: {
        ...shapes[IRI].literals,
        descriptor: {
          ...descriptors[IRI],
          position: {
            ...transforms[IRI].translate,
            scale: transforms[IRI]?.scale,
            rotation: transforms[IRI]?.rotation,
          },
          svgAttributes: this.editorService.getBackSVGAttributes(
            svgAttributes[IRI],
          ),
          _animationsByKey: Object.entries(animations[IRI] || {}).reduce(
            (object, [animationId, animations]) => {
              object[animationId] = Object.entries(animations || {}).map(
                ([key, value]) => ({ ...value, key }),
              );
              return object;
            },
            {},
          ),
          updatesByState: updatesByState?.[IRI],
          ...override,
          // fixPosition: false,
          'show-hide': undefined,
        },
      },
      relationships: {
        ...relationships,
        ...(relationshipPatches[IRI] || {}),
      },
    };
  }
}
