import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CanvasService } from '../../../services/canvas/canvas.service';

@Component({
  selector: 'nw-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.scss'],
})
export class EditableTextComponent implements OnInit, OnDestroy {
  @Input()
  underline: boolean;

  @Input()
  id: string;

  @Input()
  text: string;

  @Output()
  textChanged = new EventEmitter<string>();

  editMode = false;

  @Input()
  editDisabled: boolean;

  @ViewChild('host') host: ElementRef;

  @ViewChild('input') input: ElementRef;

  sub: Subscription;

  // enterSubscription: Subscription;

  // @Input()
  // set selected(val: boolean) {
  //   if (val) {
  //     this.enterSubscription?.unsubscribe();
  //     this.enterSubscription = this.cs.keyEventSubscribe('Enter', () => {
  //       this.activateInput();
  //     });
  //   } else {
  //     this.enterSubscription?.unsubscribe();
  //   }
  // }

  @Input()
  set activate(val: boolean) {
    if (val) {
      this.activateInput();
    } else {
      this.deActivateInput();
    }
  }

  activateInput() {
    this.editMode = true;
    setTimeout(() => {
      // this.input.nativeElement.focus();
      this.input?.nativeElement.select();
    }, 10);
  }

  deActivateInput() {
    this.editMode = false;
    setTimeout(() => {
      this.input?.nativeElement.blur();
    }, 10);
  }

  ngOnInit(): void {
    this.sub = this.cs.generalEventSubscribe('globalClick', () => {
      this.editMode = false;
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  // @HostListener('document:click', ['$event'])
  // async click(e: MouseEvent) {
  //   if (!this.editMode) {
  //     return;
  //   }
  //   if (!this.host.nativeElement.contains(e.target)) {
  //     this.finishEdit();
  //   }
  // }

  constructor(private readonly cs: CanvasService) {}

  clickText(e: MouseEvent) {
    if (this.editDisabled || this.editMode) {
      return;
    }
    e.stopPropagation();
    this.editMode = true;
  }

  finishEdit() {
    this.editMode = false;
    this.input.nativeElement.blur();
    // this.input.nativeElement.blur(); //
    this.textChanged.emit(this.text);
  }

  change(event: KeyboardEvent) {
    const key = event.key;
    this.cs.consumeKeyEvent(key);

    if (key === 'Enter' || key === 'Tab') {
      this.finishEdit();
    } else {
      event.stopPropagation();
    }
  }
}
