import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAttributeComponent } from '../../../base/base-attribute.component';
import { _attributeState } from '../../../../store/selector/editor.selector';

@Component({
  selector: 'iw-rotate-effect-attribute',
  templateUrl: './rotate-effect-attribute.component.html',
  styleUrls: ['./rotate-effect-attribute.component.scss'],
})
export class RotateEffectAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'rotateEffect';
    this.attributeState$ = this.store.select(_attributeState('rotateEffect'));
    this.attributeState$.subscribe(state => {
      // -- // -- // -- //
    });
  }
  ngOnInit(): void {}
}
