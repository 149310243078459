import { SVG } from './svg-element';
import { BaseVector } from '../../../../elements/base/vector/vector';
import { CurveHoverItem } from '../path-shape/path-shape.types';
import { cloneDeep } from 'lodash';
import { Graphics } from 'pixi.js';
import { PathShape } from '../path-shape/path-shape';

export class CurveCoverElement extends SVG<{
  hoverItem: CurveHoverItem;
}> {
  get pathShape() {
    return this.parent as PathShape;
  }

  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }
    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return Math.PI - angle;
    }
    if (x < 0 && y < 0) {
      return Math.PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * Math.PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? Math.PI / 2 : (3 * Math.PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : Math.PI;
    }
  }

  initElement() {
    this._element = new Graphics();
  }
  updateAttr() {
    super.updateAttr();

    const { x: px, y: py } = cloneDeep(this.position);
    // this.element.moveTo(px, py);
    let [_x, _y] = [px, py];

    const { x, y, bx, by, cx, cy, height } = this.config
      .hoverItem as CurveHoverItem;

    // console.log('cc', { x, y, bx, by, cx, cy, height });

    let ab = this.getAngle(bx, by);
    const angle = this.getAngle(x, y);
    let cc: boolean;

    if (!bx && !by) {
      ab = this.getAngle(cx, cy);
    }

    if (angle < ab) {
      cc = ab - angle < Math.PI;
    } else {
      cc = angle - ab > Math.PI;
    }

    const vb = new BaseVector(!bx && !by ? [cx, cy] : [bx, by]);
    const vc = new BaseVector(!cx && !cy ? [bx, by] : [cx, cy]);

    const v = new BaseVector([x, y]);

    const _startCW = vb.isClockwise(v);
    const _endCW = vc.isClockwise(v);

    const spline = (!_startCW && _endCW) || (_startCW && !_endCW);

    const vbl = vb.length;
    const dB = [bx, by].map(val => (val * height) / vbl);

    const vcl = vc.length;
    const dC = [cx, cy].map(val => (val * height) / vcl);

    if (cc) {
      vb.rotate(Math.PI / 2);
      vc.rotate(-Math.PI / 2);
    } else {
      vb.rotate(-Math.PI / 2);
      vc.rotate(Math.PI / 2);
    }
    vb.reScale(height / 2);
    vc.reScale(height / 2);

    const { x: bDx, y: bDy } = vb.end;
    const { x: cDx, y: cDy } = vc.end;

    _x += bDx;
    _y += bDy;

    this.element.lineTo(_x, _y);

    const l = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
    const ratio = (l + height) / l;

    // const _vb = new BaseVector([bx, by]).scale(ratio);
    // const _vc = new BaseVector([cx, cy]).scale(ratio);

    _x = px + x + cDx;
    _y = py + y + cDy;

    this.element.bezierCurveTo(
      px + bDx + bx + dB[0],
      py + bDy + by + dB[1],
      px + cDx + x + cx + (!spline ? dC[0] : -dC[0]),
      py + cDy + y + cy + (!spline ? dC[1] : -dC[1]),
      _x,
      _y,
    );

    _x -= 2 * cDx;
    _y -= 2 * cDy;

    this.element.lineTo(_x, _y);

    this.element.bezierCurveTo(
      px - cDx + x + cx + (spline ? dC[0] : -dC[0]),
      py - cDy + y + cy + (spline ? dC[1] : -dC[1]),
      px - bDx + bx + -dB[0],
      py - bDy + by + -dB[1],
      px - bDx,
      py - bDy,
    );

    this.element.closePath();
    this.element.endFill();
  }
}
