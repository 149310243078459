// import { Easing, IncrementController } from './increment.controller';

export class ColorIncrementController {
  currentIncrement = 0;

  startR: number;
  startG: number;
  startB: number;

  endR: number;
  endG: number;
  endB: number;

  constructor(
    startHex: string,
    endHex: string,
    private maxIncrement: number,
  ) {
    const start = Number(`0x${(startHex || '#ffffff').substring(1)}`);
    const end = Number(`0x${(endHex || '#ffffff').substring(1)}`);

    this.startR = (start >> 16) & 0xff;
    this.startG = (start >> 8) & 0xff;
    this.startB = start & 0xff;

    // Extract the RGB components from the end color
    this.endR = (end >> 16) & 0xff;
    this.endG = (end >> 8) & 0xff;
    this.endB = end & 0xff;
  }

  static getColor(startHex: string, endHex: string, ratio: number) {
    const start = Number(`0x${(startHex || '#ffffff').substring(1)}`);

    if (!endHex) {
      return;
    }
    const end = Number(`0x${endHex.substring(1)}`);

    const startR = (start >> 16) & 0xff;
    const startG = (start >> 8) & 0xff;
    const startB = start & 0xff;

    // Extract the RGB components from the end color
    const endR = (end >> 16) & 0xff;
    const endG = (end >> 8) & 0xff;
    const endB = end & 0xff;

    const newR = Math.round(startR + ratio * (endR - startR));
    const newG = Math.round(startG + ratio * (endG - startG));
    const newB = Math.round(startB + ratio * (endB - startB));

    // Combine the interpolated components back into a single color
    const newColor = (newR << 16) | (newG << 8) | newB;

    return this.numberToHexColor(newColor);
  }

  static numberToHexColor(number) {
    // Extract the RGB components from the number
    const r = (number >> 16) & 0xff;
    const g = (number >> 8) & 0xff;
    const b = number & 0xff;

    // Convert each component to a two-digit hexadecimal string
    const rHex = r.toString(16).padStart(2, '0');
    const gHex = g.toString(16).padStart(2, '0');
    const bHex = b.toString(16).padStart(2, '0');

    // Concatenate the components into a single hex string
    return `#${rHex}${gHex}${bHex}`;
  }

  transitionColor(ratio: number) {
    // Ensure the ratio is clamped between 0 and 1
    ratio = Math.max(0, Math.min(1, ratio));

    // Interpolate each component
    const newR = Math.round(this.startR + ratio * (this.endR - this.startR));
    const newG = Math.round(this.startG + ratio * (this.endG - this.startG));
    const newB = Math.round(this.startB + ratio * (this.endB - this.startB));

    // Combine the interpolated components back into a single color
    const newColor = (newR << 16) | (newG << 8) | newB;

    return newColor;
  }

  getValue(t: number) {
    return Math.min(1, t);
  }

  increment(increment: number) {
    this.currentIncrement += increment;
    const t = this.getValue(this.currentIncrement / this.maxIncrement);
    const color = this.transitionColor(t);
    return this.numberToHexColor(color);
  }

  numberToHexColor(number) {
    // Extract the RGB components from the number
    const r = (number >> 16) & 0xff;
    const g = (number >> 8) & 0xff;
    const b = number & 0xff;

    // Convert each component to a two-digit hexadecimal string
    const rHex = r.toString(16).padStart(2, '0');
    const gHex = g.toString(16).padStart(2, '0');
    const bHex = b.toString(16).padStart(2, '0');

    // Concatenate the components into a single hex string
    return `#${rHex}${gHex}${bHex}`;
  }
}
