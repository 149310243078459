<div class="color-selector-comp flex-column">
  <div
    *ngFor="
      let option of colorOptions$ | async;
      let i = index;
      let last = last;
      let first = first
    "
    class="flex-row gap-8"
    style="padding: 10px 20px"
    [ngClass]="{
      last,
      first,
      selected: i == selectedIndex,
      delimiter: i == 1,
    }"
  >
    <div
      style="width: 16px; height: 16px; border-radius: 4px"
      [ngStyle]="{
        border: first ? '1px solid' : 'none',
        width: first ? '14px' : '16px',
        height: first ? '14px' : '16px',
      }"
      [style.background]="option.color"
    ></div>
    <div>{{ option.label }}</div>
  </div>
</div>
