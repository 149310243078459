<div
  class="zoom-panel-component editor-container"
  *ngIf="cs.previewShape"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <nw-icon
    type="fit_screen"
    [size]="18"
    [outlined]="true"
    (click)="cs.resetCanvasPosition()"
  ></nw-icon>
  <div class="flex-row fs-13 gap-4">
    <div class="flex-row gap-6">
      <div>{{ cs.previewShape.translateX?.toFixed() }}</div>
      <div>{{ cs.previewShape.translateY?.toFixed() }}</div>
    </div>
    <div style="width: 1px; height: 14px; background-color: black"></div>
    <div>{{ (cs.canvasScale * 100).toFixed() }}%</div>
  </div>
</div>
