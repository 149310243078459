<div
  class="translate-effect-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Translate Effect"
    [attributeState]="attributeState"
    key="translateEffect"
    [initialValue]="{ x: 0, xInterval: 0, y: 0, yInterval: 0 }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-4 fs-12"
  >
    <div>Forth</div>
    <div class="flex-row space-between">
      <div class="flex-row gap-4">
        <div>x</div>
        <nw-number-input
          [value]="attributeState.value['x1']"
          (changed)="updateSubAttributeValue('x1', $event)"
        >
        </nw-number-input>
      </div>

      <div class="flex-row gap-4">
        <div>y</div>
        <nw-number-input
          [value]="attributeState.value['y1']"
          (changed)="updateSubAttributeValue('y1', $event)"
        >
        </nw-number-input>
      </div>
    </div>
    <div class="flex-row space-between">
      <div class="flex-row gap-4">
        <div>speed</div>
        <nw-number-input
          [value]="attributeState.value['speed1']"
          (changed)="updateSubAttributeValue('speed1', $event)"
        >
        </nw-number-input>
      </div>

      <div class="flex-row gap-4">
        <div>time</div>
        <nw-number-input
          [value]="attributeState.value['time1']"
          (changed)="updateSubAttributeValue('time1', $event)"
        >
        </nw-number-input>
      </div>
    </div>
    <div>Back</div>
    <div class="flex-row space-between">
      <div class="flex-row gap-4">
        <div>x</div>
        <nw-number-input
          [value]="attributeState.value['x2']"
          (changed)="updateSubAttributeValue('x2', $event)"
        >
        </nw-number-input>
      </div>

      <div class="flex-row gap-4">
        <div>y</div>
        <nw-number-input
          [value]="attributeState.value['y2']"
          (changed)="updateSubAttributeValue('y2', $event)"
        >
        </nw-number-input>
      </div>
    </div>
    <div class="flex-row space-between">
      <div class="flex-row gap-4">
        <div>speed</div>
        <nw-number-input
          [value]="attributeState.value['speed2']"
          (changed)="updateSubAttributeValue('speed2', $event)"
        >
        </nw-number-input>
      </div>

      <div class="flex-row gap-4">
        <div>time</div>
        <nw-number-input
          [value]="attributeState.value['time2']"
          (changed)="updateSubAttributeValue('time2', $event)"
        >
        </nw-number-input>
      </div>
    </div>
  </div>
</div>
