import { PointData } from '../../../services/util/vector-service';
import { ResourceMin } from '../../resource/resource-min';
import * as math from 'mathjs';
import { DynamicLine } from '../../../coorgeo/line';
import { Coords } from '../../resource/types/shape.type';
import { cloneDeep } from 'lodash';

const { PI, sqrt } = Math;

export interface Point {
  x: number;
  y: number;
}

export class Vector extends ResourceMin {
  public normal: NormalVector;
  private localDynamicLine: DynamicLine;

  get pojo() {
    return { start: this.start, end: this.end };
  }

  get startCoords(): Coords {
    const { x, y } = this.start;
    return [x, y];
  }

  get endCoords(): Coords {
    const { x, y } = this.end;
    return [x, y];
  }

  get x() {
    return this.end.x - this.start.x;
  }

  get y() {
    return this.end.y - this.start.y;
  }

  xs: number;
  ys: number;

  set start({ x, y }: Point) {
    this.xs = x;
    this.ys = y;
  }

  get start() {
    return { x: this.xs, y: this.ys };
  }

  xe: number;
  ye: number;

  set end({ x, y }: Point) {
    this.xe = x;
    this.ye = y;
  }

  get end() {
    return { x: this.xe, y: this.ye };
  }

  constructor(start?: Point, end?: Point, noVector = false) {
    super();
    this.start = start || { x: 0, y: 0 };
    this.end = end || { x: 0, y: 0 };
    if (!noVector) {
      // tslint:disable-next-line: no-use-before-declare
      this.normal = new NormalVector(this);
    }
  }

  /**
   *  This function returns the change of the x, y coordinates in case of turning
   *  the vector with a certain angle.
   * @param angle
   *
   */

  radToDeg(rad: number) {
    return (180 * rad) / Math.PI;
  }

  degToRad(deg: number) {
    return (Math.PI * deg) / 180;
  }

  getDCoords(angle: number) {
    const [x, y] = this.getCoords();
    const [x1, y1] = this.copy().getCoords(angle);
    return [x1 - x, y1 - y] as Coords;
  }

  getDCoordsDeg(deg: number) {
    return this.getDCoords(this.degToRad(deg));
  }

  getCoords(dAngle?: number) {
    if (dAngle) {
      return this.copy().rotate(dAngle).getCoords();
    }
    return [this.x, this.y];
  }

  setStart([x, y]: Coords) {
    this.start = { x, y };
    return this;
  }

  setEnd([x, y]: Coords) {
    this.end = { x, y };
    return this;
  }

  public static norm(angle: number) {
    // Okay
    angle = angle % (2 * PI);
    if (angle < 0) {
      return 2 * PI + angle;
    } else {
      return angle;
    }
  }

  get dynamicLine(): DynamicLine {
    this.localDynamicLine ||= new DynamicLine(this);
    return this.localDynamicLine;
  }

  public getPoint(key: string): Point {
    return key === 'start' ? this.start : this.end;
  }

  get coords(): Coords {
    // return [this.end.x - this.start.x, this.end.y - this.start.y];
    return [this.end.x, this.end.y];
  }
  getUnifiedEnd(): PointData {
    return {
      x: this.end.x - this.start.x,
      y: this.end.y - this.start.y,
    };
  }

  intersection(vector: Vector): Point {
    return this.dynamicLine.intersection(vector.dynamicLine);
  }

  set(start: Point, end: Point): Vector {
    this.start = cloneDeep(start);
    this.end = cloneDeep(end);
    return this;
  }

  translate(x: number, y: number) {
    this.start.x += x;
    this.start.y += y;
    this.end.x += x;
    this.end.y += y;
    return this;
  }

  setPoints(start: Point, end: PointData) {
    this.start = start;
    this.end = end;
  }

  rotateTo(angle: number) {
    // -- //
    const diff = angle - this.getAngle();
    return this.rotate(diff);
  }

  public rotate(angle: number, log = false): Vector {
    angle = -angle;
    const [sin, cos] = [Math.sin(angle), Math.cos(angle)];
    const [x, y] = math.multiply(
      [
        [cos, sin],
        [-sin, cos],
      ],
      cloneDeep([this.x, this.y]),
    ) as [any, any];
    // TODO - check if it works

    this.end = { x: this.xs + x, y: this.ys + y };
    return this;
  }

  public static dRotate([x, y]: Coords, _angle: number): Coords {
    // console.log('coords', x, y);
    const angle = (_angle * PI) / 180;
    const sin = Math.sin(angle);
    const cos = Math.cos(angle);
    const matrix = [
      [cos, sin],
      [-sin, cos],
    ];
    const [xn, yn] = math.multiply(matrix, [x, y]) as [any, any];
    return [xn - x, yn - y];
  }

  size(): void {
    // return Math.sqrt(Math.pow(this.x,2) + Math.pow(this.y, 2));
  }

  public scale(scale: number): Vector {
    scale = scale < 0 ? 1 / Math.abs(scale) : scale;
    return this.scale1(scale);
  }

  public scale1(scale: number): Vector {
    this.setEnd([this.start.x + this.x * scale, this.start.y + this.y * scale]);
    return this;
  }

  flip(): Vector {
    return this.set(this.end, this.start);
  }

  turn(): Vector {
    return this.set(
      cloneDeep(this.start),
      cloneDeep({
        x: this.start.x - this.x,
        y: this.start.y - this.y,
      }),
    );
  }

  angle(vector: Vector): number {
    return vector.getAngle() - this.getAngle();
  }

  /**
   * The function tells, whether turning the vector to a target vector
   * can be done in clockwise direction with a smaller angle.
   *
   * @param target - the target vector to which the base vector is turned
   *
   */
  isClockwise(target: Vector) {
    const [a1, a2] = [this.getAngle(), target.getAngle()];
    if (a1 === a2) {
      return true;
    }
    if (a1 > a2) {
      return a1 - a2 > PI;
    }
    if (a2 > a1) {
      return a2 - a1 <= PI;
    }
  }

  smallerAngle(vector: Vector) {
    const angle = Math.abs(this.angle(vector));
    if (angle <= PI) {
      return angle;
    } else {
      return 2 * PI - angle;
    }
  }

  getAngle(): number {
    const x = this.x;
    const y = this.y;
    const x_neg = x < 0;
    const y_neg = y < 0;

    let correction = 0;
    if ((x_neg && y_neg) || (x_neg && !y_neg)) {
      correction = PI;
    }
    if (!x_neg && y_neg) {
      correction = 2 * PI;
    }
    return this.normAngle(Math.atan(this.y / this.x) + correction);
  }

  normAngle(angle: number) {
    if (angle >= 2 * PI) {
      angle -= 2 * PI;
    }

    if (angle < 0) {
      angle += 2 * PI;
    }

    return angle;
  }

  static getAngle(x: number, y: number) {
    const x_neg = x < 0;
    const y_neg = y < 0;

    let correction = 0;
    if ((x_neg && y_neg) || (x_neg && !y_neg)) {
      correction = PI;
    }
    if (!x_neg && y_neg) {
      correction = 2 * PI;
    }
    return this.normAngle(Math.atan(y / x) + correction);
  }

  static normAngle(angle: number) {
    if (angle >= 2 * PI) {
      angle -= 2 * PI;
    }

    if (angle < 0) {
      angle += 2 * PI;
    }

    return angle;
  }

  getQuarter(): number {
    const x = this.x >= 0;
    const y = this.y >= 0;
    switch (true) {
      case x && y:
        return 1;
      case !x && y:
        return 2;
      case !x && !y:
        return 3;
      case x && !y:
        return 4;
    }
  }

  getQuarterAngle(angle: number): number {
    angle = this.normalize(angle);
    switch (true) {
      case angle < PI / 2:
        return 1;
      case PI / 2 <= angle && angle < PI:
        return 2;
      case PI <= angle && angle < (3 * PI) / 2:
        return 3;
      case (3 * PI) / 2 <= angle && angle < 2 * PI:
        return 4;
    }
  }

  public reScale(scale: number) {
    this.unify();
    this.scale1(scale);
    return this;
  }

  public unify() {
    const length = this.length;
    this.setEnd([
      this.start.x + this.x / length,
      this.start.y + this.y / length,
    ]);
    return this;
  }

  get length(): number {
    return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
  }

  public normalize(angle: number) {
    angle = angle % (2 * PI);
    if (angle < 0) {
      return 2 * PI + angle;
    } else {
      return angle;
    }
  }

  flipX(angle: number): number {
    angle = this.normalize(angle);
    switch (this.getQuarterAngle(angle)) {
      case 1:
        return this.normalize(-angle);
      default:
        return 2 * PI - angle;
    }
  }

  flipY(angle: number): number {
    angle = this.normalize(angle);
    switch (this.getQuarterAngle(angle)) {
      case 1:
      case 2:
        return PI - angle;
      case 3:
      case 4:
        return 3 * PI - angle;
      // default:
      //   return 2 * PI - this.normalize(angle);
    }
  }

  copy(): Vector {
    return new Vector(cloneDeep(this.start), cloneDeep(this.end));
  }

  add(v: Vector): Vector {
    return new Vector(
      cloneDeep(this.start),
      cloneDeep({ x: this.end.x + v.x, y: this.end.y + v.y }),
    );
  }

  static getLength(x: number, y: number) {
    return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
  }

  static abs([x1, y1]: [number, number], [x2, y2]: [number, number]) {
    return this.getLength(x2 - x1, y2 - y1);
  }

  static setToLength(x: number, y: number, length: number): [number, number] {
    const lOriginal = Vector.getLength(x, y);
    const scale = length / lOriginal;
    return [x * scale, y * scale];
  }

  substract(vector: Vector) {
    this.setEnd([this.end.x - vector.end.x, this.end.y - vector.end.y]);
    return this;
  }
}

export class DynamicVector extends Vector {
  constructor(public line: DynamicLine) {
    super();
  }

  // TODO - fix those vector
  set start1(p: Point) {}
  set end1(p: Point) {}

  get start1(): Point {
    return this.line.S;
  }

  get end1(): Point {
    return this.line.E;
  }
}

export class NormalVector extends Vector {
  buf = { x: 0, y: 0 };
  constructor(private of: Vector) {
    super({ x: 0, y: 0 }, { x: 0, y: 0 }, true);
  }

  public get x(): number {
    return this.endAlias.y;
  }

  public get y(): number {
    return -this.endAlias.x;
  }

  get endAlias(): Point {
    return {
      x: this.of.end.x - this.of.start.x,
      y: this.of.end.y - this.of.start.y,
    };
  }
}

export class BaseVector extends Vector {
  constructor([x, y]: Coords) {
    super({ x: 0, y: 0 }, { x, y }, true);
  }

  copy() {
    return new BaseVector([this.x, this.y]);
  }

  turn90() {
    const [x, y] = [this.end.x, this.end.y];
    this.setEnd([y, -x]);
    return this;
  }

  get x() {
    return this.end.x;
  }

  get y() {
    return this.end.y;
  }
}
