import {
  AnimationFrame,
  SoundAnimationFrame,
  StateTransitionConfig,
  TextAnimationFrame,
} from '../components/animation-frame/animation.types';
import {
  pick as _pick,
  cloneDeep as _cloneDeep,
  omit as _omit,
  set as _set,
} from 'lodash';
import { ConnectionTypes } from '../../../services/canvas/canvas.service';
import { GeneralShape } from '../../shape/shapes/general/general-shape';
import {
  AnimationItem,
  AnimationKeys,
  ImportedShapeDescriptor,
  SelectParams,
  ShapeIRI,
  __Animation,
} from '../../../elements/resource/types/shape.type';
import { setCurrentAnimationId } from '../store/animation.actions';
import {
  MSAnimationFrame,
  MainAnimationFrameObject,
  RootAnimationFrame,
  SoundMainAnimationFrame,
} from './main-animation-frame-object';
import { getAnimationsByFrame } from '../store/animation.selector';
import { FrameCollection } from '../animation.service';
import { FunctionAnimationFrameObject } from './function/function-animation-frame';
import { RootShape } from '../../shape/shapes/general/root/root-shape';
import { AnimationEnvironment } from '../../../services/animation/animation.types';

export type AnimationId = string;

export interface TimeFrame {
  id: string;
  start: number;
  end: number;
}

export interface AnimationFrameRelationships {
  functionParent?: MainAnimationFrameObject | FunctionAnimationFrameObject;
  soundParent?: AnimationFrameObject;
  parent?: AnimationFrameObject;
  prev?: AnimationFrameObject;
  next?: AnimationFrameObject;
  child?: AnimationFrameObject;
  root?: RootAnimationFrame;
}

export class AnimationFrameObject<T extends AnimationFrame = AnimationFrame> {
  absoluteTimeStart: number;
  absoluteTimeEnd: number;

  fcnParent: MainAnimationFrameObject | FunctionAnimationFrameObject;
  animationStopped = false;

  rootStore: Record<string, AnimationFrameObject> = {};
  maxRowIndex: number;

  height = 1;

  selected = false;

  start = 0;
  end = 0;

  get _parentCount() {
    return this.parent
      ? this.parent._parentCount + 1
      : this.prev?._parentCount || 0;
  }

  get __start() {
    return this._start - this._offset;
  }

  _start: number;
  _end: number;

  child: AnimationFrameObject;
  next: AnimationFrameObject;

  actualRow: number;
  offset = 0;

  // animationStore: Record<AnimationId, Record<ShapeIRI, AnimationItem>>; //
  edit = false;
  sequenceEnd: number;
  rowElements: AnimationFrameObject[] = [];

  _selected: Record<string, boolean> = {};

  shapesToAnimate: GeneralShape[];

  animationsByShape: Record<
    ShapeIRI,
    Record<string, Omit<AnimationItem, 'key'>>
  > = {};
  timeStore: Array<{
    t: number;
    frames: string[];
  }> = [];

  get inverse() {
    return this.frame.inverse;
  }

  get amIRoot() {
    return this.mainFrame.id == this.service.mainFrame.id;
  }

  get service() {
    return this.shape.service.animationService;
  }

  get appStore() {
    return this.shape.service.store;
  }

  get shape() {
    return this.rootMainFrame.parentShape;
  }

  get randomId() {
    return Math.random().toString();
  }

  getRandomId() {
    return Math.random().toString();
  }

  get cs() {
    return this.shape.cs;
  }

  get _offset() {
    if (this.mainFrame.soundParent) {
      return 0;
    }

    if (this.mainFrame.fcnParent) {
      const [start] = this.mainFrame.fcnParent.interval;
      return start;
    }
    return 0;
  }
  get interval() {
    return [this._start, this._end].map(val => val + this._offset);
  }

  get _interval() {
    return [this._start, this._end];
  }

  get shapeService() {
    return this.shape.service;
  }

  get animationService() {
    return this.shape.service.animationService;
  }

  get isRoot() {
    return this.shape?.getType() == 'root-shape';
  }

  get shapesInScope() {
    // TODO - remove that - multipliedShapes should be animated by the root shape
    return [
      this.shape,
      ...this.shape.shapes.reduce((prev, current) => {
        prev.push(current);
        prev.push(...current.multipliedShapesArray);
        prev.push(...current._shapes);
        return prev;
      }, []),
    ];
  }

  get animationIRIs() {
    return {
      ...this.store,
      ...(this.child?.animationIRIs || {}),
      ...(this.next?.animationIRIs || {}),
    };
  }

  get fullId() {
    if (this.mainFrame.id == this.id) {
      return this.rootMainFrame.id + '_' + this.id;
    }

    return this.mainFrame.fullId + '_' + this.id;
  }

  get minimalFrameObject() {
    const object = _pick(this.frame, [
      'id',
      'duration',
      'type',
      'delay',
      'function',
      'functionTarget',
      'inverseOf',
      'inverse',
      'ms',
      'preDelay',
      'baseAction',
      'stateTransition',
      'soundFileUrl',
      'target',
      'functions',
    ]) as AnimationFrame;

    // if (this.functions?.length) {
    //   object.functions = this.functions.map(({ name, frame }) => ({
    //     name,
    //     frame: frame.frameObject,
    //   }));
    // }
    // if (Object.values(this.localFunctions).length) {
    //   object.localFunctions = {};
    //   Object.entries(this.localFunctions).map(([key, value]) => {
    //     object.localFunctions[key] = value.frameObject;
    //   });
    // }
    return object;
  }

  get isStateTransition() {
    return !!this.frame.stateTransition;
  }

  get frameObject() {
    return {
      ...this.minimalFrameObject,
      next: this.next?.frameObject,
      paralell: this.child?.frameObject,
      soundAnimationFrame: this.soundAnimationFrame?.frameObject,
    };
  }

  get framesById() {
    let object: Record<string, AnimationFrame> = {
      [this.id]: this.minimalFrameObject,
    };

    if (this.next) {
      object = {
        ...object,
        ...this.next.framesById,
      };
    }
    if (this.child) {
      object = {
        ...object,
        ...this.child.framesById,
      };
    }
    return object;
  }

  get selectedFrameObject() {
    const frame = this.minimalFrameObject;

    if (this.next?.selected) {
      frame.next = this.next.selectedFrameObject;
    }
    // frame.next = this.next?.selectedFrameObject; //

    if (this.child?.selected) {
      frame.paralell = this.child.selectedFrameObject;
    }
    // frame.paralell = this.child?.selectedFrameObject;

    return frame;
  }

  msFunction: MSAnimationFrame;
  open = false;

  parent: AnimationFrameObject;
  soundParent: AnimationFrameObject;
  prev: AnimationFrameObject;
  root: RootAnimationFrame;
  soundAnimationFrame: MainAnimationFrameObject;
  constructor(
    public frame: T,
    relationships: AnimationFrameRelationships,
  ) {
    // -- //
    const { parent, prev, next, child, root, functionParent, soundParent } =
      relationships;

    this.fcnParent = functionParent;
    if (this.fcnParent) {
      if (this.fcnParent.type !== 'root') {
        (this.fcnParent as MainAnimationFrameObject).firstFrame = this;
        // console.log('fcnParent', this.fcnParent, 'soundParent', this);
      }
    }

    this.parent = parent;

    if (this.parent) {
      this.parent.child = this;
    }

    this.soundParent = soundParent;
    this.prev = prev;

    if (this.prev) {
      this.prev.next = this;
    }

    this.next = next;

    if (this.next) {
      this.next.prev = this;
    }

    this.child = child;

    if (this.child) {
      this.child.parent = this;
    }

    this.root = root;

    if (!prev && !parent) {
      this.absoluteTimeStart = 0;
    }

    if (!this.id) {
      this.frame.id = Math.random().toString();
    }

    // if (frame.function && frame.functionTarget?.IRI?.startsWith('tmp-')) {
    //   this.cs.tmpFunctionFrames[frame.functionTarget.IRI] = this;
    // }

    // if (this.frame.ms) {
    //   this.initMS(this.frame.ms);
    // }

    // this._end = this.start + this._duration //
    this.calcIntervals();
  }

  calcIntervals(log = false) {
    this.rowIndex = undefined;
    const prev = this.prev;
    const parent = this.parent || this.soundParent;

    if (!parent && !prev) {
      this._start = 0;
    } else if (parent) {
      this._start = parent._start;
    } else if (prev) {
      this._start = prev._end;
    }

    this._end = this._start + this._duration;

    this._start += this.preDelay;
    this._end += this.preDelay;

    this.next?.calcIntervals(log);
    this.child?.calcIntervals(log);

    this.soundAnimationFrame?.calcIntervals();
  }

  nextSceneRoot: RootAnimationFrame;

  init() {
    this.store[this.originalId] = this;
    // -- // -- // this.store[this.id] = this; // -- // -- //

    const frame = this.frame;

    // console.log('init', this.id); //
    // TODO - move this to constructor //
    this.shape.service.store
      .select(getAnimationsByFrame(this.id))
      .subscribe(animationsByFrame => {
        // console.log({ id: this.id, animationsByFrame }); //
        Object.entries(animationsByFrame || {}).map(
          ([shapeIRI, animations]) => {
            this.animationsByShape[shapeIRI] = animations;
          },
        );
      });

    if (frame.next) {
      this.next = this.service.initFrameObject(frame.next, { prev: this });
    }

    if (frame.paralell) {
      this.child = this.service.initFrameObject(frame.paralell, {
        parent: this,
      });
    }

    if (this.stateTransition) {
      // -- //
    }

    if (
      this.frame.stateTransition &&
      (!this.cs.currentScene || this.shape.getType() == 'is')
    ) {
      // const { to } = this.frame.stateTransition;
      // let nextSceneFrame: AnimationFrame;
      // if (this.shape.getType() == 'is') {
      //   // -- //
      //   nextSceneFrame = (this.shape as ImportedShape).descriptor
      //     .baseShapeDescriptor.animiationFrameByScene?.[to];
      // } else {
      //   nextSceneFrame = this.shape.descriptor.animiationFrameByScene?.[to];
      // }
      // if (nextSceneFrame) {
      //   console.warn('init-next-scene-frame');
      //   this.next = new RootAnimationFrame(this.shape, nextSceneFrame);
      //   this.next.init();
      //   this.rootMainFrame.nextSceneRoot = this.next as RootAnimationFrame;
      // }
    }

    if (frame.soundAnimationFrame) {
      this.soundAnimationFrame = new SoundMainAnimationFrame(
        frame.soundAnimationFrame,
        this.mainFrame,
      );

      this.soundAnimationFrame.init({ soundParent: this });
      // this.soundAnimationFrame.calcPositions(true);
    }
  }
  updated() {
    this.calcIntervals();
    this.calcPositions();
    this.mainFrame.fcnParent?.updated();
  }

  insertSoundAnimation(frame: SoundAnimationFrame) {
    this.frame.soundAnimationFrame = frame;
    this.soundAnimationFrame = new SoundMainAnimationFrame(
      frame,
      this.mainFrame,
    );
    this.soundAnimationFrame.init({ soundParent: this });

    this.soundAnimationFrame.firstFrame.select();
    this.calcPositions();
    this.save();
  }

  insertTextAnimation(frame: TextAnimationFrame) {
    this.frame.soundAnimationFrame = frame;
    this.soundAnimationFrame = new SoundMainAnimationFrame(
      frame,
      this.mainFrame,
    );
    this.soundAnimationFrame.init({ soundParent: this });

    this.soundAnimationFrame.firstFrame.select();
    this.calcPositions();
    this.save();
  }

  selectSoundAnimationFrame() {
    if (!this.soundAnimationFrame) {
      return;
    }
    this.soundAnimationFrame.select();
    this.rootMainFrame.soundEditMode = true;
  }

  durationChange(duration: number) {
    // TODO - implement function
    this._end = this._start + this.duration;
  }

  getAnimationsByShape() {
    return this.animationsByShape;
  }

  initMS(ms: string) {
    this.frame.ms = ms;
    const { frame, error } = this.shape.msService.getAnimationFrameByMS(ms);

    if (error) {
      // TODO - handle compile error //
      console.error(`ms-compile-error by ${this.frame.ms}`, error);
      return;
    }

    const { functionTarget } = frame as AnimationFrame;
    const targetShape = this.shape._shapes.find(
      shape => shape.label === functionTarget.targetAlias,
    );

    if (targetShape) {
      this.msFunction = new MSAnimationFrame(
        targetShape,
        _omit(frame, 'functionTarget'),
      );

      this.frame.duration = this.msFunction.fcnDuration;
      return;
    } else {
      console.warn(`Targetshape does not exist: ${functionTarget.targetAlias}`);
    }
  }

  get parentCount() {
    return this.mainFrame.fcnParent
      ? this.mainFrame.fcnParent.parentCount + 1
      : 0;
  }

  get inverseOf() {
    return this.frame.inverseOf;
  }

  get stateTransition() {
    return this.frame.stateTransition;
  }

  get children() {
    if (!this.child) {
      return [];
    }
    return [this.child, ...this.child.children];
  }

  getAlias() {
    return Math.random().toString();
  }

  getAnimationsByStart() {
    const store = this.getFrameCollection();
    const val = Object.entries(store)
      .sort(([, frame1], [, frame2]) => frame1.start - frame2.start)
      .map(([globalId, frame]) => ({
        globalId: globalId.includes('_') ? globalId : undefined,
        ...frame,
        id: frame.id,
      }));

    return val;
  }

  getSelectedDuration(duration = 0) {
    const next = this.next
      ? this.next.getSelectedDuration(duration + this.duration)
      : duration + this.duration;
    const child = this.child
      ? this.child.getSelectedDuration(duration)
      : duration;
    return Math.max(next, child);
  }

  get type() {
    return 'base';
  }
  get row() {
    if (this.parent) {
      return this.parent.row + 1;
    }
    if (this.prev) {
      return this.prev.row;
    }
    return 0;
  }

  get rootMainFrame(): RootAnimationFrame {
    return this.mainFrame.rootMainFrame;
  }

  get mainFrame(): MainAnimationFrameObject {
    if (this.prev) {
      return this.prev.mainFrame;
    }
    if (this.parent) {
      return this.parent.mainFrame;
    }
    return this.fcnParent.mainFrame;
  }

  get function() {
    return this.frame.function;
  }

  get functionTarget() {
    return this.frame.functionTarget;
  }

  get functionTooltip() {
    if (this.frame.ms) {
      return this.frame.ms;
    }
    const { function: f, functionTarget } = this.frameObject;
    if (functionTarget) {
      return `${functionTarget.label} - ${f}`;
    }
    return f;
  }

  get _parent() {
    return this.parent || this.prev?._parent;
  }

  get _child() {
    return this.child || this.prev?._child;
  }

  get store() {
    return this.mainFrame.store;
  }

  get __store(): Record<string, AnimationFrameObject> {
    if (this.nextSceneRoot) {
      return {
        ...this.store,
        ...this.nextSceneRoot.__store,
      };
    }
    return this.store;
  }

  set store(store: Record<string, AnimationFrameObject>) {
    this.mainFrame._store = store;
  }

  get delay() {
    return this.frame.delay;
  }

  get empty() {
    return this.frame.empty;
  }

  get _duration() {
    if (this.edit) {
      return 3;
    }
    return this.frame.duration || 0;
  }

  get fcnDuration() {
    const nextLine = this.frame.duration + (this.next?.fcnDuration || 0);
    const paralellLine = this.child?.fcnDuration || 0;
    return Math.max(nextLine, paralellLine);
  }

  get duration() {
    return isNaN(this.frame.duration) ? 1 : this.frame.duration;
  }

  get absDuration() {
    return this._end - this._start;
  }

  set duration(val: any) {
    if (!isNaN(this.frame.duration)) {
      this.frame.duration = 1;
    }
    this.frame.duration = +val;
  }

  get preDelay() {
    return this.frame.preDelay || 0;
  }

  set preDelay(val: any) {
    this.frame.preDelay = +val;
  }

  get id() {
    return this.frame.id;
  }

  get globalID() {
    if (!this.mainFrame.fcnParent) {
      return;
    }
    return [
      this.mainFrame.fcnParent.globalID || this.mainFrame.fcnParent.id,
      this.id,
    ].join('_');
  }

  get originalId() {
    return this.frame.id;
  }

  get length() {
    return (isNaN(this._duration) ? 0 : this._duration) * 32;
  }

  addFunctionByMS(id: string, ms: string) {
    // --> // --> // --> // --> //
  }

  addFunction(name: string, frame: AnimationFrame) {
    // this.rootMainFrame.frame.functions ||= [];
    this.rootMainFrame.frame.functions = [
      ...(this.rootMainFrame.frame.functions || []),
      { name, frame },
    ];

    console.log('add-function', this.rootMainFrame.frame.functions);
    // -- // -- // -- //
    // const fcnFrame = new MainAnimationFrameObject(
    //   frame,
    //   this
    // );
    // fcnFrame.init();

    // this.functions.push({
    //   name,
    //   frame: fcnFrame,
    // });
  }

  getRootSelected() {
    return Object.keys(this._selected)
      .map(id => this.store[id])
      .find(frame => !frame.parent?.isSelected && !frame.prev?.isSelected);
  }

  addMSFunction(name: string, frame: AnimationFrame) {
    this.frame.functions ||= [];
    // this.functions.push({
    //   name,
    //   frame: new MSAnimationFrame(this.shape, frame, this.id),
    // });
  }

  deleteFunction(name: string) {
    this.frame.functions = this.frame.functions.filter(
      fcn => fcn.name !== name,
    );
    this.frame.functions = this.frame.functions.filter(
      fcn => fcn.name !== name,
    );
  }

  save() {
    this.mainFrame.save();
  }

  patch(frame: T) {
    this.frame = frame;
  }

  getFrames(): AnimationFrameObject[] {
    return [
      this,
      ...(this.child?.getFrames() || []),
      ...(this.next?.getFrames() || []),
    ];
  }

  isPositionProcessed = false;

  breathFirstTree = [];
  depthFirstTree: AnimationFrameObject[] = [];
  rowIndex: number;
  columnIndex: number;

  hasPrev() {
    return !!this.prev;
  }

  hasParent() {
    return !!this.parent;
  }

  detach() {
    if (this.parent) {
      this.parent.child = null;
    }
    if (this.prev) {
      this.prev.next = null;
    }
  }

  getFunctionNeighbours() {
    return {
      parent: this.parent,
      prev: this.prev,
    };
  }

  getUnselectedChild() {
    if (this.child) {
      return this.child.isSelected
        ? this.child.getUnselectedChild()
        : this.child;
    }
  }

  getUnselectedNext() {
    let next: AnimationFrameObject;
    if (this.next) {
      next = this.next.isSelected ? this.next.getUnselectedNext() : this.next;
    }
    return next || this.child?.getUnselectedNext();
  }

  selectMainFrame() {
    // TODOx - implement
  }

  moveUp() {
    if (this.prev) {
      console.warn(
        'This frame has a previous frame, so it cannot be moved up!',
      );
    }

    if (!this.parent) {
      console.warn("This frame doesn't have a parent cannot be moved up!");
    }

    if (!this.parent.prev) {
      console.warn('This frame cannot be moved up!');
    }

    const originalParent = this.parent;
    const prevTarget = this.parent.prev;

    prevTarget.next = this;
    this.prev = prevTarget;

    this.parent.prev = null;

    // if (this.parent.parent) {
    //   this.parent.parent.child = this;
    //   this.parent = this.parent.parent;
    // }

    this.parent = null;
    originalParent.child = this.child;

    if (this.child) {
      this.child.parent = originalParent;
    }

    originalParent.parent = this;
    this.child = originalParent;
  }

  arrowLeft() {
    if (this.prev) {
      this.prev.select({ shift: this.cs.isShiftPressed });
    } else if (this.mainFrame.fcnParent) {
      this.mainFrame.fcnParent.selectMainFrame();
    } else {
      // This is the main
      this.mainFrame._selected = {};
      // this.cs.reloadPreviewShape();
      this.shape.store.dispatch(setCurrentAnimationId({ id: null }));
    }
  }

  arrowUp() {
    if (this.parent) {
      this.parent?.select({ shift: this.cs.isShiftPressed });
    }

    if (this.soundParent) {
      this.deselect();
      this.soundParent.select();
      this.service.mainFrame.soundEditMode = false;
    }
  }

  arrowDown() {
    if (this.child) {
      this.child.select({ shift: this.cs.isShiftPressed });
    }
  }

  insertParent(frame?: AnimationFrame) {
    // TODO - implement
    // frame.id = this.getRandomId();
    // const parent = new AnimationFrameObject(
    //   frame,
    //   this,
    //   null,
    //   // { paralell: this }
    // );
    // this.parent.select();
    // this.parent = parent;
    // if (frame) {
    //   return;
    // }
  }

  insertNext<T = AnimationFrameObject>(frame?: AnimationFrame): T {
    if (this.mainFrame.fcnParent) {
      console.log('mainFrame.fcnParent', this.mainFrame);
    }
    return this.shape.service.animationService.newFrameObject<T>(frame, {
      prev: this,
      next: this.next,
    });
  }
  insertParalell<T = AnimationFrameObject>(frame?: AnimationFrame): T {
    return this.shape.service.animationService.newFrameObject<T>(frame, {
      parent: this,
      child: this.child,
    });
  }

  insertPrev(frame?: Partial<AnimationFrame>) {
    const f = this.shape.service.animationService.newFrameObject<T>(frame, {
      functionParent: this.fcnParent,
      next: this,
      parent: this.parent,
      prev: this.prev,
    });

    if (this.fcnParent) {
      this.fcnParent = null;
    }
    return f;
  }

  patchAnimation(frame: AnimationFrameObject) {}

  insertInverse() {
    // TODO - implement
    // const next = new AnimationFrameObject(
    //   {
    //     id: Math.random().toString(),
    //     duration: this.duration,
    //     inverseOf: this.id,
    //   },
    //   null,
    //   this,
    //   // { next: this.next }
    // );
    // next.select();
    // this.next = next;
  }

  get horizontal() {
    return !!this.prev || !!this.next;
  }

  get vertical() {
    return !!this.parent || !!this.child;
  }

  get isSelected() {
    const amISound = this.mainFrame.isSound;
    const soundEdit = this.service.mainFrame.soundEditMode;

    if ((amISound && !soundEdit) || (!amISound && soundEdit)) {
      return false;
    }

    if (amISound) {
      const isSelected =
        !!this.mainFrame._selected[this.id] &&
        Object.keys(this.mainFrame._selected).length == 1;

      return isSelected;
    }

    return (
      !!this.mainFrame._selected[this.id] &&
      Object.keys(this.mainFrame._selected).length == 1
    );
  }

  get isSelected2() {
    return !!this.mainFrame._selected[this.id];
  }

  getFrameCollection(): FrameCollection {
    return Object.entries(this.__store).reduce((object, [id, frame]) => {
      if (!frame || id == '__selected__') {
        return object;
      }
      if (frame.function && !frame.functionTarget) {
        const fcn = (frame as FunctionAnimationFrameObject).functionInstance;
        if (fcn) {
          object = {
            ...object,
            ...fcn.getFrameCollection(),
          };
        } else {
          // - IS functions are not in the root collection
        }
      } else {
        const [start, end] = frame.interval;
        object[frame.globalID || frame.id] = {
          start,
          end,
          id,
        };
      }
      return object;
    }, {});
  }

  removeSelected() {
    delete this.mainFrame.store[this.id];
    if (this.child?.isSelected2) {
      this.child.removeSelected();
    }
    if (this.next?.isSelected2) {
      this.next.removeSelected();
    }
  }

  shiftLeft() {
    this.parent.setChild(this.next);
    this.next?.setChild(this.child);
  }

  shiftUp() {
    this.parent.setChild(this.child);
    this.child?.setNext(this.next);
  }

  remove() {
    // if (this.horizontal && this.vertical) {
    //   return alert('You cannot delete this node!');
    // }

    if (this.parent && this.next) {
      return alert('This frame cannot be deleted! - 1');
    }

    if (!this.soundParent && !this.prev && !this.parent) {
      if (!this.prev && !this.parent) {
        if (this.next) {
          this.next.fcnParent = this.mainFrame;
          this.mainFrame.firstFrame = this.next;
          this.next.prev = null;
          this.next.select();
        }
      }

      // return alert('This frame cannot be deleted! - 2');
    }

    // if (this.prev) {
    //   this.prev.select({ uniqueSelect: true });
    // } else if (this.next) {
    //   this.next.select({ uniqueSelect: true });
    // } else if (this.parent) {
    //   this.parent.select({ uniqueSelect: true });
    // } else if (this.child) {
    //   this.child.select({ uniqueSelect: true });
    // }

    this.deselect();
    if (this.soundParent) {
      // -- // -- //

      if (this.next) {
        return alert('This frame cannot be deleted');
      }

      this.soundParent.soundAnimationFrame = null;
    } else if (this.parent) {
      console.log('set-child', this.id);
      this.parent.setChild(this.child);
      if (this.child) {
        this.child.select();
      } else {
        this.parent.select();
      }
    } else if (this.prev) {
      this.prev.setNext(this.next);
      if (this.next) {
        this.next.select();
      } else {
        this.prev.select();
      }
    }

    // if (this.parent) {
    //   // --> // --> //
    //   if (this.next) {
    //     // If the child has next then it cannot shifted upwards
    //     if (this.cs.isShiftPressed || !this.child || this.child?.next) {
    //       // The next is shifted to the left
    //       this.shiftLeft();
    //     } else if (this.child) {
    //       // --> //
    //       this.shiftUp();
    //     }
    //   } else {
    //     this.shiftUp();
    //   }
    // } else if (this.prev) {
    //   if (this.child && !this.child.next && this.cs.isShiftPressed) {
    //     this.prev.setNext(this.child);
    //     this.child.setNext(this.next);
    //   } else if (this.next) {
    //     this.prev.setNext(this.next);
    //     this.next.setChild(this.child);
    //   } else if (this.child) {
    //     this.prev.setNext(this.child);
    //   } else {
    //     this.prev.setNext(null);
    //   }
    // }

    // this.child?.setParent(this.parent);
    // this.parent?.setChild(this.child);
    // this.prev?.setNext(this.next);
    // this.next?.setPrev(this.prev);
    delete this.store[this.id];
  }

  get connection(): { connection: ConnectionTypes; id: string } {
    if (this.parent) {
      return { connection: 'parent', id: this.parent.id };
    }

    if (this.child) {
      return { connection: 'child', id: this.child.id };
    }

    if (this.prev) {
      return { connection: 'prev', id: this.prev.id };
    }

    if (this.next) {
      return { connection: 'next', id: this.next.id };
    }
  }

  preDelayMode = false;

  findFrameById(id: string): AnimationFrameObject {
    // theoretically we cannot end up here
    return null;
  }

  deselect() {
    this.mainFrame._selected = {};
    this.cs.currentAnimation = null;
    this.cs.currentAnimationFrame = null;
  }

  deselectAll() {
    this.animationService.selectedFrame = null;
    this.mainFrame._selected = {};
    this.mainFrame.store.__selected__ = null;
    this.cs.currentAnimation = null;
    this.cs.currentAnimationFrame = null;
  }

  select(params: SelectParams = {}) {
    const { open, uniqueSelect } = params;

    if (this.cs.isPressed('Space')) {
      this.cs.consumeKeyEvent('Space');
      this.preDelayMode = true;
    }

    // if (this.stateTransition?.to && !noStateChangeEvent) {
    //   this.shapeService.previewShape.setCurrentState(this.stateTransition.to);
    // }

    // if (this.next?.stateTransition) {
    //   // Find the previous state
    //   this.shapeService.previewShape.setCurrentState(
    //     this.next.stateTransition.from,
    //   );
    // }

    if (!this.cs.isShiftPressed || open) {
      // TODO - move it to the animation-service somehow

      const offset = this.mainFrame.fcnParent?._start || 0;
      (this.shape as RootShape).applyAnimationByTime(offset + this._end);
    }

    // this.animationService.selectedFunctionId = this.mainFrame.id; //
    // console.log('select', { mainFrame: this.mainFrame.id, root: this.rootMainFrame.id }); //
    // console.log('setCurrentAnimationId', { id: this.globalID || this.id });
    this.shapeService.store.dispatch(
      setCurrentAnimationId({ id: this.globalID || this.id }),
    );

    // Open the frame with with shift - therefore it has to be eliminated
    this.mainFrame.store.__selected__ = this;
    if (this.cs.isShiftPressed && !uniqueSelect && !open) {
      console.log('------ heyheyhey -----');
      this.selected = true;
      this.mainFrame._selected[this.id] = true;
    } else {
      // Setting this frame to the only one selected element //
      this.mainFrame._selected = { [this.id]: true };
      this.service.selectedFrame = this;
      // -- // -- //
      this.cs.currentAnimation = { id: this.globalID || this.id };
      this.cs.currentAnimationFrame = this;

      // this.cs.deselectAll(); //
      // this.cs.shapes.filter(shape => shape.hasAnimation(this.id)).map(shape => shape.select()) //
      // this.apply();
    }

    if (this.mainFrame.fcnParent) {
      this.mainFrame.fcnParent.setFunctionStepper();
    }

    if (!this.service.mainFrame) {
      return;
    }

    // TODO - revise that part
    if (this.mainFrame.isSound) {
      this.service.mainFrame.deselectAll();
      this.service.mainFrame.soundEditMode = true;
    } else {
      this.service.mainFrame.soundEditMode = false;
    }
  }

  apply() {
    if (this.msFunction) {
      this.msFunction.apply();
      return;
    }
    this.mainFrame?.shape?.applyAnimationFrame(this);
  }

  _selectFunction() {
    this.animationService.selectedFunctionId = this.mainFrame.id;
  }

  selectFirstFunction() {
    // TODO - implement
  }

  get selectedObject() {
    return this.mainFrame.store.__selected__;
  }

  setChild(child: AnimationFrameObject) {
    this.child = child;
    if (child) {
      child.parent = this;
    }
  }

  setParent(parent: AnimationFrameObject) {
    this.parent = parent;
    if (!parent) {
      return;
    }
    parent.child = this;
  }

  setPrev(prev: AnimationFrameObject) {
    this.prev = prev;
    if (!prev) {
      return;
    }
    prev.next = this;
  }

  setNext(next: AnimationFrameObject) {
    this.next = next;
    if (!next) {
      return;
    }

    if (next.prev) {
      next.prev.next = null;
    }

    next.prev = this;
  }

  calcPositions() {
    this.mainFrame.calcPositions();
  }

  findFunctionDescriptor(fcnName: string, noLog = false): AnimationFrame {
    if (fcnName === 'main') {
      return this.mainFrame.frame;
    }

    const frameObject = this.rootMainFrame.frame.functions?.find(
      ({ name }) => name === fcnName,
    );

    if (!frameObject) {
      // frameObject = (
      //   this.rootMainFrame.shape.descriptor as ImportedShapeDescriptor
      // ).baseShapeDescriptor?.animationFrame?.functions?.find(
      //   ({ name }) => name === fcnName,
      // );
    }

    if (!frameObject && !noLog) {
      console.warn(`'${fcnName}' could not be found`);
    }
    return frameObject?.frame;
  }

  clicked() {
    switch (this.cs.animationFrameMoveMode) {
      case 'set-prev':
        if (!this.cs.frameToBeMoved) {
          console.warn('CS frameToBeMoved is not defined!');
          return;
        }

        if (this.cs.frameToBeMoved.parent) {
          console.warn(
            'Unfortunately this operation cannot be performed in the selected frame',
          );
          return;
        }

        this.setNext(this.cs.frameToBeMoved);
        this.calcPositions();
        this.save();

        this.cs.animationFrameMoveMode = null;
        this.cs.frameToBeMoved = null;
        break;

      case 'move-up':
        console.log('move-up-yooo');
        const frameToMove = this.cs.frameToBeMoved;

        if (!frameToMove.parent) {
          console.warn(
            'Unfortunately this operation cannot be performed in the selected frame',
          );
          return;
        }

        if (this.parent) {
          console.log('frame.setParnt');
          frameToMove.setParent(this.parent);
          this.parent = null;
          this.child = null;
        } else if (this.prev) {
          frameToMove.setPrev(this.prev);
          this.prev = null;
        } else {
          console.warn(
            'Unfortunately this operation cannot be performed in the selected frame',
          );
          return;
        }

        if (frameToMove.child) {
          frameToMove.child.setParent(this);
        }
        this.setParent(frameToMove);

        this.calcPositions();
        this.save();

        break;
      default:
        this.cs.frameToBeMoved = this;
        break;
    }
  }

  async animate(env: AnimationEnvironment = {}) {
    /* if (this.cs.playOnlyTillNextStage && this.stateTransition) {
      console.log('----- return');
      return;
    } */

    if (this.service.isAnimationStopped) {
      console.log('animation-is-stopped');
      return;
    }

    if (this.preDelay) {
      await new Promise(res => setTimeout(res, this.preDelay * 1_000));
    }

    const p1 = async () => {
      await this._animate(env);
      if (this.next) {
        await this.next.animate(env);
      }
    };
    if (this.child || this.soundAnimationFrame) {
      await Promise.all([
        this.soundAnimationFrame?.animate(env),
        this.child?.animate(env),
        p1(),
      ]);
    } else {
      await p1();
    }
  }

  async _animate(env: AnimationEnvironment = {}) {
    const { timeScale } = env;
    const { delay, duration, inverseOf } = this.frame;

    if (inverseOf) {
      await this.prev.animate(env);
    }

    if (delay) {
      await new Promise(resolve =>
        setTimeout(resolve, timeScale * duration * 1_000),
      );
    } else {
      if (this.msFunction) {
        await this.msFunction.animate(env);
      } else {
        await this.executeAnimation(timeScale || 1);
      }
    }

    if (this.amIRoot) {
      this.service.lastAnimationFrame = this;
    }

    // if (inverse) {
    //   await this.next.next?.animate(false, timeScale);
    // } else {
    // const promises = [this.next, this.child]
    //   .filter(frame => !!frame)
    //   .map(frame => frame.animate(false, timeScale));
    // await Promise.all(promises);
    return Promise.resolve();
  }

  currentIncrement: number;

  setShapesToAnimate() {
    if (this.frame.target) {
      // econsole.log('--- 1 ---');
      const { if: ifValue, notIf, iris } = this.frame.target;
      if (ifValue || notIf) {
        this.shapesToAnimate = this.shapesInScope
          .filter(shape => (ifValue ? shape.checkState(ifValue) : true))
          .filter(shape => (notIf ? !shape.checkState(notIf) : true))
          .filter(shape => {
            if (this.frame.baseAction.key == 'appear') {
              return !shape.setPreAnimationState();
            }
            return true;
          });

        return;
      }

      if (iris) {
        this.shapesToAnimate = this.shapesInScope.filter(shape =>
          iris.includes(shape.IRI),
        );
        return;
      }
    }

    const shapeType = this.shape.getType();
    // TODO - revise that step

    if (shapeType == 'is') {
      // console.log('--- 2 ---');
      this.shapesToAnimate = this.shapesInScope.filter(
        shape => !!shape?.hasAnimation?.(this._id),
      ) as GeneralShape[];
    } else if (shapeType === 'root-shape') {
      this.shapesToAnimate = this.shapesInScope.filter(
        shape => !!shape?.hasAnimation?.(this._id),
      ) as GeneralShape[];
      // console.log('--- 3 ---', this.shapesInScope, this.shapesToAnimate);
    } else {
      this.shapesToAnimate = [this.shape];
    }
  }

  get _id() {
    return this.id;
  }

  startAnimations(numberOfBatches: number, timeScale: number) {
    this.shapesToAnimate.map(shape =>
      shape.startAnimation(
        this._id,
        numberOfBatches,
        false,
        (timeScale || 1) * this.duration,
      ),
    );
  }

  animationId: string;

  async executeAnimation(timeScale: number): Promise<void> {
    // console.log('execute-animation', this.id, 'prev', !!this.prev)

    if (this.service.isAnimationStopped) {
      return;
    }

    this.setShapesToAnimate();

    return new Promise(resolve => {
      this.currentIncrement = 0;

      const numberOfBatches = Math.floor(
        this.duration * timeScale * this.cs.batchPerSecond,
      );

      if (this.frame.baseAction) {
        const { key, value } = this.frame.baseAction;
        this.shapesToAnimate.map(shape =>
          shape.prepareKeyValueAnimation(
            { key: key as AnimationKeys, value },
            numberOfBatches,
            (timeScale || 1) * this.duration,
          ),
        );
      } else if (this._id) {
        this.startAnimations(numberOfBatches, timeScale);
      } else {
        throw new Error(
          `Either the id or the baseAction must be defined for the animation`,
        );
      }

      // One increment is 16 ms //

      this.animationId = Math.random().toString() + Math.random().toString();
      this.service.animationFrames[this.animationId] = increment => {
        this.currentIncrement += increment;
        let end = false;
        if (this.currentIncrement >= numberOfBatches) {
          end = true;
          // Occasional overflow is eliminated
          increment -= this.currentIncrement - numberOfBatches;
        }

        this.shapesToAnimate.map(shape =>
          shape.incrementAnimation(increment, this._id, numberOfBatches),
        );
        if (end) {
          // console.log('end---------- x x x -------animation', this.shapesToAnimate.length); //
          if (this.frame.baseAction) {
            const { key, value } = this.frame.baseAction;
            this.shapesToAnimate.map(shape =>
              shape.endAnimationByKeyValue(key, value),
            );
          } else if (this.id) {
            this.endAnimations();
          }
          delete this.service.animationFrames[this.animationId];
          resolve();
        }
      };
    });
  }

  endAnimations() {
    this.shapesToAnimate.map(shape => shape.endAnimation(this._id));
  }

  calcAnimationsByStart() {
    // -- //
  }

  getResourceByIRI(iri: string) {
    return (
      this.shapeService.getResource(iri) ||
      this.shapeService.getResource(`${this.rootMainFrame.shape.IRI}_${iri}`)
    );
  }

  createFunction(name: string) {
    const functionRoot = this.getRootSelected();
    const { parent, prev } = functionRoot.getFunctionNeighbours();

    this.addFunction(name, functionRoot.selectedFrameObject);

    const functionInvokeFrame = this.animationService.getFunctionAnimationFrame(
      {
        id: Math.random().toString(),
        function: name,
        duration: 1,
      },
      { parent, prev },
    );

    if (parent) {
      parent.child = functionInvokeFrame;
    }
    if (prev) {
      prev.next = functionInvokeFrame;
    }

    functionRoot.removeSelected();
    functionInvokeFrame.select();
  }

  nextMany(array?: AnimationFrameObject[]) {
    array ||= [];
  }

  childMany(array?: AnimationFrameObject[]) {
    array ||= [];
  }

  removeFunction(fcnName: string) {
    this.frame.functions = this.frame.functions.filter(
      ({ name }) => name !== fcnName,
    );
  }

  get amIFunctionStepper() {
    return this.cs.functionStepper === this.id;
  }

  // get amISelected() {
  //   return this.mainFrame.id === this.cs.selectedFunctionId;
  // }

  setFunctionStepper() {
    // This line is questionnaire
    // this.cs.selectedFunctionId = this.id;
    this.cs.functionStepper = this.id;
  }

  selectNext() {
    // this.selectedFrameObject.
  }

  addToTimeStore(t: number, id: string, prevT?: number) {
    if (prevT !== undefined) {
      this.removeFromTimeStore(prevT, id);
    }

    for (let i = 0; i < this.timeStore.length; i++) {
      const element = this.timeStore[i];
      if (element.t == t) {
        element.frames.push(id);
        return;
      } else if (element.t > t) {
        this.timeStore = [
          ...this.timeStore.slice(0, i),
          {
            t,
            frames: [id],
          },
          ...this.timeStore.slice(i),
        ];
        return;
      }
    }
    this.timeStore.push({ t, frames: [id] });
  }

  removeFromTimeStore(t: number, id: string) {
    const index = this.timeStore.findIndex(element => element.t == t);
    if (index == -1) {
      /* console.warn(
        `${JSON.stringify({
          t,
          id,
        })} could not be found in animation-frame's time-store!`
      ); */
      return;
    }
    const element = this.timeStore[index];
    element.frames = element.frames.filter(frame => frame != id);
    if (element.frames.length) {
      this.timeStore[index] = element;
    } else {
      this.timeStore.splice(index, 1);
    }
  }

  calcDepthFirstTree(): AnimationFrameObject[] {
    const array: AnimationFrameObject[] = [];
    array.push(this);
    if (this.child) {
      array.push(...this.child.calcDepthFirstTree());
    }

    if (this.next) {
      array.push(...this.next.calcDepthFirstTree());
    }

    return array;
  }

  getBreathFirstTree(): AnimationFrameObject[] {
    const array: AnimationFrameObject[] = [];
    array.push(this);

    if (this.next) {
      array.push(...this.next.getBreathFirstTree());
    }

    if (this.child) {
      array.push(...this.child.getBreathFirstTree());
    }

    return array;
  }
}
