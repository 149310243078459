<div
  class="number-input-comp row-space-between"
  (click)="$event.stopPropagation()"
>
  <div class="flex-row gap-4" style="position: relative">
    <div class="attr-key">{{ key }}</div>
    <!-- <nw-icon type="bookmark" [size]="14" [outlined]="true"></nw-icon> -->
    <ng-container *ngIf="array.length > 1">
      <div (click)="selectorOpen = !selectorOpen" class="indefinite-wave">
        ~
      </div>
    </ng-container>
    <div
      (mouseenter)="hovered = true"
      (mouseleave)="hovered = false"
      class="flex-row"
    >
      <input
        *ngIf="array.length == 1"
        [value]="_value | step"
        type="number"
        (keyup)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        (change)="inputChanged($event)"
        [step]="step"
        (focus)="focused = true"
        [class.blue-border]="isVariable"
      />
      <div
        *ngIf="hovered"
        style="position: absolute; right: 3px"
        (click)="openSelector($event)"
      >
        <nw-icon type="more_horiz" [size]="13"></nw-icon>
      </div>
      <nw-floating-input *ngIf="newVariableOpen" (save)="saveVariable($event)">
      </nw-floating-input>
      <nw-dropdown-component
        *ngIf="selectorOpen"
        [data]="selectorOptions"
        [right]="0"
        [top]="12"
        (clicked)="selectorClicked($event)"
      >
        <div>
          <ng-template let-value>
            <div [ngSwitch]="value.id" class="no-wrap">
              <div *ngSwitchCase="'save'" class="flex-row gap-8 ph-6 pv-2">
                <nw-icon
                  type="bookmark"
                  [size]="14"
                  [outlined]="true"
                ></nw-icon>
                <div>Save</div>
              </div>
              <div *ngSwitchCase="'current'" class="flex-row gap-8 ph-6 pv-2">
                <div class="base-blue-text">{{ value.label }}</div>
                <nw-icon type="close" [size]="12" [outlined]="true"></nw-icon>
              </div>
              <div *ngSwitchDefault>{{ value.label }}</div>
            </div>
          </ng-template>
        </div>
      </nw-dropdown-component>
    </div>
  </div>
</div>
