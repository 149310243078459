import {
  Constraint,
  ConstraintEntity,
  Coords,
  HorizontalOrientation,
  PathSectionData,
  PathSectionDescriptor,
  PositionVector,
  ShapeSelectParams,
  TrajectoryDTransform,
  VerticalOrientation,
} from '../../../../../elements/resource/types/shape.type';
import { PathShape } from '../path-shape';
import { PointController } from '../../../../../elements/util/point-controller/point-controller';
import { Subscription } from 'rxjs';
import { cloneDeep } from 'lodash';
import { BaseVector, Vector } from '../../../../../elements/base/vector/vector';
import { Point } from '../../../../../elements/base/point';
import { PathElement } from '../../primitive/path-element';
import { RightTriangle } from '../../../../../elements/util/right-triangle';
import { CurveSection } from './curve/curve-section';
import { Line } from '../../primitive/line-element';
import { LineRectangleElement } from '../../primitive/line-rectangle-element';
import { ArcItem, PathItem } from '../path-shape.types';

const { abs, pow, sign, sqrt, PI } = Math;

export enum ElementType {
  Arc = 'arc',
  Line = 'line',
  Path = 'path',
  QPath = 'qpath',
}

export type AdjustParams = {
  shapeDrag: boolean;
  fromConstraintId: string;
  fromAdjust: boolean;
};

export interface PathSectionConfig {
  initByDrag?: boolean;
  noEdit?: boolean;
}

export class PathSection {
  angle: number;
  nextAngle: number;
  positiveTurn: boolean;
  absCoords: [number, number] = [0, 0];

  pc: PointController;
  rPc: PointController;

  initialR: number;

  turnAngle: number;

  currentRatio: number;
  inRefresh = false;

  unMoved: [number, number];

  xOffset: number;
  yOffset: number;

  dragSubscribe: Subscription;

  mouseMoveSubscription: Subscription;

  index: number;

  selected = false;

  dragIsInProgress = false;

  subscriptions: Subscription[] = [];

  startLength = 0;
  endLength: number;

  arcStartLength: number;
  arcLength: number;

  sectionPath: PathElement;
  hoverSectionPath: PathElement;

  _x: number;
  _y: number;

  get coords() {
    return [this.x, this.y];
  }

  get x() {
    return this.descriptor.x;
  }

  set x(val: number) {
    this.descriptor.x = val;
  }

  get y() {
    return this.descriptor.y;
  }

  set y(val: number) {
    this.descriptor.y = val;
  }

  get editable() {
    return (
      !this.config?.noEdit && this.pathShape.parent?.getType() === 'root-shape'
    );
  }

  get asTarget() {
    return {
      type: 'section' as ConstraintEntity,
      id: this.id,
    };
  }

  get cs() {
    return this.pathShape.cs;
  }

  get service() {
    return this.pathShape.service;
  }

  get id() {
    return this.descriptor.id;
  }

  get canvasScale() {
    return this.pathShape.canvasScale;
  }

  get amILast() {
    return this.pathShape.amILast(this.index);
  }

  get amILastButOne() {
    return this.pathShape.amILastButOne(this.index);
  }

  get amIFirst() {
    return this.index === 0;
  }

  get absStart() {
    return {
      x: this.pathShape.currentX + this.prevPS.absCoords[0],
      y: this.pathShape.currentY + this.prevPS.absCoords[1],
    };
  }

  get absEnd() {
    const { x, y } = this.absStart;
    const [dx, dy] = this.getCoords();
    return {
      x: x + dx,
      y: y + dy,
    };
  }

  _r: number;

  set r(val: number) {
    this._r = val;
  }

  get isCurve(): boolean {
    return false;
  }

  get isLine() {
    return true;
  }

  get prevLine() {
    return this.prevPS.isLine ? (this.prevPS as PathSection) : undefined;
  }

  get nextLine() {
    return this.nextPS.isLine ? (this.nextPS as PathSection) : undefined;
  }

  get prevCurve() {
    return this.prevPS.isCurve ? (this.prevPS as CurveSection) : undefined;
  }

  get nextCurve() {
    return this.nextPS.isCurve ? (this.nextPS as CurveSection) : undefined;
  }

  get r() {
    return this._r;
  }

  get container() {
    return this.pathShape.container;
  }

  get circleContainer() {
    return this.pathShape.circleContainer;
  }

  arcConfig: PathItem;
  arcStartVector: Vector;
  arcEndVector: Vector;

  get type() {
    return 'ps';
  }

  get _turnAngle() {
    if (this.next) {
      return this.inverseVector.smallerAngle(this.next.vector);
    }
    return;
  }

  upAuxLine: Line;
  downAuxLine: Line;

  leftAuxLine: Line;
  rightAuxLine: Line;

  constructor(
    public pathShape: PathShape,
    protected descriptor: PathSectionDescriptor,
    protected config?: PathSectionConfig,
  ) {
    this.x = this.descriptor.x || 0;
    this.y = this.descriptor.y || 0;
    this.r = this.descriptor.r || 0;
    this.index = this.descriptor.index;
    this.constraints = this.descriptor.constraints || {};
    this.cs.pathSections[this.id?.toString()] = this;

    this.vector = new BaseVector([this.x, this.y]);
    this.inverseVector = new BaseVector([-this.x, -this.y]);

    this._x = this.x;
    this._y = this.y;

    if (this.pathShape.editable) {
      this.cs.keyEventSubscribe('h', () => {
        if (this.dragIsInProgress) {
          this.y = 0;
          this.refresh();
          this.save();
        }
      });
      this.cs.keyEventSubscribe('v', () => {
        if (this.dragIsInProgress) {
          this.x = 0;
          this.refresh();
          this.save();
        }
      });
    }

    if (this.pathShape.editable) {
      const config = { position: { x: 0, y: 0 } };
      this.upAuxLine = new Line(this, this.pathShape.circleContainer, config);
      this.upAuxLine.hide();
      this.downAuxLine = new Line(this, this.pathShape.circleContainer, config);
      this.downAuxLine.hide();
      this.leftAuxLine = new Line(this, this.pathShape.circleContainer, config);
      this.leftAuxLine.hide();
      this.rightAuxLine = new Line(
        this,
        this.pathShape.circleContainer,
        config,
      );
      this.rightAuxLine.hide();
    }

    this.cs.keyDownEventSubscribe('Space', () => {
      if (this.cs.hoveredSection?.id == this.id) {
        this.initRectangleController();
      }
    });

    this.cs.keyEventSubscribe('Space', () => {
      if (this.groupRCShown) {
        this.service.closeAfterEndDrag = true;
      }
    });

    this.cs.keyDownEventSubscribe('Shift', () => {
      if (this.cs.hoveredSection?.id == this.id) {
        this.showSectionPath();
      }
    });

    this.cs.keyEventSubscribe('Shift', () => {
      if (this.sectionPathShown) this.hideSectionPath();
    });

    //   // if (!this.pathShape.selected && this.cs.hoveredSection?.id == this.id) {
    //   if (!this.pathShape.selected) {
    //     console.log('ps > shift > yoo');
    //     this.sideDragMode = true;
    //     this.sectionPath?.patch({
    //       'stroke-opacity': 1,
    //       'stroke-width': 2,
    //     });
    //   }
    // });
  }

  initRectangleController() {
    this.groupRCShown = true;
    this.service.showGroupRC(this.pathShape);
  }

  groupRCShown = false;

  unMovedX: number;
  unMovedY: number;

  startDragByWeight() {
    this.unMovedX = this.x;
    this.unMovedY = this.y;
  }

  updateByScale(scaleX: number, scaleY: number) {
    this.x = this.unMovedX * scaleX;
    this.y = this.unMovedY * scaleY;
  }

  getDescriptor(): PathSectionDescriptor {
    return {
      type: 'line',
      id: this.descriptor.id,
      x: this.x,
      y: this.y,
      r: this.r,
      constraints: this.constraints,
      anchor: this.anchor,
    };
  }

  getArcConfig(start?: number, end?: number): ArcItem {
    let dStart = 0;
    let dEnd = 0;

    if (start && this.arcStartLength < start) {
      dStart = (start - this.arcStartLength) / this.r;
    }

    if (end && end < this.endLength) {
      // dEnd is negative by default
      dEnd = (end - this.endLength) / this.r;
    }

    const antiClockwise = !this.arcStartVector.isClockwise(this.arcEndVector);
    if (antiClockwise) {
      dStart *= -1;
      dEnd *= -1;
    }

    const [x1, y1] = this.arcStartVector.getCoords(dStart);
    const [x2, y2] = this.arcEndVector.getCoords(dEnd);

    let offset: Coords;
    if (dStart) {
      const [x, y] = this.arcAbsStartCoords;
      const [xa, ya] = this.arcStartVector.getDCoords(dStart);
      offset = [x + xa, y + ya];
    }

    return {
      type: 'arc',
      r: this.r,
      cx: -x1,
      cy: -y1,
      a1: Vector.norm(this.arcStartVector.getAngle() + dStart),
      a2: Vector.norm(this.arcEndVector.getAngle() + dEnd),
      x: -x1 + x2,
      y: -y1 + y2,
      antiClockwise,
      offset,
    };
  }

  getPathItem(start?: number, end?: number): PathItem | PathItem[] {
    if (start == 0 && end == 0) {
      // this case shouldn't really happen
      return [];
    }

    const lineConfig = this.getLineConfig(start, end);
    if (this.r) {
      // here comes a next level of magic
      if (start || end) {
        // case 1 - line will not be displayed
        if (this.arcStartLength <= start) {
          return this.getArcConfig(start, end);
        }
        // case 2 - arc will not be displayed
        if (end <= this.arcStartLength) {
          return lineConfig;
        }
      }
      return [lineConfig, this.getArcConfig(start, end)];
    }

    return lineConfig;
  }

  getLineConfig(start?: number, end?: number): PathItem {
    if (this.endLength < start || end < this.startLength) {
      return;
    }

    // if (!this.prev.r && !this.r) {

    //   return {
    //     type: 'line',
    //     x: this.x,
    //     y: this.y,
    //   };
    // }

    let dLength = 0;

    let offset: Coords;
    if (start && this.startLength < start) {
      dLength += start - this.startLength;
      const [xo, yo] = this.getVector(dLength);
      const [xa, ya] = this.absStartCoords;
      offset = [xo + xa, yo + ya];
    }

    const refEnd = this.r ? this.arcStartLength : this.endLength;
    if (end && end < refEnd) {
      dLength += refEnd - end;
    }

    const [x, y] = this.getVector(this.lineLength - dLength);
    return {
      type: 'line',
      x,
      y,
      offset,
    };
  }

  getDTransform(totalLength: number, division: number): TrajectoryDTransform {
    const d = this.d;
    const localDivision = division * (d / totalLength);
    return {
      dx: this.x / localDivision,
      dy: this.y / localDivision,
      incrementMax: Math.floor(localDivision),
    };
  }

  get absStartCoords(): Coords {
    let [x, y] = this.amIFirst ? [0, 0] : this.prevPS.absCoords;
    if (this.startOffset) {
      const [dx, dy] = this.getVector(this.startOffset);
      x += dx;
      y += dy;
    }
    return [x, y];
  }

  get arcAbsStartCoords(): Coords {
    const [xo, yo] = this.absStartCoords;
    const [x, y] = this.getVector(this.lineLength);
    return [xo + x, yo + y];
  }

  hideHoverSections() {
    this.hideHoverPaths();
  }

  updateHoverSections() {
    if (this.amILast && !this.pathShape.closed) {
      this.hideHoverPaths();
      // this.sectionPath?.hide();
      // this.hoverSectionPath?.hide();
    } else {
      this.showHoverPaths();
      // this.sectionPath?.show();
      // this.hoverSectionPath?.show();
    }

    const [x, y] = this.amIFirst ? [0, 0] : this.prevPS.absCoords;

    let [dx, dy] = [0, 0];
    if (this.startOffset) {
      [dx, dy] = this.getVector(this.startOffset);
      // console.log('ps-dx', dx, 'dy', dy);
    }

    this.sectionPath?.patch({
      position: { x: x + dx, y: y + dy },
      x: this.x,
      y: this.y,
      elements: [this.getLineConfig()],
    });

    this.hoverSectionPath?.patch({
      position: { x, y },
      elements: this.elements,
    });
  }

  save() {
    this.unMoved = [this.x, this.y];
  }

  // Implemented by the curve-section
  copy(_section: PathSection, _inverse = false) {}

  initialized = false;

  get elements(): PathItem[] {
    const [x, y] = new BaseVector([this.x, this.y])
      .turn90()
      .unify()
      .scale((this.pathShape.strokeWidth || 1) / 2).endCoords;
    return [
      {
        type: 'line',
        x,
        y,
      },
      {
        type: 'line',
        x: this.x,
        y: this.y,
      },
      {
        type: 'line',
        x: -2 * x,
        y: -2 * y,
      },
      {
        type: 'line',
        x: -this.x,
        y: -this.y,
      },
      {
        type: 'line',
        x,
        y,
      },
    ];
  }

  init() {
    // TODO - remove that part if it is not necessary
    if (this.initialized) {
      return;
    }
    this.initialized = true;

    this.unMoved = [this.x, this.y];

    this.refresh();
    // This a is a super poor code quality. This function calculates the turnAngle field

    this.getRAngle();

    if (this.editable) {
      this.subscriptions.push(
        this.cs.keyEventSubscribe('r', () => {
          if (this.pc?.inProgress || this.rPc?.inProgress) {
            if (this.pathShape.pathSectionLength === 2) {
              this.cs.notify('You cannot delete the only one path-section!');
              return;
            }
            console.log('ps:r:0');
            this.cs.consumeKeyEvent('r');
            this.pc.interrupt();
            this.pcEndDrag(this._dx, this._dy);
            this.pathShape.deleteSection(this);
          }
        }),
      );

      this.subscriptions.push(
        this.cs.keyEventSubscribe('x', () => {
          if (this.pc?.inProgress) {
            this.removeConstraints();
            this.cs.notify('Point constraints have been removed!');
          }
        }),
      );
    }

    // this.sectionPath = new PathElement(this, this.pathShape.sectionContainer, {
    //   'stroke-opacity': 0,
    //   'stroke-width': 1 / this.cs.scaleValue,
    //   stroke: this.pathShape.convertHexToNumber('#7C0A02'),
    //   x: this.x,
    //   y: this.y,
    //   noFill: true,
    // });

    // // console.log('childOffset', this.pathShape.parent.childOffsetX);
    // this.hoverSectionPath = new PathElement(
    //   this,
    //   this.pathShape.sectionContainer,
    //   {
    //     // opacity: 0.001,
    //     fill: '#000',
    //     stroke: '#ff0000',
    //     x: this.x,
    //     y: this.y,
    //     elements: this.elements,
    //   },
    // )
    //   .mouseover(() => {
    //     if (this.pathShape.iamDragged) {
    //       return;
    //     }
    //     this.cs.hoveredSection = this;

    //     if (this.cs.isSpacePressed) {
    //       this.initRectangleController();
    //       return;
    //     }

    //     if (this.sideDragMode) {
    //       this.showSectionPath();
    //     }
    //     this.pathShape.select({ onHover: true });
    //   })
    //   .mouseout(() => {
    //     if (this.pathShape.iamDragged) {
    //       return;
    //     }
    //     this.cs.hoveredSection = null;

    //     if (this.sideDragMode) {
    //       this.sectionPath?.patch({
    //         'stroke-width': 1 / this.cs.canvasScale,
    //         'stroke-opacity': 0,
    //       });
    //     }
    //     this.pathShape.deselect({ onHover: true });

    //     // if (!this.pathShape.selected && !this.dragIsInProgress) {
    //     //   // console.log('ps-deselect > onHover');
    //     //   this.pathShape.deselect({ onHover: true });
    //     //   return;
    //     // }
    //   })
    //   .drag(
    //     (dx, dy) => {
    //       if (
    //         (this.cs.isShiftPressed || this.sideDragMode) &&
    //         this.pathShape.pathSectionLength > 2
    //       ) {
    //         this.dragSection(dx, dy);
    //       } else {
    //         this.service.drag(dx, dy);
    //       }
    //     },
    //     () => {
    //       if (
    //         (this.cs.isShiftPressed || this.sideDragMode) &&
    //         this.pathShape.pathSectionLength > 2
    //       ) {
    //         this.startSectionDrag();
    //       } else {
    //         this.cs.startDrag(this.pathShape);
    //       }
    //     },
    //     () => {
    //       this.cs.hoveredSection = null;
    //       if (
    //         (this.cs.isShiftPressed || this.sideDragMode) &&
    //         this.pathShape.pathSectionLength > 2
    //       ) {
    //         this.endSectionDrag();
    //       } else {
    //         // if (!this.pathShape.selected) {
    //         // }
    //         this.pathShape.deselect({ onHover: true });
    //         this.service.endDrag();
    //       }
    //     },
    //   )
    //   .click(() => {
    //     if (this.pathShape.selected) {
    //       this.showControlPoints();
    //     } else {
    //       this.pathShape.clicked();
    //     }
    //   });

    if (this.amILast && !this.pathShape.closed) {
      this.hoverSectionPath?.hide();
      this.sectionPath?.hide();
    }
    // }
    this.dragInit();
    this.initLineRectangle();
    this.showHideRPC();

    if (this.amILast && !this.pathShape.closed) {
      this.lineRectangle?.hide();
    }
  }

  lineRectangle: LineRectangleElement;
  dragSideStarted = false;
  initLineRectangle() {
    if (this.pathShape.fill || !this.pathShape.stroke) {
      return;
    }

    const [x, y] = this.prevPS.absCoords;
    this.lineRectangle = new LineRectangleElement(this, this.container, {
      position: { x, y },
      x: this.x,
      y: this.y,
      height: this.pathShape.svgAttributes?.stroke?.width || 1,
      // stroke: '#ff0000',
    })
      .drag(
        (dx, dy) => {
          if (!this.pathShape.selected) {
            return;
          }
          if (this.cs.isShiftPressed) {
            return this.pathShape.dragSections(
              dx / this.cs.canvasScale,
              dy / this.cs.canvasScale,
            );
          }
          this.pathShape.service.drag(dx, dy);
        },
        () => {
          if (!this.pathShape.selected) {
            return;
          }

          if (this.cs.isShiftPressed) {
            this.dragSideStarted = true;
            return this.pathShape.prepareForBatchDrag([
              this.prevPS.index,
              this.index,
            ]);
          }

          this.pathShape.localStartDrag();
        },
        () => {
          if (!this.pathShape.selected) {
            return;
          }

          this.pathShape.hideAuxLines();
          if (this.dragSideStarted) {
            this.dragSideStarted = false;
            this.pathShape.endDragMany();
            return;
          }

          this.pathShape.service.endDrag();
          return;
        },
      )
      .mouseover(() => {
        // console.log('mouse-over');
        this.pathShape.select({ onHover: true });
      })
      .mouseout(() => {
        // console.log('mouse-out');
        this.pathShape.deselect({ onHover: true });
      })
      .click(() => this.pathShape.clicked());
  }

  sectionIsDragged = false;

  dragSection(dx: number, dy: number) {
    // TODO - wft?
    if (this.pathShape.getType() === 'shadow') {
      this.service.drag(dx, dy);
      return;
    }

    [this.dx, this.dy] = [dx, dy];
    // This is not the elegant but works

    this.pathShape.dragSections(
      dx / this.cs.canvasScale,
      dy / this.cs.canvasScale,
    );
  }

  get sideDragMode() {
    return this.cs.isShiftPressed;
  }

  endSectionDrag() {
    console.log('end-section-drag');
    if (this.pathShape.getType() === 'shadow') {
      this.service.endDrag();
      return;
    }

    if (!this.pathShape.selected) {
      this.pathShape.deselect({ onHover: true });
    }

    this.dragIsInProgress = false;

    this.enableHover();

    // TODO - check this
    // this.cs.clickAbsorbed = true;
    this.pathShape.iamDragged = false;

    this.pathShape.deselectSections();
    this.pathShape.endDragMany();
    this.pathShape.refresh();
    this.updateHoverSections();
    this.nextPS.refresh();

    this.dx = 0;
    this.dy = 0;
    this.pathShape.endPathShapeDrag();
  }

  shapeDragMode = false;
  dx: number;
  dy: number;

  makeEditable() {
    // -- // -- // -- // -- // -- //
    this.initPC();
    console.log('ps-makeEditable');
  }

  setStartEndLengths() {}

  get lineLength() {
    return this.d - this.startOffset - this.endOffset;
  }

  _setAbsCoords() {
    this.setAbsCoords(this.x, this.y);
    // console.log('_setAbsCoords', this.x, this.y, ' - ', this.absCoords);
  }

  setAbsCoords(x: number, y: number) {
    if (this.amIFirst) {
      // this.absCoords = [x + this.pathShape._dx, y + this.pathShape._dy];
      this.absCoords = [x, y];
    } else if (this.amILast) {
      this.absCoords = [0, 0];
    } else {
      this.absCoords = [
        this.prevPS?.absCoords[0] + x,
        this.prevPS?.absCoords[1] + y,
      ];
    }
  }

  _getAbsCoords(x: number, y: number) {
    if (this.amIFirst) {
      return [x, y];
    } else if (this.amILast) {
      return [0, 0];
    } else {
      return [this.prevPS?.absCoords[0] + x, this.prevPS?.absCoords[1] + y];
    }
  }

  addSectionToSectionConstraint() {
    const hoveredSectionStart = this.localCoords(
      this.cs.hoveredSection.getAbsoluteStartCoords(),
    );

    const hoveredSectionEnd = this.localCoords(
      this.cs.hoveredSection.getAbsoluteEndCoords(),
    );

    const draggedStart = [0, 0] as Coords;
    const draggedEnd = this.getCoords() as Coords;

    const hs_ds = Vector.abs(hoveredSectionStart, draggedStart);
    const hs_de = Vector.abs(hoveredSectionStart, draggedEnd);

    const he_ds = Vector.abs(hoveredSectionEnd, draggedStart);
    const he_de = Vector.abs(hoveredSectionEnd, draggedEnd);

    let startToStart: boolean;

    if (hs_ds < hs_de) {
      //the hoveredStart is closer to draggedStart than to draggedEnd
      if (he_ds < he_de) {
        // the hoveredEnd is closer AS WELL to draggedStart than to draggedEnd
        // so we need to check
        // console.log('1');
        startToStart = he_de < hs_de;
      } else {
        // console.log('2');
        startToStart = true;
      }
    } else {
      // hoverStart is closer to draggedEnd than to draggedStart
      // console.log('es', he_ds, 'ee', he_de);
      if (he_ds < he_de) {
        // hoverEnd is closer AS WELL to draggedEnd than to draggedStart
        // console.log('3');
        startToStart = he_de < hs_de;
      } else {
        // console.log('4');
        startToStart = he_de > hs_de;
      }
    }

    if (startToStart) {
      // --> //

      this.prevPS.setToExternal(
        this.cs.hoveredSection.getAbsoluteStartCoords(),
      );
      this.cs.postAdjusts();

      this.setToExternal(this.cs.hoveredSection.getAbsoluteEndCoords());
      this.cs.postAdjusts();

      this.pathShape.refresh();
      this.prevPS.addPointConstraint(this.cs.hoveredSection.prevPS, true);
      this.addPointConstraint(this.cs.hoveredSection, true);

      const id1 = this.getConstraintId();

      this.constraints[id1] = {
        type: 'intersection',
        subject: 'point:a1',
        objects: [{ id: this.cs.hoveredSection.id, type: 'point:a1' }],
      };

      this.cs.hoveredSection.constraints[id1] = {
        type: 'intersection',
        subject: 'point:a1',
        objects: [{ id: this.id, type: 'point:a1' }],
      };

      const id2 = this.getConstraintId();

      this.constraints[id2] = {
        type: 'intersection',
        subject: 'point:a2',
        objects: [{ id: this.cs.hoveredSection.id, type: 'point:a2' }],
      };

      this.cs.hoveredSection.constraints[id2] = {
        type: 'intersection',
        subject: 'point:a2',
        objects: [{ id: this.id, type: 'point:a2' }],
      };
      this.cs.hoveredSection.copy(this);
    } else {
      // startToEnd //
      // console.log('startToEnd');

      this.prevPS.setToExternal(this.cs.hoveredSection.getAbsoluteEndCoords());
      this.cs.postAdjusts();

      this.setToExternal(this.cs.hoveredSection.getAbsoluteStartCoords());
      this.cs.postAdjusts();

      this.pathShape.refresh();
      this.prevPS.addPointConstraint(this.cs.hoveredSection, true);
      this.addPointConstraint(this.cs.hoveredSection.prevPS, true);

      const id1 = this.getConstraintId();

      this.constraints[id1] = {
        type: 'intersection',
        subject: 'point:a1',
        objects: [{ id: this.cs.hoveredSection.id, type: 'point:a2' }],
      };

      this.cs.hoveredSection.constraints[id1] = {
        type: 'intersection',
        subject: 'point:a2',
        objects: [{ id: this.id, type: 'point:a1' }],
      };

      const id2 = this.getConstraintId();

      this.constraints[id2] = {
        type: 'intersection',
        subject: 'point:a2',
        objects: [{ id: this.cs.hoveredSection.id, type: 'point:a1' }],
      };

      this.cs.hoveredSection.constraints[id2] = {
        type: 'intersection',
        subject: 'point:a1',
        objects: [{ id: this.id, type: 'point:a2' }],
      };

      this.cs.hoveredSection.copy(this, true);
    }

    this.pathShape.refresh();
  }

  showHoverPaths() {
    // console.log('showHoverPath', this.index);
    this.sectionPath?.show();
    this.hoverSectionPath?.show();
  }

  hideHoverPaths() {
    this.sectionPath?.hide();
    this.hoverSectionPath?.hide();
    this.lineRectangle?.hide();
  }

  sectionPathShown = false;
  showSectionPath() {
    this.sectionPathShown = true;
    this.sectionPath.patch({
      'stroke-opacity': 1,
    });
  }
  hideSectionPath() {
    this.sectionPathShown = false;
    this.sectionPath.patch({
      'stroke-opacity': 0,
    });
  }

  get otherHoverSectionCondition() {
    return false;
  }

  clickSubscription: Subscription;

  get initByDrag() {
    return this.config?.initByDrag;
  }

  set initByDrag(val: boolean) {
    this.config.initByDrag = val;
  }

  dragInit() {
    if (this.initByDrag) {
      this.cs.draggingPathSection = this;
      // In this case the path-section is set by the mouseMove event not by the dragging point-controller's circle.
      // This means the end of the dragging is click, which would be considered by the point-controller as start,
      // therefore they must be deleted till the initial drag is in progress.

      this.disableHover();

      // - is it needed?
      this.pathShape.prepareForBatchDrag([this.index]);

      this.mouseMoveSubscription = this.cs.mouseMove.subscribe(({ x, y }) => {
        this.updateByMouseMove(x, y);
      });
    }
  }

  _unMoved = null;

  updateByMouseMove(x: number, y: number) {
    if (!this._unMoved) {
      this._unMoved = [x, y];
    }
    const [x0, y0] = this._unMoved;
    const [dx, dy] = [x - x0, y - y0].map(val => val / this.cs.canvasScale);
    // console.log({ x, y, dx, dy, _x: this.x, _y: this.y });
    // TODO - revise that

    // this.refreshXY(dx, dy, dx, dy);
    this.pathShape.dragSections(dx, dy);

    // this.pathShape.refreshElement();

    return [dx, dy];
  }

  getPositionVector(t: number): PositionVector {
    if (t < this.startLength || this.endLength < t) {
      return;
    }

    const v = new BaseVector([this.x, this.y]);
    let x: number, y: number, angle: number;
    if (t == this.startLength) {
      x = 0;
      y = 0;
      angle = v.getAngle();
    } else if (t == this.endLength) {
      x = this.x;
      y = this.y;
      angle = v.getAngle();
    } else {
      const ratio =
        (t - this.startLength) / (this.endLength - this.startLength);
      v.scale(ratio);
      x = v.end.x;
      y = v.end.y;
    }

    const [ax, ay] = this.prevPS.absCoords;
    return {
      x: ax + x,
      y: ay + y,
      angle: v.getAngle(),
    };
  }

  constraints: Record<string, Constraint> = {};

  initPC() {
    this.pc = new PointController(
      this,
      {
        pOffset: this.prevPS?.absCoords,
        // showCircle: !!this.initByDrag
        // showCircle: true,
        p: [this.x, this.y],
        start: () => this.pcStartDrag(),
        drag: ({ dx, dy }) => this.pcDrag(dx, dy),
        end: ({ dx, dy }) => this.pcEndDrag(dx, dy),
        click: () => this.clickHandler(),
        mouseover: () => {
          this.cs.hoveredPointController = this.pc;

          if (this.pathShape.iamDragged) {
            return;
          }
          this.pathShape.select({ onHover: true });
        },
        mouseout: () => {
          // console.log('pc > mouse-out'); //
          if (this.cs.hoveredPointController == this.pc) {
            this.cs.hoveredPointController = null;
          }
          if (this.pathShape.iamDragged) {
            return;
          }
          this.pathShape.deselect({ onHover: true });
        },
        dragOnto: params => {
          if (!params) {
            return;
          }
          console.log('path-section > draggedOnto');
          const { name: point, shapeIRI } = params;
          this.pathShape.addConstraint(`section_${this.id}`, shapeIRI, point);
        },
      },
      this.pathShape.circleContainer,
      this.pathShape.auxCircleContainer,
    );
    this.pc.hide();
  }

  postInit() {
    this.initRPc();
    this.initPC();
  }

  pcStartDrag() {
    if (this.cs.isPressed(['a', 's', 'd'])) {
      this.pc.stopDrag();

      if (this.cs.isPressed('a')) {
        // console.log('------ add-section ------');
        this.pathShape.addSection(this.index + 1, 'line');
      } else if (this.cs.isPressed('s')) {
        this.pathShape.addSection(this.index + 1, 'curve');
      } else if (this.cs.isPressed('d')) {
        this.pathShape.addSection(this.index + 1, 'anchor-curve');
      }
      return;
    }

    this.prev.startDragR();
    this.startDragR();
    this.next.startDragR();

    this.pathShape.iamDragged = true;
    this.unMoved = [this.x, this.y];
    this.unMovedL = Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
    if (this.selected) {
      return;
    }

    this.rPc?.hide();

    this.pathShape.prepareForBatchDrag([this.index]);
    this.pathShape.disableHover();
  }

  setUnMoved() {
    this.unMoved = [this.x, this.y];
  }

  pcDrag(dx: number, dy: number) {
    this._dx = dx;
    this._dy = dy;
    this.pathShape.dragSections(dx, dy);
  }

  initByDragCount = 0;

  clickHandler() {
    // if (this.initByDragCount == 0) {
    //   this.initByDragCount++;
    //   return;
    // }

    if (this.initByDrag) {
      this.initByDrag = false;
      this.mouseMoveSubscription.unsubscribe();
      this.cs.consumeKeyEvent('a');
      this.cs.draggingPathSection = null;
      this.enableHover();
      this.config.initByDrag = null;
      this.pathShape.saveSections();
      this.pathShape.select();
    } else {
      this.pathShape.clicked();
    }
  }

  unMovedL: number;
  unMovedR: number;
  unMovedDragCoeff: number;

  startDragR() {
    if (!this.r) {
      return;
    }
    this.unMovedR = this.r;
    this.unMovedDragCoeff = this.dragCoeff;
  }

  pcEndDrag(dx?: number, dy?: number) {
    this.pathShape.iamDragged = false;
    this.pathShape.enableHover();
    this.dragIsInProgress = false;

    this.hideCSAuxLines();
    this.hideAuxLines();

    if (this.selected) {
      return this.pathShape.endDragMany();
    }

    this.pc.patch({
      color: '#000',
      'stroke-width': 1,
    });

    // if (this.amILast) { //
    //   // TODO - clarify that part //
    //   this.pathShape.unMoved = null; //
    //   this.pathShape.save(); //
    // }

    this.rPc?.show();
    this.rPc?.patch({
      pOffset: this.absCoords,
      angle: this.getRAngle(),
    });

    if (this.amILast) {
      this.pathShape.updateTranslate(dx + this._dx, dy + this._dy);
      this.pathShape.element.patch({
        position: {
          x: 0,
          y: 0,
        },
      });
    }

    if (
      this.cs.hoveredPointController &&
      this.cs.hoveredPointController !== this.pc &&
      !this.pathShape.closed
    ) {
      if (
        this.cs.hoveredPointController.pathSectionParent?.amILast ||
        this.amILast
      ) {
        this.pathShape.pathSections
          .splice(this.pathShape.pathSections.length - 1, 1)
          .map(ps => {
            console.log('last-section', { ps, last: ps.amILast });
            this.cs.removeAdjust(ps.id);
            ps.remove();
          });
        this.pathShape.closed = true;
      }

      // we shall modify the dx, dy
    }

    this.pathShape.saveSections({ section: this });

    // if (this.cs.hoveredPoint && this.cs.hoveredPoint.id !== this.id) {
    //   // Simple check for not adding the same type of constraints //
    //   this.addPointToPointConstraint();
    //   return;
    // }
    // if (this.cs.hoveredSection) {
    //   this.addPointToSectionConstraint();
    //   this.cs.postAdjusts();
    // }

    // this.updateConstraints();
    this.pathShape.refresh();
    this.hideAuxLines();
  }

  _drag(dx: number, dy: number) {
    const [x, y] = this.unMoved;

    this.refreshXY(x + dx, y + dy, dx, dy);

    // in this case
    if (this.amILast) {
      this.pathShape.pathSections.map(ps => ps._setAbsCoords());
      this.pathShape.pathSections.map(ps => ps.updatePointControllers());
    }

    this.prev.dragR();
    this.dragR();
    this.next.dragR();
  }

  dragR() {
    if (this.r) {
      this.turnAngle = this.dAngle(this.m, this.nextPS.m);
      if (this.dragCoeff < this.unMovedDragCoeff) {
        this.r = this.dragCoeff * (this.unMovedR / this.unMovedDragCoeff);
      }
    }
  }

  drag(x: number, y: number, dx: number, dy: number) {
    console.log({ dx, dy });
    // this.pathShape._batchDrag(dx, dy);
    // if (this.selected) {
    //   return this.pathShape.dragSide(dx, dy);
    // }
    // this.refreshXY(x, y, dx, dy);
  }

  get isXYProblematic() {
    return Math.abs(this.x) < 1 && Math.abs(this.y) < 1;
  }

  hideAuxLines(propagate = true) {
    this.vAuxLine?.hide();
    this.hAuxLine?.hide();
    this.auxLines.map(line => line?.hide());
    if (propagate) {
      this.next?.hideAuxLines(false);
      this.prev?.hideAuxLines(false);
    }
  }

  get auxLines() {
    return [
      this.upAuxLine,
      this.downAuxLine,
      this.leftAuxLine,
      this.rightAuxLine,
    ];
  }

  removeAuxLines() {
    this.upAuxLine?.remove();
    this.upAuxLine = null;

    this.downAuxLine?.remove();
    this.downAuxLine = null;

    this.leftAuxLine?.remove();
    this.leftAuxLine = null;

    this.rightAuxLine?.remove();
    this.rightAuxLine = null;
  }

  get constraintedSections() {
    const arr = [];

    Object.values(this.constraints).map(({ objects }) => {
      objects.map(o => arr.push(o.id));
    });

    return [...new Set(arr)]
      .map(IRI => this.cs.pathSections[IRI])
      .filter(s => !!s);
  }

  findConstraint(_id: string) {
    return Object.values(this.constraints).find(({ objects }) =>
      objects.find(({ id }) => id === _id),
    );
  }

  addPointToPointConstraint() {
    // if (
    //   Object.values(this.constraints).find(({ objects }) => {
    //     const firstReason = !!objects.find(
    //       ({ type, id }) => type === 'point' && id === this.cs.hoveredPoint.id
    //     );

    //     const secondReason = !!this.constraintedSections.find(section =>
    //       section.findConstraint(this.cs.hoveredPoint.id)
    //     );

    //     return firstReason || secondReason;
    //   })
    // ) {
    //   return;
    // }

    const [xl, yl] = this.localCoords(
      this.cs.hoveredPoint.getAbsoluteEndCoords(),
    );

    this.refreshXY(xl, yl);
    // this.addPointConstraint(this.cs.hoveredPoint);
  }

  addPointToSectionConstraint() {
    const hoveredSection = this.cs.hoveredSection;

    const pe = hoveredSection.getCoords();

    const v1 = new Vector(
      { x: 0, y: 0 } as Point,
      { x: pe[0], y: pe[1] } as Point,
    );

    const hoveredSectionStart = hoveredSection.getAbsoluteStartCoords();
    const intersectionPoint = this.getAbsoluteEndCoords();

    const v2 = new Vector(
      {
        x: hoveredSectionStart[0],
        y: hoveredSectionStart[1],
      } as Point,
      {
        x: intersectionPoint[0],
        y: intersectionPoint[1],
      } as Point,
    );

    const d = v2.length / v1.length;

    v1.scale1(1 - d);

    const [ux, uy] = [
      hoveredSection.pathShape.currentX +
        hoveredSection.absCoords[0] -
        v1.end.x -
        (this.pathShape.currentX + this.prevPS.absCoords[0]),
      hoveredSection.pathShape.currentY +
        hoveredSection.absCoords[1] -
        v1.end.y -
        (this.pathShape.currentY + this.prevPS.absCoords[1]),
    ];

    const [dx, dy] = [ux - this.x, uy - this.y].map(
      d => d / this.cs.canvasScale,
    );
    if (this.amILast) {
      this.pathShape.adjustPosition(dx, dy);
    }
    this.nextPS.adjust(dx, dy);

    this.x = ux;
    this.y = uy;

    this.refresh();
    this.pathShape.patchOverrideByKey('sections');

    const hoveredSectionEnd = hoveredSection.getAbsoluteEndCoords();
    this.pc?.patch({
      angle: hoveredSection.m,
      p: [ux, uy],
      ps: this.localCoords(hoveredSectionStart),
      pe: this.localCoords(hoveredSectionEnd),
    });
    // this.lineConstraint = true;

    const id = this.getConstraintId();

    this.cs.hoveredSection.constraints[id] = {
      type: 'intersection',
      subject: 'section',
      pointRatio: d,
      objects: [
        {
          type: 'point',
          id: this.id,
        },
      ],
    };

    this.constraints[id] = {
      type: 'intersection',
      subject: 'point',
      objects: [
        {
          type: 'section',
          id: hoveredSection.id,
        },
      ],
    };

    this.cs.hoveredSection.constraintsHaveChanged = true;
    this.constraintsHaveChanged = true;

    this.pathShape.saveSections({ noPatchIncrement: true, section: this });
    this.cs.hoveredSection.pathShape.saveSections({
      noPatchIncrement: true,
      section: this,
    });
  }

  flip() {
    this.x *= -1;
  }

  _flip() {
    // --> //
  }

  /**
   * Updates the data related to constraints.
   * Like pointRatio, and the [a, ps, pe] parameters of the PointController
   */
  updateConstraints() {
    return;
    Object.entries(this.constraints).map(([id, { type, subject, objects }]) => {
      if (type === 'intersection') {
        switch (subject) {
          case 'point':
            objects.map(object => {
              if (object.type === 'point') {
                return;
              }

              const element = this.cs.getTarget(object) as PathSection;
              const [start, end] = [
                element.getAbsoluteStartCoords(),
                element.getAbsoluteEndCoords(),
              ];
              const middle = this.getAbsoluteEndCoords();

              const l1 = Vector.abs(start, end);
              const l2 = Vector.abs(start, middle);

              if (element.constraints[id]) {
                element.constraints[id].pointRatio = Math.abs(l2 / l1);
              }

              this.pc?.patch({
                angle: element.m,
                ps: this.localCoords(start),
                pe: this.localCoords(end),
              });
            });
            break;
          case 'section':
            objects.map(object => {
              const element = this.cs.getTarget(object) as PathSection;
              // if (!element) {
              //   return;
              // }
              const [start, end] = [
                this.getAbsoluteStartCoords(),
                this.getAbsoluteEndCoords(),
              ];
              element?.pc?.patch({
                angle: this.m,
                ps: element.localCoords(start),
                pe: element.localCoords(end),
              });
            });
            break;
        }
      }
    });
  }

  constraintsHaveChanged = false;

  removeConstraints() {
    this.constraintsHaveChanged = true;
    Object.entries(this.constraints).map(([id, { subject, type, objects }]) => {
      objects.map(object => {
        const element = this.cs.getTarget(object) as PathSection;
        element.constraintsHaveChanged = true;
        delete element.constraints[id];
        this.cs.addIndirectPatch(element.pathShape.IRI, 'sections');
      });
    });

    this.pc?.delete(['a', 'ps', 'pe']);
    // this.lineConstraint = false;

    this.constraints = {};
  }

  localCoords(p: Coords): Coords {
    const [x, y] = this.pathShape.__localize(p);
    return [x - this.prevPS.absCoords[0], y - this.prevPS.absCoords[1]];
  }

  getConstraintId() {
    return Math.random().toString();
  }

  enableHover() {
    this.hoverSectionPath?.enableActions();
  }

  disableHover() {
    this.hoverSectionPath?.disableActions();
  }

  initRPc() {
    // return;
    if (this.rPc) {
      return;
    }

    // if (((this.amILast || this.amILastButOne) && !this.pathShape.isClosed) && !this.mouseMoveSubscription && !force) {
    //   return;
    // }
    // if (this.nextLine) {
    this.currentRatio = this.r / this.dragCoeff;
    this.rPc = new PointController(
      this,
      {
        pOffset: this.absCoords as [number, number],
        showCircle: !!this.initByDrag,
        ds: 0,
        dOffset: 15,
        angle: this.getRAngle(),
        de: this.maxR / this.dragCoeff,
        d: this.r / this.dragCoeff,
        drag: ({ d: r }) => {
          r = (r || 0) * this.dragCoeff;
          this.r = Math.abs(r) > 10e-5 ? r : 0;
          this.inRefresh = true;
          this.currentRatio = this.r / this.dragCoeff;
          this.pathShape.refresh();

          // console.log('r', this.r, 'ps.rOffset', this.pathShape.rOffset);
        },
        end: () => {
          // set it back to original

          this.pathShape.saveSections({ section: this });
        },
        mouseover: () => {
          if (this.pathShape.iamDragged) {
            return;
          }
          this.pathShape.select({ onHover: true });
        },
        mouseout: () => {
          if (this.pathShape.iamDragged) {
            return;
          }
          this.pathShape.deselect({ onHover: true });
        },
      },
      this.pathShape.circleContainer,
      this.pathShape.auxCircleContainer,
    );
    this.rPc.hide();
    // }
  }

  selectMany() {
    this.selected = true;
    this.pc?.selectMany();
  }

  deselectSide() {
    this.selected = false;
    this.pc?.deselectMany();
  }

  zoomUpdate() {
    this.pc.zoomUpdate();
  }

  select(params: ShapeSelectParams = {}) {
    this.pc?.show();
    this.pc?.zoomUpdate();
    if (this.rpcHidden) {
      return;
    }
    this.rPc?.show();
    this.rPc?.zoomUpdate();
  }

  _select() {
    this.pc?.show();
    this.pc?.circle.patch({
      stroke: '#ff0000',
    });
    this.selected = true;
  }

  deselect() {
    this.pc?.hide();
    this.hideControlPoints();
    this.deselectSide();
  }

  hideControlPoints() {
    this.rPc?.hide();
  }

  showControlPoints() {
    this.pc?.show();
    this.pc?.zoomUpdate();
    if (this.rpcHidden) {
      return;
    }
    this.rPc?.show();
  }

  setToExternal(coords: [number, number]) {
    const [x, y] = this.localCoords(coords);
    this.refreshXY(x, y);
  }

  _dx = 0;
  _dy = 0;

  refreshXY(
    xInput: number,
    yInput: number,
    dx?: number,
    dy?: number,
    noAdjust = false,
  ): void {
    // TODO - revise if this magic is necessary //

    // const [_dx, _dy] = this.pcUpdate(xInput, yInput, dx, dy);

    const [_dx, _dy] = [0, 0];
    this._dx = _dx;
    this._dy = _dy;

    const x = xInput + _dx;
    const y = yInput + _dy;

    this.x = x;
    this.y = y;

    // move back
    if (this.amILast) {
      this.pathShape.movePath(dx + _dx, dy + _dy);
      this.next.setAbsCoords(this.next.x + dx + _dx, this.next.y + dy + _dy);
    }

    // check if that is needed

    this.refresh();

    // move back
    this._setAbsCoords();

    // if (this.isLine) {
    //   if (this.prevCurve?.anchor) {
    //     this.prevCurve._b2 = this.norm(this.m - PI);
    //     this.prevCurve.refresh();
    //   }

    //   if (this.nextCurve && this.anchor) {
    //     this.nextCurve._b1 = this.m;
    //   }
    // }

    // this.nextCurve?.refresh();

    // if (noAdjust) {
    //   return;
    // }

    // if (this.pathShape.dashFill) {
    //   this.prevCurve?.setDashCache();
    //   this.nextCurve?.setDashCache();
    //   if (this.isCurve) {
    //     this.setDashCache();
    //   }
    // }
  }

  setDashCache() {}

  applyConstraints(dx: number, dy: number, params?: Partial<AdjustParams>) {
    if (Object.entries(this.constraints).length) {
      // const a = Math.random();
    }

    Object.entries(this.constraints)
      .filter(([constraintId]) => constraintId !== params?.fromConstraintId)
      .map(([constraintId, { pointRatio, objects, subject }]) => {
        objects
          .filter(
            ({ id }) =>
              // If both the subject and object is dragged (selected simultaneously)
              // then no constraint should be applied
              !params?.shapeDrag ||
              !this.cs.pathSections[id]?.pathShape.iamDragged,
          )
          .map(({ id: objectId, type: objectType }) => {
            const newParams = {
              ...params,
              fromConstraintId: constraintId,
            };

            const section = this.cs.pathSections[objectId];
            if (!section) {
              return;
            }
            if (params?.shapeDrag) {
              switch (objectType) {
                case 'section':
                  section.applyConstraints(dx, dy, newParams);
                  section.prevPS.move(dx, dy, newParams);
                  section.nextPS.adjust(dx, dy, constraintId);
                  if (section.amILast || section.prevPS.amILast) {
                    section.pathShape.adjustPosition(dx, dy);
                  }
                  section.pathShape.refresh();
                  break;
                case 'point':
                  section.adjustByConstraint(dx, dy, 1, newParams);
                  break;
              }
            } else {
              if (params?.fromAdjust && objectType === 'section') {
                // In this case a point is adjust which is on a section
                return;
              }

              switch (subject) {
                case 'section':
                  section.adjustByConstraint(
                    dx,
                    dy,
                    params?.fromAdjust ? 1 - pointRatio : pointRatio,
                    newParams,
                  );
                  break;
                case 'point':
                  // this is where the different types of of point/section constraints will be handled
                  if (params?.fromAdjust) {
                    return;
                  }
                  if (objectType === 'point') {
                    section.adjustByConstraint(dx, dy, 1, newParams);
                  }
                  break;
              }
            }
            // Todo - somehow we should save this here
          });
      });
  }

  get constrainedSections() {
    return Object.values(this.constraints)
      .reduce((acc, curr) => {
        acc.push(...curr.objects.map(o => this.cs.pathSections[o.id]));
        return acc;
      }, [])
      .filter(e => !!e);
  }

  // Rubbish code
  // saveChangesByConstraints() {
  //   this.updateConstraints();
  //   Object.entries(this.constraints).map(([, { objects }]) => {
  //     objects.map(({ id }) => {
  //       const ps = this.cs.pathSections[id];
  //       ps.updateConstraints();
  //       ps.pathShape.save();
  //       ps.nextPS.updateConstraints();
  //       ps.pathShape.saveSections(true);
  //     });
  //   });
  // }

  start(tryConstraints = false) {}

  move(dx: number, dy: number, params?: Partial<AdjustParams>) {
    const [x0, y0] = this.unMoved;
    this.x = x0 + dx;
    this.y = y0 + dy;
    console.log(
      this.index,
      'move',
      [this.x, this.y].map(Math.floor),
      'ps',
      // [this.pathShape.x, this.pathShape.y, this.pathShape.offset, ].map(Math.floor)
    );

    this.applyConstraints(dx, dy, params);
    this.refresh();
  }

  applyAdjustment(dx: number, dy: number) {
    // console.log('apply-adjustment', dx, dy);
    const [x0, y0] = this.unMoved;
    this.x = x0 + dx;
    this.y = y0 + dy;

    this.angle = this.getAngle(this.x, -this.y);
    this.setD();

    // console.log('apply-adjustment', this.x, this.y);

    if (this.isLine) {
      if (this.prevCurve?.anchor) {
        this.prevCurve._b2 = this.norm(this.m - PI);
        this.prevCurve.updateLine2();
      }

      if (this.anchor && this.nextCurve) {
        this.nextCurve._b1 = this.m;
        this.nextCurve.updateLine1();
      }
    }

    // this.updateConstraints(); //

    this.pathShape.hasBeenChanged = true;
  }

  hideCSAuxLines() {
    this.cs.previewShape.hideHorizontalAuxLine();
    this.cs.previewShape.hideVerticalAuxLines();
  }

  unMovedAbsCoords: Coords;
  saveAbsCoords() {
    this.unMovedAbsCoords = this.absCoords;
  }

  hAuxLine: PathElement;
  vAuxLine: PathElement;

  pcUpdate(x: number, y: number, dx: number, dy: number): [number, number] {
    this.hAuxLine?.hide();
    this.vAuxLine?.hide();

    this.hideCSAuxLines();

    const [abs_x, abs_y] = this.amILast ? [dx, dy] : this._getAbsCoords(x, y);
    const check = (val: number) =>
      Math.abs(val) <= this.cs.orientationLimit / this.cs.canvasScale;
    let [X, Y] = [this.pathShape.actualX, this.pathShape.actualY];

    const verticalOrientations: VerticalOrientation[] = [];
    const horizontalOrientations: HorizontalOrientation[] = [];

    if (this.isLine) {
      // actualX, actualY contains the tmp [dx, dy] coming from dragging the last section
      const [prev_abs_x, prev_abs_y] = this.prev.absCoords;

      if (check(x)) {
        const [yStart, yEnd] =
          abs_y < prev_abs_y ? [abs_y, prev_abs_y] : [prev_abs_y, abs_y];

        verticalOrientations.push({
          config: {
            type: 'cs',
            x: X + prev_abs_x + (this.amILast ? x - dx : 0),
            yStart: Y + yStart,
            yEnd: Y + yEnd,
          },
          diff: -x,
        });
      }
      if (check(y)) {
        const [xStart, xEnd] =
          abs_x < prev_abs_x ? [abs_x, prev_abs_x] : [prev_abs_x, abs_x];

        horizontalOrientations.push({
          config: {
            type: 'cs',
            y: Y + prev_abs_y + (this.amILast ? y - dy : 0),
            xStart: X + xStart,
            xEnd: X + xEnd,
          },
          diff: -y,
        });
      }
    }

    const sections = this.pathShape.pathSections.filter(
      ps =>
        ps.id != this.prevPS.id &&
        ps.id !== this.nextPS.id &&
        ps.id !== this.id,
    );

    if (this.isCurve) {
      sections.push(this.prevPS);
    }
    if (this.next.isCurve) {
      sections.push(this.next);
    }

    // for (const section of sections) {
    //   const [x, y] = section.absCoords;

    //   if (check(x - abs_x)) {
    //     verticalOrientations.push({
    //       diff: x - abs_x,
    //       config: {
    //         type: 'inner',
    //         x,
    //         yStart: abs_y < y ? abs_y : y,
    //         yEnd: y < abs_y ? abs_y : y,
    //       },
    //     });
    //   }
    //   if (check(y - abs_y)) {
    //     horizontalOrientations.push({
    //       diff: y - abs_y,
    //       config: {
    //         type: 'inner',
    //         y,
    //         xStart: abs_x < x ? abs_x : x,
    //         xEnd: x < abs_x ? abs_x : x,
    //       },
    //     });
    //   }
    // }

    if ((this.amILastButOne && !this.pathShape.closed) || this.next.isCurve) {
      return [
        this.findVerticalOrientation(verticalOrientations),
        this.findHorizontalOrientation(horizontalOrientations),
      ];
    }

    const [next_abs_x, next_abs_y] = this.next.unMovedAbsCoords;
    const [_dx, _dy] = [abs_x - next_abs_x, abs_y - next_abs_y];

    if (check(_dx)) {
      const [yStart, yEnd] =
        abs_y < next_abs_y ? [abs_y, next_abs_y] : [next_abs_y, abs_y];

      const xNew = this.amILast ? 0 : abs_x - _dx;

      if (this.amILast) {
        Y -= dy;
      }

      verticalOrientations.push({
        config: {
          type: 'cs',
          x: X + xNew,
          yStart: Y + yStart,
          yEnd: Y + yEnd,
        },
        diff: -_dx,
      });
    }

    if (check(_dy)) {
      const [xStart, xEnd] =
        abs_x < next_abs_x ? [abs_x, next_abs_x] : [next_abs_x, abs_x];
      const yNew = this.amILast ? 0 : abs_y - _dy;

      if (this.amILast) {
        X -= dx;
      }

      horizontalOrientations.push({
        config: {
          type: 'cs',
          y: Y + yNew,
          xStart: X + xStart,
          xEnd: X + xEnd,
        },
        diff: -_dy,
      });
    }

    return [
      this.findVerticalOrientation(verticalOrientations),
      this.findHorizontalOrientation(horizontalOrientations),
    ];
  }

  findVerticalOrientation(orientations: VerticalOrientation[]) {
    const foundVertical = orientations.reduce((prev, curr) => {
      if (!prev) {
        return curr;
      }
      const { diff } = curr;
      if (Math.abs(diff) < Math.abs(prev.diff)) {
        return curr;
      }
      return prev;
    }, null);

    if (foundVertical) {
      // -- //
      const { type, x, yStart, yEnd } = foundVertical.config;
      switch (type) {
        case 'cs':
          this.cs.previewShape.drawVerticalAuxLine(x, yStart, yEnd);
          break;
        case 'inner':
          this.vAuxLine ||= new PathElement(
            this,
            this.pathShape.circleContainer,
            this.cs.auxLineConfig,
            0,
          );
          this.vAuxLine.patch(
            {
              position: {
                x,
                y: yStart,
              },
              x: 0,
              y: yEnd - yStart,
            },
            true,
          );
          break;
      }
      return foundVertical.diff;
    }

    return 0;
  }

  findHorizontalOrientation(orientations: HorizontalOrientation[]) {
    const foundHorizontal = orientations.reduce((prev, curr) => {
      if (!prev) {
        return curr;
      }
      const { diff } = curr;
      if (Math.abs(diff) < Math.abs(prev.diff)) {
        return curr;
      }
      return prev;
    }, null);

    if (foundHorizontal) {
      // -- //
      // console.log('foundHorizontal', foundHorizontal.config.type);
      const { type, y, xStart, xEnd } = foundHorizontal.config;
      switch (type) {
        case 'cs':
          this.cs.previewShape.drawHorizontalAuxLine(y, xStart, xEnd);
          break;
        case 'inner':
          this.hAuxLine ||= new PathElement(
            this,
            this.pathShape.circleContainer,
            this.cs.auxLineConfig,
            0,
          );
          // console.log('config', foundHorizontal.config);
          this.hAuxLine.patch(
            {
              position: {
                x: xStart,
                y,
              },
              y: 0,
              x: xEnd - xStart,
            },
            true,
          );
          break;
      }
      return foundHorizontal.diff;
    }

    return 0;
  }

  clearStartAnchor() {
    this.prevPS.anchor = false;
  }

  adjust(dx: number, dy: number, fromConstraintId?: string) {
    this.cs.addAdjust(this.id, -dx, -dy);
    // this.applyConstraints(dx, dy, {
    //   fromConstraintId,
    //   fromAdjust: true,
    // });
  }

  adjustedByConstraint = false;

  adjustByConstraint(
    dx: number,
    dy: number,
    ratio: number,
    params?: Partial<AdjustParams>,
  ) {
    return;
    // TODO - revise that //
    if (ratio === undefined) {
      return;
    }

    this.cs.addAdjust(this.id, dx * ratio, dy * ratio);
    this.nextPS.adjust(dx * ratio, dy * ratio);

    if (this.amILast) {
      this.pathShape.drag(dx * ratio, dy * ratio);
      // TODO - Revise
      this.cs.addIndirectPatch(this.pathShape.IRI, 'transform');
    }

    this.cs.addIndirectPatch(this.pathShape.IRI, 'sections');
    this.pathShape.refresh();

    if (params) {
      this.applyConstraints(dx, dy, params);
    }
  }

  getRAngle() {
    this.turnAngle = this.dAngle(this.m, this.nextPS.m);

    // TODO - this should work
    // this.turnAngle = this.inverseVector.smallerAngle(this.next.vector);
    const rAngle = this.nextPS.m - this.turnAngle / 2;
    return rAngle >= 0 ? rAngle : rAngle + 2 * PI;
  }

  get dragCoeff() {
    const sin = Math.sin(Math.abs(this.turnAngle / 2));
    return sin / (1 - sin);
  }

  setLast() {
    const { x, y } = this.pathShape.lastXY;
    // console.log('------ set-last', { x, y });
    this.x = x;
    this.y = y;
  }

  setD() {
    this._d = sqrt(pow(this.x, 2) + pow(this.y, 2));
  }

  refresh(dx = 0, dy = 0) {
    // TODO - move it back

    if (this.amILast) {
      this.setLast();
    }

    // if (this.type !== 'ps' || this.nextPS.type !== 'ps') {
    //   this.rPc?.hide();
    // }

    // move-back
    this.setD();
    this.vector.setEnd([this.x, this.y]);
    this.inverseVector.setEnd([-this.x, -this.y]);
    this.setAbsCoords(this.x, this.y);

    // TODO - move-back
    this.updatePointControllers();

    // else {
    //   this.pc?.patch({
    //     pOffset: cloneDeep<[number, number]>(this.prevPS?.absCoords),
    //     p: [this.x, this.y],
    //   });
    // }

    // if (this.inRefresh) {
    //   this.inRefresh = false;
    // } else if (this.r >= 0 && this.type !== 'as') {
    //   // The dragCoeff may change
    //   // TODO - clean this part - rubbish code
    //   if (
    //     this.r / this.dragCoeff !== this.currentRatio &&
    //     !!this.currentRatio &&
    //     !!this.dragCoeff
    //   ) {
    //     this.r = this.currentRatio * this.dragCoeff;
    //   }
    //   if (this.maxR === Infinity || this.maxR === -Infinity || this.maxR < 0) {
    //     this.r = 0;
    //   } else if (!!this.nextPS && this.r > this.maxR && !!this.maxR) {
    //     this.r = this.maxR;
    //   }
    // }

    if (this.amIFirst) {
      this.startLength = 0;
    } else {
      this.startLength = this.prevPS.endLength;
    }

    if (this.r) {
      const v1 = new BaseVector(cloneDeep([-this.x, -this.y])).unify();

      const v2 = new BaseVector(
        cloneDeep([this.nextPS.x, this.nextPS.y]),
      ).unify();

      const angle = Math.abs(v1.smallerAngle(v2));

      // if (this.amILastButOne) {
      //   console.log(
      //     '----- yoo -----',
      //     angle,
      //     'next.x',
      //     this.nextPS.x,
      //     this.nextPS.y
      //   );
      // }

      const b = RightTriangle.getB({
        a: this.r,
        alfa: angle / 2,
      });

      const c = RightTriangle.getC({
        a: this.r,
        alfa: angle / 2,
      });

      this.endOffset = b;
      this.arcStartLength = this.startLength + this.lineLength;
      const vc = v1.copy().add(v2).reScale(c);

      // TODO - this should works //
      // this.rAngle = vc.copy().getAngle(); // -- // -- //

      const [v1b, v2b] = [v1, v2].map(v => v.reScale(b));

      this.arcStartVector = v1b.substract(vc);
      this.arcEndVector = v2b.substract(vc);

      const arcAngle = this.arcStartVector.smallerAngle(this.arcEndVector);
      this.arcLength = arcAngle * this.r;
      this.endLength = this.arcStartLength + this.arcLength;
      this.currentRatio = this.r / this.dragCoeff;
    } else {
      this.endOffset = 0;
      this.endLength = this.startLength + this.lineLength;
      this.currentRatio = 0;

      if (this.amILast) {
        this.pathShape.rOffset = [0, 0];
      }
    }

    this.angle = this.getAngle(this.x, -this.y);
    this.turnAngle = this.dAngle(this.m, this.nextPS.m);

    // if (this.amILastButOne) {
    //   console.log('----- turnangle ------', this.turnAngle);
    // }

    // if (this.amILast) {
    //   console.log('----- turnangle ------', this.x, this.y);
    // }

    // TODO - this.should work
    // this can be cleaned only with the point-controller together

    // this.angle = this.vector.getAngle();
    // this.turnAngle = this.inverseVector.smallerAngle(this.nextPS.vector);

    // TODO - clean this
    this.setPathPositionOffsetByRadius();

    this.refreshRPC(dx, dy);
    this.updateHoverSections();

    // this.showHideRPC();
    this.refreshCoverElement();
  }

  refreshCoverElement() {
    const [x, y] = this.prevPS.absCoords;
    this.lineRectangle?.patch({
      position: { x, y },
      x: this.x,
      y: this.y,
      height: (this.pathShape.svgAttributes?.stroke?.width || 1) * 5,
    });
  }

  setStartLength() {
    if (this.amIFirst) {
      this.startLength = 0;
    } else {
      this.startLength = this.prev.endLength;
    }
  }

  setEndLength() {
    if (this.amIFirst) {
      this.startLength = 0;
    } else {
      this.startLength = this.prevPS.endLength;
    }
    // console.log('lineLength', this.lineLength);
    this.endLength = this.startLength + this.lineLength;
  }

  setLengths() {
    this.setStartLength();
    this.setEndLength();
  }

  updatePointControllers() {
    if (this.amIFirst) {
      // this.pc?.patch({
      //   // pOffset: cloneDeep<[number, number]>(this.prevPS?.absCoords),
      //   p: [
      //     this.x + (this.pathShape.dx || 0),
      //     this.y + (this.pathShape.dy || 0),
      //   ],
      // });
      this.pc?.patch({
        pOffset: cloneDeep<[number, number]>(this.prevPS?.absCoords),
        p: [this.x, this.y],
      });
    } else if (this.amILast) {
      this.pc?.patch({
        pOffset: [0, 0],
        p: [0, 0],
      });
    } else {
      this.pc?.patch({
        pOffset: cloneDeep<[number, number]>(this.prevPS?.absCoords),
        p: [this.x, this.y],
      });
    }
  }

  rpcHidden = true;
  showHideRPC() {
    if (this.pathShape.pathSections.length == 2) {
      this.rPc?.hide();
      return;
    }

    if ((this.amILast || this.amILastButOne) && !this.pathShape.closed) {
      this.rPc?.hide();
      return;
    }

    if (this.isLine && this.nextLine) {
      this.rpcHidden = false;
      this.rPc?.show();
    }
    this.rPc?.hide();
  }

  setPathPositionOffsetByRadius() {
    if (this.amILast && this.endOffset) {
      this.pathShape.rOffset = this.nextPS.getVector(this.endOffset);
    }
  }

  rAngle: number;

  refreshRPC(dx = 0, dy = 0) {
    const [ax, ay] = this.absCoords;

    this.rPc?.patch({
      d: this.currentRatio,
      pOffset: [ax + dx, ay + dy],
      angle: this.getRAngle(),
      // a: this.rAngle,
      de: this.maxR / this.dragCoeff,
    });
  }

  // TODO - check if this is needed
  a: number;

  vector: Vector;
  inverseVector: Vector;

  // TODO - rename //

  _d: number;

  get d() {
    return this._d || sqrt(pow(this.x, 2) + pow(this.y, 2));
  }

  set d(val: number) {
    this._d = val;
  }

  get m() {
    if (this.angle && this.r > 0) {
      return this.angle;
    }
    if (this.isDef(this.x) && this.isDef(this.y)) {
      return this.getAngle(this.x, -this.y);
    }
    if (this.isDef(this.a)) {
      return this.prevM - this.convertAngle(this.a);
    }
  }

  get pcs() {
    return [this.pc, this.rPc].filter(v => !!v);
  }

  showPCs() {
    this.pcs.map(pc => pc.show());
  }

  hidePCs() {
    console.log('ps > hidePCs');
    this.pcs.map(pc => pc.hide());
  }

  get prevPS() {
    return this.pathShape.previousPathSection(this.index);
  }

  get nextPS() {
    return this.pathShape.nextPathSection(this.index);
  }

  get prev() {
    return this.pathShape.previousPathSection(this.index);
  }

  get next() {
    return this.pathShape.nextPathSection(this.index);
  }

  get _next() {
    return this.pathShape.nextPathSection(this.index, true);
  }

  get firstPS() {
    return this.pathShape.pathSections[0];
  }

  get prevM() {
    return this.prevPS?.m || 0;
  }

  get mStart() {
    return this.m;
  }

  get mEnd() {
    return this.m;
  }

  get anchor() {
    return this.descriptor.anchor;
  }

  set anchor(val: boolean) {
    this.descriptor.anchor = val;
  }

  flipAnchor() {
    this.anchor = !this.anchor;
  }

  get nextM() {
    return this.flipAngle(this.nextPS?.m || 0);
  }

  get tan1() {
    return false;
  }

  get tan2() {
    return false;
  }

  get startOffset() {
    return this.prevPS?.endOffset || 0;
  }

  _endOffset: number;

  set endOffset(val: number) {
    this._endOffset = val;
  }

  get endOffset() {
    return this._endOffset || 0;
  }

  get offsetCoords(): Coords {
    const angle = this.angle;
    const offset =
      this.startOffset +
      this.intervalStartOffset +
      this.endOffset +
      this.intervalEndOffset;
    return offset
      ? [offset * Math.cos(angle), -offset * Math.sin(angle)]
      : [0, 0];
  }

  get lineXY() {
    const [ox, oy] = this.offsetCoords;
    return [this.x - ox, this.y - oy];
  }

  get maxR() {
    const left = this.d - this.startOffset;
    const right = this.nextPS.offsetForR;
    const d = Math.min(left, right);
    return d / Math.tan(Math.PI / 2 - this._turnAngle / 2);
  }

  get offsetForR() {
    // console.log('d', this.d, 'endOffset', this.endOffset); //
    return this.d - this.endOffset;
  }

  _intervalStartOffset: number;

  set intervalStartOffset(val: number) {
    this._intervalStartOffset = val;
  }

  get intervalStartOffset() {
    return this._intervalStartOffset || 0;
  }

  _intervalEndOffset: number;

  set intervalEndOffset(val: number) {
    this._intervalEndOffset = val;
  }

  get intervalEndOffset() {
    return this._intervalEndOffset || 0;
  }

  get __endOffset() {
    return 0;
  }

  get startOffsetByInterval() {
    if (this.interval && this.startLength < this.startLengthRef) {
      return this.startLengthRef - this.startLength;
    }
    return 0;
  }

  get startOffsetByR() {
    return this.prevPS?.type === 'as' ? 0 : this.prevPS?.endOffset || 0;
  }

  get arcStartOffset() {
    if (this.interval && this.arcStart < this.startLengthRef) {
      return this.startLengthRef - this.arcStart;
    }
    return 0;
  }

  get arcEndOffset() {
    if (this.interval && this.endLengthRef < this.arcEnd) {
      return this.arcEnd - this.endLengthRef;
    }
    return 0;
  }

  get lineEnd() {
    return this.startLength + this.lineLength;
  }

  // It is only accessed when r !== 0
  get arcStart() {
    return this.lineEnd;
  }

  get arcEnd() {
    return this.endLength;
  }

  get interval() {
    return !!this.pathShape.lengthInterval;
  }

  get startLengthRef() {
    return this.pathShape.lengthInterval?.[0];
  }

  get endLengthRef() {
    return this.pathShape.lengthInterval?.[1];
  }

  getVector(d: number): Coords {
    return d ? [Math.cos(this.angle) * d, -Math.sin(this.angle) * d] : [0, 0];
  }

  _getSection() {
    const { m, l, a } = this.getSectionData();

    let d = ``;
    if (m) {
      d += `M ${m[0]} ${m[1]} `;
    }
    if (l) {
      d += `l ${l[0]} ${l[1]} `;
    }
    if (a) {
      const { r, largeArc, p } = a;
      d +=
        `a ${abs(r)} ${abs(r)} ` +
        `0 ${largeArc ? '1' : '0'} ${r > 0 ? '1' : '0'} ` +
        `${p[0]} ${p[1]}`;
    }
    return d;
  }

  getSectionData(): PathSectionData {
    const sectionData: PathSectionData = {};

    if (
      this.interval &&
      (this.endLengthRef <= this.startLength ||
        this.endLength <= this.startLengthRef)
    ) {
      return {};
    }

    if (!this.interval || this.startLengthRef < this.lineEnd) {
      const startOffsetByInterval = this.startOffsetByInterval;
      const startOffsetByR = this.startOffsetByR;
      const startOffset = startOffsetByInterval + startOffsetByR;

      if (startOffsetByInterval) {
        const [x, y] = this.getVector(startOffset);
        sectionData.m = this.convertToAbs(x, y);
      }

      let endOffset = this.endOffset;
      if (this.endLengthRef < this.lineEnd) {
        endOffset += this.lineEnd - this.endLengthRef;
      }

      const offset = startOffset + endOffset;
      const [ox, oy] = this.getVector(offset);

      sectionData.l = [this.x - (ox || 0), this.y - (oy || 0)];
    }

    if (!this.r || this.endLengthRef < this.arcStart) {
      return sectionData;
    }

    if (this.amILast && this.endOffset) {
      this.pathShape.rOffset = this.nextPS.getVector(this.endOffset);
    }

    return sectionData;
  }

  ax: number;
  ay: number;

  aArc: number;

  getStartPoint(start: number) {
    const diff = start - this.startLength;
    const [x, y] = this.prevPS?.absCoords;
    const [dx, dy] = new BaseVector([this.x, this.y]).reScale(diff).endCoords;
    return [x + dx, y + dy];
  }

  getEndPoint(end: number) {
    const diff = this.endLength - end;
    const scale = diff / this.d;
    let [x, y] = this.absCoords;
    if (this.prevPS.endOffset) {
      const angle = this.angle;
      x += this.prevPS.endOffset * Math.cos(angle);
      y -= this.prevPS.endOffset * Math.sin(angle);
    }
    return [x - this.x * scale, y - this.y * scale];
  }

  getBaseAngle() {
    return Math.atan(this.y / this.x);
  }

  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }
    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return Math.PI - angle;
    }
    if (x < 0 && y < 0) {
      return Math.PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * Math.PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? Math.PI / 2 : (3 * Math.PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : Math.PI;
    }
  }

  getDiff(alfa: number, theta: number, positive: boolean, l: number) {
    const gamma = positive ? alfa - theta : alfa + theta;
    return {
      x: l * Math.cos(gamma),
      y: l * Math.sin(gamma),
    };
  }

  flipAngle(angle: number) {
    return angle >= PI ? angle - PI : angle + PI;
  }

  dAngle(a1: number, a2: number) {
    a1 = this.flipAngle(a1);
    return this.dA(a1, a2);
  }

  dA(a1: number, a2: number) {
    const diff = a2 - a1;
    if (Math.abs(diff) > PI) {
      a2 += a2 > a1 ? -2 * PI : 2 * PI;
      return a2 - a1;
    } else {
      return diff;
    }
  }

  norm(angle: number) {
    if (angle > 2 * PI) {
      return angle - 2 * PI;
    }
    if (angle < 0) {
      return angle + 2 * PI;
    }
    return angle;
  }

  getCoords(): [number, number] {
    return [this.x, this.y];
  }

  removeEditPaths() {
    if (this.sectionPath) {
      this.sectionPath.remove();
      delete this.sectionPath;
    }

    // if (this.hoverSectionPath) {
    //   this.hoverSectionPath?.remove();
    //   this.hoverSectionPath = undefined;
    // }
  }

  isDef(variable: any) {
    return variable !== undefined;
  }

  convertAngle(a: number) {
    return (a * PI) / 2;
  }

  remove() {
    this.subscriptions.map(s => s.unsubscribe());
    this.pc?.remove();
    this.rPc?.remove();
  }

  getAbsoluteStartCoords(): [number, number] {
    const [endX, endY] = this.getAbsoluteEndCoords();
    return [endX - this.x, endY - this.y];
  }

  getAbsoluteEndCoords(): [number, number] {
    return [
      this.pathShape.currentX + this.absCoords[0],
      this.pathShape.currentY + this.absCoords[1],
    ];
  }

  getAbsCoords(localX = 0, localY = 0) {
    return [
      this.pathShape.currentX + this.prevPS?.absCoords[0] + localX,
      this.pathShape.currentY + this.prevPS?.absCoords[1] + localY,
    ];
  }

  getRelativeCoords(absX: number, absY: number) {
    return [
      absX - (this.pathShape.currentX + this.prevPS?.absCoords[0]),
      absY - (this.pathShape.currentY + this.prevPS?.absCoords[1]),
    ];
  }

  addPointConstraint(section: PathSection, sectionType = false) {
    const id = this.getConstraintId();

    this.constraints[id] = {
      type: 'intersection',
      subject: 'point',
      objects: [{ id: section.id, type: 'point' }],
    };

    section.constraints[id] = {
      type: 'intersection',
      subject: 'point',
      objects: [{ id: this.id, type: 'point' }],
    };

    this.pathShape.saveSections();

    if (sectionType) {
      this.cs.hoveredSection.pathShape.saveSections({ noPatchIncrement: true });
    } else {
      this.cs.hoveredPoint.pathShape.saveSections({ noPatchIncrement: true });
    }
  }

  getArcDistance(params: { r: number; l: number; largeArc: number }) {
    const { r, l, largeArc } = params;
    const dOrigin = sqrt(abs(pow(r, 2) - pow(l, 2)));
    if (largeArc === 1) {
      return r + sign(r) * dOrigin;
    } else {
      return r - sign(r) * dOrigin;
    }
  }

  originCircle: Element;

  getArcOrigin(params: { x: number; y: number; r: number; largeArc?: number }) {
    const { x, y, r, largeArc } = params;
    const rpcR = this.getArcDistance({
      l: sqrt(pow(x, 2) + pow(y, 2)) / 2,
      r,
      largeArc,
    });
    return new BaseVector([x, y])
      .scale(0.5)
      .translate(x / 2, y / 2)
      .reScale(r - rpcR)
      .rotate(PI / 2).endCoords;
  }

  getArcSectionString(params: {
    m?: string;
    r: number;
    largeArc: number;
    x: number;
    y: number;
    absXY?: boolean;
  }) {
    const { m, r, x, y, largeArc, absXY } = params;
    return (
      `${m || ''}` +
      `${absXY ? 'A' : 'a'} ${r} ${r} ` +
      `0 ${largeArc === 1 ? '1' : '0'} ${sign(r) === 1 ? '1' : '0'} ` +
      `${x} ${y}`
    );
  }

  convertToAbs(x: number, y: number): Coords {
    const [x0, y0] = this.prevPS.absCoords || [0, 0];
    return [(x0 || 0) + x, (y0 || 0) + y];
  }

  getArcStartPoint(params: {
    dA: number;
    x: number;
    y: number;
    r: number;
    largeArc?: number;
  }) {
    const { dA, x, y, r, largeArc } = params;
    const angleToRotateWith = dA / r;
    const [xo, yo] = this.getArcOrigin({ x, y, r, largeArc });
    return {
      coords: new Vector(new Point(xo, yo), new Point(0, 0)).rotate(
        angleToRotateWith,
      ).endCoords,
      cutArc: abs(angleToRotateWith),
    };
  }

  getArcEndPoint(params: {
    dA: number;
    x: number;
    y: number;
    r: number;
    largeArc?: number;
  }) {
    const { x, y, r, dA, largeArc } = params;
    const angleToRotateWith = dA / r;
    const [xs, ys] = this.getArcOrigin({ x, y, r, largeArc });
    return {
      coords: new Vector(new Point(xs, ys), new Point(x, y)).rotate(
        -angleToRotateWith,
      ).endCoords,
      cutArc: abs(angleToRotateWith),
    };
  }
}

// this.hoverPath = this.cs.snap
//   .path(this.sectionPathD)
//   .attr({
//     fill: 'none',
//     stroke: 'transparent',
//     'stroke-width': 20 / this.cs.scaleValue,
//   })
//   .mouseover(() => {
//     if (this.dragIsInProgress || this.cs.isPressed('Shift')) {
//       return;
//     }

//     // f stands for 'force'
//     if (!this.pathShape.selected && !this.cs.isPressed('f')) {
//       return;
//     }

//     if (this.otherHoverSectionCondition) {
//       return;
//     }

//     this.cs.hoveredSection = this;
//   })
//   .mouseout(() => {
//     this.cs.hoveredSection = null;
//     this.sectionPath.attr({ stroke: 'transparent' });
//   })
//   // This is crucial
//   .click(e => e.stopPropagation())
//   .drag(
//     (dx, dy) => {
//       // if (!this.pathShape.selected) {
//       //   return;
//       // }
//       [this.dx, this.dy] = [dx, dy];

//       if (
//         // Line drag mode
//         this.pathShape.selected &&
//         this.pathShape.pathSections.length > 2
//       ) {
//         this.pathShape.dragMany(
//           dx / this.cs.scaleValue,
//           dy / this.cs.scaleValue
//         );
//       } else {
//         this.cs.drag(dx / this.cs.scaleValue, dy / this.cs.scaleValue);
//       }
//     },
//     () => {
//       if (
//         // Line drag mode
//         this.pathShape.selected &&
//         this.pathShape.pathSections.length > 2
//       ) {
//         this.dragIsInProgress = true;

//         this.pathShape.deselectMany();
//         this.pathShape.preSelectMany(
//           [this.prevPS.index, this.index].sort((i1, i2) => i1 - i2)
//         );
//         this.pathShape.startDrag();
//       } else {
//         this.pathShape.currentlyDragged = true;
//         this.cs.startDrag();
//       }
//     },
//     () => {
//       if (
//         // Line drag mode
//         this.pathShape.selected &&
//         this.pathShape.pathSections.length > 2
//       ) {
//         this.enableHover();
//         this.dragIsInProgress = false;

//         // TODO - check this
//         // this.cs.clickAbsorbed = true;

//         this.showControlPoints();
//         this.pathShape.deselectMany();
//         this.pathShape.saveSections();
//         this.pathShape.refresh();
//         this.updateHoverPaths();
//         this.nextPS.refresh();
//         // console.log('here', this.pathShape.selected); //
//       } else {
//         if (this.dx || this.dy) {
//           this.cs.endDrag(this.dx, this.dy);
//         } else {
//           this.pathShape.clicked();
//         }
//         this.showControlPoints();
//       }
//       this.dx = 0;
//       this.dy = 0;
//       this.pathShape.currentlyDragged = false;
//     }
//   );
// this.pathShape.sectionGroup.add(this.hoverPath);
