import { Pipe, PipeTransform } from '@angular/core';

import { cloneDeep as _cloneDeep } from 'lodash';

@Pipe({ name: 'step' })
export class StepPipe implements PipeTransform {
  transform<T>(value: number): number {
    if (typeof value !== 'number') {
      return value || 0;
    }

    // if (value < 1000) {
    //   return parseFloat(value.toFixed(2));
    // }

    // if (value < 10000) {
    //   return parseFloat(value.toFixed(2));
    // }

    if (value > 100 || value % 1 == 0) {
      return parseFloat(value.toFixed(0));
    }

    return parseFloat(value.toFixed(2));
  }
}
