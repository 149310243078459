import { on } from '@ngrx/store';
import { Project } from './project.interface';
import {
  deleteProjectFromStore,
  loadProjects,
  resetDeleteIsPending,
  setCurrentProject,
  setDeleteIsPending,
  setProject,
  setProjects,
  setFile,
  saveProjectLanguagesBase,
  saveProjectColorPaletteBase,
  setCurrentLanguage,
  stepLanguage,
  setFiles,
  setNewProjectLoading,
  setNewlyCreatedProject,
  setNewFileLoading,
  loadProjectFailed,
  loadProjectWithFilesById,
  setLibraryValue,
  deleteFile,
} from './project.actions';
import { loadHome } from '../home/home.actions';
import { createImmerReducer } from 'ngrx-immer/store';
import { ResourceData } from '../elements/resource/resource.types';
import { setProjectLoaded } from '../element-editor/store/editor.actions';
import { set as _set } from 'lodash';

export type OverlayComponentType =
  | 'languages'
  | 'color-palette'
  | 'color-selector'
  | 'prompt'
  | 'users'
  | 'styles';

export interface OverlayConfig {
  type: OverlayComponentType;
  data?: any;
  callback?: (response: any) => void;
}

export interface ProjectState {
  listLoading: boolean;
  allProjects: Record<string, Project>;
  deleteIsPending?: string;
  currentProjectID?: string;
  newFileLoading?: boolean;
  currentLanguage?: string;
  files?: Record<string, ResourceData>;
  newProjectLoading?: boolean;
  newlyCreatedProjects?: Record<string, number>;
  isProjectLoaded?: boolean;
  error?: {
    type: 'load-project' | 'load-list' | 'save-project';
    message: string;
  };
}

export const initialProjectState: ProjectState = {
  listLoading: false,
  allProjects: {},
  // currentLanguage: 'hu',
  files: {},
  newlyCreatedProjects: {},
  newFileLoading: false,
  isProjectLoaded: false,
};

export const projectReducer = createImmerReducer(
  initialProjectState,
  on(loadProjects, state => ({ ...state, listLoading: true })),
  on(loadHome, state => ({ ...state, listLoading: true })),
  on(setDeleteIsPending, (state, { id }) => ({
    ...state,
    deleteIsPending: id,
  })),
  on(setProjectLoaded, (state, { value }) => {
    // -- // -- //
    state.isProjectLoaded = value;
    return state;
  }),
  on(setFiles, (state, { files }) => {
    state.files = files;
    return state;
  }),
  on(resetDeleteIsPending, state => ({
    ...state,
    deleteIsPending: undefined,
  })),
  on(setLibraryValue, (state, { key, varName, value }) => {
    const { currentProjectID, allProjects } = state;
    if (!allProjects[currentProjectID]) {
      return;
    }
    _set(state.allProjects[currentProjectID], ['library', key, varName], value);
    return state;
  }),
  on(loadProjectFailed, (state, { message }) => {
    state.error = {
      type: 'load-project',
      message,
    };
    return state;
  }),
  on(setNewlyCreatedProject, (state, { id: IRI, time }) => {
    state.newlyCreatedProjects[IRI] = time;
    return state;
  }),
  on(setProject, (state, { project }) => {
    return {
      ...state,
      allProjects: {
        ...state.allProjects,
        [project.id]: project,
      },
      isProjectLoaded: true,
    };
  }),
  on(loadProjectWithFilesById, (state, { id }) => {
    state.isProjectLoaded = false;
    state.currentProjectID = id;
    return state;
  }),
  on(deleteProjectFromStore, (state, { id }) => {
    delete state.allProjects[id];
    return state;
  }),
  on(setNewProjectLoading, (state, { value }) => {
    state.newProjectLoading = value;
    return state;
  }),
  on(setNewFileLoading, (state, { value }) => {
    state.newFileLoading = value;
    return state;
  }),
  on(setProjects, (state, { projects }) => {
    return {
      ...state,
      allProjects: projects.reduce(
        (existing, project) => ({
          ...existing,
          [project.id]: project,
        }),
        { ...state.allProjects },
      ),
      listLoading: false,
    };
  }),
  on(setCurrentProject, (state, { id }) => {
    state.currentProjectID = id;
    return state;
  }),
  on(setCurrentLanguage, (state, { language }) => {
    state.currentLanguage = language;
    console.log('set-current-lang', language);
    return state;
  }),
  on(stepLanguage, state => {
    const { allProjects, currentProjectID, currentLanguage } = state;
    const currentProject = allProjects[currentProjectID];

    if (!currentProject.languages?.length) {
      return;
    }

    if (!currentLanguage) {
      state.currentLanguage = currentProject.languages[0];
      console.log('set-current-lang 1', state.currentLanguage);
      return state;
    }

    const index = currentProject.languages.findIndex(
      lang => lang == currentLanguage,
    );
    state.currentLanguage = currentProject.languages[index + 1] || '';
    console.log('set-current-lang 2', state.currentLanguage);
    return state;
  }),
  on(saveProjectColorPaletteBase, (state, { colorPalette }) => {
    console.log('save-project-color-palette-base', colorPalette);
    const { allProjects, currentProjectID } = state;
    const currentProject = allProjects[currentProjectID];
    currentProject.colorPalette = colorPalette;
    state.allProjects[currentProjectID] = currentProject;
    return state;
  }),
  on(saveProjectLanguagesBase, (state, { languages }) => {
    const { allProjects, currentProjectID } = state;
    const currentProject = allProjects[currentProjectID];
    currentProject.languages = languages;
    state.allProjects[currentProjectID] = currentProject;
    return state;
  }),
  on(setFile, (state, { file }) => {
    console.log('project-reducer » setFile');
    state.files[file.IRI] = file;
    return state;
  }),
  on(deleteFile, (state, { IRI }) => {
    delete state.files[IRI];
    return state;
  }),
);
