import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Project } from '../../projects/project.interface';
import { Store } from '@ngrx/store';
import {
  projectList,
  projectListLoading,
  projectByOrganisationId,
  projectById,
  isDeletePending,
  newProjectLoading,
} from '../../projects/project.selector';
import {
  createProject,
  deleteProject,
  loadProjectById,
  setNewProjectLoading,
} from '../../projects/project.actions';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';
import { myUserId, userById } from '../../user/user.selector';
import { User } from '../../user/user.interface';
import {
  myRoleByOrganisationId,
  userListByOrganisationId,
} from '../../organisation/organisation.selector';
import { Role, RoleTypes } from '../../organisation/role.interface';

@Component({
  selector: 'nw-project-selector',
  templateUrl: './project-selector.component.html',
  styleUrls: ['./project-selector.component.scss'],
  host: {
    '(document:keyup)': 'keyup($event)',
  },
})
export class ProjectSelectorComponent {
  @Input() set organisationId(id: string) {
    this.organisationId$.next(id);
  }

  @Input() editable = false;

  readonly organisationId$ = new BehaviorSubject<string>(undefined);

  newProjectPanel = false;
  newProjectName = '';

  editProjectPanel = false;
  editProject: Project;
  editProjectSubscription: Subscription;

  organisationUsers$ = this.organisationId$.pipe(
    distinctUntilChanged(),
    mergeMap(id => this.store.select(userListByOrganisationId(id))),
  );

  isDeletePendingSub: Subscription;
  isDeletePending: string;

  hoveredProject: string;

  readonly projects$: Observable<Project[]>;
  readonly myOrganisationRole$: Observable<RoleTypes>;
  readonly myUserId$: Observable<string>;
  readonly loading$: Observable<boolean>;
  readonly newProjectLoading$: Observable<boolean>;

  constructor(
    private readonly store: Store,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    this.projects$ = this.organisationId$.pipe(
      distinctUntilChanged(),
      mergeMap(id =>
        this.store.select(id ? projectByOrganisationId(id) : projectList),
      ),
    );
    this.loading$ = this.store.select(projectListLoading);
    this.newProjectLoading$ = this.store.select(newProjectLoading);

    this.myOrganisationRole$ = this.organisationId$.pipe(
      distinctUntilChanged(),
      mergeMap(id => this.store.select(myRoleByOrganisationId(id))),
    );

    this.myUserId$ = this.store.select(myUserId);

    this.isDeletePendingSub = this.store
      .select(isDeletePending)
      .subscribe(isDeletePending => {
        this.isDeletePending = isDeletePending;
      });
  }

  keyup(event: KeyboardEvent) {
    if (event.key !== 'Escape') {
      return;
    }
    this.resetNewPanel();
  }

  addProject() {
    this.newProjectPanel = true;
  }

  resetNewPanel(event?: Event) {
    if (event && event.target !== event.currentTarget) {
      return;
    }

    this.newProjectName = '';
    this.newProjectPanel = false;
  }

  submitNewProject() {
    if (!this.newProjectName) {
      return;
    }

    const name = this.newProjectName;
    this.resetNewPanel();

    this.store.dispatch(setNewProjectLoading({ value: true }));
    this.store.dispatch(createProject({ name }));
  }

  deleteProject(project: Project) {
    const result = confirm('Are you sure you want to delete this project?');
    if (result) {
      this.store.dispatch(deleteProject({ id: project.id }));
    }
  }

  submitEditedProject() {
    throw new Error('Method not implemented.');
  }

  resetEditPanel() {
    this.editProjectSubscription.unsubscribe();
    this.editProject = undefined;
    this.editProjectPanel = false;
  }

  getUserById(id: string): Observable<User> {
    return this.store.select(userById(id));
  }

  getKeys<T>(object: T): string[] {
    return Object.keys(object);
  }
}
