import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { _attributeState } from '../../../../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../../../../base/base-attribute.component';

@Component({
  selector: 'iw-color-blink-animation',
  templateUrl: './color-blink-animation.component.html',
  styleUrls: ['./color-blink-animation.component.scss'],
})
export class ColorBlinkAnimationComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'color-blink';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('color-blink'));

    this.attributeState$.subscribe(attributeState => {
      // console.log('color-blink-attr', attributeState);
    });
  }
}
