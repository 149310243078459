<div class="animation-controller-component h-100 editor-container">
  <div class="title">Animation</div>
  <div
    *ngIf="selectedObject"
    class="flex-column gap-4 w-100"
    style="padding: 0 12px"
  >
    <!-- <div class="delimiter"></div>
    <div>Current</div>
    <div class="delimiter"></div> -->
    <nw-function-frame
      style="width: 100%"
      *ngIf="selectedObject.frame.function"
      [frame]="functionFrameObject"
    ></nw-function-frame>

    <nw-canvas-transform
      *ngIf="
        selectedObject.frame.type == 'canvas-transform' &&
        selectedCanvasTransformFrame
      "
      [frame]="selectedCanvasTransformFrame"
    ></nw-canvas-transform>

    <!-- <nw-canvas-transform
      *ngIf="selectedSubSceneTransitionFrame.frame.targetSubScene"
      [frame]="selectedSubSceneTransitionFrame"
    ></nw-canvas-transform> -->

    <nw-text-animation
      *ngIf="textFrameObject.type == 'text'"
      [frameObject]="textFrameObject"
    >
    </nw-text-animation>
    <div *ngIf="selectedObject.frame.stateTransition">
      StateTransition
      <nw-boolean-input
        key="hide"
        [value]="[selectedSceneTransitionObject.frame.hide]"
        (changed)="setTransitionParameter('hide', $event)"
      ></nw-boolean-input>
      <nw-boolean-input
        key="show"
        [value]="[selectedSceneTransitionObject.frame.show]"
        (changed)="setTransitionParameter('show', $event)"
      ></nw-boolean-input>
      <nw-boolean-input
        key="transform"
        [value]="[selectedSceneTransitionObject.frame.transform]"
        (changed)="setTransitionParameter('transform', $event)"
      ></nw-boolean-input>
      <nw-boolean-input
        key="backgroundColor"
        [value]="[selectedSceneTransitionObject.frame.backgroundColor]"
        (changed)="setTransitionParameter('backgroundColor', $event)"
      ></nw-boolean-input>
    </div>

    <!-- <div *ngIf="selectedObject.frame.type == 'text'">
      Text
      <textarea
        [value]="selectedSoundFrameText"
        (keyup)="$event.stopPropagation()"
        (keydown)="$event.stopPropagation()"
        (change)="changeText($event)"
      ></textarea>
      <div (click)="_requestSpeechFile()">Generate speech</div>
    </div> -->
  </div>
  <div *ngIf="selectedObject" class="delimiter" style="margin-top: 4px"></div>

  <div
    class="w-100 text-center fs-13"
    style="text-align: center; margin-top: 1px"
  >
    Insert
  </div>
  <div class="flex-column gap-4 start">
    <div class="flex-row gap-6 pointer" (click)="addFrame()">
      <nw-icon type="crop_7_5" [size]="14"></nw-icon>
      <div>Frame</div>
    </div>
    <div
      class="flex-row gap-6 pointer"
      *ngIf="currentFrame"
      (click)="addInverseFrame()"
    >
      <nw-icon type="undo" [size]="13"></nw-icon>
      <div>Inverse</div>
    </div>

    <div class="flex-row gap-6 pointer" (click)="addSceneTransition()">
      <nw-icon type="transition_fade" [outlined]="true" [size]="13"></nw-icon>
      <div>Scene Transition</div>
    </div>

    <div class="flex-row gap-6 pointer" (click)="addCanvasTransform()">
      <nw-icon type="dynamic_feed" [outlined]="true" [size]="13"></nw-icon>
      <div>Canvas transform</div>
    </div>

    <div class="flex-row gap-6 pointer">
      <nw-icon type="music_note" [size]="13"></nw-icon>
      <div>Music</div>
    </div>

    <div class="flex-row gap-6 pointer" (click)="insertLocalSound()">
      <nw-icon type="volume_up" [size]="13"></nw-icon>
      <div>Sound</div>
    </div>

    <div class="flex-row gap-6 pointer">
      <img src="assets/icons/chat.svg" (click)="insertTextAnimation()" />
      <div>Text</div>
    </div>
  </div>

  <ng-container *ngIf="functions.length">
    <div class="function-delimiter"></div>
    <div class="fs-13 text-center functions-title">Functions</div>
    <div class="flex-column gap-4 start">
      <div
        *ngFor="let fcn of functions; index as i"
        class="function-item flex-row gap-4"
        (click)="addFunction(fcn)"
      >
        <div class="function-name">
          {{ fcn }}
        </div>
        <nw-icon
          type="close"
          [size]="11"
          (click)="$event.stopPropagation(); deleteFunction(i)"
        ></nw-icon>
      </div>
    </div>
  </ng-container>
</div>

<!-- <ng-container
*ngIf="
  frame?.selectedObject?.function &&
  !frame?.selectedObject?.functionTarget
"
>
<div>
  {{ frame.selectedObject.function }}
</div>
</ng-container>
<div>
{{ frame.selectedObject.duration }}
</div>
</div> -->

<!-- <div *ngIf="frame?.selectedObject" class="flex-row duration">
  <div class="flex-row gap-4">
    <ng-container *ngIf="frame?.selectedObject?.functionTarget">
      <div>
        {{
          service.getResource(frame.selectedObject.functionTarget.IRI)
            ?.label
        }}
        :
      </div>
      <div>
        {{ frame.selectedObject.function }}
      </div>
    </ng-container>
  </div>
</div> -->
