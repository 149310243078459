import { NgModule } from '@angular/core';
import { PathFillAnimationComponent } from './path-fill-animation/path-fill-animation.component';
import { UtilComponentModule } from '../../../../../components/util/util-component.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GeneralAttributeHeaderModule } from '../../../general-attribute-header/general-attribute-header.module';
import { SubPathMoveAnimationComponent } from './subpath-move-animation/subpath-move-animation.component';

@NgModule({
  imports: [
    UtilComponentModule,
    CommonModule,
    FormsModule,
    GeneralAttributeHeaderModule,
  ],
  declarations: [PathFillAnimationComponent, SubPathMoveAnimationComponent],
  exports: [PathFillAnimationComponent, SubPathMoveAnimationComponent],
})
export class PathAnimationComponentModule {}
