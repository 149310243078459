import { Container } from 'pixi.js';
import { EventEmitter } from '@angular/core';
import { ResourceData } from '../../../../../elements/resource/resource.types';
import {
  AnimationItem,
  CanvasOrientation,
  GeneralShapeDescriptor,
  Translate,
  PositionAnimation,
  ShapeConfig,
  ShapePosition,
} from '../../../../../elements/resource/types/shape.type';
import { RectangleShape } from '../../base/rectangle/rectangle-shape';
import { GeneralShape } from '../general-shape';
import { ContainerShape } from '../../container/container.shape';
import { Line } from '../../primitive/line-element';
import { Dimension } from '../../../../../services/canvas/canvas.types';
import { ShapeService } from '../../../shape.service';
import {
  canvasOrientation,
  selectCurrentBackgroundColor,
} from '../../../../store/selector/editor.selector';
import { CanvasPosition } from '../../../../store/reducer/editor.reducer';
import { applyBulkShapeUpdates } from '../../../../store/editor.actions';
import { AnimationByStart } from '../../../../animation/animation.service';
import { getCombinedColorPaletteObject } from '../../../../../projects/project.selector';
import { combineLatest } from 'rxjs';
import { StoryBoardController } from '../../../../../services/canvas/story-board/story-board-controller';

export class RootShape<
  T extends GeneralShapeDescriptor = GeneralShapeDescriptor,
> extends ContainerShape<T> {
  rootContainer: Container;

  getType(): string {
    return 'root-shape';
  }

  get mainContainer() {
    return this.outerContainer;
  }
  scene: string;

  get isRoot() {
    return true;
  }
  get canvasPosition() {
    return {
      x: this.translateX || 0,
      y: this.translateY || 0,
      scale: {
        x: this._scaleX || 1,
        y: this._scaleY || 1,
      },
    };
  }

  get completeResourcData() {
    const resourceData = this.resourceObject;
    resourceData.relationships = {
      shape: this.shapes.map(shape => shape.completeResourceData),
    };
    return resourceData;
  }

  get resourceObject(): ResourceData {
    return {
      IRI: this.IRI,
      literals: {
        label: this.literals.label || 'label',
        descriptor: this.descriptor,
      },
    };
  }

  get editable() {
    return true;
  }

  get shapesToDrag(): GeneralShape[] {
    return this.shapes.reduce((acc, shape) => {
      if (shape.selected || shape.currentlyDragged) {
        acc.push(shape);
      }
      return acc;
    }, []);
  }

  mask: RectangleShape<any>;

  get _scaleX() {
    return this.cs.canvasScale;
  }

  set _scaleX(val: number) {
    this.cs.canvasScale = val;
  }

  get _scaleY() {
    return this.cs.canvasScale;
  }

  set _scaleY(val: number) {
    this.cs.canvasScale = val;
  }
  orientation: CanvasOrientation;
  nextSceneIRI: string;
  constructor(
    service: ShapeService,
    data: ResourceData<T>,
    config?: ShapeConfig,
  ) {
    super(service, data, config);

    if (config?.state) {
      this.currentState = config.state;
    }

    this.orientation = this.descriptor.orientation || 'landscape';

    this.contextChangedEvent = new EventEmitter();

    // console.log('root-shape-init', this.descriptor.animationFrame); //
    // bit dirty place for that //
    // Index handling emergency //

    this.cs.keyEventSubscribe('Shift+i', () => {
      this.containerForChildren.removeChildren();

      this._shapes.reverse();
      this.containerForChildren.addChild(
        ...this._shapes.map(shape => shape.mainContainer),
      );
    });

    this.subscriptions.push(
      ...[
        // this.store
        //   .select(selectScenes)
        //   .subscribe(scenes => (this.scenes = scenes)),
        // this.store.select(canvasPosition).subscribe(position => {
        //   if (!position) {
        //     return;
        //   }

        //   console.log('------- root-shape')

        //   const { x, y, scale } = position;

        //   this.translateX = x * this.cs.scaleOffset;
        //   this.translateY = y * this.cs.scaleOffset;
        //   this._scaleX = scale.x * this.cs.scaleOffset;
        //   this._scaleY = scale.y * this.cs.scaleOffset;
        //   this.redraw();
        // }),

        combineLatest([
          this.store.select(selectCurrentBackgroundColor),
          this.store.select(getCombinedColorPaletteObject),
        ]).subscribe(([color, palette]) => {
          if (color.startsWith('$')) {
            color = (palette || {})[color.slice(2)];
            if (!color) {
              return;
            }
          }
          // this.cs.app.renderer.background.color = color;
          this.cs.setBgColor(color);
        }),
        this.store.select(selectCurrentBackgroundColor).subscribe(color => {
          if (!color) {
            return;
          }
          // this.cs.app.renderer.background.color =
          //   this.service.resolveColor(color);
        }),
        this.store.select(canvasOrientation).subscribe(orientation => {
          if (this.orientation == orientation) {
            return;
          }

          this.orientation = orientation;
          this.cs.initScreen(this);
        }),
      ],
    );

    // this.cs.circleContainer = this.circleContainer;
    // this.cs.auxCircleContainer = this.auxCircleContainer;

    this.init();
    this.initShapes();
    this.initAnimationFrame();

    // if (this.currentState) {
    //   this.setAnimationFrameByState(this.currentState);
    // }

    // this.contextChanged();

    // for (const shape of this.shapes) {
    //   if (shape.maskedBy) {
    //     const baseShape = (this.service.getResource(shape.maskedBy) ||
    //       this.service.getResource(
    //         this.IRI + '_' + shape.maskedBy,
    //       )) as GeneralShape;
    //     // console.log('---------- shape.maskedBy ---------', baseShape); //
    //     baseShape?.setMeAsMask(shape as GeneralShape, true);
    //   }
    // }

    this.checkShapeIndexes();

    // if (this.currentState) {
    //   this.setStatePosition(this.currentState);
    // }

    this.animationFrame?.calcAnimationsByStart();
  }

  sceneIndexMap: Record<string, number> = {};

  resetBaseState() {
    // -- //
    console.log('rs > resetBaseState');
    super.resetBaseState();
    this.translateX = 1;
    this.translateY = 1;
    this._scaleX = 1;
    this._scaleY = 1;
    return;
  }

  initShapes(scene?: string): void {
    this.initStoryBoard();
    super.initShapes();
    this.setContainerIndexes();
  }

  storyboardController: StoryBoardController;
  initStoryBoard() {
    // -- // -- //
    this.storyboardController ||= new StoryBoardController(this);
    this.storyboardController.set({
      items: [
        {
          notes: [],
        },
        {
          notes: [],
        },
      ],
    });
  }

  updateAnimationsByStart(animationsByStart: AnimationByStart[], time: number) {
    this.animationsByStart = animationsByStart;
    this.shapes.map(shape =>
      shape.setConsequtiveAnimationsByKey(this.animationsByStart),
    );

    this.applyAnimationByTime(time);
  }

  checkShapeIndexes() {
    const lookup = {};
    for (const shape of this._shapes) {
      if (lookup[shape.index]) {
        return this.fixShapeIndexes();
      }
      lookup[shape.index] = true;
    }
  }

  fixShapeIndexes() {
    return;
    this._shapes
      .sort((s1, s2) => s1.index - s2.index)
      .map((shape, index) => {
        shape.index = index;
        shape.save();
      });
  }

  setPreviousState(state: string) {
    const index = this.cs.previewShape.states.findIndex(val => val == state);
    if (index > 0) {
      this.setCurrentState[this.cs.previewShape.states[index - 1]];
    }
  }

  get statePositions() {
    return this.descriptor.statePositions;
  }

  set statePositions(val: Record<string, Dimension>) {
    this.descriptor.statePositions = val;
  }

  saveStagePosition() {
    if (!this.currentState) {
      return;
    }

    this.statePositions ||= {};
    this.statePositions[this.currentState] = {
      x: this.translateX,
      y: this.translateY,
      scale: this._scaleX,
    };
    this.save();
  }

  setCurrentState(state: string) {
    // this.currentState = state;
    // this.cs.currentFunctionName = state;
    // this.cs.animationIsBeingPlayed = false;
    // console.log('addRemoveByState', this.shapesByIndex.length, state);
    // this.shapesByIndex.map(shape => shape.removeContainersFromParent());
    // this.shapesByIndex.map(shape => shape.addByState(state));
    // this.shapesByIndex.map(shape => shape.showHideByState(state));
    // this.cs.generalEventEmit('route-update', { queryParams: { state } });
    // if (!state) {
    //   return;
    // }
    // // this.setStatePosition(state);
    // this.setAnimationFrameByState(state);
  }

  setStatePosition(state: string) {
    // if (this.statePositions?.[state]) {
    //   const { x, y, scale } = this.statePositions[state];
    //   this.translateX = x;
    //   this.translateY = y;
    //   this._scaleX = scale;
    //   this._scaleY = scale;
    // } else {
    //   this.translateX = 0;
    //   this.translateY = 0;
    //   this._scaleX = 1;
    //   this._scaleY = 1;
    // }
    // this.redraw();
  }

  setAnimationFrameByState(state: string) {
    for (const frame of this.animationFrame.getFrames() || []) {
      if (frame.stateTransition) {
        const { to } = frame.stateTransition;
        if (to == state) {
          this.cs.currentAnimationFrame = frame;
          frame.select({ noStateChangeEvent: true });
          // this.shapes
          //   .filter(shape => !shape.if || shape.if == to)
          //   .map(shape => {
          //     const shown = this.animationFrame.getShowHideTill(
          //       shape,
          //       frame.id
          //     );
          //     shown ? shape._show() : shape._hide();
          //   });
          return;
        }
      }
    }
    this.cs.currentAnimationFrame?.deselect();
  }

  clicked() {
    console.log('root-shape-clicked');
  }

  shiftStateToRight() {
    if (!this.states.length) {
      return;
    }
    let currentIndex = this.states.indexOf(this.currentState);
    currentIndex++;
    if (currentIndex == this.states.length) {
      currentIndex = 0;
    }
    this.setCurrentState(this.states[currentIndex]);
  }

  shiftStateToLeft() {
    if (!this.states.length) {
      return;
    }
    let currentIndex = this.states.indexOf(this.currentState);
    currentIndex--;
    if (currentIndex == -1) {
      currentIndex = this.states.length - 1;
    }
    this.setCurrentState(this.states[currentIndex]);
  }

  init() {
    this.initContainers();
    this.setWidthHeight();
    this.redraw();
  }

  set opacity(val: number) {
    this.outerContainer.alpha = val;
  }
  initContainers() {
    super.initContainers();

    this.outerContainer = new Container();
    this.rootContainer = new Container();
    // this.initContainers(); //
    // this.outerContainer.setTransform(100, 50);

    this.outerContainer.addChild(this.rootContainer);
    this.rootContainer.addChild(this.container);
    this.rootContainer.addChild(this.sectionContainer);
    this.rootContainer.addChild(this.auxCircleContainer);
    this.rootContainer.addChild(this.circleContainer);
  }

  applyAnimationByTime(time: number) {
    // console.log('apply-animation-by-time', time);
    const bulkData = {};

    this.shapes.map(shape => {
      const updates = shape.applyAnimationByTime(time);
      if (Object.keys(updates || {}).length) {
        bulkData[shape.IRI] = updates;
      }
    });

    if (Object.keys(bulkData).length) {
      this.store.dispatch(applyBulkShapeUpdates({ data: bulkData }));
    }

    return {};
  }

  fixIndexes() {
    const shapes = this.shapesByIndex;

    // shapes.map((shape, index) => {
    //   if (isNaN(+shape.index)) {
    //     shape
    //   }
    // })
  }

  editInputs() {
    // TODO - migrate
    // this.cs.openKeyValueEditor(this.descriptor.inputs, data => {
    //   this.descriptor.inputs = data as TypeDef;
    //   this.save();
    // });
  }

  maskShown = false;

  drag(dx: number, dy: number) {
    const [x0, y0] = this.dragBase;
    this.translateX = x0 + dx;
    this.translateY = y0 + dy;
    this.redraw();
  }

  dTransformIncrement(increment: number) {
    const { x, y, scale } = this.dTranslate;

    if (scale) {
      this._scaleX += increment * (scale.x as number);
      this._scaleY += increment * (scale.y as number);
    }

    this.translateX += increment * x;
    this.translateY += increment * y;

    this.redraw();

    // this.dTransform.localIncrement += increment; //
    // this.drag(
    //   this.dTransform.localIncrement * x,
    //   this.dTransform.localIncrement * y,
    //   this.IRI,
    //   'translate'
    // );
  }

  prepareTranslateAnimation(value: Translate, division: number) {
    const { x, y } = value || {};
    // const length = Math.sqrt((Math.pow(x, 2) || 0) + (Math.pow(y, 2) || 0));

    this.dTranslate = {
      localIncrement: 0,
      x: -((+x || 0) / division),
      y: -((+y || 0) / division),
    };
    console.log('dTransform', this.dTranslate);

    this.startBaseDrag();
  }

  prepareTransformAnimation(
    { dx, dy, x, y, offsetX, offsetY, scale }: PositionAnimation,
    division: number,
  ) {
    let _dx: number;
    let _dy: number;

    if (dx || dy) {
      _dx = dx;
      _dy = dy;
    } else {
      _dx = x - this.translateX;
      _dy = y - this.translateY;
    }

    this.dTranslate = {
      localIncrement: 0,
      x: _dx / division,
      y: _dy / division,
      scale: scale
        ? {
            x: ((scale.x as number) - this._scaleX) / division,
            y: ((scale.y as number) - this._scaleY) / division,
          }
        : null,
    };
  }

  prepareKeyValueAnimation(
    animation: AnimationItem,
    division: number,
    duration?: number,
  ): void {
    super.prepareKeyValueAnimation(animation, division, duration);
    const { key, value } = animation;
    switch (key) {
      case 'transform':
        this.prepareTransformAnimation(value as PositionAnimation, division);
        break;
      case 'transform-to-state':
        // -- // -- // -- //
        const to = value as string;
        if (this.statePositions?.[to]) {
          const { x, y, scale } = this.statePositions[to];
          this.prepareTransformAnimation(
            {
              x,
              y,
              scale: {
                x: scale,
                y: scale,
              },
            },
            division,
          );
        }

        break;
    }
  }

  get currentAnimationFrame() {
    return this.animationFrame;
  }

  get currentAnimations() {
    // console.log('root--current-animations', this.animationsById?.[this.cs.currentAnimation?.id]);
    return Object.values(
      this.animationsById?.[this.cs.currentAnimation?.id] || {},
    );
  }

  transformAction({ x, y, scale }: { x: number; y: number; scale: number }) {
    this.translateX += (this.translateX - x) * (scale - 1);
    this.translateY += (this.translateY - y) * (scale - 1);

    this._scaleX *= scale;
    this.service.zoomUpdate();
    this.redraw();
  }

  translateCanvas(dx: number, dy: number) {
    this.translateX += dx / this._scaleX;
    this.translateY += dy / this._scaleY;

    if (this.cs.currentAnimation && this.cs.zoomMode) {
      console.log('patch-animation', this.translateX, this.translateY);
      this.patch('transform', {
        x: this.translateX,
        y: this.translateY,
        scale: { x: this._scaleX, y: this._scaleY },
      });
    }

    this.redraw();
  }

  redraw() {
    this.rootContainer.setTransform(
      this.translateX,
      this.translateY,
      this._scaleX,
      this._scaleY,
    );
  }

  _redraw({ x, y, scale }: ShapePosition): void {
    this.rootContainer.setTransform(
      x,
      y,
      (scale?.x as number) || 1,
      (scale?.y as number) || 1,
    );
  }

  resetPosition() {
    const position = {
      x: this.cs.translateOffsetX,
      y: 0,
      scale: { x: this.cs.scaleOffset, y: this.cs.scaleOffset },
    };
    this.applyPosition(position);
    this.setPosition(position);
  }

  applyPosition(position: CanvasPosition) {
    const { x, y, scale } = position;
    this.setPosition(position);
    this.rootContainer.setTransform(x, y, scale.x, scale.y);
  }

  setPosition(position: CanvasPosition) {
    if (position.scale?.x == 1.44) {
      // console.log('-- root-shape gotya---', position, this.scene);
    }
    const { x, y, scale } = position;
    this.translateX = x;
    this.translateY = y;
    this._scaleX = scale.x;
    this._scaleY = scale.y;
  }

  save(contextChanged = false) {
    if (contextChanged) {
      this.contextChanged();
    }
  }

  removeAll(): void {
    this.rootContainer?.removeChildren();
    super.removeAll();
  }

  vAuxLine1: Line;
  vAuxLine2: Line;

  hideAuxLines() {
    this.hideVerticalAuxLines();
    this.hideHorizontalAuxLine();
  }

  hideVerticalAuxLines() {
    this.vAuxLine1?.hide();
    this.vAuxLine2?.hide();
  }

  getAuxLine() {
    return new Line(
      this,
      this.circleContainer,
      {
        position: { x: 0, y: 0 },
        x: 0,
        y: 0,
        ...this.cs.auxLineConfig,
      },
      0,
    );
  }

  drawVerticalAuxLine(x: number, yStart: number, yEnd: number) {
    this.vAuxLine1 ||= this.getAuxLine();
    this.vAuxLine2 ||= this.getAuxLine();

    this.vAuxLine1.patch(
      {
        position: {
          x,
          y: 0,
        },
        x,
        y: yStart,
      },
      true,
    );

    this.vAuxLine2.patch(
      {
        position: {
          x,
          y: yEnd,
        },
        x,
        y: 10_000,
      },
      true,
    );
  }

  hAuxLine1: Line;
  hAuxLine2: Line;

  hideHorizontalAuxLine() {
    this.hAuxLine1?.hide();
    this.hAuxLine2?.hide();
  }

  drawHorizontalAuxLine(y: number, xStart: number, xEnd: number) {
    this.hAuxLine1 ||= this.getAuxLine();
    this.hAuxLine2 ||= this.getAuxLine();

    this.hAuxLine1.patch(
      {
        position: {
          x: 0,
          y,
        },
        x: xStart,
        y,
      },
      true,
    );

    this.hAuxLine2.patch(
      {
        position: {
          x: xEnd,
          y,
        },
        x: 10_000,
        y,
      },
      true,
    );
  }
}
