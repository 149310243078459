<div
  *ngIf="rightClickMenu"
  class="right-click-menu-comp flex-column gap-4"
  [ngStyle]="{ left: x, top: y }"
  (click)="$event.stopPropagation()"
>
  <div
    *ngFor="let option of options"
    (click)="select($event, option.id)"
    class="option-item flex-row space-between w-100"
  >
    <div>
      {{ option.label }}
    </div>
    <div *ngIf="option.shortcut">({{ option.shortcut }} )</div>
  </div>
</div>
