import { Container } from 'pixi.js';
import { RectangleController } from '../../../../elements/util/rectangle-controller/rectangle-controller';
import { GeneralShape } from '../general/general-shape';
import {
  ResourceData,
  ResourceType,
} from '../../../../elements/resource/resource.types';
import { ContainerShape } from '../container/container.shape';
import {
  ShapeConfig,
  ShapePosition,
  ShapeSelectParams,
} from '../../../../elements/resource/types/shape.type';
import { ShapeService } from '../../shape.service';
import { omit } from 'lodash';
import { setDescriptorValue } from '../../../store/editor.crud.actions';

export class GroupShape extends ContainerShape {
  childShapes: GeneralShape[] = [];
  groupRCContainer: Container;
  currentPosition: {
    x: number;
    y: number;
    width: number;
    height: number;
  } = { x: undefined, y: undefined, width: undefined, height: undefined };
  groupEdit = false;

  beforeDragPosition: {
    x: number;
    y: number;
    width: number;
    height: number;
  } = { x: undefined, y: undefined, width: undefined, height: undefined };

  getType(): string {
    return 'group-shape';
  }

  selectedShapes() {
    if (this.groupEdit) {
      return this._shapes.filter(shape => shape.selected);
    }
    return super.selectedShapes();
  }

  constructor(service: ShapeService, data: ResourceData, config: ShapeConfig) {
    super(service, data, config);
    this.type = ResourceType.GroupShape;

    this.init();
    this.subscriptions.push(
      this.cs.keyEventSubscribe('u', () => {
        if (this.selected && this.cs.selectedShapes.length == 1) {
          this.ungroup();
        }
      }),
    );

    this.subscriptions.push(
      this.cs.keyEventSubscribe(
        'Enter',
        () => {
          if (this.selected && this.cs.selectedShapes.length == 1) {
            this.startGroupEdit();
            this.cs.consumeKeyEvent('Enter');
          }
        },
        10,
      ),
    );

    this.initShapes();
    this.redraw();
    this.initRC();
    this.rc?.hide();
  }

  startGroupEdit() {
    this.selected = false;
    this.groupEdit = true;
    this.rc.hide();
    this.cs.generalEventEmit('opacity-set', 0.4);
  }

  endGroupEdit() {
    console.log('endGroupEdit');
    // return;
    this.selected = true;
    this.cs.generalEventEmit('opacity-set', 0);
    this.updateRC();
    this.rc.show();
    this.groupEdit = false;
    this._shapes.map(shape => shape.deselect());
  }

  ungroup() {
    this._shapes
      .sort((s1, s2) => s1.index - s2.index)
      .map(shape => {
        shape.descriptor.if = this.if;
        shape.x += this.x;
        shape.y += this.y;
        shape.saveTranslate();
        shape.locked = false;
        shape.index += this.index;
        shape.descriptor.childOf = null;
        this.store.dispatch(
          setDescriptorValue({
            IRI: shape.IRI,
            key: 'childOf',
            value: undefined,
          }),
        );
        shape.relationships.parent = this.parent.IRI;
        // if (shape.animationsByKey && this.cs.currentAnimation) {
        //   delete shape.animationsByKey[this.cs.currentAnimation.id];
        // }
        this.containerForChildren.removeChild(shape.mainContainer);
        this.circleContainer?.removeChild(shape.circleContainer);
        this.auxCircleContainer?.removeChild(shape.auxCircleContainer);

        this.parent.containerForChildren.addChild(shape.mainContainer);
        this.parent.circleContainer.addChild(shape.circleContainer);
        this.parent.auxCircleContainer.addChild(shape.auxCircleContainer);
        shape.initRC();
        shape.select();
        shape._show();
        shape.redraw();
        shape.save();
      });
    this.delete();
  }

  deleteJustMe() {
    super.delete();
  }

  delete() {
    super.delete();
    this.shapes.map(shape => shape.delete());
  }

  addChildren(shapes: GeneralShape[]) {
    this._shapes = shapes;
    shapes.map(shape => shape.addMeToGroup(this));
    this.initRC();
  }

  active = false;
  initCount = 0;
  initRC(force = false) {
    if (this.rc && !force) {
      return;
    }

    if (this.rc) {
      this.rc.remove();
    }

    const { x, y, width, height } = this.container.getBounds();

    if (!width || !height) {
      if (this.initCount > 10) {
        return;
      }

      setTimeout(() => {
        this.initCount++;
        this.initRC();
      }, 25);

      return;
    }

    // this.service.getSelectedBoundingRectangle(
    //   this.shapes,
    // );

    let currentPosition: {
      x: number;
      y: number;
      width: number;
      height: number;
    } = { x: undefined, y: undefined, width: undefined, height: undefined };

    let beforeDragPosition: {
      x: number;
      y: number;
      width: number;
      height: number;
    } = { x: undefined, y: undefined, width: undefined, height: undefined };

    this.rc = new RectangleController(
      this,
      {
        width: width / this.cs.scaleOffset,
        height: height / this.cs.scaleOffset,
        noFill: true,
        startDrag: () => {
          let { x, y, width, height } = this.container.getBounds();

          x /= this.cs.canvasScale;
          y /= this.cs.canvasScale;
          width /= this.cs.canvasScale;
          height /= this.cs.canvasScale;

          beforeDragPosition = { x, y, width, height };
          currentPosition = { x, y, width, height };
          this._shapes.forEach(shape => {
            const dx = shape.x - shape.offsetX;
            const dy = shape.y - shape.offsetY;
            console.log({ dx, dy });
            shape.startTransformation(dx, dy);
          });
        },
        drag: ({ x: cX, y: cY, width: newWidth, height: newHeight }) => {
          this.containerForRc.setTransform(this.x + cX, this.y + cY);
          this.circleContainer.setTransform(cX, cY);
          this.auxCircleContainer.setTransform(cX, cY);

          cX -= this.x;
          cY -= this.y;

          const scaleX = newWidth / beforeDragPosition.width;
          const scaleY = newHeight / beforeDragPosition.height;

          currentPosition.x = beforeDragPosition.x + cX;
          currentPosition.y = beforeDragPosition.y + cY;

          currentPosition.width = newWidth;
          currentPosition.height = newHeight;

          // console.log('gs > drag', { scaleX, scaleY });
          this._shapes.forEach(shape => {
            shape.transformation(scaleX, scaleY);
            // shape.redraw(); //
            shape.refresh();
          });
          // console.log('--- current-position ---', currentPosition);
          this.container.setTransform(currentPosition.x, currentPosition.y);
        },
        endDrag: () => {
          this.rc.patch({
            width: currentPosition.width,
            height: currentPosition.height,
          });

          this.x = currentPosition.x;
          this.y = currentPosition.y;

          this.saveTransform();
          console.log('end-group shape drag', this._shapes);
          this._shapes.forEach(shape => shape.saveTransform());
          this.save();
        },
        // mouseover: () => {
        //   console.log('rc > mouseover');
        //   this.active = true;
        // },
        // mouseout: () => {
        //   console.log('rc > mouseout');
        //   this.active = false;
        //   this.deselect({ onHover: true });
        // },
        clicked: () => {
          if (this.selected) {
            this.startGroupEdit();
          } else {
            this.select();
          }
        },
      },
      this.circleContainer,
      this.auxCircleContainer,
    );
  }

  updateRC() {
    const { x, y, width, height } = this.service.getBoundingRectangle(
      this._shapes,
    );

    console.log('update-rc', { x, y, width, height });

    const [dx, dy] = [this.x - x, this.y - y];

    this._shapes.map(shape => {
      shape.x += dx;
      shape.y += dy;
      shape.redraw();
    });

    this.x = x;
    this.y = y;
    this.redraw();

    this.rc.patch({ width, height });
  }

  copy(IRI: string, position: ShapePosition, config?: ShapeConfig) {
    return new GroupShape(
      this.service,
      {
        IRI,
        type: 'nw:Shape',
        relationships: {
          ...this.clone(this.relationships),
          parent: this.IRI,
        },
        literals: {
          descriptor: {
            ...omit(this.descriptor, [
              'multiplicationX',
              'multiplicationY',
              '_animationsByKey',
              'code',
            ]),
            position,
          },
        },
      },
      {
        noSave: true,
        updateParentIRIForShapes: true,
        IRIprefix: this.IRI,
        ...(config || {}),
      },
    );
  }

  getChildren() {
    return this.asArray(this.relationships['children'])
      .map((k: string) => this.service.getResource(k))
      .filter(r => !!r) as GeneralShape[];
  }

  select(params: ShapeSelectParams = {}) {
    // this.initRC();
    super.select(params);
    this.opened = true;
  }

  deselect(params: ShapeSelectParams = {}) {
    // console.log('group-shape > deselect');
    if (this.groupEdit) {
      return;
      // return this.endGroupEdit();
    }

    super.deselect(params);
    this.opened = false;
  }
}
