import { Injectable } from '@angular/core';
import {
  CircleShapeDescriptor,
  CurveSectionDescriptor,
  GeneralShapeDescriptor,
  PathShapeDescriptor,
  RectangleShapeDescriptor,
} from '../../elements/resource/types/shape.type';
import { ShapeService } from '../shape/shape.service';
import { Regex } from '../../services/util/regex';

@Injectable()
export class SVGParseService {
  maskRecord: Record<string, number> = {};

  constructor(private readonly shapeService: ShapeService) {}

  async parse(file) {
    this.maskRecord = {};

    const text = await file.text();
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(text, 'text/xml');

    const [svg] = Array.from(xmlDoc.children);
    // -- //
    const children = svg.children as HTMLCollectionOf<SVGElement>;
    const descriptors: GeneralShapeDescriptor[] = [];
    let index = this.shapeService.previewShape.getNewShapeIndex();
    for (const element of Array.from(children)) {
      // -- // -- // -- // -- //
      const [newDescriptors] = this.getDescriptorByElement(element, index);

      descriptors.push(...newDescriptors);
      index++;
    }

    this.shapeService.addShapesByDescriptor(descriptors, this.maskRecord);
  }

  getDescriptorByElement(
    element: SVGElement,
    index: number,
    mask?: string,
  ): [GeneralShapeDescriptor[], number] {
    const _stroke = element.getAttribute('stroke');
    const strokeWidthParam = element.getAttribute('stroke-width');
    const strokeWidth = isNaN(+strokeWidthParam)
      ? _stroke
        ? 1
        : undefined
      : +strokeWidthParam;

    const stroke = _stroke
      ? {
          color: _stroke,
          width: strokeWidth,
        }
      : undefined;

    const opacity = +element.getAttribute('opacity');
    if (!mask && element.getAttribute('mask')) {
      mask = Regex.between('url(#', ')', element.getAttribute('mask'));
    }

    const descriptors = [];

    let _fill = element.getAttribute('fill');
    const style = element.getAttribute('style');
    for (const s of style?.split(';') || []) {
      if (s.startsWith('fill')) {
        _fill = s.split(':')[1]?.trim();
      }
    }

    function componentToHex(c) {
      const hex = (+c).toString(16).padStart(2, '0');
      return hex.length == 1 ? '0' + hex : hex;
    }

    if (_fill?.startsWith('rgb(')) {
      _fill =
        '#' +
        _fill
          .slice(0, _fill.length - 2)
          .slice(4)
          .split(',')
          .map(val => componentToHex(val))
          .join('');
    }

    const fill = _fill
      ? {
          color: _fill,
        }
      : undefined;

    if (element instanceof SVGMaskElement) {
      const id = element.getAttribute('id');

      this.maskRecord[id] = index;
      // -- // -- // -- // -- // -- //

      for (const maskChild of Array.from(element.children)) {
        const [maskElementDescriptor, newIndex] = this.getDescriptorByElement(
          maskChild as SVGElement,
          index,
        );
        descriptors.push(...maskElementDescriptor);
        index = newIndex;
      }
    } else if (element instanceof SVGPathElement) {
      const path = element as SVGPathElement;
      let d = path.getAttribute('d');

      try {
        const transform = path.getAttribute('transform');
        let px: number,
          py: number,
          cx = 0,
          cy = 0;
        if (transform) {
          [px, py] = transform
            .slice(10)
            .split(',')
            .map(val => +/[\d.]*/.exec(val)[0]);
        }

        const isClosed = d.endsWith('Z');
        if (isClosed) {
          // removing Z from the end
          d = d.slice(0, d.length - 1);
        }

        d = d.replace('\n', '');
        d = d.replace(/,/g, ' ');

        for (const _d of d.split('M')) {
          if (!_d) {
            continue;
          }

          const [first, ...elements] = Regex.matchAll(
            _d,
            /([HhVvCcLlSs]{0,1}[-*\d*\.*\s]*)/,
          ).map(e => e.trim());

          const [mx, my] = first
            .split(/[\s,]/)
            .filter(v => !!v)
            .map(v => +v);
          if ((!isNaN(mx) && mx !== 0) || (!isNaN(my) && my !== 0)) {
            px = mx || 0;
            py = my || 0;
            cx = mx || 0;
            cy = my || 0;
          }
          const sections = [];
          let lastParams = null;
          for (let e of elements) {
            const letter = e[0];
            e = e.slice(1).replace(/-/g, ' -').trim();

            switch (letter) {
              case 'H':
                const [__x] = e
                  .split(/[\s,]/)
                  .filter(v => !!v)
                  .map(v => +v);
                sections.push({
                  type: 'line',
                  x: __x - cx,
                  y: 0,
                });
                cx = __x;
                break;
              case 'h':
                const [xh] = e
                  .split(/[\s,]/)
                  .filter(v => !!v)
                  .map(v => +v);
                sections.push({
                  type: 'line',
                  x: xh,
                  y: 0,
                });
                cx += xh;
                break;
              case 'V':
                const [__y] = e
                  .split(/[\s,]/)
                  .filter(v => !!v)
                  .map(v => +v);
                sections.push({
                  type: 'line',
                  x: 0,
                  y: __y - cy,
                });
                cy = __y;
                break;
              case 'v':
                const [yv] = e
                  .split(/[\s,]/)
                  .filter(v => !!v)
                  .map(v => +v);
                sections.push({
                  type: 'line',
                  x: 0,
                  y: yv,
                });
                cy += yv;
                break;
              case 'L':
                const [_x, _y] = e
                  .split(/[\s,]/)
                  .filter(v => !!v)
                  .map(v => +v);
                sections.push({
                  type: 'line',
                  x: _x - cx,
                  y: _y - cy,
                });
                cx = _x;
                cy = _y;
                break;
              case 'l':
                const [xl, yl] = e
                  .split(/[\s,]/)
                  .filter(v => !!v)
                  .map(v => +v);
                sections.push({
                  type: 'line',
                  x: xl,
                  y: yl,
                });
                cx += xl;
                cy += yl;
                break;
              case 'C':
                // console.log('c e >', e);
                const [a1x, a1y, a2x, a2y, x, y] = e
                  .split(/[\s,]/)
                  .filter(v => !!v)
                  .map(v => +v);

                lastParams = {
                  a1x,
                  a1y,
                  a2x,
                  a2y,
                  x,
                  y,
                };
                sections.push({
                  type: 'curve',
                  a1: [a1x - cx, a1y - cy],
                  a2: [a2x - x, a2y - y],
                  x: x - cx,
                  y: y - cy,
                });
                cx = x;
                cy = y;

                break;
              case 'c':
                console.log('c e >', e);
                const [ca1x, ca1y, ca2x, ca2y, _cx, _cy] = e
                  .split(/[\s,]/)
                  .map(v => +v);
                // console.log({ ca1x, ca1y, ca2x, ca2y, _cx, _cy });
                // -- // -- //
                lastParams = {
                  ca1x,
                  ca1y,
                  ca2x,
                  ca2y,
                  _cx,
                  _cy,
                  a2: [ca2x - _cx, ca2y - _cy],
                };
                sections.push({
                  type: 'curve',
                  a1: [ca1x, ca1y],
                  a2: [ca2x - _cx, ca2y - _cy],
                  x: _cx,
                  y: _cy,
                });
                cx += _cx;
                cy += _cy;
                break;
              case 'S':
                const { _a2x: __a2x, _a2y: __a2y } = lastParams;
                // console.log('sc');
                const [sA2x, sA2y, sX, sY] = e
                  .trim()
                  .split(/[\s,]/)
                  .map(v => +v);
                sections.push({
                  type: 'curve',
                  a1: [-__a2x, -__a2y],
                  a2: [sA2x - sX, sA2y - sY],
                  x: sX - cx,
                  y: sY - cy,
                });
                cx = sX;
                cy = sY;
                break;
              case 's':
                const { ca2x: pa2x, ca2y: pa2y } = lastParams;
                const [sa2x, sa2y, sx, sy] = e
                  .split(/[\s,]/)
                  .filter(v => !!v)
                  .map(v => +v);
                // console.log('sc,', e);
                // console.log('sc', { sa2x, sa2y, sx, sy });

                sections.push({
                  type: 'curve',
                  a1: lastParams.a2.map(v => -v),
                  a2: [sa2x - sx, sa2y - sy],
                  x: sx,
                  y: sy,
                });
                cx += sx;
                cy += sy;
                break;
            }
          }

          console.log('sections', sections);

          sections.push({
            type: 'line',
            x: cx,
            y: cy,
          });
          // console.log('fill', fill, 'position', { px, py });
          descriptors.push({
            svgAttributes: {
              // stroke: '#ff0000',
              fill,
              stroke,
            },
            index: index++,
            type: 'path-shape',
            position: { x: px, y: py },
            closed: true,
            // maskedBy: mask,
            sections: sections.map(
              s =>
                ({
                  id: Math.random().toString(),
                  ...s,
                }) as CurveSectionDescriptor,
            ),
          } as PathShapeDescriptor);
        }
      } catch (error) {
        console.log('------------ error ------------', error.message, path);
      }
    } else if (element instanceof SVGCircleElement) {
      const cx = +element.getAttribute('cx');
      const cy = +element.getAttribute('cy');
      const r = +element.getAttribute('r');
      console.log('circle-r', r);
      descriptors.push({
        svgAttributes: {
          stroke,
          fill,
        },
        index: index++,
        type: 'circle-shape',
        position: { x: cx - r, y: cy - r },
        rx: r,
        ry: r,
        r,
        // maskedBy: mask,
      } as CircleShapeDescriptor);
    } else if (element instanceof SVGRectElement) {
      const width = +element.getAttribute('width');
      const height = +element.getAttribute('height');

      const x = +element.getAttribute('x');
      const y = +element.getAttribute('y');
      const rx = +element.getAttribute('rx');
      console.log('rectangle', { rx, width, height, fill, x, y });
      descriptors.push({
        svgAttributes: {
          stroke,
          fill,
        },
        index: index++,
        type: 'rectangle-shape',
        position: { x, y },
        width,
        height,
        r: Math.floor(rx),
        maskedBy: mask,
      } as RectangleShapeDescriptor);
    } else if (element.tagName == 'g') {
      // -- // -- //
      for (const maskChild of Array.from(element.children)) {
        const [maskElementDescriptor, newIndex] = this.getDescriptorByElement(
          maskChild as SVGElement,
          index,
          mask,
        );
        descriptors.push(...maskElementDescriptor);
        index = newIndex;
      }
    }

    return [descriptors, index];
  }

  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }

    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return Math.PI - angle;
    }
    if (x < 0 && y < 0) {
      return Math.PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * Math.PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? Math.PI / 2 : (3 * Math.PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : Math.PI;
    }
  }
  norm(angle: number) {
    if (angle > 2 * Math.PI) {
      return angle - 2 * Math.PI;
    }
    if (angle < 0) {
      return angle + 2 * Math.PI;
    }
    return angle;
  }
}
