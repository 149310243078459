import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { catchError, switchMap } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationHttpInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthenticationService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.Unauthorized) {
          return of(this.authService.updateSession()).pipe(
            switchMap(() => this.authService.session),
            switchMap(session => {
              const token = session.getAccessToken().getJwtToken();
              // console.log('update-session > yooo');
              const updatedRequest = req.clone({
                setHeaders: { Authorization: `Bearer ${token}` },
              });
              return next.handle(updatedRequest);
            }),
          );
        }

        return throwError(() => error);
      }),
    );
  }
}
