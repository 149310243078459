import { combineLatest, Observable } from 'rxjs';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { KeyHandlerService } from '../../services/keyhandler/keyhandler.service';
import { CanvasService } from '../../services/canvas/canvas.service';
import { Point } from '../../elements/base/point';
import { ComponentEvent } from '../../components/general-component/general.component.type';
import { ListConfig } from '../../components/util/list/list.types';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FileUploaderComponent } from '../../components/file-uploader/file-uploader.component';
import { ActivatedRoute, Router } from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  tap,
} from 'rxjs/operators';
import { HttpService } from '../../store/http/http.service';
import { Store } from '@ngrx/store';
import {
  loadProjectWithFilesById,
  setCurrentProject,
} from '../../projects/project.actions';
import { bulkUpdateFinish, openFile } from '../store/editor.actions';
import {
  setDescriptorValue,
  incrementStrokeWidth,
} from '../store/editor.crud.actions';
import { ShapeService } from '../shape/shape.service';
import {
  getFile,
  isFileLoading,
  selectFontLoaded,
} from '../store/selector/editor.selector';
import {
  OverlayComponentType,
  OverlayConfig,
} from '../../projects/project.reducer';
import { Organisation } from '../../organisation/organisation.interface';
import { setCurrentOrganisationID } from '../../organisation/organisation.actions';
import {
  currentOrganisation,
  organisationById,
} from '../../organisation/organisation.selector';
import {
  getCurrentProject,
  isProjectLoaded,
  projectLoadError,
} from '../../projects/project.selector';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'iw-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
})
export class EditorComponent implements OnInit {
  x: number;
  y: number;
  clicked: Point;
  menu = false;
  options: string[] = [];
  selected = 0;
  svgDisabled = false;
  readonly editorState$: Observable<
    'project-loading' | 'project-loaded' | 'file-loading' | 'file-loaded'
  >;
  readonly projectState$: Observable<'error' | 'loading' | 'loaded'>;
  readonly organisationId$: Observable<string>;
  readonly organisation$: Observable<Organisation>;
  elements$: Observable<string[]>;
  loadedScene: string;
  isFileLoading$: Observable<boolean>;
  newFileId: string;

  overlayConfig: OverlayConfig;

  @ViewChild('editorContainer') editorContainer: ElementRef;

  constructor(
    readonly cs: CanvasService,
    readonly shapeService: ShapeService,
    khs: KeyHandlerService,
    readonly http: HttpService,
    readonly dialog: MatDialog,
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
  ) {
    this.isFileLoading$ = this.store.select(isFileLoading);

    // this.route.params
    //   .pipe(filter(({ organisationID }) => !!organisationID))
    //   .subscribe(
    //     ({ organisationID }) =>
    //       (this.cs.currentOrganisationID = organisationID),
    //   );

    this.cs.keyEventSubscribe('f+Shift', () => {
      this.overlayConfig = {
        type: 'color-selector',
        callback: value => {
          console.log('add-fill', value);
          this.store.dispatch(
            setDescriptorValue({
              key: 'fill',
              innerKey: 'color',
              value,
            }),
          );
          this.store.dispatch(bulkUpdateFinish());
        },
      };

      this.cs.consumeKeyEvent('Shift');
    });

    this.cs.keyEventSubscribe('f+Backspace', () => {
      this.store.dispatch(
        setDescriptorValue({
          key: 'fill',
          value: undefined,
        }),
      );
      this.store.dispatch(bulkUpdateFinish());
    });

    this.cs.keyEventSubscribe('s+Shift', () => {
      this.overlayConfig = {
        type: 'color-selector',
        callback: value => {
          this.store.dispatch(
            setDescriptorValue({
              key: 'stroke',
              innerKey: 'color',
              value,
            }),
          );
          this.store.dispatch(bulkUpdateFinish());
        },
      };
    });

    this.cs.keyEventSubscribe('s+Backspace', () => {
      this.store.dispatch(
        setDescriptorValue({
          key: 'stroke',
          value: undefined,
        }),
      );
      this.store.dispatch(bulkUpdateFinish());
    });

    this.cs.generalEventSubscribe('set-overlay', (config: OverlayConfig) => {
      this.overlayConfig = config;
    });
    this.cs.keyEventSubscribe('s+ArrowUp', () => {
      this.store.dispatch(incrementStrokeWidth({ increment: 1 }));
      this.store.dispatch(bulkUpdateFinish());
      this.cs.consumeKeyEvent('ArrowUp');
    });
    this.cs.keyEventSubscribe('s+ArrowDown', () => {
      this.store.dispatch(incrementStrokeWidth({ increment: -1 }));
      this.store.dispatch(bulkUpdateFinish());
      this.cs.consumeKeyEvent('ArrowDown');
    });
    this.cs.keyEventSubscribe('s+ArrowRight', () => {
      this.store.dispatch(incrementStrokeWidth({ increment: 5 }));
      this.store.dispatch(bulkUpdateFinish());
      this.cs.consumeKeyEvent('ArrowRight');
    });
    this.cs.keyEventSubscribe('s+ArrowLeft', () => {
      this.store.dispatch(incrementStrokeWidth({ increment: -5 }));
      this.store.dispatch(bulkUpdateFinish());
      this.cs.consumeKeyEvent('ArrowLeft');
    });

    this.cs.generalEventSubscribe('globalClick', () => {
      // -- // this.overlayResponse(); // -- //
    });

    this.projectState$ = combineLatest([
      this.store.select(projectLoadError),
      this.store.select(isProjectLoaded),
    ]).pipe(
      map(([error, isLoaded]) => {
        if (error) {
          return 'error';
        }

        if (isLoaded) {
          return 'loaded';
        }

        return 'loading';
      }),
    );

    combineLatest([this.store.select(isProjectLoaded)]);

    this.organisationId$ = this.route.params.pipe(
      map(({ organisationID }) => organisationID),
      filter(id => !!id),
    );

    this.organisation$ = this.organisationId$.pipe(
      distinctUntilChanged(),
      tap(id => {
        this.cs.currentOrganisationID = id;
        this.store.dispatch(setCurrentOrganisationID({ ID: id }));
      }),
      mergeMap(id => this.store.select(organisationById(id))),
    );

    combineLatest([
      this.store.select(getCurrentProject),
      this.store.select(getFile),
      this.store.select(currentOrganisation),
      this.store.select(selectFontLoaded),
    ])
      .pipe(
        filter(
          ([project, file, org, fontLoaded]) =>
            !!project && !!file && fontLoaded && !!org,
        ),
      )
      .subscribe(async ([project, file, org]) => {
        if (
          this.shapeService.currentProject?.id === project.id &&
          this.shapeService.currentFile?.IRI == file.IRI
        ) {
          return;
        }

        this.shapeService.currentProject = cloneDeep(project);
        this.shapeService.currentFile = file;

        this.shapeService.animationService.currentProject = cloneDeep(project);
        this.shapeService.openRootShape(file);
        this.cs.generalEventEmit('fileLoaded', true);
      });

    this.organisation$.subscribe(org => {
      // console.log('Organisation data:', org); // -- // -- // -- //
    });

    this.route.params
      .pipe(filter(({ projectID }) => !!projectID))
      .subscribe(async ({ projectID, fileID }) => {
        if (projectID != this.cs.currentProjectID) {
          this.cs.store.dispatch(setCurrentProject({ id: projectID }));
          this.cs.store.dispatch(loadProjectWithFilesById({ id: projectID }));
        }
        this.cs.currentProjectID = projectID;

        if (fileID) {
          this.shapeService.previewShapeIsLoading = true;
          this.cs.store.dispatch(
            openFile({
              ID: fileID,
            }),
          );

          // this.cs.previewShape?.remove();
        }
        this.cs.currentFileID = fileID;
      });
  }

  ngAfterViewInit() {
    this.cs.canvasOffset = {
      x: this.editorContainer.nativeElement.offsetLeft,
      y: this.editorContainer.nativeElement.offsetTop,
    };
    // -- // -- // -- //

    const { scrollWidth, scrollHeight } = this.editorContainer.nativeElement;
    console.log('resize', { scrollWidth, scrollHeight });
    this.cs.resizeCanvas(scrollWidth, scrollHeight);
  }

  setOverlayConfig(type: OverlayComponentType) {
    this.overlayConfig = { type };
  }
  overlayMode: 'set-fill' | 'set-stroke';

  overlayResponse(value?: unknown) {
    this.overlayConfig?.callback?.(value);
    // -- //
    this.overlayConfig = null;
    if (value == undefined) {
      this.overlayMode == null;
      return;
    }
  }

  ngOnInit() {
    this.x = 100;
    this.clicked = new Point(0, 0);
  }

  eventHandler(e: ComponentEvent) {
    switch (e.type) {
      case 'disableCanvas':
        this.svgDisabled = true;
        break;
      case 'enableCanvas':
        this.svgDisabled = false;
        break;
    }
  }

  openDialog() {
    this.dialog.open(FileUploaderComponent, {});
  }

  get showBack() {
    return !!this.shapeService.currentFile.relationships.parent;
  }

  moveBack() {
    this.shapeService.goBack();
  }
}
