import { Coords } from '../../../../elements/resource/types/shape.type';
import { SVG } from './svg-element';
import { BaseVector } from '../../../../elements/base/vector/vector';
import { Graphics } from 'pixi.js';

export class LineRectangleElement extends SVG<{
  height?: number;
}> {
  initElement() {
    this._element = new Graphics();
  }
  updateAttr() {
    super.updateAttr();
    let { x: _x, y: _y } = this.position;
    const { x, y } = this.config;

    if (x == undefined) {
      return;
    }

    this.element.moveTo(_x, _y);

    const v = new BaseVector([x, y]);
    v.rotate(Math.PI / 2);
    v.reScale(this.config.height / 2);

    [
      {
        type: 'line',
        x: v.x,
        y: v.y,
      },
      {
        type: 'line',
        x,
        y,
      },
      {
        type: 'line',
        x: -2 * v.x,
        y: -2 * v.y,
      },
      {
        type: 'line',
        x: -x,
        y: -y,
      },
    ].forEach(config => {
      const { x, y } = config;
      this.element.lineTo(_x + x, _y + y);
      _x += x;
      _y += y;
    });
    this.element.closePath();
    this.element.endFill();
  }
}
