<div
  class="color-blink-animation-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Color Blink"
    [attributeState]="attributeState$ | async"
    key="color-blink"
    [noFunction]="true"
    [initialValue]="{ color: '#000000', count: 1 }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-8"
  >
    <div class="flex-row gap-6">
      <div class="fs-12">color</div>
      <nw-color-select
        [colors]="attributeState.value.color"
        (changed)="updateSubAttributeValue('color', $event)"
      ></nw-color-select>
    </div>

    <nw-number-input
      [value]="attributeState.value.count"
      key="count"
      (changed)="updateSubAttributeValue('count', $event)"
    ></nw-number-input>
  </div>
  <!-- </div> -->
</div>
