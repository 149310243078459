import { Component, Input, OnInit } from '@angular/core';
import { ResourceData } from '../../../elements/resource/resource.types';
import {
  GeneralShapeDescriptor,
  __Animation,
  AnimationValue,
  AnimationItem,
  AnimationKeys,
  ShowHideAnimation,
} from '../../../elements/resource/types/shape.type';
import { ImportedShape } from '../../../element-editor/shape/shapes/general/imported/imported-shape';
import { PathShape } from '../../../element-editor/shape/shapes/path-shape/path-shape';
import { TrajectoryShape } from '../../../element-editor/shape/shapes/trajectory/trajectory-shape';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { GroupShape } from '../../../element-editor/shape/shapes/group/group-shape';
import { Observable } from 'rxjs';
import {
  isShapeSelected,
  selectMaskChildren,
  shapeAttribute,
  showByIf,
} from '../../../element-editor/store/selector/editor.selector';
import {
  setShapeLabel,
  setShapeToBeSaved,
} from '../../../element-editor/store/editor.actions';
import {
  currentAnimationId,
  selectMyAnimations,
} from '../../../element-editor/animation/store/animation.selector';
import { Store } from '@ngrx/store';
import {
  addAnimationItemBaseAction,
  removeAnimationItemAction,
  setAnimationEase,
} from '../../../element-editor/animation/store/animation.actions';
import { Easing } from '../../../element-editor/animation/frame/increment/controller/increment.controller';
import { ShapeService } from '../../../element-editor/shape/shape.service';

@Component({
  selector: 'nw-shape-item',
  templateUrl: './shape-item.component.html',
  styleUrls: ['./shape-item.component.scss'],
})
export class ShapeItemComponent implements OnInit {
  @Input()
  shape: ResourceData<GeneralShapeDescriptor>;

  @Input()
  noEdit = false;

  @Input()
  force = false;

  showByIf$: Observable<boolean>;
  auxMode$: Observable<boolean>;
  hidden$: Observable<boolean>;
  currentAnimations$: Observable<AnimationItem[]>;

  hoveredAnimation: string;

  _animationIcons = false;

  isHovered = false;
  get _shape() {
    return this.shapeService.getShapeByIRI(this.shape.IRI);
  }

  getShowAnimationValue(value: AnimationValue) {
    return (value as ShowHideAnimation)?.value;
  }

  @Input()
  yOffset = 0;

  get IRI() {
    return this.shape.IRI;
  }

  get descriptor(): GeneralShapeDescriptor {
    return this.shape?.literals?.descriptor || {};
  }

  get type(): string {
    return this.descriptor.type;
  }

  get trajectoryShape() {
    return this._shape as TrajectoryShape;
  }

  get importedShape() {
    return this._shape as ImportedShape;
  }

  get pathShape() {
    return this._shape as PathShape;
  }

  get groupShape() {
    return this._shape as GroupShape;
  }

  get animationIsInProgress() {
    return this.cs.currentAnimation?.id;
  }

  get isPathShape() {
    return this.descriptor.type === 'path-shape';
  }

  get isImportedShape() {
    return this.descriptor.type === 'imported-shape';
  }

  get isTextShape() {
    return this.descriptor.type === 'text-shape';
  }

  get isRectangleShape() {
    return this.descriptor.type === 'rectangle-shape';
  }

  // What's that
  // get isTrajectoryShape() {
  //   return this.shape.type === ResourceType.TrajectoryShape;
  // }

  isSelected$: Observable<boolean>;
  currentAnimationId: string;

  maskChildren$: Observable<ResourceData[]>;

  constructor(
    private readonly cs: CanvasService,
    private readonly store: Store,
    private readonly shapeService: ShapeService,
  ) {
    this.store
      .select(currentAnimationId)
      .subscribe(id => (this.currentAnimationId = id));
  }

  ngOnInit(): void {
    this.maskChildren$ = this.store.select(selectMaskChildren(this.IRI));

    this.isSelected$ = this.store.select(isShapeSelected(this.IRI));
    this.showByIf$ = this.store.select(showByIf(this.IRI));
    this.auxMode$ = this.store.select(
      shapeAttribute<boolean>(this.IRI, 'auxMode'),
    );

    this.hidden$ = this.cs.store.select(
      shapeAttribute<boolean>(this.IRI, 'hidden'),
    );

    this.currentAnimations$ = this.cs.store.select(
      selectMyAnimations(this.IRI),
    );
  }

  show() {
    this._shape._show();
  }

  disableMask() {
    this._shape.disableMask();
  }

  hide() {
    this._shape._hide();
  }

  setOriginalSectionsAnimation() {
    // -- //

    this.store.dispatch(
      addAnimationItemBaseAction({
        item: {
          key: 'sections',
          fcn: 'original',
        },
        shapeIRI: this._shape.IRI,
        animationId: this.currentAnimationId,
      }),
    );
  }

  deleteShape() {
    this._shape.delete();
  }

  getTiming(animation: AnimationItem) {
    const { meta } = animation;
    return meta?.timing || '';
  }

  getEase(animation: AnimationItem) {
    const { meta } = animation;
    return meta?.ease || Easing.LINEAR;
  }

  flipEase(animation: AnimationItem) {
    // -- // -- //
    let ease: Easing;
    switch (this.getEase(animation)) {
      case Easing.LINEAR:
        ease = Easing.RANDOM;
        break;
      case Easing.RANDOM:
        ease = Easing.SMOOTH;
        break;
      case Easing.SMOOTH:
        ease = Easing.OVERFLOW;
        break;
      case Easing.OVERFLOW:
        ease = Easing.START;
        break;
      case Easing.START:
        ease = Easing.LINEAR;
        break;
    }
    this.store.dispatch(
      setAnimationEase({
        shapeIRI: this.shape.IRI,
        ease,
        key: animation.key,
      }),
    );
  }

  flipTiming(animation: AnimationItem) {
    // TODO - implement
    // const timing = this.getTiming(animation);
    // // -- // -- //
    // if (!timing) {
    //   animation.meta ||= {};
    //   animation.meta.timing = 'start';
    // } else if (timing == 'start') {
    //   animation.meta ||= {};
    //   animation.meta.timing = 'end';
    // } else {
    //   animation.meta ||= {};
    //   animation.meta.timing = null;
    // }
    // this.shape.save();
  }

  handleKeyUp(event: any) {
    this.cs.consumeKeyEvent(event.key);
  }

  nameChanged(label: string) {
    this.cs.store.dispatch(
      setShapeLabel({
        IRI: this.IRI,
        label,
      }),
    );
  }

  select(event: MouseEvent, sidePanel = false) {
    event.stopPropagation();

    if (this._shape.isImportedShape && this.cs.isPressed('f')) {
      this.importedShape.navigateToFile();
      return;
    }

    this.shapeService.getResource(this.IRI)?.select();

    if (this.cs.isPressed('Shift')) {
      // -- // -- //
      // -- //
      // this._shape.rootParent.selectFrom(this._shape.index);
    }
    // if (sidePanel && this.cs.isPressed('Shift')) {
    //   // upwards
    //   this.cs.store.dispatch(shiftSelectShapes({ IRI: this.IRI }));
    // }
  }

  addLine() {
    this.pathShape.addLine();
  }

  isAddTrajectoryShown() {
    return (
      !!this.cs.currentAnimation && !this.pathShape.currentTrajectoryAnimation
    );
  }

  deleteAnimation(key: AnimationKeys) {
    this.store.dispatch(
      removeAnimationItemAction({ IRI: this.shape.IRI, key }),
    );
  }

  hideAnimation(key: string) {
    // console.log(
    //   this.shape.animationsByKey?.[this.cs.currentAnimation?.id].find(
    //     animation => animation.key === key
    //   )?.value
    // );
  }

  cbValue = false;

  cbChange() {
    // console.log('event', this.cbValue); //
  }

  saveAnimationAsFunction(key: string, value: AnimationValue) {
    // this.cs.openGeneralSelector(
    //   { text: { title: 'Name of the animation' } },
    //   (result: string) => {
    //     this.cs.previewShape.descriptor.animationStore ||= {};
    //     this.cs.previewShape.descriptor.animationStore[result] = {
    //       key,
    //       value: cloneDeep(value),
    //     };
    //     this.cs.previewShape.save();
    //   }
    // );
  }
}
