import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ResourceData } from '../../elements/resource/resource.types';
import { CanvasService } from '../../services/canvas/canvas.service';
import { HttpService } from '../../store/http/http.service';
import { first } from 'rxjs/operators';
import { ShapeService } from '../shape/shape.service';
import { EditorService } from '../editor.service';

const INITIAL_TEXT = 'Search for the component';

@Component({
  selector: 'nw-component-search',
  templateUrl: './component-search.component.html',
  styleUrls: ['./component-search.component.scss'],
})
export class ComponentSearchComponent implements OnInit {
  text: string;

  results: ResourceData[];

  hint: string;

  @ViewChild('host')
  host: ElementRef;

  @ViewChild('search')
  inputElement: ElementRef;

  @HostListener('document:click', ['$event'])
  async documentClick(e: MouseEvent) {
    if (!this.host.nativeElement.contains(e.target)) {
      this.editorService.hideComponentSearch();
    }
  }

  constructor(
    private readonly cs: CanvasService,
    readonly httpService: HttpService,
    private readonly shapeService: ShapeService,
    private readonly editorService: EditorService,
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.inputElement?.nativeElement?.focus();
    // Dirthy hack but works
    setTimeout(() => (this.text = ''), 1);
  }

  click() {
    if (this.text === INITIAL_TEXT) {
      this.text = '';
    }
  }

  async change(event: KeyboardEvent) {
    if (this.text?.length === 1) {
      setTimeout(() => {
        if (this.text?.length === 1) {
          this.hint = 'Please give at least two letters!';
        }
      }, 500);
    } else {
      this.hint = null;
    }

    if (this.text?.length >= 2) {
      if (this.text.startsWith('@')) {
        this.results = await this.searchImageByLabel(this.text.slice(1));
      } else {
        this.results = await this.searchFileByLabel(this.text);
      }

      if (!this.results.length) {
        this.hint = 'No component could be found';
      }
    } else {
      this.results = [];
    }
  }

  async searchFileByLabel(label: string) {
    const { error, data } = await this.httpService
      .get<ResourceData[]>('editor/file', '_', {
        resourceData: {
          literals: { label },
        },
      })
      .pipe(first(p => !!p))
      .toPromise();
    if (error) {
      throw new Error(error);
    }
    return data;
  }

  async searchImageByLabel(label: string) {
    return await this.httpService.searchImageByLabel(label);
  }

  async clickItem(data: ResourceData) {
    this.editorService.hideComponentSearch();
    this.shapeService.addImportedShape(data);
  }

  clck(e: Event) {
    e.stopPropagation();
    console.log('clck');
  }
}
