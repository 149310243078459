import {
  ResourceData,
  ResourceType,
} from '../../../../../elements/resource/resource.types';
import {
  _Scale,
  AnimationItem,
  Coords,
  DropShadowConfig,
  IncrementState,
  LiteralValue,
  RectangleAnimationKeys,
  RectangleShapeDescriptor,
  Scale,
  ShapeConfig,
  TypeDef,
} from '../../../../../elements/resource/types/shape.type';
import {
  RectangleController,
  RectangleDragResponse,
} from '../../../../../elements/util/rectangle-controller/rectangle-controller';
import { PrimitiveShape } from '../../primitive/primitive-shape';
import { RectangleElement } from '../../primitive/rectangle-element';
import { Container, Text } from 'pixi.js';
import { BlurFilter } from '@pixi/filter-blur';
import { IncrementController } from '../../../../animation/frame/increment/controller/increment.controller';
import { bulkUpdateFinish } from '../../../../store/editor.actions';
import { setDescriptorValue } from '../../../../store/editor.crud.actions';
import { ShapeService } from '../../../shape.service';
import { selectRectangleRadius } from '../../../../store/selector/editor.selector';

export class RectangleShape<
  T extends RectangleShapeDescriptor = RectangleShapeDescriptor,
> extends PrimitiveShape<RectangleElement, T> {
  get text() {
    return this.descriptor.text;
  }

  set text(val: string) {
    this.descriptor.text = val;
  }

  get fontSize() {
    return this.descriptor.fontSize;
  }

  set fontSize(val: number) {
    this.descriptor.fontSize = val;
  }

  get align() {
    return this.descriptor.align || 'left';
  }

  set align(val: 'left' | 'center' | 'right') {
    this.descriptor.align = val;
  }

  get width() {
    return this._scale.x;
  }

  set width(val: number) {
    this._scale ||= { x: 0, y: 0 };
    this._scale.x = val;
  }

  get height() {
    return this._scale.y;
  }

  set height(val: number) {
    this._scale ||= { x: 0, y: 0 };
    this._scale.y = val;
  }

  get r() {
    return this.descriptor.r;
  }

  set r(val: number) {
    this.descriptor.r = val;
  }

  getType(): string {
    return 'rectangle-shape';
  }

  get elementAttributes() {
    return {
      width: this.width,
      height: this.height,
      r: this.r,
    };
  }

  // TODO - check the return type of it
  get inputs(): TypeDef {
    return [
      ...super.inputs,
      {
        key: 'r',
        value: 'number',
      },
      {
        key: 'width',
        value: 'number',
      },
      {
        key: 'height',
        value: 'number',
      },
      {
        key: 'text',
        value: 'string',
      },
      {
        key: 'fontSize',
        value: 'string',
      },
      {
        key: 'align',
        value: 'option',
        options: ['left', 'center', 'right'],
      },
    ];
  }

  get env() {
    return {
      ...super.env,
      ...{
        r: this.r || '',
        width: this.width || '',
        height: this.height || '',
        text: this.text || '',
        fontSize: this.fontSize || '',
        align: this.align || '',
      },
    };
  }

  get keys() {
    return ['r', 'width', 'height', 'text', 'fontSize', 'align'];
  }

  textContainer: Container;
  textElement: Text;

  widthIncrement: number;
  heightIncrement: number;

  currentWidth: number;
  currentHeight: number;

  getCurrentBBox() {
    return {
      x: this.x,
      y: this.y,
      width: this.width,
      height: this.height,
    };
  }

  constructor(
    service: ShapeService,
    resourceData: ResourceData<T>,
    config?: ShapeConfig,
  ) {
    super(service, resourceData, config);
    // this.scale = {
    //   x: this.descriptor.width,
    //   y: this.descriptor.height,
    // };
    // this.width = this._scale.x;
    // this.width = this._scale.x;
    this.init();

    this.type = ResourceType.RectangleShape;
    this.scale = { x: this.descriptor.width, y: this.descriptor.height };
    this._scale = { x: this.descriptor.width, y: this.descriptor.height };

    if (this.config?.isRoot) {
      this.subscriptions.push(
        this.store.select(selectRectangleRadius(this.IRI)).subscribe(r => {
          // console.log('r', r, 'this.r', this.r);
          if (r !== this.r) {
            this.r = r;
            this.refreshElement();
          }
        }),
      );
    }

    if (this.editable) {
      // this.selectedKeyEventSubscribe('r+ArrowUp', () => {
      //   this.r ||= 0;
      //   this.r++;
      //   this.saveR();
      //   this.refreshElement();
      //   this.cs.consumeKeyEvent('ArrowUp');
      //   this.cs.consumeKeyEvent('r');
      // });
      // this.selectedKeyEventSubscribe('d+h', () => {
      //   this.saveDescriptorKey('hole', true);
      // });
      // this.selectedKeyEventSubscribe('r+ArrowDown', () => {
      //   this.r ||= 0;
      //   this.r--;
      //   this.saveR();
      //   this.refreshElement();
      //   this.cs.consumeKeyEvent('ArrowDown');
      //   this.cs.consumeKeyEvent('r');
      // });
      // this.selectedKeyEventSubscribe('r+ArrowRight', () => {
      //   this.r ||= 0;
      //   this.r += 5;
      //   this.saveR();
      //   this.refreshElement();
      //   this.cs.consumeKeyEvent('ArrowRight');
      //   this.cs.consumeKeyEvent('r');
      //   console.log('rectangle-shape > r+ArrowRight');
      // });
      // this.selectedKeyEventSubscribe('r+ArrowLeft', () => {
      //   this.r ||= 0;
      //   this.r -= 5;
      //   this.saveR();
      //   this.refreshElement();
      //   this.cs.consumeKeyEvent('ArrowLeft');
      //   this.cs.consumeKeyEvent('r');
      // });
    }
    this.redraw();
  }

  saveR() {
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'r',
        value: this.r,
      }),
    );
  }

  applyScale(scale: _Scale, noRcPatch = false): void {
    const { x, y } = scale;
    this.element.patch({
      width: x,
      height: y,
    });
    this.maskCopyElement?.patch({
      width: x,
      height: y,
    });
    if (noRcPatch) return;
    this.rc?.patch(
      {
        width: x,
        height: y,
      },
      true,
    );
  }

  get copyDescriptor() {
    return {
      ...super.copyDescriptor,
      type: 'rectangle-shape',
      width: this.width,
      height: this.height,
    };
  }

  widthIncrementState: IncrementState;
  centerScaleIncrementState: IncrementState<{ width: number; height: number }>;

  widthIncrementController: IncrementController;

  prepareKeyValueAnimation(
    animation: AnimationItem<RectangleAnimationKeys>,
    division: number,
    duration: number,
    inverse = false,
  ): void {
    super.prepareKeyValueAnimation(animation, division, duration, inverse);
    const { key, value } = animation;
    switch (key) {
      case 'width':
        this.widthIncrement = ((value as number) - this.width) / division;
        break;
      case 'height':
        this.heightIncrement = ((value as number) - this.height) / division;
        break;
      case '_appear':
        // this.heightIncrement = ((value as number) - this.height) / division; //

        this.widthIncrementController = new IncrementController(
          0,
          this.width,
          division,
        );

        // this.widthIncrementState = {
        //   current: 0,
        //   increment: 1 / division,
        // };
        this.element.patch({
          width: 0,
        });
        (this.dropShadowElement as RectangleElement)?.patch({
          width: 0,
        });
        this._show();
      // case 'center-scale':
      //   const { ratio } = value as CenterScaleAnimation;
      //   // const { width, height } = this.container.getBounds();
      //   this.centerScaleIncrementState = {
      //     current: 1,
      //     increment: (ratio - 1) / division,
      //     data: { width: this.width, height: this.height },
      //   };
      //   break;
    }
  }

  updateOpacity(opacity: number): void {
    opacity *= this.opacity;
    opacity = Math.min(1, opacity);
    this.element.patch({
      opacity,
      'stroke-opacity': opacity,
    });
    (this.dropShadowElement as RectangleElement)?.patch({
      opacity,
      'stroke-opacity': opacity,
    });
  }

  incrementAnimation(
    increment: number,
    id?: string,
    maxIncrement?: number,
  ): void {
    super.incrementAnimation(increment, id, maxIncrement);
    let refresh = false;
    if (this.widthIncrement) {
      this.width += this.widthIncrement * increment;
      refresh = true;
    }
    if (this.heightIncrement) {
      this.height += this.heightIncrement * increment;
      refresh = true;
    }
    if (refresh) {
      this.refresh();
    }
    let current;
    this.getAnimationsById(id).map(({ key, value }) => {
      switch (key) {
        case '_appear':
          // current = this.incrementState(this.widthIncrementState, increment);
          const _currentWidth =
            this.widthIncrementController.increment(increment);
          this.element.patch({
            width: _currentWidth,
          });
          (this.dropShadowElement as RectangleElement)?.patch({
            width: _currentWidth,
          });
          break;
        case 'center-scale':
          // -- // -- //
          current = this.incrementState(
            this.centerScaleIncrementState,
            increment,
          );
          const { width: originalWidth, height: originalHeight } =
            this.centerScaleIncrementState.data;
          const [currentWidth, currentHeight] = [
            originalWidth,
            originalHeight,
          ].map(val => val * current);
          this._redraw({
            x: this.x - (currentWidth - originalWidth) / 2,
            y: this.y - (currentHeight - originalHeight) / 2,
          });
          this.element.patch({
            width: currentWidth,
            height: currentHeight,
          });

          // TODO - this won't work with margin in the drop-shadow
          (this.dropShadowElement as RectangleElement)?.patch({
            width: currentWidth,
            height: currentHeight,
          });

          break;
      }
    });
  }

  endAnimationByKeyValue(key: string, value: any) {
    super.endAnimationByKeyValue(key, value);
    switch (key) {
      case 'width':
        this.widthIncrement = null;
        break;
      case 'height':
        this.heightIncrement = null;
        break;
      case '_appear':
        // this.heightIncrement = ((value as number) - this.height) / division; //
        this.widthIncrementState = null;
        this.element.patch({
          width: this.width,
        });
        break;
      case 'center-scale':
        const { width: originalWidth, height: originalHeight } =
          this.centerScaleIncrementState.data;
        const [currentWidth, currentHeight] = [
          originalWidth,
          originalHeight,
        ].map(val => val * this.centerScaleIncrementState.current);

        this.width = currentWidth;
        this.height = currentHeight;
        this.x = this.x - (currentWidth - originalWidth) / 2;
        this.y = this.y - (currentHeight - originalHeight) / 2;
    }
  }

  centerScale(bBox: Coords, ratio: number) {
    const [width, height] = bBox;

    const [newWidth, newHeight] = [width, height].map(val => val * ratio);
    this._redraw({
      x: this.x - (newWidth - width) / 2,
      y: this.y - (newHeight - height) / 2,
    });
    this.element.patch({
      width: newWidth,
      height: newHeight,
    });

    // TODO - this won't work with margin in the drop-shadow
    (this.dropShadowElement as RectangleElement)?.patch({
      width: newWidth,
      height: newHeight,
    });
  }

  envChanged(_env: Record<string, LiteralValue>) {
    // super.envChanged(omit(env, this.keys));
    // let changed = false;
    // this.keys.map(key => {
    //   if (env[key] !== this[key]) {
    //     this[key] = env[key];
    //     changed = true;
    //   }
    // });
    // if (changed) {
    //   this.refreshElement();
    //   this.save();
    // }
  }

  // save() {
  //   if (this.changeSubscription) {
  //     this.changeSubscription();
  //   } else {
  //     super.save();
  //   }
  // }

  // _w: number;
  // _h: number;

  startTransformation(dx?: number, dy?: number): void {
    this._w = this.width;
    this._h = this.height;

    super.startTransformation(dx, dy);
  }

  transformation(scaleX?: number, scaleY?: number, dx = 0, dy = 0): void {
    this.width = this._w * scaleX;
    this.height = this._h * scaleY;
    super.transformation(scaleX, scaleY, dx, dy);
  }

  endTransformation(): void {
    super.endTransformation();
    this._w = this.width;
    this._h = this.height;
    this.rc.patch({ width: this.width, height: this.height });
    this.rc.hide();
    this.saveScale({
      x: this.width,
      y: this.height,
    });
  }

  _resize(width: number, height: number) {
    this.rc?.patch({ width, height });
    this.resize(width, height);
  }

  resize(width: number, height: number) {
    // this.patch('width', width);
    // this.patch('height', height);
    this.width = width;
    this.height = height;

    if (this.width < 0) {
      this.redraw();
    }
    if (this.height < 0) {
      this.redraw();
    }

    this.saveScale({
      x: this.width,
      y: this.height,
    });

    this.element?.patch(
      {
        width,
        height,
      },
      // true
    );

    // this is necessary by the initializastion

    this.maskCopyElement?.patch(
      {
        width,
        height,
      },
      // true
    );
    if (this.textElement) {
      this.refreshTextElement();
    }

    // this.store.dispatch(updateDescriptorOfShapeBaseAction({
    //   IRI: this.IRI,
    //   descriptor: {
    //     width,
    //     height,
    //   } as Partial<RectangleShapeDescriptor>
    // }))

    // this.save();
  }

  updateByMultiplication(
    width: number,
    height: number,
    xGap: number,
    yGap: number,
  ) {
    super.updateByMultiplication(width, height, xGap, yGap);
    // -- // -- //
    this.width = width;
    this.height = height;

    this.applyScale({
      x: this.width,
      y: this.height,
    });
  }

  remove() {
    super.remove();
    this.element?.remove();
  }

  completeSelect() {
    this.rc.show({
      withoutRectangle: true,
    });
  }

  init() {
    super.init();
    this.element = new RectangleElement(this, this.container, {
      ...this.elementAttributes,
      ...this.resolvedSVGAttributes,
      hole: this.descriptor.hole,
    })
      .click(() => this.clicked())
      .mouseover(() => {
        this.select({ onHover: true });
        // }
      })
      .mouseout(() => {
        // if (!this.cs.shapeAddMode) {
        this.deselect({ onHover: true });
        // }
        // handled at the rectangle controller appear
      });

    // if (this.descriptor.text) {
    //   this.textContainer = new Container();
    //   this.textElement = new Text(this.descriptor.text, {
    //     fontSize: this.fontSize,
    //   });

    //   this.container.addChild(this.textContainer);
    //   this.textContainer.addChild(this.textElement);

    //   this.refreshElement();
    // }

    // console.log('editable', this.editable); //
    // console.log('parent', this.parent); //

    this.element
      .drag(
        (dx, dy) => {
          this.service.drag(dx, dy);
        },
        () => this.localStartDrag(),
        () => {
          this.service.endDrag();
        },
      )
      .click(() => {
        this.clicked();
      });

    if (this.editable) {
      this.initRC();
    }
  }

  hide() {
    super._hide();
    this.maskCopyElement?.hide();
  }

  initRC() {
    const [_w, _h] = [this.width, this.height];

    this.rc = new RectangleController(
      this,
      {
        offset: [0, 0],
        width: this.width,
        height: this.height,
        noRect: true,
        drag: resp => this.rcDrag(resp),
        endDrag: () => this.endRCDrag(),
        clicked: () => {
          this.clicked();
        },
        mouseover: () => {
          this.select({ onHover: true });
        },
        mouseout: () => {
          this.deselect({ onHover: true });
        },
      },
      this.circleContainer,
      this.auxCircleContainer,
    );
    this.rc.hide();
  }

  rcDrag({ x, y, width, height, dx, dy }: RectangleDragResponse) {
    this.dx = dx;
    this.dy = dy;

    this.width = width;
    this.height = height;

    this.applyScale({ x: width, y: height }, true);
    this.applyTranslate({ x, y });

    const { x: xm, y: ym } = this.currentMultiplication || {};
    if (xm || ym) {
      this.multipliedShapesArray.map(shape =>
        shape.updateByMultiplication(width, height, xm?.gap || 0, ym?.gap || 0),
      );
    }

    // -- // console.log({ dw, dh }); // -- //
    // console.log({ width, height });
    // const [newDx, hLines] = this.checkHorizontalOrientation(
    //   dw,
    //   dh,
    //   {
    //     x,
    //     y,
    //     width: _w,
    //     height: _h,
    //   },
    //   true
    // );

    // const [newDy, vLines] = this.checkVerticalOrientation(
    //   dw,
    //   dh,
    //   {
    //     x,
    //     y,
    //     width: _w,
    //     height: _h,
    //   },
    //   true
    // );

    // this.showHorizontalOrientationLines(
    //   hLines.map(({ position, x, y }) => {
    //     const diff = dw - newDx;
    //     return {
    //       position,
    //       x,
    //       y,
    //     };
    //   })
    // );

    // this.showVerticalOrientationLines(
    //   vLines.map(({ position, x, y }) => {
    //     const diff = dw - newDx;
    //     return {
    //       position,
    //       // x: x - diff,
    //       x,
    //       y,
    //     };
    //   })
    // );

    // this.element.patch({ width, height, position: { x: -ox, y: -oy } });
    // this.rc.updatePointControllers(_w + dw, _h + dh);
  }

  endRCDrag() {
    this.hideOrientationLines();

    this.rc.patch({ width: this.width, height: this.height }, true);

    this.saveScale({
      x: this.width,
      y: this.height,
    });

    /* console.log(
      'end-rc-drag > w',
      this.width,
      'h',
      this.height,
      'dx',
      this.dx,
      'dy',
      this.dy,
    ); */
    // After this action is fired, in the subscription section
    // the x, y of the shape will be written
    this.saveTranslate(this.dx, this.dy);

    this.dx = 0;
    this.dy = 0;
    // this.x = this._x;
    // this.y = this._y;
  }

  initDropShadowElement(config: DropShadowConfig) {
    this.blurContainer?.destroy();

    if (!config) {
      return;
    }

    if (!this.container) {
      return;
    }

    // console.log('init-drop-shadow', config);

    const { strength, margin, color, dx, dy } = config;
    this.blurContainer = new Container();
    console.log('init-drop-shadow', color, this.service.resolveColor(color));
    this.dropShadowElement = new RectangleElement(this, this.blurContainer, {
      ...this.elementAttributes,
      ...this.resolvedSVGAttributes,
      ...{
        width: this.width + 2 * margin,
        height: this.height + 2 * margin,
      },
      'stroke-width': 0,
      fill: this.service.resolveColor(color),
    });

    // return;
    // TOOD - x

    this.container.addChildAt(this.blurContainer, 0);

    this.blurContainer.setTransform(-margin + (dx || 0), -margin + (dy || 0));
    if (strength !== 0) {
      const filter = new BlurFilter(strength);
      this.dropShadowElement.element.filters = [filter];
      // console.log('filter-was-added');
    }
  }

  get leftX() {
    return this.currentX;
  }

  get centerX() {
    return this.currentX + this.width / 2;
  }

  get rightX() {
    return this.currentX + this.width;
  }

  get topY() {
    return this.currentY;
  }

  get centerY() {
    return this.currentY + this.height / 2;
  }

  get bottomY() {
    return this.currentY + this.height;
  }

  __setRotation(deg: number, cx?: number, cy?: number) {
    this.currentAngle = deg || 0;

    if (deg === undefined || cx === undefined || cy === undefined) {
      return;
    }

    // console.log('set-rotation', this.degToRad(100)); //
    const { width, height } = this.container.getBounds();
    // console.log('bBox-width-height', width, height);

    this.container.x = this.x + width / 2;
    this.container.y = this.y + height / 2;
    // console.log('original-width-height', this.width, this.height);
    this.container.pivot.set(width / 2, height / 2);
    // this.container.pivot.set(0);
    this.container.transform.rotation = -this.degToRad(deg);
    return;
  }

  finishInit(defaultSize: boolean) {
    if (defaultSize) {
      this.resize(200, 100);
      this.rc.patch({ width: 200, height: 100 });
    }
    this.store.dispatch(bulkUpdateFinish());
    super.finishInit(defaultSize);
  }

  refreshElement(): void {
    super.refreshElement();
    if (this.textElement) {
      this.refreshTextElement();
    }
    this.refreshDropshadowElement();
    this.maskCopyElement?.patch({
      ...this.elementAttributes,
      ...this.resolvedSVGAttributes,
      // noFill: true
    });
  }

  refreshDropshadowElement() {
    if (!this.dropShadow) {
      return;
    }
    // -- // -- //
    const { strength, margin, color, dx, dy } = this.dropShadow;
    this.dropShadowElement?.patch({
      ...this.elementAttributes,
      ...this.resolvedSVGAttributes,
      ...{
        width: this.width + 2 * margin,
        height: this.height + 2 * margin,
      },
      'stroke-width': 0,
      fill: this.service.resolveColor(color),
    });
    this.blurContainer?.setTransform(-margin + (dx || 0), -margin + (dy || 0));
  }

  refreshTextElement() {
    this.textElement.style = {
      fontSize: +this.fontSize,
    };
    const [hContainer, hText] = [
      this.container.getBounds().height,
      this.textContainer.getBounds().height,
    ];

    const y = hContainer / 2 - hText / 2;

    let x: number;

    this.textContainer.setTransform(0, hContainer / 2 - hText / 2);

    if (this.align === 'left') {
      x = 0;
    } else {
      const [wContainer, wText] = [
        this.container.getBounds().width,
        this.textContainer.getBounds().width,
      ];
      if (this.align === 'center') {
        x = wContainer / 2 - wText / 2;
      } else {
        // right
        x = wContainer - wText;
      }
    }
    this.textContainer.setTransform(x, y);
  }

  maskCopyElement: RectangleElement;
  maskContainer: Container;

  setMeAsMask(maskTarget: PrimitiveShape) {
    super.setMeAsMask(maskTarget);
    // maskTarget.maskedBy = this.IRI;
    if (!this.maskCopyElement) {
      this.maskCopyElement = new RectangleElement(
        this,
        this.container,
        {
          ...this.elementAttributes,
          ...this.resolvedSVGAttributes,
          // noFill: true
        },
        0,
      );
      this.maskCopyElement.show();
    }

    // console.log('set-me-as-mask', maskTarget);
    maskTarget.container.mask = this.maskCopyElement.element;
    // maskTarget.container.mask = this.maskCopyElement.element;

    // Object.values(maskTarget.multipliedShapes || {}).map(shape => {
    //   shape.container.mask = this.maskCopyElement.element;
    // });
  }
}

export class IndividualRectangleShape extends RectangleShape {
  constructor(service: ShapeService, descriptor: RectangleShapeDescriptor) {
    super(
      service,
      {
        IRI: '',
        literals: {
          descriptor,
        },
      },
      {
        noStoreAdd: true,
      },
    );
  }
  clicked() {}

  resize(width: number, height: number): void {
    this.width = width;
    this.height = height;
    this.element?.patch(
      {
        width,
        height,
      },
      // true
    );
  }
}
