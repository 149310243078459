<div
  class="show-hide-animation-component"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Show / Hide"
    [attributeState]="attributeState$ | async"
    key="show-hide"
    [noFunction]="true"
    [initialValue]="{ value: true }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-8"
  >
    <div class="show-hide-value-container">
      <div class="flex-row w-100">
        <div
          class="show-option"
          [class.selected]="attributeState.value.value[0]"
          (click)="updateSubAttributeValue('value', true)"
        >
          show
        </div>
        <div
          class="hide-option"
          [class.selected]="!attributeState.value.value[0]"
          (click)="updateSubAttributeValue('value', false)"
        >
          hide
        </div>
      </div>
    </div>

    <div class="fs-12">Translate</div>
    <div class="flex-row space-between">
      <nw-number-input
        [value]="attributeState.value.x"
        key="x"
        (changed)="updateSubAttributeValue('x', $event)"
      ></nw-number-input>
      <nw-number-input
        [value]="attributeState.value.y"
        key="y"
        (changed)="updateSubAttributeValue('y', $event)"
      ></nw-number-input>
    </div>
    <div class="fs-12">Scale</div>
    <div class="flex-row space-between">
      <nw-number-input
        [value]="attributeState.value.scaleX"
        key="x"
        (changed)="updateSubAttributeValue('scaleX', $event)"
      ></nw-number-input>
      <nw-number-input
        [value]="attributeState.value.scaleY"
        key="y"
        (changed)="updateSubAttributeValue('scaleY', $event)"
      ></nw-number-input>
    </div>
  </div>
  <!-- </div> -->
</div>
