<div
  class="hand-shape-attribute-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Hand Shape Config"
    [attributeState]="attributeState"
    key="_config"
    [noDelete]="true"
    [initialValue]="{ color: '#000000', width: 1 }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-2"
  >
    <nw-boolean-input
      key="closed"
      [value]="attributeState.value.closed || false"
      (changed)="updateSubAttributeValue('closed', $event)"
    ></nw-boolean-input>
    <nw-boolean-input
      key="flat"
      [value]="attributeState.value.flat || false"
      (changed)="updateSubAttributeValue('flat', $event)"
    ></nw-boolean-input>

    <nw-number-input
      key="curve"
      [value]="attributeState.value.curve"
      (changed)="updateSubAttributeValue('curve', $event)"
    ></nw-number-input>

    <div
      *ngFor="
        let section of attributeState.value.handSections?.[0] || [];
        index as i
      "
    >
      <iw-hand-shape-section
        [section]="section"
        [index]="i"
        [length]="attributeState.value.handSections?.[0].length"
        [closed]="attributeState.value.closed?.[0]"
        (change)="changeSection(i, $event)"
        (delete)="removeSection($event)"
      ></iw-hand-shape-section>
    </div>

    <div class="flex-row gap-4">
      <div class="fs-11">Add section</div>
      <nw-icon type="add" [size]="12" (click)="addNewSection()"></nw-icon>
    </div>
  </div>
</div>
