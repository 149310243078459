import { AfterViewInit, Component } from '@angular/core';
import { CanvasService } from '../../services/canvas/canvas.service';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import {
  loadFileAction,
  setCurrentScene,
} from '../../element-editor/store/editor.actions';
import { Store } from '@ngrx/store';
import {
  loadProjectWithFilesById,
  setCurrentProject,
} from '../../projects/project.actions';
import { ShapeService } from '../../element-editor/shape/shape.service';
import { AnimationService } from '../../element-editor/animation/animation.service';
import { getCurrentProject } from '../../projects/project.selector';
import {
  getFile,
  scenesAreLoaded,
  selectFontLoaded,
} from '../../element-editor/store/selector/editor.selector';
import { currentOrganisation } from '../../organisation/organisation.selector';
import { cloneDeep } from 'lodash';
import { combineLatest } from 'rxjs';
import { setCurrentOrganisationID } from '../../organisation/organisation.actions';
import { RootShape } from '../../element-editor/shape/shapes/general/root/root-shape';
import { IRIToId } from '../../util/iri';

@Component({
  selector: 'nw-recorder',
  templateUrl: './recorder.component.html',
  styleUrls: ['./recorder.component.scss'],
})
export class RecorderComponent implements AfterViewInit {
  newFileId: string;
  constructor(
    private readonly cs: CanvasService,
    private readonly animationService: AnimationService,
    private readonly route: ActivatedRoute,
    private readonly store: Store,
    readonly shapeService: ShapeService,
  ) {
    this.cs.showAnimationPanel = false;

    this.route.params
      .pipe(filter(({ organisationID }) => !!organisationID))
      .subscribe(
        ({ organisationID }) =>
          (this.cs.currentOrganisationID = organisationID),
      );

    combineLatest([
      this.store.select(getCurrentProject),
      this.store.select(getFile),
      this.store.select(currentOrganisation),
      this.store.select(selectFontLoaded),
    ])
      .pipe(
        filter(
          ([project, file, org, fontLoaded]) =>
            !!project && !!file && fontLoaded && !!org,
        ),
      )
      .subscribe(async ([project, file, org]) => {
        if (
          this.shapeService.currentProject?.id === project.id &&
          this.shapeService.currentFile?.IRI == file.IRI
        ) {
          return;
        }

        this.shapeService.currentProject = cloneDeep(project);
        this.shapeService.currentFile = file;

        this.shapeService.animationService.currentProject = cloneDeep(project);
        this.shapeService.openRootShape(file);
        this.store.dispatch(
          loadFileAction({ ID: IRIToId(file.IRI), mode: 'subscene-load' }),
        );
        console.log('openRootShape');
        this.cs.generalEventEmit('fileLoaded', true);
      });

    // -- // -- //
    this.route.params
      .pipe(
        filter(
          ({ organisationID, projectID }) => !!projectID && !!organisationID,
        ),
      )
      .subscribe(async ({ organisationID, projectID, fileID }) => {
        // -- // -- //
        this.store.dispatch(setCurrentOrganisationID({ ID: organisationID }));

        if (projectID != this.cs.currentProjectID) {
          this.cs.store.dispatch(setCurrentProject({ id: projectID }));
          this.cs.store.dispatch(loadProjectWithFilesById({ id: projectID }));
        }

        this.newFileId = fileID;

        if (fileID) {
          console.log('load-file');
          this.cs.loadedFileID = fileID;
          this.shapeService.previewShapeIsLoading = true;
          this.cs.store.dispatch(
            loadFileAction({
              ID: fileID,
              mode: 'main-file',
            }),
          );

          this.cs.previewShape?.remove();
        }
        this.cs.currentFileID = fileID;
        this.cs.currentProjectID = projectID;
      });

    this.store.select(scenesAreLoaded).subscribe(val => {
      if (!val) {
        return;
      }
      console.log('scenesAreLoaded', val);
      console.log('init-scene-root-shapes', this.shapeService.currentScenes);
      let previousScene = this.cs.previewShape;
      this.shapeService.currentSceneRootShape = this.cs.previewShape;
      this.shapeService.currentScenes.map(data => {
        console.log('initializing scene root-shapes', data.literals.label);
        const sceneRootShape = new RootShape(this.shapeService, data);
        sceneRootShape.afterInit();
        previousScene.nextSceneIRI = sceneRootShape.IRI;
        this.cs.previewShapesByScene[data.IRI] = sceneRootShape;
        previousScene = sceneRootShape;
      });
    });
  }

  state: 'idle' | 'load-resources' | 'recording' | 'finished' = 'idle';

  ngAfterViewInit(): void {
    // this.cs.generalEventSubscribe('fileLoaded', loaded => {
    //   if (loaded) {
    //     console.log('record');
    //     this.cs.generalEventEmit('start-recording');
    //   }
    // });
  }
  async startRecording() {
    console.log('------- start-recording -----');
    const canvas = this.cs.canvasNativeElement.firstChild as HTMLCanvasElement;

    if (!canvas) {
      console.warn('Missing canvas');
      return;
    }

    const videoStream = canvas.captureStream(50);
    const audioContext = await this.animationService.audioContext;
    const audioStream = await this.animationService.audioDestination;

    const mediaTracks = new MediaStream(
      [].concat(videoStream.getTracks()).concat(audioStream.stream.getTracks()),
    );

    const recorder = new MediaRecorder(mediaTracks, {
      mimeType: 'video/webm; codecs=vp9',
    });

    const recordedChunks: Blob[] = [];
    const recording = new Promise<string>((resolve, reject) => {
      recorder.addEventListener('dataavailable', event => {
        console.log('ondataavailable', event.data.size);
        recordedChunks.push(event.data);
      });

      recorder.addEventListener('error', (error: Event) =>
        console.error(error),
      );

      recorder.addEventListener('stop', () => {
        console.log('stopped');
        const blob = new Blob(recordedChunks, { type: 'video/webm' });
        console.log({ size: blob.size });
        const url = URL.createObjectURL(blob);
        resolve(url);
      });
    });

    const ready = new Promise<void>(resolve => {
      recorder.addEventListener('dataavailable', event => {
        if (event.data.size > 0) {
          resolve();
        } else {
          setTimeout(() => recorder.requestData(), 100);
        }
      });
    });

    const stop = () => recorder.stop();

    recorder.start();
    recorder.requestData();

    console.log('recorder data requested');

    (async () => {
      // play 2s empty sound
      const buffer = new AudioBuffer({
        numberOfChannels: 2,
        length: audioContext.sampleRate * 2.0,
        sampleRate: audioContext.sampleRate,
      });

      const source = audioContext.createBufferSource();
      source.buffer = buffer;
      source.connect(audioStream);
      source.start(0);

      console.log('playing 2s empty sound');
    })();

    await ready;

    return {
      stop,
      recording,
    };
  }

  async downloadRecording(url: string) {
    const previousLink = document.querySelector('a#record-downloader');

    if (previousLink) {
      previousLink.remove();
    }

    const link$ = document.createElement('a');

    link$.id = 'record-downloader';
    link$.style.display = 'none';

    link$.setAttribute(
      'download',
      `${this.cs.currentFileID}-${+new Date()}.webm`,
    );
    link$.setAttribute('href', url);

    document.body.appendChild(link$);

    link$.click();
  }

  async record() {
    this.state = 'recording';
    console.time('recording:full');
    console.time('recording:prepare');

    this.state = 'load-resources';
    this.cs.previewShape.__setPreAnimationState();
    await this.animationService.audioService.waitForAudioFiles();
    const { stop, recording } = await this.startRecording();

    console.timeEnd('recording:prepare');

    console.time('recording:play');
    // await this.cs.waitForsoundFilesAndPlay();
    console.log('------ canvas.comp >> playAnimations');
    this.animationService.audioService.startBackgroundMusic();
    this.state = 'recording';
    this.cs.generalEventSubscribe('stop', async () => {
      stop();
      console.timeEnd('recording:play');

      console.time('recording:rendering');
      const url = await recording;
      console.timeEnd('recording:rendering');

      console.time('recording:downloading');
      await this.downloadRecording(url);
      console.timeEnd('recording:downloading');
      console.timeEnd('recording:full');
    });
    await this.animationService.playAnimations();
    console.log('animations-ended');
  }

  stopAnimation() {
    this.cs.generalEventEmit('stop');
  }
}
