<div
  class="path-animation-component attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Subpath move"
    [attributeState]="attributeState$ | async"
    key="subpath-move"
    [initialValue]="{ offset: 0, length: 100, reverse: false }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-6"
  >
    <nw-number-input
      key="offset"
      [value]="attributeState.value['offset']"
      (changed)="updateSubAttributeValue('offset', $event)"
    ></nw-number-input>
    <nw-number-input
      key="length"
      [value]="attributeState.value['length']"
      (changed)="updateSubAttributeValue('length', $event)"
    ></nw-number-input>
    <nw-number-input
      key="speed"
      [value]="attributeState.value['speed']"
      (changed)="updateSubAttributeValue('speed', $event)"
    ></nw-number-input>
    <nw-boolean-input
      key="reverse"
      [value]="attributeState.value['reverse']"
      (changed)="updateSubAttributeValue('reverse', $event)"
    ></nw-boolean-input>
  </div>
</div>
