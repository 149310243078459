import { Component, Input, OnInit } from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { Observable } from 'rxjs';
import { ResourceData } from '../../../elements/resource/resource.types';
import { shapeList } from '../../../element-editor/store/selector/editor.selector';

@Component({
  selector: 'nw-shape-item-list',
  templateUrl: './shape-item-list.component.html',
  styleUrls: ['./shape-item-list.component.scss'],
})
export class ShapeItemListComponent implements OnInit {
  shapes$: Observable<ResourceData[]>;

  constructor(public readonly cs: CanvasService) {}

  ngOnInit(): void {
    this.shapes$ = this.cs.store.select(shapeList);
    this.shapes$.subscribe(shapes => {
      // console.log('----- shapes ---', shapes);
    });
  }
}
