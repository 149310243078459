<div
  class="scene-item-comp"
  [ngClass]="{
    selected: isActive || this.cs.currentFileID == ID,
    'drag-hover-mode': true,
  }"
  (mouseenter)="mouseEnter()"
  (mouseleave)="mouseLeave()"
  (mouseup)="clicked()"
>
  <div class="flex-row gap-4 pointer" (click)="goToFile(scene.IRI)">
    <nw-icon type="crop_7_5" [size]="13"></nw-icon>
    <div>
      {{ scene.literals.label }}
    </div>
  </div>

  <div
    *ngIf="addSceneMode"
    style="padding-left: 22px"
    class="flex-row gap-6"
    (click)="$event.stopPropagation()"
  >
    <input
      type="text"
      [(ngModel)]="newSceneName"
      style="height: 14px; width: 112px"
    />
    <nw-icon type="check" (click)="saveNewScene()"></nw-icon>
  </div>
</div>
