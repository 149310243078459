<div class="org-index-comp w-100 h-100">
  <ng-container *ngIf="organisation$ | async as organisation; else loading">
    <div class="w-100 h-100">
      <nw-header></nw-header>
      <div class="flex-row h-100">
        <div class="side-bar h-100">
          <div class="header h-100" style="position: absolute">
            <!-- <nw-icon
          type="arrow_back"
          [size]="24"
          [outlined]="true"
          [routerLink]="['/']"
          ></nw-icon> -->
            <div class="title">{{ organisation.name }}</div>
            <div class="menu flex-column text-center">
              <div
                class="menu-item"
                [class.menu-item-selected]="isProjectsMode"
                (click)="mode = 'projects'"
              >
                Projects
              </div>
              <div
                class="menu-item"
                [class.menu-item-selected]="isUsersMode"
                (click)="mode = 'users'"
              >
                Users
              </div>
              <div
                class="menu-item"
                [class.menu-item-selected]="isSettingsMode"
                (click)="mode = 'settings'"
              >
                Settings
              </div>
            </div>
          </div>
        </div>

        <div class="body w-100 h-100">
          <nw-project-selector
            *ngIf="isProjectsMode"
            [organisationId]="organisation.id"
            [editable]="true"
          ></nw-project-selector>
          <nw-user-selector
            *ngIf="isUsersMode"
            [organisationId]="organisation.id"
            [editable]="true"
          ></nw-user-selector>
          <div
            *ngIf="isSettingsMode"
            style="margin-top: 48px"
            class="flex-row space-around"
          >
            <nw-org-color-palette-edit></nw-org-color-palette-edit>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template #loading>
    <div class="w-100 h-100 flex-row space-around">
      <div>Page is being loaded...</div>
    </div>
  </ng-template>
  <div class="exit" routerLink="">
    <div>Exit</div>
  </div>
</div>
