import { Component, EventEmitter, Output } from '@angular/core';
import { OverlayComponentType } from '../../../projects/project.reducer';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCurrentProjectField } from '../../../projects/project.selector';

@Component({
  selector: 'nw-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss'],
})
export class ProjectHeaderComponent {
  editOpen = false;

  projectLabel$: Observable<string>;

  @Output()
  setOverlay = new EventEmitter<OverlayComponentType>();

  constructor(
    private readonly cs: CanvasService,
    private readonly store: Store,
  ) {
    this.projectLabel$ = this.store.select(getCurrentProjectField('name'));
  }

  selectOverlay(type: OverlayComponentType) {
    this.setOverlay.emit(type);
  }

  exit() {
    this.cs.resetImage();
    this.cs.router.navigate(['organisations', this.cs.currentOrganisationID]);
  }
}
