import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  assignUsersToOrganisation,
  createOrganisation,
  loadOrganisationById,
  loadOrganisations,
  saveOrganisation,
  saveOrganisationColorPalette,
  setCurrentOrganisationID,
  setOrganisation,
  setOrganisationColorPalette,
  setOrganisationFromProject,
  setOrganisations,
  setOrgIsLoaded,
} from './organisation.actions';
import { exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OrganisationService } from './organisation.service';
import { Observable } from 'rxjs';
import { Action, Store } from '@ngrx/store';
import { setUsers } from '../user/user.actions';
import { setProjects } from '../projects/project.actions';
import {
  allOrganisations,
  currentOrganisationID,
} from './organisation.selector';

@Injectable()
export class OrganisationEffects {
  readonly setOrganisationFromProject$: Observable<Action>;
  readonly loadOrganisations$: Observable<Action>;
  readonly loadOrganisationById$: Observable<Action>;
  readonly createOrganisation$: Observable<Action>;
  readonly saveOrganisation$: Observable<Action>;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly organisationService: OrganisationService,
  ) {
    this.setOrganisationFromProject$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setOrganisationFromProject),
        map(({ organisation }) =>
          setOrganisation({
            organisation:
              this.organisationService.mapResourceToOrganisation(organisation),
          }),
        ),
      ),
    );

    this.loadOrganisations$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadOrganisations),
        exhaustMap(() => this.organisationService.listOrganisations()),
        map(organisations => setOrganisations({ organisations })),
      ),
    );
    this.loadOrganisationById$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadOrganisationById),
        exhaustMap(({ id }) =>
          this.organisationService.getOrganisationById(id),
        ),
        switchMap(({ projects, users, organisation }) => [
          setUsers({ users }),
          setProjects({ projects }),
          setOrganisation({ organisation }),
          assignUsersToOrganisation({ users, organisation }),
        ]),
      ),
    );

    this.createOrganisation$ = createEffect(() =>
      this.actions$.pipe(
        ofType(createOrganisation),
        exhaustMap(({ organisation }) =>
          this.organisationService.createOrganisation(organisation),
        ),
        map(organisation => setOrganisation({ organisation })),
      ),
    );

    this.saveOrganisation$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveOrganisationColorPalette),
        withLatestFrom(
          this.store.select(currentOrganisationID),
          this.store.select(allOrganisations),
        ),
        switchMap(([{ colorPalette }, currentOrganisationID, orgs]) =>
          this.organisationService
            .saveOrganisation({
              ...orgs[currentOrganisationID],
              colorPalette,
            })
            .pipe(map(() => colorPalette)),
        ),
        switchMap(colorPalette => [
          setOrganisationColorPalette({ colorPalette }),
        ]),
      ),
    );
  }
}
