import { Coords } from '../../../../elements/resource/types/shape.type';

export interface AnimationFrame {
  id?: string;
  delay?: boolean;
  type?:
    | 'sound'
    | 'text'
    | 'sub-scene-transition'
    | 'canvas-transform'
    | 'local-sound'
    | 'subscene-transform'
    | 'scene-transition'
    | 'subscene-transition';
  duration?: number;
  next?: AnimationFrame;
  paralell?: AnimationFrame;
  function?: string;
  functionTarget?: { targetAlias?: string; IRI?: string; label?: string };
  inverseOf?: string;
  inverse?: boolean;
  fcnParams?: any[];
  functions?: Array<{ name: string; frame: AnimationFrame }>;
  baseAction?: {
    key: string;
    value?: any;
  };
  preDelay?: number;
  ms?: string;
  global?: GlobalAnimationFunction;
  target?: {
    if?: string;
    notIf?: string;
    iris?: string[];
  };
  stateTransition?: StateTransitionConfig;
  localFunctions?: Record<string, AnimationFrame>;
  // helper field, its not save
  empty?: boolean;

  soundAnimationFrame?: AnimationFrame;
  backgrounMusic?: string;
}

export interface SceneTransitionFrame extends AnimationFrame {
  hide?: boolean;
  show?: boolean;
  transform?: boolean;
  backgroundColor?: boolean;
  mode?: SceneTransitionType;
}

export interface CanvasTransformFrame extends AnimationFrame {
  translate: Coords;
  targetSubScene: string;
  scale: number;
}

export interface SubSceneTransitionFrame extends CanvasTransformFrame {
  mainScene: string;
}

export interface SoundAnimationFrame extends AnimationFrame {
  audioDuration?: number;
  soundFileUrl?: string;
}

export interface TextAnimationFrame extends SoundAnimationFrame {
  voiceType?: 'generated' | 'recorded';
  text: string;
  subtitle?: boolean;
  recordFileUrl?: string;
  langs?: Record<
    string,
    {
      text: string;
      soundFileUrl?: string;
      recordFileUrl?: string;
      audioDuration?: number;
      voiceType?: 'generated' | 'recorded';
    }
  >;
}

export enum SceneTransitionType {
  ShowHide = 'show-hide',
  FloatRight = 'float-right',
  FloatLeft = 'float-left',
  FloatUp = 'float-up',
  FloatDown = 'float-down',
  ZoomIn = 'zoom-in',
  ZoomOut = 'zoom-out',
  Custom = 'custom',
}

export interface StateTransitionConfig {
  from?: string;
  to?: string;
  isTransform?: boolean;
  mode: SceneTransitionType;
}

export interface GlobalAnimationFunction {
  type: 'state-change' | 'component-change';
}

export interface StateChangeAnimation extends GlobalAnimationFunction {
  from?: string;
  to?: string;
}

export interface ComponentChangeAnimation extends GlobalAnimationFunction {
  hide?: string[];
  show?: string[];
}

export interface AnimationLoop extends AnimationFrame {
  root: AnimationFrame;
}
