import {
  Coords,
  HandShapeConfig,
  HandShapeNewDescriptor,
  ShapeControlPointPosition,
} from '../../../../../elements/resource/types/shape.type';
import { PathShape, PathShapeConfig } from '../path-shape';
import { PathItem } from '../path-shape.types';
import { selectHandShapeConfig } from '../../../../store/selector/editor.selector';
import { cloneDeep, isEqual } from 'lodash';
import { GeneralShape } from '../../general/general-shape';
import { ShapeService } from '../../../shape.service';
import { ResourceData } from '../../../../../elements/resource/resource.types';
import { HandSection } from './hand-section';
import { PathElement } from '../../primitive/path-element';
import { Vector } from '../../../../../elements/base/vector/vector';
import { currentAnimationId } from '../../../../animation/store/animation.selector';
import { HandShapeSectionDescriptorNew } from '../hand-shape-next/hand-shape-next';
import { setDescriptorValue } from '../../../../store/editor.crud.actions';

const { PI, sin, cos, pow, sqrt } = Math;

export class HandShape extends PathShape<HandShapeNewDescriptor> {
  get isClosed() {
    return this.handIsClosed;
  }
  handIsClosed: boolean;
  handSections: HandSection[];
  getType(): string {
    return 'hand-shape';
  }
  get lastSectionIndex() {
    return this.handSections.length - 1;
  }
  get lastHandSection() {
    return this.handSections[this.lastSectionIndex];
  }
  get firstHandSection() {
    return this.handSections[0];
  }
  get lastButOneHandSection() {
    return this.handSections[this.lastSectionIndex - 1];
  }
  constrainedShape: GeneralShape;
  constraintPoint: ShapeControlPointPosition;
  handSectionDescriptors: HandShapeSectionDescriptorNew[];
  curve: number;
  constructor(
    service: ShapeService,
    data: ResourceData<HandShapeNewDescriptor>,
    config: PathShapeConfig,
  ) {
    super(service, data, config);
    this.strokeElements = [];
    if (!this.descriptor.config) {
      return;
    }

    this.handIsClosed = !!this.descriptor.config.closed;
    this.curve = this.descriptor.config.curve;
    this.handSectionDescriptors = this.descriptor.config.handSections;

    this.store
      .select(currentAnimationId)
      .subscribe(id => (this.currentAnimationId = id));
    this.store.select(selectHandShapeConfig(this.IRI)).subscribe(config => {
      const { handSections, closed, curve } = config || {};
      if (!handSections?.length) {
        return;
      }

      if (
        !isEqual(this.handSectionDescriptors, handSections) ||
        this.curve !== curve ||
        this.handIsClosed !== closed
      ) {
        if (!this.currentAnimationId) {
          this.handIsClosed = closed;
          this.curve = curve;
        }
        this.handSectionDescriptors = cloneDeep(handSections);
        this.initHandSections();
        this.refreshElement();
      }

      if (!this.currentAnimationId) {
        this.handIsClosed = closed;
        this.curve = curve;
      }
    });

    if (this.editable) {
      this.cs.keyDownEventSubscribe('c', () => {
        if (this.selected) {
          return;
        }
        this.handSections?.map(hs => hs.pc.show());
      });

      this.cs.keyEventSubscribe('c', () => {
        if (this.selected) {
          return;
        }
        this.handSections?.map(hs => hs.pc.hide());
      });
    }

    this.initHandSections();
  }

  startAnimation(
    id: string,
    division: number,
    inverse?: boolean,
    duration?: number,
  ): void {
    super.startAnimation(id, division, inverse, duration);
    this.getAnimationsById(id).map(({ key, value }) => {
      switch (key) {
        case 'config':
          const { handSections } = value as HandShapeConfig;
          this.handSections.map((hs, i) =>
            hs.startAnimation(handSections[i], division),
          );
          break;
      }
    });
  }

  incrementAnimation(increment: number, id?: string, percent?: number): void {
    super.incrementAnimation(increment, id, percent);
    this.getAnimationsById(id).map(({ key }) => {
      switch (key) {
        case 'config':
          this.handSections.map(hs => hs.incrementAnimation(increment));
          this.refreshElement();
          break;
      }
    });
  }

  initHandSections() {
    // console.log('init-hand-sections', this.handSectionDescriptors);
    this.handSections?.map(hs => hs.remove());
    this.handSections = this.handSectionDescriptors?.map(
      (config, i) => new HandSection(this, config, i),
    );

    this.handSections.map(hs => hs.afterInit());
  }

  updateSection(index: number, section: HandShapeSectionDescriptorNew) {
    this.handSectionDescriptors[index] = section;
    this.saveSections();
  }
  baseConfigs: Coords[];

  firstSectionDrag = false;
  dragSectionStartIndex: number;
  dragSectionEndIndex: number;

  _startDrag(key: string, sourceShapeIRI?: string): void {
    if (!this.cs.isPressed('n')) {
      this.startConstraintDrag(key, sourceShapeIRI || this.IRI);
    }

    if (!this.isClosed && key == 'section.0') {
      return this.startBaseDrag(key, { [sourceShapeIRI || this.IRI]: true });
    }

    const index = +key.split('.')[1];
    if (this.isClosed) {
      if (index == 0) {
        this.startBaseDrag(key, { [sourceShapeIRI || this.IRI]: true }, true);
        this.firstSectionDrag = true;
        this.dragSectionStartIndex = 1;
        this.dragSectionEndIndex = 1000;
      } else {
        this.dragSectionStartIndex = index;
        this.dragSectionEndIndex = index;
      }
    } else {
      this.dragSectionStartIndex = index;
      this.dragSectionEndIndex = index + 1;
    }

    this.baseConfigs = this.handSections
      .slice(this.dragSectionStartIndex, this.dragSectionEndIndex + 1)
      .map(hs => [hs.x, hs.y]);

    if (!this.isClosed && index == 1 && !this.cs.isPressed('n')) {
      this.startConstraintDrag('section.2', sourceShapeIRI || this.IRI);
    }
  }
  _drag(x: number, y: number, dx: number, dy: number) {
    this.constrainedShapes.map(shape => shape._drag(x, y, dx, dy));

    if (this.dragSectionStartIndex == undefined) {
      this.firstHandSection.pc.patch({
        p: [0, 0],
      });
      this.drag(dx, dy);
      return;
    }

    if (this.firstSectionDrag) {
      this.drag(dx, dy);
    }

    this.handSections
      .slice(this.dragSectionStartIndex, this.dragSectionEndIndex + 1)
      .map((hs, i) => {
        const [x, y] = this.baseConfigs[i];
        hs.x = x + (this.firstSectionDrag ? -dx : dx);
        hs.y = y + (this.firstSectionDrag ? -dy : dy);
        hs.refreshPC();
      });

    this.handSections.map(hs => hs.refreshPC());
    this.refreshElement();
  }

  _endDrag() {
    this.endDrag();
    // if (this.dragSectionStartIndex == undefined) {
    //   this.endDrag();
    // }
    this.firstSectionDrag = false;
    this.dragSectionStartIndex = undefined;
    this.dragSectionEndIndex = undefined;
    this.saveSections();
  }

  saveSections() {
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'config',
        innerKey: 'handSections',
        value: cloneDeep(this.handSectionDescriptors),
      }),
    );
  }

  strokeElement: PathElement;
  strokeElements: PathElement[] = [];

  afterInit() {
    console.log('translate', this.translate);
    this.refreshElement();
    super.afterInit();
    // this._config = this.descriptor._config;

    // if (this._config.constraintTo) {
    //   const { shapeIRI, point } = this._config.constraintTo;
    //   this.constrainedShape = this.service.getShapeByIRI(shapeIRI);
    //   this.constraintPoint = point;
    //   if (this.constrainedShape) {
    //     this.constrainedShape.constrainedBy = point;

    //     if (this.constrainedShape instanceof HandShapeNext) {
    //       const [_x, _y] = this.xy;

    //       this.constrainedShape.applyShapeTranslate({
    //         x: this.x + _x,
    //         y: this.y + _y,
    //       });
    //     }
    //   }
    // }

    // this.turnPC.hide(); //
  }

  showDragControllers(): void {
    // DragPC can be removed
    this.handSections.map(hs => hs.showRC());
  }

  hideDragControllers(): void {
    this.handSections.map(hs => hs.hideRC());
  }

  dragOntoHandler() {
    // -- // -- // -- //
  }

  refreshElement(start?: number, end?: number): void {
    // -- // -- // -- //
    super.refreshElement();
    // -- // -- // -- //

    this.handSections?.map(hs => hs.initStrokeElements());
    // if (this.handSections) {
    //   const strokeSections = [];
    //   this.handSections.map((_hs, index) => {
    //     strokeSections.push([index, 'side']);
    //     strokeSections.push([index, 'bottom']);
    //   });

    //   if (!this.handIsClosed) {
    //     for (let i = this.handSections.length - 1; 0 < i; i--) {
    //       strokeSections.push([i, 'top']);
    //     }
    //   }

    //   this.strokeElements?.map(se => se.remove());
    //   this.strokeElements = [];
    // }
  }

  __elements: PathItem[];

  _getElements(start?: number, end?: number) {
    this.__elements = this._elements;
    // console.log('elements', this.__elements);
    return this.__elements;
  }

  get _elements(): PathItem[] {
    const _elements = [];

    if (!this.handSections) {
      return [];
    }

    this.handSections.map(section => section.calcAngles());
    this.handSections.map(section => _elements.push(...section.getElements()));

    if (!this.isClosed) {
      if (this.curve) {
        // -- // -- // -- //

        const [xs, ys] = this.lastHandSection.lineEnd;
        const [xe, ye] = this.firstHandSection.offset;

        const v = this.firstHandSection.prevV.copy();

        const cw = this.firstHandSection.isCW;

        const vector = new Vector({ x: xs, y: ys }, { x: xe, y: ye });
        const l = sqrt(pow(xe - xs, 2) + pow(ye - ys, 2));

        vector.reScale(l / 3);
        v.reScale(l / 3);
        v.rotate(!cw ? -PI / 2 : PI / 2);
        vector.rotate(!cw ? -(this.curve * PI) / 6 : (this.curve * PI) / 6);

        _elements.push({
          type: 'curve',
          bx: vector.x,
          by: vector.y,
          cx: v.x,
          cy: v.y,
          X: xe,
          Y: ye,
        });
      } else {
        const [X, Y] = this.handSections[0].offset;
        _elements.push({
          type: 'line',
          X,
          Y,
        });
      }
    }

    return _elements;
  }

  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }
    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return PI - angle;
    }
    if (x < 0 && y < 0) {
      return PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? PI / 2 : (3 * PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : PI;
    }
  }
}
