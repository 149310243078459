import { PathSection, PathSectionConfig } from '../path-section';
import { Subscription } from 'rxjs';
import {
  Coords,
  CurveSectionDescriptor,
  PositionVector,
  ShapeSelectParams,
} from '../../../../../../elements/resource/types/shape.type';
import { PathShape } from '../../path-shape';
import {
  BaseVector,
  Vector,
} from '../../../../../../elements/base/vector/vector';
import { PathElement } from '../../../primitive/path-element';
import { Circle } from '../../../primitive/circle-element';
import { CurveDashCache, CurveItem, PathItem } from '../../path-shape.types';
import { PointController } from '../../../../../../elements/util/point-controller/point-controller';
import { CurveCoverElement } from '../../../primitive/curve-cover-element';

const { PI, sqrt, pow } = Math;
const RATIO = 2;

export class CurveSection extends PathSection {
  dashCache: CurveDashCache[] = [];

  pc1: PointController;
  pc2: PointController;

  subW: Subscription;
  subT: Subscription;

  _a1: [number, number];
  _a2: [number, number];

  _b: number;
  _b1: number;
  _b2: number;
  _bs: number;
  _h: number;
  _h1: number;
  _h2: number;

  _tan1: boolean;
  _tan2: boolean;

  curveLength: number;

  d1: number;
  d2: number;

  get pcs() {
    return [this.pc, this.pc1, this.pc2].filter(v => !!v);
  }

  get pcLines() {
    return [this.pcLine1, this.pcLine2].filter(v => !!v);
  }

  showPCs(): void {
    this.pcs.map(pc => pc.show());
    this.pcLines.map(pc => pc.show());
  }

  hidePCs(): void {
    this.pcs.map(pc => pc.hide());
    this.pcLines.map(pc => pc.hide());
  }

  constructor(
    public pathShape: PathShape,
    protected descriptor: CurveSectionDescriptor,
    protected config?: PathSectionConfig,
  ) {
    super(pathShape, descriptor, config);

    this._tan1 = this.descriptor.tan1;
    this._tan2 = this.descriptor.tan2;

    this._a1 = this.descriptor.a1 || [0, 0];
    this._a2 = this.descriptor.a2 || [0, 0];

    this.setD1();
    this.setD2();

    if (this.pathShape.editable) {
      this.cs.generalEventSubscribe('zoom', scale => {
        this.pcLine1?.patch({
          'stroke-width': 1 / scale,
        });
        this.pcLine2?.patch({
          'stroke-width': 1 / scale,
        });
      });
    }
    // if (!this.isDef(this._b) && !this.isDef(this._bs)) {
    //   if (!this.isDef(this._b1)) {
    //     this._b1 = (6 * Math.PI) / 10;
    //   }
    //   if (!this.isDef(this._b2)) {
    //     this._b2 = (4 * Math.PI) / 10;
    //   }
    // }
    // if (
    //   !this.isDef(this._h1) &&
    //   !this.isDef(this._h2) &&
    //   !this.isDef(this._h)
    // ) {
    //   // --> // --> //
    //   this._h = Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
    //   if (isNaN(this._h)) {
    //     console.log('ejnye-bejnye');
    //   }
    // }

    // if (!this.h) {
    //   this._h = Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
    //   console.log('this._h', this._h);
    // }

    // ... // ... //

    // if (this.pathShape.dashArray) {
    //   this.setDashCache();
    // }
    // this.setDashCache();
  }

  x_: number;
  y_: number;

  a1_: Coords;
  a2_: Coords;

  started = false;

  testElement: PathElement;
  c1: Circle;
  c2: Circle;

  cut(t: number, s: number) {
    if (t < s) {
      return;
    }
    const {
      x: xs,
      y: ys,
      bx: bxs,
      by: bys,
      cx: cxs,
      cy: cys,
    } = this.getConfigT(t - s, s);
    const { x, y, bx, by, cx, cy } = this.getConfigT(t, s);

    const [xa, ya] = this.prevPS.absCoords;
    this.testElement?.patch({
      position: { x: xa + xs, y: ya + ys },
      elements: [
        {
          type: 'curve',
          bx: bxs,
          by: bys,
          cx,
          cy,
          x: x - xs,
          y: y - ys,
        } as CurveItem,
      ],
    });
  }

  setDashCache() {
    this.started = false;

    const resolution = 100;

    const step = 1 / resolution;

    let currentLength = 0;
    let [currentX, currentY] = [0, 0];

    const [bx, by] = this.a1;

    this.dashCache = [];
    this.dashCache.push({
      length: 0,
      t: 0,
      config: {
        x: 0,
        y: 0,
        bx,
        by,
      },
    });

    for (let t = step; t <= 1; t += step) {
      t = +t.toFixed(2);
      const config = this.getConfigT(t);

      const [dx, dy] = [config.x - currentX, config.y - currentY];
      // console.log({ t, x: config.x, y: config.y });

      const length = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

      this.dashCache.push({
        t,
        length: currentLength + length,
        config,
      });

      currentLength += length;
      currentX = config.x;
      currentY = config.y;
    }

    this.curveLength = currentLength;

    if (this.next) {
      this.next.startLength = this.startLength + this.curveLength;
    }
  }

  get lineLength() {
    return this.curveLength;
  }

  get baseConfig(): CurveItem {
    const [x, y] = this.getCoords();
    const [a1x, a1y] = this.a1;
    const [a2x, a2y] = this.a2;
    return {
      type: 'curve',
      bx: a1x,
      by: a1y,
      cx: a2x,
      cy: a2y,
      x,
      y,
    };
  }

  zoomUpdate() {
    super.zoomUpdate();
    this.pc1?.zoomUpdate();
    this.pc2?.zoomUpdate();
    this.pcLine1?.patch({
      'stroke-width': 1 / this.cs.canvasScale,
    });
    this.pcLine2?.patch({
      'stroke-width': 1 / this.cs.canvasScale,
    });
  }

  setD1() {
    const [x, y] = this._a1;
    this.d1 = sqrt(pow(x, 2) + pow(y, 2));
  }

  setD2() {
    const [x, y] = this._a2;
    this.d2 = sqrt(pow(x, 2) + pow(y, 2));
  }

  _getPathItem(start?: number, end?: number): PathItem | PathItem[] {
    if (!start && !end) {
      return this.baseConfig;
    }

    if (end <= this.startLength || this.endLength <= start) {
      return [];
    }

    if (start <= this.startLength && this.endLength <= end) {
      return this.baseConfig;
    }

    let startT: number;
    let startConfig: Partial<CurveItem>;
    let endT: number;
    let endConfig: Partial<CurveItem>;

    const _start = Math.max(start, this.startLength);
    const _end = Math.max(end, this.endLength);

    const s = (_end - _start) / this.curveLength;

    console.log('s', s);
    for (const { length, t, config } of this.dashCache) {
      if (!startConfig && start <= this.startLength + length) {
        startT = t;
        startConfig = config;
      }

      if (!endConfig && end <= this.startLength + length) {
        endT = t;
        endConfig = config;
        break;
      }
    }

    if (!startConfig || !endConfig) {
      return [];
    }

    const { x, y, bx, by } = startConfig;
    const vb = new BaseVector([bx, by]).scale(s / (1 - startT));

    const { x: xe, y: ye, cx, cy } = endConfig;
    const vc = new BaseVector([cx, cy]).scale(s / endT);

    // console.log({ s, startT, endT }); //
    // console.log({ 's / (1 - startT': s / (1 - startT), 's / endT': s / endT }); //

    const [ax, ay] = this.prevPS.absCoords;
    // console.warn('--- return-4 --', { x, ax, ay, y });
    return {
      type: 'curve',
      offset: [ax + x, ay + y],
      x: xe - x,
      y: ye - y,
      bx: vb.x,
      by: vb.y,
      cx: vc.x,
      cy: vc.y,
    } as CurveItem;
  }

  getPathItem(start?: number, end?: number): PathItem | PathItem[] {
    if (!start && !end) {
      return this.baseConfig;
    }

    if (end <= this.startLength || this.endLength <= start) {
      return [];
    }

    if (start <= this.startLength && this.endLength <= end) {
      return this.baseConfig;
    }

    const _start = Math.max(start, this.startLength);
    const _end = Math.max(end, this.endLength);

    const startT = (_start - this.startLength) / this.curveLength;
    const endT = (_end - this.startLength) / this.curveLength;

    const startConfig = this.dashCache[Math.floor(startT * 100)]?.config;
    if (!startConfig) {
      console.warn('startConfig is not found');
      return [];
    }

    const endConfig = this.dashCache[Math.floor(endT * 100)]?.config;
    if (!endConfig) {
      console.warn('endConfig is not found');
      return [];
    }

    // console.log({ _start, _end });
    // console.log('s', Math.floor(startT * 100), 'e', Math.floor(endT * 100));
    // -- //

    const s = (_end - _start) / this.curveLength;

    const { x, y, bx, by } = startConfig;
    const vb = new BaseVector([bx, by]).scale(s / (1 - startT));

    const { x: xe, y: ye, cx, cy } = endConfig;
    const vc = new BaseVector([cx, cy]).scale(s / endT);

    const [ax, ay] = this.prevPS.absCoords;

    return {
      type: 'curve',
      offset: x !== 0 || y !== 0 ? [ax + x, ay + y] : [ax, ay],
      x: xe - x,
      y: ye - y,
      bx: vb.x,
      by: vb.y,
      cx: vc.x,
      cy: vc.y,
    } as CurveItem;
  }

  setEndLength() {
    this.setDashCache();
    this.endLength = this.startLength + this.curveLength;
  }

  getPositionVector(t: number): PositionVector {
    if (t < this.startLength || this.endLength < t) {
      return;
    }

    if (t == this.endLength) {
      const [cx, cy] = this.a2;
      const [ax, ay] = this.prevPS.absCoords;
      return {
        x: ax + this.x,
        y: ay + this.y,
        angle: new BaseVector([cx, cy]).rotate(Math.PI).getAngle(),
      };
    }

    const ratio = (t - this.startLength) / (this.endLength - this.startLength);
    const { x, y, bx, by } = this.getConfigT(ratio);

    const vector = new BaseVector([bx, by]);
    const [ax, ay] = this.prevPS.absCoords;

    return {
      x: ax + x,
      y: ay + y,
      angle: vector.getAngle(),
    };
  }

  getConfigT(t: number, s: number = 1): CurveItem {
    // const [ax, ay] = this.prevPS.absCoords; //
    if (!this.started) {
      this.started = true;
      this.x_ = +this.x.toString();
      this.y_ = +this.y.toString();

      this.a1_ = this.a1.map(v => +v.toString()) as Coords;
      this.a2_ = this.a2.map(v => +v.toString()) as Coords;
    }

    const _x = () => +this.x_.toString();
    const _y = () => +this.y_.toString();

    const [x1, y1] = this.a1_.map(v => +v.toString());
    const v11 = new Vector({ x: 0, y: 0 }, { x: x1, y: y1 });

    const [a2x, a2y] = this.a2_.map(v => +v.toString());
    const v13 = new Vector(
      { x: _x() + a2x, y: _y() + a2y },
      { x: _x(), y: _y() },
    );

    const v12 = new Vector({ x: x1, y: y1 }, { x: _x() + a2x, y: _y() + a2y });

    const P11 = v11.scale(t).end;
    const P12 = v12.scale(t).end;
    const P13 = v13.scale(t).end;

    const v21 = new Vector(P11, P12);
    const v22 = new Vector(P12, P13);

    const P21 = v21.scale(t).end;
    const P22 = v22.scale(t).end;

    const v3 = new Vector(P21, P22);

    v3.scale(t);
    const { x, y } = v3.end;

    const a1 = new Vector(
      { x, y },
      {
        x: P22.x,
        y: P22.y,
      },
    );

    const a2 = new Vector(
      { x, y },
      {
        x: P21.x,
        y: P21.y,
      },
    );

    const [bx, by] = [a1.x, a1.y];
    const [cx, cy] = [a2.x, a2.y];

    return {
      type: 'curve',
      x,
      y,
      bx,
      by,
      cx,
      cy,
    };
  }
  a1UnMoved: Coords;
  a2UnMoved: Coords;

  startDragByWeight() {
    super.startDragByWeight();
    this.a1UnMoved = this.a1;
    this.a2UnMoved = this.a2;
  }

  updatePointControllers() {
    super.updatePointControllers();

    const [pax, pay] = this.prevPS?.absCoords || [0, 0];
    this.pcLine1?.patch({
      position: { x: pax, y: pay },
    });
    this.pc1?.patch({
      pOffset: [pax, pay],
      p: this.a1.map(v => v / RATIO) as Coords,
    });
    const [ax, ay] = this.absCoords || [0, 0];
    this.pcLine2?.patch({
      position: { x: ax, y: ay },
    });
    this.pc2?.patch({
      pOffset: [ax, ay],
      p: this.a2.map(v => v / RATIO) as Coords,
    });
  }

  updateByScale(scaleX: number, scaleY: number) {
    super.updateByScale(scaleX, scaleY);

    const [a1x, a1y] = this.a1UnMoved;
    this.setA1(a1x * scaleX, a1y * scaleY);
    const [a2x, a2y] = this.a2UnMoved;
    this.setA2(a2x * scaleX, a2y * scaleY);
  }

  // set h1(val: number) {
  //   this._h1 = val;
  // }

  // set h2(val: number) {
  //   this._h2 = val;
  // }

  // refreshXY(
  //   xInput: number,
  //   yInput: number,
  //   dx?: number,
  //   dy?: number,
  //   noAdjust?: boolean,
  // ) {
  //   super.refreshXY(xInput, yInput, dx, dy, noAdjust);
  // }

  get otherHoverSectionCondition() {
    return this.pc1?.inProgress || this.pc2?.inProgress;
  }

  get mode() {
    if (this.isDef(this.b)) {
      return 'b';
    }
    if (this.isDef(this.bs)) {
      return 'bs';
    }
    return '';
  }

  flip() {
    super.flip();
    this._flip();
  }

  _flip() {
    if (this._b) {
      this._b *= -1;
    }

    if (this._b1) {
      this._b1 *= -1;
    }

    if (this._b2) {
      this._b2 *= -1;
    }

    if (this._h1) {
      this._h1 *= -1;
    }

    if (this._h2) {
      this._h2 *= -1;
    }

    if ((!this._h1 || !this._h2) && this._h) {
      this._h *= -1;
    }
  }

  pcLine1: PathElement;
  pcLine2: PathElement;

  coverElement: CurveCoverElement;

  _initialized: boolean;

  init() {
    if (this._initialized) {
      return;
    }

    this._initialized = true;

    // this.setDashCache();

    this.subW = this.cs.keyEventSubscribe('w', () => {
      if (this.pc1?.inProgress || this.pc2?.inProgress) {
        switch (this.mode) {
          case 'b':
            this.setBsMode(this.pc1.inProgress);
            break;
          case 'bs':
            this.setDefaultMode();
            break;
          default:
            this.setBMode(this.pc1.inProgress);
            break;
        }

        const pointConstraints = Object.values(this.constraints).find(
          ({ subject }) => subject.startsWith('point:'),
        );

        if (pointConstraints) {
          const object = pointConstraints.objects[0];
          this.copy(
            this.cs.getTarget({
              type: 'section',
              id: object.id,
            }) as CurveSection,
            pointConstraints.subject !== object.type,
          );
        }
      }
    });

    this.subT = this.cs.keyEventSubscribe('t', () => {
      if (this.pc?.inProgress || this.pc1?.inProgress) {
        this._tan1 = !this.tan1;
      }
      if (this.pc2?.inProgress) {
        this._tan2 = !this.tan2;
      }
    });

    if (this.editable) {
      this.pcLine1 = new PathElement(
        this,
        this.pathShape.circleContainer,
        {
          stroke: '#cccccc',
          'stroke-width': 1,
        },
        0,
      );
      this.pcLine1.hide();
      this.updateLine1();

      this.pcLine2 = new PathElement(
        this,
        this.pathShape.circleContainer,
        {
          stroke: '#cccccc',
          // stroke: '#ff0000',
          'stroke-width': 1,
        },
        0,
      );
      this.pcLine2.hide();
      this.updateLine2();
    }

    super.init();
  }

  postInit() {
    // -- //
    if (this.editable) {
      this.pc1 = new PointController(
        this,
        {
          pOffset: (this.prevPS?.absCoords as [number, number]) || [0, 0],
          p: this.a1.map(v => v / RATIO) as Coords,
          drag: ({ x, y }) => this.setA1(RATIO * x, RATIO * y),
          end: () => {
            // this.cs.clickAbsorbed = true;
            this.setD1();
            this.acEndDrag();
            this.prevPS?.updatePointControllers();
            this.updatePointControllers();
          },
          start: () => (this.pathShape.iamDragged = true),
          // color: '#ff0000',
          mouseover: () => {
            if (this.pathShape.iamDragged) {
              return;
            }
            this.pathShape.select({ onHover: true });
          },
          mouseout: () => {
            if (this.pathShape.iamDragged) {
              return;
            }
            this.pathShape.deselect({ onHover: true });
          },
          angle:
            this.prevPS.anchor && !this.prevPS.isCurve
              ? this.prevPS.m
              : undefined,
        },
        this.pathShape.circleContainer,
        this.pathShape.auxCircleContainer,
      );
      this.pc1.hide();

      this.pc2 = new PointController(
        this,
        {
          pOffset: this.absCoords as [number, number],
          p: this.a2.map(v => v / RATIO) as Coords,
          drag: ({ x, y }) => this.setA2(RATIO * x, RATIO * y),
          end: () => {
            this.cs.clickAbsorbed = true;
            this.acEndDrag();
            this.setD2();
          },
          start: () => (this.pathShape.iamDragged = true),
          angle:
            this.nextPS.anchor && !this.nextPS.isCurve
              ? this.norm(this.nextPS.m - PI)
              : undefined,
          // color: '#ff0000',
          mouseover: () => {
            if (this.pathShape.iamDragged) {
              return;
            }
            this.pathShape.select({ onHover: true });
          },
          mouseout: () => {
            if (this.pathShape.iamDragged) {
              return;
            }
            this.pathShape.deselect({ onHover: true });
          },
        },
        this.pathShape.circleContainer,
        this.pathShape.auxCircleContainer,
      );
      this.pc2.hide();

      this.cs.keyEventSubscribe('v', () => {
        if (this.pc1?.inProgress) {
          this.prevPS.flipAnchor();
          if (!this.prevPS.isCurve) {
            if (this.prevPS.anchor) {
              const angle = this.prevPS.m;
              this._b1 = angle;
              this.pc1.patch({
                angle,
                p: [this.h1 * Math.cos(angle), -this.h1 * Math.sin(angle)],
              });
              this.pc1.startDrag();
            } else {
              this.pc1.clear({
                angle: undefined,
              });
            }
          }
        }

        if (this.pc2?.inProgress) {
          this.flipAnchor();
          if (
            this.nextPS.isLine &&
            !(this.nextPS.amILast && !this.pathShape.closed)
          ) {
            if (this.anchor) {
              const angle = this.norm(this.nextPS.m - PI);
              this._b2 = angle;
              this.pc2.patch({
                angle,
                p: [this.h2 * Math.cos(angle), -this.h2 * Math.sin(angle)],
              });
              this.pc2.startDrag();
            } else {
              this.pc2.clear({
                angle: undefined,
              });
            }
          }
        }

        // if (this.pc.inProgress || this.pc2.inProgress) {
        //   this.flipAnchor();
        // }
      });
    }
    super.postInit();
  }

  initLineRectangle() {
    // -- //
    const [x, y] = this.prevPS.absCoords;
    const [bx, by] = this._a1;
    const [cx, cy] = this._a2;

    if (this.pathShape.fill || !this.pathShape.stroke) {
      return;
    }

    // console.log('curve-cover', x, y);
    this.coverElement = new CurveCoverElement(
      this,
      this.pathShape.circleContainer,
      {
        position: {
          x,
          y,
        },
        fill: '#000000',
        'fill-opacity': 0.001,
        // stroke: '#ff0000',
        hoverItem: {
          type: 'curve',
          x: this.x,
          y: this.y,
          bx,
          by,
          cx,
          cy,
          height: Math.max(10, this.pathShape.stroke.width || 1),
        },
      },
    )
      .drag(
        (dx, dy) => {
          // if (!this.pathShape.selected) {
          //   return;
          // }
          if (this.pathShape.selected && this.cs.isShiftPressed) {
            return this.pathShape.dragSections(
              dx / this.cs.canvasScale,
              dy / this.cs.canvasScale,
            );
          }
          this.pathShape.service.drag(dx, dy);
        },
        () => {
          // if (!this.pathShape.selected) {
          //   return;
          // }

          if (this.pathShape.selected && this.cs.isShiftPressed) {
            this.dragSideStarted = true;
            return this.pathShape.prepareForBatchDrag([
              this.prevPS.index,
              this.index,
            ]);
          }
          this.pathShape.localStartDrag();
        },
        () => {
          if (!this.pathShape.selected) {
            return;
          }

          this.pathShape.hideAuxLines();
          if (this.dragSideStarted) {
            this.dragSideStarted = false;
            this.pathShape.endDragMany();
            return;
          }

          this.pathShape.service.endDrag();
        },
      )
      .mouseover(() => {
        this.pathShape.select({ onHover: true });
      })
      .mouseout(() => {
        this.pathShape.deselect({ onHover: true });
      })
      .click(() => this.pathShape.clicked());
  }

  acEndDrag() {
    this.pathShape.iamDragged = false;
    this.pathShape.enableHover();
    this.pathShape.saveSections({ section: this });
    this.pathShape.refresh();
  }

  clearStartAnchor() {
    super.clearStartAnchor();
    this.pc1.clear({ angle: 0 });
  }

  // drag(x: number, y: number, dx: number, dy: number) {
  //   super.drag(x, y, dx, dy);
  //   // -- // -- // -- // -- //
  //   // console.log('dl', Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2)));
  // }

  setBsMode(inverse: boolean) {
    this._bs = inverse ? -this.b2 : this.b1;
    this._b = undefined;
    this.clear();
  }

  setBMode(inverse: boolean) {
    this._b = inverse ? this.b2 : this.b1;
    this._bs = undefined;
    this.clear();
  }

  setDefaultMode() {
    if (this.bs) {
      this._b1 = this.bs;
      // this._b2 = this.bs;
    }
    this._h1 = this.h;
    this._h2 = this.h;
    this._b = undefined;
    this._bs = undefined;
  }

  copy(curveSection: CurveSection, inverse = false) {
    switch (this.mode) {
      case 'b':
        curveSection.setBMode(inverse);
        curveSection._b = inverse ? Math.PI - this.b : this.b;
        curveSection._h = this.h;
        break;
      case 'bs':
        curveSection.setBsMode(inverse);
        curveSection._bs = inverse ? this.bs - Math.PI : this.bs;
        curveSection._h = this.h;
        break;
      default:
        curveSection.setDefaultMode();
        curveSection._b1 = inverse ? Math.PI - this.b2 : this.b1;
        curveSection._h1 = inverse ? this.h2 : this.h1;
        curveSection._b2 = inverse ? Math.PI - this.b1 : this.b2;
        curveSection._h2 = inverse ? this.h1 : this.h2;
    }
  }

  getDescriptor() {
    if (this._a1 && this._a2) {
      return {
        ...super.getDescriptor(),
        type: 'curve',
        a1: this._a1,
        a2: this._a2,
        tan1: this._tan1,
        tan2: this._tan2,
      };
    }
    return {
      ...super.getDescriptor(),
      // h: this._h,
      // h: this._h,
      // h1: this._h1,
      // h2: this._h2,
      // b: this._b,
      // b1: this._b1,
      // b2: this._b2,
      // bs: this._bs,
      tan1: this._tan1,
      tan2: this._tan2,
    };
  }

  get inputs() {
    return {
      b: 'number',
      b1: 'number',
      b2: 'number',
      bs: 'number',
      h: 'number',
      tan: {
        default: false,
      },
      a: 'number',
    };
  }

  get elements(): CurveItem[] {
    const vector = new BaseVector([this.x, this.y])
      .turn90()
      .unify()
      .scale(10 / this.cs.canvasScale);
    const [_x, _y] = [vector.end.x, vector.end.y];

    const { type, bx, by, cx, cy, x, y } = this.getLineConfig();

    return [
      {
        type,
        bx,
        by,
        cx,
        cy,
        x,
        y,
      },
      {
        type: 'line',
        x: _x,
        y: _y,
      },
      {
        type,
        bx: cx,
        by: cy,
        cx: bx,
        cy: by,
        x: -x,
        y: -y,
      },
      {
        type: 'line',
        x: -_x,
        y: -_y,
      },
    ];
  }

  getLineConfig(_start?: number, _end?: number): CurveItem {
    const [x, y] = this.getCoords();

    if (this.amIFirst) {
      this.absCoords = [x, y];
    } else {
      this.absCoords = [
        this.prevPS?.absCoords[0] + x,
        this.prevPS?.absCoords[1] + y,
      ];
    }

    const [a1x, a1y] = this.a1;
    const [a2x, a2y] = this.a2;

    return {
      type: 'curve',
      bx: a1x,
      by: a1y,
      cx: a2x,
      cy: a2y,
      x,
      y,
    };
  }

  updateHoverSections() {
    const [x, y] = this.amIFirst ? [0, 0] : this.prevPS.absCoords;

    this.sectionPath?.patch({
      position: { x, y },
      x: this.x,
      y: this.y,
      // 'stroke-width': 3 / this.cs.scaleValue,
      elements: [this.getLineConfig()],
    });

    this.hoverSectionPath?.patch({
      position: { x, y },
      elements: this.elements,
    });
  }

  remove() {
    super.remove();
    this.subW?.unsubscribe();
    this.subT?.unsubscribe();

    this.pc1?.remove();
    this.pc2?.remove();
    this.pcLine1?.remove();
    this.pcLine2?.remove();
    // this.?.remove();
    // this.pc2?.remove();
  }

  updateVectors: Vector[] = [];

  updateByMouseMove(x: number, y: number) {
    const [dx, dy] = super.updateByMouseMove(x, y);
    this.setA1A2ByDxDy(dx, dy);
    return [dx, dy];
  }

  setA1A2ByDxDy(dx: number, dy: number) {
    this._a1 = [dx / 3, dy / 3];
    this._a2 = [-dx / 3, -dy / 3];
    this.setD1();
    this.setD2();
  }

  refresh() {
    super.refresh();
    // TODO - remove ?

    const isAngleConstraint1 = this.prevPS.isLine && this.prevPS.anchor;
    // const angle1 = this.prevPS.m;

    // const [a1x, a1y] = [
    //   this.h1 * Math.cos(angle1),
    //   -this.h1 * Math.sin(angle1),
    // ];
    // if (isAngleConstraint1) {
    //   this.setA1(a1x, a1y, false, true);
    // }

    const [a1x, a1y] = this._a1;

    this.pc1?.patch({
      pOffset: (this.prevPS?.absCoords as [number, number]) || [0, 0],
      angle: isAngleConstraint1 ? this.prevPS.m : undefined,
      p: (isAngleConstraint1 ? [a1x, a1y] : this.a1).map(
        v => v / RATIO,
      ) as Coords,
    });

    // TODO - remove ?

    const isAngleConstraint2 =
      this.anchor &&
      this.nextPS.isLine &&
      !(this.nextPS.amILast && !this.pathShape.closed);
    if (isAngleConstraint2) {
      const angle2 = this.norm(this.nextPS.m - PI);
      const [a2x, a2y] = [
        this.h2 * Math.cos(angle2),
        -this.h2 * Math.sin(angle2),
      ];
      this.pc2?.patch({
        pOffset: (this.absCoords as [number, number]) || [0, 0],
        angle: isAngleConstraint2 ? this.norm(this.nextPS.m - PI) : undefined,
        p: [a2x, a2y].map(v => v / RATIO) as Coords,
      });
      this.setA2(a2x, a2y, false, true);
    } else {
      this.pc2?.patch({
        pOffset: (this.absCoords as [number, number]) || [0, 0],
        p: this.a2.map(v => v / RATIO) as Coords,
      });
    }

    this.updateLine1();
    this.updateLine2();

    // if (this.prevPS instanceof CurveSection && this.prevPS.anchor) {
    //   const [x, y] = this.a1;
    //   console.log('adjust-a2', x, y)
    //   this.prevPS.adjustA2(x, y);
    // }

    // if ((this.nextPS as CurveSection).anchor) {
    //   const [x, y] = this.a2;
    //   (this.nextPS as CurveSection).adjustA1(x, y);
    // }
  }

  refreshCoverElement() {
    const [a1x, a1y] = this._a1;
    const [a2x, a2y] = this._a2;
    const [x, y] = this.prevPS.absCoords;
    this.coverElement?.patch({
      position: { x, y },
      hoverItem: {
        type: 'curve',
        x: this.x,
        y: this.y,
        bx: a1x,
        by: a1y,
        cx: a2x,
        cy: a2y,
        height: Math.max(10, this.pathShape.stroke.width || 1),
      },
    });
  }

  get isCurve(): boolean {
    return true;
  }

  get isLine() {
    return false;
  }

  get d() {
    const [x, y] = [this.x, this.y];
    return sqrt(pow(x, 2) + pow(y, 2));
  }

  // TODO - override

  cc: number;

  get aRef() {
    return this.cc ? this.m - PI / 2 : this.m + PI / 2;
  }

  get h1() {
    return this.isDef(this._h1) ? this._h1 : this.h;
  }

  get h2() {
    return this.isDef(this._h2) ? this._h2 : this.h;
  }

  get mEnd() {
    if (this.nextPS.tan1) {
      // this.cs._debug('m', this.m);
      // this.cs._debug('b2', this.b2);
    }
    return this.m - this.b2;
  }

  get a1(): [number, number] {
    if (this._a1) {
      return this._a1;
    }

    if (this.tan1) {
      const angle = this.norm(this.prevCurve.b2 - 180);
      return [this.h1 * Math.cos(angle), -this.h1 * Math.sin(angle)];
    }

    if (this.pathShape.newCurveAngle) {
      return [this.h1 * Math.cos(this.b1), -this.h1 * Math.sin(this.b1)];
    }

    let angle: number;
    if (this.tan1) {
      angle = this.norm(this.prevCurve.b2 - 180);
    } else {
      angle = this.aRef + (this.cc ? this.b1 : -this.b1);
    }

    return [this.h1 * Math.cos(angle), -this.h1 * Math.sin(angle)];
  }

  get a2(): [number, number] {
    // console.log('get a2 - ._b2', this._b2)
    // return [this.h2 * Math.cos(this.b2), -this.h2 * Math.sin(this.b2)];

    if (this._a2) {
      return this._a2;
    }

    if (this.pathShape.newCurveAngle) {
      return [this.h2 * Math.cos(this.b2), -this.h2 * Math.sin(this.b2)];
    }

    let angle: number;
    if (this.tan2) {
      angle = this.norm(this.nextCurve.b1 - 180);
    } else {
      angle = this.aRef + (this.cc ? -this.b2 : this.b2);
    }

    return [this.h2 * Math.cos(angle), -this.h2 * Math.sin(angle)];
  }

  get mNext() {
    if (this.cc) {
      return this.m + PI / 2 - this.convertAngle(this.b2);
    } else {
      return this.m - PI / 2 + this.convertAngle(this.b2);
    }
  }

  get h() {
    // Todo - d has to be revisited
    if (this.isDef(this._h)) {
      return this._h;
    }

    if (this.isDef(this.d)) {
      return this.d / 2;
    }

    return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
  }

  get b() {
    return this._b;
  }

  get bs() {
    return this._bs;
  }

  get b1() {
    if (this.isDef(this._b1)) {
      return this._b1;
    }

    if (this.isDef(this._b)) {
      return this._b;
    }

    if (this.isDef(this._bs)) {
      return this._bs;
    }
    return 0;
  }

  get b2() {
    if (this.isDef(this._b2)) {
      return this._b2;
    }

    if (this.isDef(this._b)) {
      return this._b;
    }

    if (this.isDef(this._bs)) {
      return -this.bs;
    }
    return 0;
  }

  get type() {
    return 'cs';
  }

  get tan1() {
    return false; // this._tan1;
  }

  get tan2() {
    return false; //this._tan2 || this.nextPS?.tan1;
  }

  get m() {
    if (this.isDef(this.x) && this.isDef(this.y)) {
      return this.getAngle(this.x, -this.y);
    }
    const baseAngle = this.tan1 && this.prevM ? this.prevM : PI / 2;

    if (this.cc) {
      return baseAngle + PI / 2 - this.convertAngle(this.b1);
    } else {
      return baseAngle - PI / 2 + this.convertAngle(this.b1);
    }
  }

  migrateToNewAngleRep() {
    const [a1x, a1y] = this.a1;
    const [a2x, a2y] = this.a2;

    this._b1 = this.norm(this.getAngle(a1x, -a1y));
    this._b2 = this.norm(this.getAngle(a2x, -a2y));
  }

  // -- // -- // -- // -- //
  setA1(a1x: number, a1y: number, fromConstraint = false, noRefresh = false) {
    // console.log('set-a1', a1x, a1y);
    this._a1 = [a1x, a1y];

    if (this.prevCurve?.anchor) {
      const vector = new BaseVector(this._a1);
      vector.rotate(Math.PI);

      if (this.cs.isSpacePressed) {
        this.cs.consumeKeyEvent('Space');
        this.setD2();
        vector.reScale(this.d2);
      } else {
        vector.reScale(this.prevCurve.d2);
      }
      // -- // -- // -- //
      const { x, y } = vector.end;
      this.prevCurve._a2 = [x, y];
      this.prevCurve.updatePointControllers();
    }

    if (!noRefresh) {
      this.pathShape.refreshElement();
    }
    this.updateLine1();
  }

  updateLine1() {
    if (!this.pcLine1) {
      return;
    }

    const [ax, ay] = (this.prevPS?.absCoords as [number, number]) || [0, 0];
    const [x, y] = this.a1;
    this.pcLine1.patch({
      position: {
        x: ax,
        y: ay,
      },
      x: x / RATIO,
      y: y / RATIO,
    });
  }

  applyAdjustment(dx: number, dy: number): void {
    super.applyAdjustment(dx, dy);
    this.updateLine1();
    this.updatePointControllers();
  }

  setA2(a2x: number, a2y: number, fromConstraint = false, noRefresh = false) {
    this._a2 = [a2x, a2y];
    if (!noRefresh) {
      this.pathShape.refresh();
    }

    if (this.anchor && this.nextCurve) {
      const vector = new BaseVector(this._a2);
      vector.rotate(Math.PI);

      if (this.cs.isSpacePressed) {
        this.setD2();
        vector.reScale(this.d2);
      } else {
        vector.reScale(this.nextCurve.d2);
      }

      const { x, y } = vector.end;
      this.nextCurve._a1 = [x, y];
      this.nextCurve.updatePointControllers();
    }

    if (!noRefresh) {
      this.pathShape.refreshElement();
    }
    this.updateLine1();
    return;

    if (this.nextLine && !(this.amILastButOne && !this.pathShape.closed)) {
      const vector = new BaseVector([-this.nextPS.x, -this.nextPS.y]);

      const angle = this.getAngle(a2x, a2y);
      const v1 = vector.getAngle();
      const angleDiff = v1 - angle;

      const limit = 0.08726646259971647;
      if (Math.abs(angleDiff) < limit) {
        const v2 = new BaseVector([a2x, a2y]);
        v2.rotateTo(v1);
        a2x = v2.x;
        a2y = v2.y;
      }
    }

    const h = sqrt(pow(a2x, 2) + pow(a2y, 2));
    if (this.isDef(this.bs)) {
      const angle = !this.cc
        ? this.aRef - this.getAngle(a2x, -a2y)
        : this.getAngle(a2x, -a2y) - this.aRef;
      this.setBs(h, this.norm(angle));
      this.pathShape.refresh();
    } else {
      const angle = this.cc
        ? this.aRef - this.getAngle(a2x, -a2y)
        : this.getAngle(a2x, -a2y) - this.aRef;

      if (this.isDef(this.b)) {
        this.setB(h, this.norm(angle));
      } else {
        this._h2 = h;
        if (this.pathShape.newCurveAngle) {
          this._b2 = this.getAngle(a2x, -a2y);
        } else {
          this._b2 = this.norm(angle);
        }
      }
    }

    if (fromConstraint) {
      return;
    }
    this.applyAnchorConstraints(a2x, a2y, 'point:a2');
    if (this.anchor) {
      if (this.nextCurve) {
        this.nextCurve._b1 = this.norm(this.b2 - PI);
        this.nextCurve.updateLine1();
      }

      // console.log('b2', this.b2, '-- next.b1', this.nextCurve?.b1);
    }

    if (this.pathShape.dashFill) {
      this.setDashCache();
      this.nextCurve?.setDashCache();
    }

    if (!noRefresh) {
      this.adjusted = true;
      this.pathShape.refresh();
    } else {
      this.adjusted = false;
    }

    if (this.cs.isPressed('Space') && this.anchor && this.nextCurve) {
      this.nextCurve._h1 = this.h2;
      this.nextCurve.updateLine1();
    }

    this.updateLine2();
  }

  updateLine2() {
    if (!this.pcLine2) {
      return;
    }

    const [ax, ay] = this.absCoords;
    const [x, y] = this.a2;

    this.pcLine2.patch({
      position: {
        x: ax,
        y: ay,
      },
      x: x / RATIO,
      y: y / RATIO,
    });
  }

  adjustA1(x0: number, y0: number) {
    if (this.adjusted) {
      this.adjusted = false;
      return;
    }

    const [x, y] = Vector.setToLength(-x0, -y0, this.h1);
    this.setA1(x, y, false, true);
  }

  adjusted = false;

  adjustA2(x0: number, y0: number) {
    if (this.adjusted) {
      this.adjusted = false;
      return;
    }
    const [x, y] = Vector.setToLength(-x0, -y0, this.h2);
    this.setA2(x, y, false, true);
  }

  applyAnchorConstraints(x: number, y: number, type: string) {
    Object.entries(this.constraints)
      .filter(([, { subject }]) => subject === type)
      .map(([key, { objects }]) => {
        objects.map(({ type, id }) => {
          const section = this.cs.getTarget({ type: 'section', id });
          if (type === 'point:a1') {
            (section as CurveSection).setA1(x, y, true);
          } else if (type === 'point:a2') {
            (section as CurveSection).setA2(x, y, true);
          }
        });
      });
  }

  setB(h: number, angle: number) {
    this._h = h;
    this._b = this.norm(angle);
    this.clear();
  }

  setBs(h: number, angle: number) {
    this._h = h;
    this._bs = this.norm(angle);
    this.clear();
  }

  private clear() {
    this._h1 = undefined;
    this._b1 = undefined;
    this._h2 = undefined;
    this._b2 = undefined;
  }

  _getSection(start?: number, end?: number) {
    const [x, y] = this.getCoords();

    if (this.amIFirst) {
      this.absCoords = [x, y];
    } else {
      this.absCoords = [
        this.prevPS?.absCoords[0] + x,
        this.prevPS?.absCoords[1] + y,
      ];
    }

    const [a1x, a1y] = this.a1.map(v => 2 * v);
    const [a2x, a2y] = this.a2.map(v => 2 * v);
    return `c ${a1x} ${a1y} ${x + a2x} ${y + a2y} ${x} ${y}`;
  }

  select({ onHover }: ShapeSelectParams = {}) {
    super.select({ onHover });
    this.showControlPoints();
  }

  hideControlPoints() {
    // -- // -- //
    this.pc1?.hide();
    this.pc2?.hide();

    this.pcLine1?.hide();
    this.pcLine2?.hide();
  }

  showControlPoints() {
    super.showControlPoints();
    this.pcLine1?.show();
    this.pcLine2?.show();
    this.pc1?.show();
    this.pc2?.show();
  }

  // setA1(x: number, y: number) {
  //   this.set_('d1', sqrt(pow(x, 2) + pow(y, 2)));

  //   const angle = this.getAngle(x, -y);
  //   const ref = this.cc ? this.m - PI / 2 : this.m + PI / 2;
  //   const dAngle = this.cc ? this.dA(angle, ref) : this.dA(ref, angle);

  //   if (this.isDef(this.b)) {
  //     this._b = dAngle)
  //     return;
  //   }

  //   if (this.isDef(this.bs)) {
  //     this._bs = dAngle);  //     return;
  //   }

  //   this._b1 = dAngle)
  // }

  // setA2(x: number, y: number) {
  //   this.set_('d2', sqrt(pow(x, 2) + pow(y, 2)));

  //   const angle = this.getAngle(x, -y);
  //   const ref = this.cc ? this.m - PI / 2 : this.m + PI / 2;
  //   const dAngle = this.cc ? this.dA(ref, angle) : this.dA(angle, ref);

  //   if (this.isDef(this.b)) {
  //     this._b = dAngle)
  //     return;
  //   }

  //   if (this.isDef(this.bs)) {
  //     this._bs = dAngle);  //     return;
  //   }

  //   this._b2 = dAngle)
  // }
}
