import { Inject, Injectable } from '@angular/core';
import { CognitoService, User } from './cognito.service';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';
import type { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import type { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { Observable } from 'rxjs-compat';
import { AuthClassToken } from './authentication.constans';
import { Store } from '@ngrx/store';
import { setMyUserId } from '../user/user.actions';
import { IRIToId } from '../util/iri';

@Injectable()
export class AuthenticationService {
  isUserRestricted = false;
  readonly session = new Subject<CognitoUserSession>();
  readonly failedLogins: Observable<string>;
  readonly lastUrlStoreKey = 'last-url';

  constructor(
    private readonly cognitoService: CognitoService,
    private readonly router: Router,
    @Inject(AuthClassToken) private auth: AuthClass,
    private readonly store: Store,
  ) {
    this.updateSession();

    this.router.events.subscribe(event => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }

      // check session instead
      if (
        !['/', '/signIn', '/signUp'].includes(event.url) &&
        !event.url.includes('?code=')
      ) {
        sessionStorage.setItem(this.lastUrlStoreKey, event.url);
      }

      if (event.url.includes('?code=')) {
        const target = sessionStorage.getItem(this.lastUrlStoreKey) || '/home';
        console.log(target);
        this.router.navigate([target, { queryParamsHandling: 'preserve' }]);
      }
    });

    this.failedLogins = this.cognitoService.cognitoEvents.pipe(
      filter(({ event }) => ['signIn_failure'].includes(event)),
      map(({ message }) => message),
    );

    this.failedLogins.subscribe(() => (this.isUserRestricted = true));
  }

  async updateSession() {
    try {
      const session = await this.getCurrentSession();
      this.session.next(session);
      // console.log('session', session);
      const userIRI = session?.getIdToken().payload['custom:IRI'];
      if (userIRI) {
        this.store.dispatch(setMyUserId({ id: IRIToId(userIRI) }));
      }
    } catch (error) {
      console.error('session-error', error);
      this.session.next();
    }
  }

  getCurrentSession() {
    return this.auth.currentSession();
  }

  isAuthenticated(): Promise<boolean> {
    return this.cognitoService.isAuthenticated();
  }

  //use provider = "COGNITO" for logging in through the hosted UI
  async signIn(
    user?: User,
    provider?: CognitoHostedUIIdentityProvider,
  ): Promise<void> {
    if (provider) {
      await this.cognitoService.socialSignIn(provider);
      return;
    }
    console.log('auth-service > signIn');
    await this.cognitoService.signIn(user);
    await this.updateSession();
  }

  async signOut() {
    await this.cognitoService.signOut();
    await this.updateSession();
  }

  isSocialAuthenticationFailed(responseString: string) {
    if (responseString.toString().includes('error_description')) {
      this.isUserRestricted = true;
      return true;
    }
  }

  isAuthenticationFailed(responseString: string) {
    if (responseString.toString().includes('Unauthorized user')) {
      this.isUserRestricted = true;
      return true;
    }
  }

  isUserWhitelisted(responseString: string) {
    const user = this.getBlacklistedUser(responseString);
    if (user) {
      alert(
        `Access denied for user ${user}! Please, contact us if you need access!`,
      );
      return false;
    }
    return true;
  }

  private getBlacklistedUser(responseString: string) {
    const emailRegex = /[\w.-]+@[\w-]+(?:\.[\w-]+)+/;
    return decodeURI(responseString).toString().match(emailRegex)?.[0];
  }
}
