import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { _attributeState } from '../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../base/base-attribute.component';
import { ArmShapeConfig } from '../../shape/shapes/path-shape/arm-section/arm-shape-new';
import { AttributeStateOf } from '../../../elements/resource/types/shape.type';

@Component({
  selector: 'iw-arm-shape-attribute',
  templateUrl: './arm-shape-attribute.component.html',
  styleUrls: ['./arm-shape-attribute.component.scss'],
})
export class ArmShapeAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'config';
  }
  currentConfig: AttributeStateOf<ArmShapeConfig>;

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('config'));
    // -- // -- //
    this.attributeState$.subscribe(val => {
      this.currentConfig = val?.value;
    });
  }

  changeStrokeSection(
    key: 'top' | 'start' | 'bottom' | 'end' | 'center',
    event: Event,
  ) {
    const value = (event.target as HTMLInputElement).checked;
    this.updateSubAttributeValue(
      key,
      value
        ? {
            start: 0,
            end: 1,
          }
        : undefined,
    );
  }

  changeSectionInterval(
    key: 'top' | 'bottom' | 'start' | 'end' | 'center',
    type: 'start' | 'end',
    value: number | string,
  ) {
    // implement-later

    let start = this.currentConfig[key][0].start;
    let end = this.currentConfig[key][0].end;

    switch (type) {
      case 'start':
        start = value as number;
        break;
      case 'end':
        end = value as number;
        break;
    }

    this.updateSubAttributeValue(key, {
      start,
      end,
    });
  }
}
