import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MaterialModule } from '../../material.module';
import { CanvasService } from '../../services/canvas/canvas.service';
import { UtilComponentModule } from '../util/util-component.module';
import { FileItemComponent } from './file-item/file-item.component';
import { SidePanelComponent } from './side-panel.component';
import { SortByPipe } from './sort.pipe';
import { ShapeItemComponent } from './shape-item/shape-item.component';
import { ShapeItemListComponent } from './shape-item-list/shape-item-list.component';
import { TrajectoryAnimationItemComponent } from './shape-item/trajectory-animation-item/trajectory-animation-item.component';
import { ImportedShapeConfigComponent } from './shape-item/imported-shape-config/imported-shape-config.component';
import { LoopTranslateAnimationItemComponent } from './shape-item/loop-translate-animation-item/loop-translate-animation-item.component';
import { SceneItemComponent } from './scene-item/scene-item.component';

@NgModule({
  imports: [
    FlexLayoutModule,
    MaterialModule,
    CommonModule,
    FormsModule,
    BrowserModule,
    UtilComponentModule,
  ],
  providers: [CanvasService],
  declarations: [
    SidePanelComponent,
    FileItemComponent,
    SceneItemComponent,
    SortByPipe,
    ShapeItemComponent,
    ShapeItemListComponent,
    TrajectoryAnimationItemComponent,
    // RotationAnimationItemComponent,
    ImportedShapeConfigComponent,
    LoopTranslateAnimationItemComponent,
  ],
  exports: [SidePanelComponent],
})
export class SidePanelModule {}
