import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  selectNumberVariables,
  selectTakenVariables,
} from '../../../projects/project.selector';

@Component({
  selector: 'nw-floating-input',
  templateUrl: './floating-input.component.html',
  styleUrls: ['./floating-input.component.scss'],
})
export class FloatingInputComponent implements OnInit, OnDestroy {
  @ViewChild('input') inputElement: ElementRef;

  @Input()
  variableType: string;

  @Output()
  save = new EventEmitter<string>();

  text = 'name';
  takenVariables: Record<string, number> = {};
  sub: Subscription;

  constructor(private readonly store: Store) {}

  get isInvalid() {
    return this.takenVariables[this.text];
  }

  ngOnInit(): void {
    this.sub = this.store
      .select(selectNumberVariables)
      .subscribe(val => (this.takenVariables = val));

    setTimeout(() => {
      this.inputElement.nativeElement.focus();
      this.inputElement.nativeElement.select();
      // -- // console.log(this.inputElement.nativeElement.focus()); // -- // -- //
    }, 10);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  clickCheck() {
    this.save.emit(this.text);
  }
}
