import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  addNewShapeAction,
  addShapeToStore,
  setOriginalDescriptor,
  saveChangedShapes,
  setShapeOriginalTransform,
  setOriginalSVGAttributes,
  setAttributeByState,
  openFile,
  addFileToStore,
  setFileLoading,
  setCurrentFile,
  loadFileAction,
  setCanvasShapes,
  setRelationship,
  setRelationshipBase,
  incrementRadius,
  setShape,
  popVisitStack,
  moveBack,
  bulkUpdateFinish,
  setBulkUpdateItem,
  applyBulkShapeUpdates,
  setResourcePatch,
  createNewComponentAction,
  saveResourcesRequestAction,
  saveResourcesAction,
  setResourcePost,
  setResourceDelete,
  removeRelationshipBase,
  deselectShape,
  addFile,
} from '../editor.actions';
import {
  setDescriptorValue,
  incrementStrokeWidth,
  deleteShapeAction,
} from '../editor.crud.actions';
import {
  selectedShapes,
  selectNoAnimationMode,
  selectedSelectedState,
  selectFiles,
  selectShapes,
  selectDescriptors,
  currentDescriptors,
  selectedShapeList,
  selectVisitStack,
  currentSVGAttributes,
  selectBulkUpdates,
  selectShapeTransforms,
  baseSvgAttributes,
  selectUpdatesByState,
  selectCrudEvents,
  selectCurrentFileIRI,
  _selectedShapes,
} from '../selector/editor.selector';
import {
  AnimationValue,
  CircleShapeDescriptor,
  GeneralShapeDescriptor,
  RectangleShapeDescriptor,
  SVGAttributes,
  Translate,
} from '../../../elements/resource/types/shape.type';
import { pick as _pick, cloneDeep } from 'lodash';
import {
  setAnimationSubValue,
  addAnimationsOfShapesToStoreBase,
  addAnimationItemAction,
  initAnimationsOfFile,
} from '../../animation/store/animation.actions';
import {
  animationsByShape,
  currentAnimationId,
} from '../../animation/store/animation.selector';
import { IdToIRI } from '../../../util/iri';
import { ResourcePatch } from '../reducer/editor.reducer';
import { ResourceData } from '../../../elements/resource/resource.types';
import { error } from 'console';

@Injectable()
export class EditorEffects {
  moveBack$: Observable<Action>;
  setDescriptorValue$: Observable<Action>;
  bulkUpdateFinish$: Observable<Action>;
  updateShapeDescriptor$: Observable<Action>;
  saveTranslate$: Observable<Action>;
  addShapeToStore$: Observable<Action>;
  deleteShape$: Observable<Action>;
  newShape$: Observable<Action>;
  setShapeAttributeAction$: Observable<Action>;
  updateShapeDescriptorAction$: Observable<Action>;
  removeAttribute$: Observable<Action>;
  resetBaseState$: Observable<Action>;
  selectSubScene$: Observable<Action>;
  addNewScene$: Observable<Action>;
  switchScene$: Observable<Action>;
  incrementRadius$: Observable<Action>;
  incrementStrokeWidth$: Observable<Action>;
  openFile$: Observable<Action>;
  addFileToStore$: Observable<Action>;
  setRelationship$: Observable<Action>;
  createNewComponent$: Observable<Action>;
  saveResources$: Observable<Action>;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {
    /**************  REQUEST RELATED  ****************/

    this.addFileToStore$ = createEffect(() =>
      this.actions$.pipe(
        ofType(addFileToStore),
        switchMap(({ file, shapes }) => {
          const canvasShapes = Object.keys(
            (file.relationships?.shapes as Record<string, boolean>) || [],
          ).map(shapeIRI => shapes[shapeIRI]);

          return [
            setCurrentFile({ file }),
            setCanvasShapes({ shapes: canvasShapes }),
            initAnimationsOfFile({ file, shapes: canvasShapes }),
            setFileLoading({ value: false }),
          ];
        }),
      ),
    );

    this.saveResources$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveResourcesAction),
        withLatestFrom(
          this.store.select(selectCrudEvents),
          this.store.select(selectShapeTransforms),
          this.store.select(baseSvgAttributes),
          this.store.select(selectDescriptors),
          this.store.select(selectUpdatesByState),
          this.store.select(animationsByShape),
          this.store.select(selectShapes),
          this.store.select(selectFiles),
        ),
        switchMap(
          //@ts-ignore
          ([
            action,
            crudEvents,
            transforms,
            svgAttributes,
            descriptors,
            updatesByState,
            animationsByShape,
            shapes,
            files,
          ]) => {
            const { posts, patches, deletes } = crudEvents;
            return [
              saveResourcesRequestAction({
                posts: Object.entries(posts || {}).map(([IRI, type]) =>
                  type == 'nw:File'
                    ? ({
                        IRI,
                        type,
                        literals: files[IRI].literals,
                        relationships: files[IRI].relationships,
                      } as ResourceData)
                    : ({
                        IRI,
                        type,
                        literals: {
                          ...shapes[IRI].literals,
                          descriptor: {
                            ...descriptors[IRI],
                            position: transforms[IRI],
                            svgAttributes: svgAttributes[IRI],
                            updatesByState: updatesByState[IRI],
                            animations: animationsByShape[IRI],
                          },
                        },
                        relationships: shapes[IRI].relationships,
                      } as ResourceData),
                ),
                deletes: Object.entries(deletes).map(
                  ([IRI, type]) =>
                    ({
                      IRI,
                      type,
                    }) as ResourceData,
                ),
                patches: Object.entries(patches)
                  .map(([IRI, patchDescription]) => {
                    const { type, literals, relationships } =
                      patchDescription as ResourcePatch;
                    switch (type) {
                      case 'nw:Shape':
                        return {
                          IRI,
                          type,
                          literals: {
                            descriptor: literals?.descriptor
                              ? {
                                  ...descriptors[IRI],
                                  position: transforms[IRI],
                                  svgAttributes: svgAttributes[IRI],
                                  updatesByState: updatesByState[IRI],
                                  animations: animationsByShape[IRI],
                                }
                              : undefined,
                          },
                          relationships: {
                            file: relationships?.file
                              ? shapes[IRI].relationships.file
                              : undefined,
                          },
                        };
                    }
                  })
                  .filter(v => !!v),
              }),
            ];
          },
        ),
        catchError(error => {
          console.error('saveResourceAction error', error.message);
          return [];
        }),
      ),
    );

    /**************  SHAPE/FILE MANIPULATION  ****************/

    this.setDescriptorValue$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setDescriptorValue),
        withLatestFrom(
          this.store.select(currentAnimationId),
          this.store.select(selectNoAnimationMode),
          this.store.select(selectedSelectedState),
          this.store.select(selectedShapes),
        ),
        switchMap(
          ([
            action,
            currentAnimationId,
            noAnimationMode,
            selectedState,
            selectedShapes,
          ]): Action[] => {
            const { key, innerKey, value, IRI } = action;
            const shapesToUpdate = IRI ? [IRI] : selectedShapes;

            if (selectedState) {
              switch (key) {
                case 'translate':
                  return [
                    setAttributeByState({
                      IRIs: shapesToUpdate,
                      state: selectedState,
                      key: 'position',
                      innerKey,
                      value,
                    }),
                  ];
                case 'scale':
                  return [
                    setAttributeByState({
                      IRIs: shapesToUpdate,
                      state: selectedState,
                      keys: ['position', 'scale', innerKey as string],
                      value,
                    }),
                  ];
              }
            } else if (currentAnimationId && !noAnimationMode) {
              return [
                innerKey
                  ? setAnimationSubValue({
                      IRIs: shapesToUpdate,
                      animationKey: key,
                      innerKey,
                      value,
                    })
                  : addAnimationItemAction({
                      IRIs: shapesToUpdate,
                      item: {
                        key,
                        value: value as AnimationValue,
                      },
                    }),
              ];
            } else {
              return [
                setBulkUpdateItem({
                  items: shapesToUpdate.map(IRI => ({
                    IRI,
                    key,
                    value,
                    innerKey,
                    base: true,
                  })),
                }),
                setResourcePatch({
                  IRIs: shapesToUpdate,
                  resourceType: 'nw:Shape',
                  field: 'literals',
                  key: 'descriptor',
                }),
              ];
            }
          },
        ),
        catchError(e => {
          console.log('..........error', e);
          return of(e.message);
        }),
      ),
    );

    this.incrementRadius$ = createEffect(() =>
      this.actions$.pipe(
        ofType(incrementRadius),
        withLatestFrom(
          this.store.select(selectedShapeList),
          this.store.select(selectDescriptors),
          this.store.select(currentDescriptors),
        ),
        switchMap(
          ([
            { increment },
            selectedShapes,
            descriptors,
            currentDescriptors,
          ]) => {
            return selectedShapes
              .filter(
                shapeIRI => descriptors[shapeIRI].type == 'rectangle-shape',
              )
              .map(shapeIRI => {
                const r =
                  (currentDescriptors[shapeIRI] as RectangleShapeDescriptor)
                    .r || 0;
                return setDescriptorValue({
                  IRI: shapeIRI,
                  key: 'r',
                  value: r + increment,
                });
              });
          },
        ),
      ),
    );

    this.incrementStrokeWidth$ = createEffect(() =>
      this.actions$.pipe(
        ofType(incrementStrokeWidth),
        withLatestFrom(
          this.store.select(selectedShapeList),
          this.store.select(currentDescriptors),
          this.store.select(currentSVGAttributes),
        ),
        switchMap(
          ([{ increment }, selectedShapes, descriptors, svgAttributes]) => {
            console.log('incrementStrokeWidth', increment);
            return selectedShapes
              .filter(
                shapeIRI => descriptors[shapeIRI].type != 'imported-shape',
              )
              .map(shapeIRI => {
                const currentWidth =
                  (svgAttributes[shapeIRI] as SVGAttributes).stroke?.width || 1;

                return setDescriptorValue({
                  IRI: shapeIRI,
                  key: 'stroke',
                  innerKey: 'width',
                  value: currentWidth + increment,
                });
              });
          },
        ),
      ),
    );

    this.newShape$ = createEffect(() =>
      this.actions$.pipe(
        ofType(addNewShapeAction),
        switchMap(({ data }) => [
          setResourcePost({
            IRI: data.IRI,
            resourceType: 'nw:Shape',
          }),
          addShapeToStore({ data }),
        ]),
      ),
    );

    this.addShapeToStore$ = createEffect(() =>
      this.actions$.pipe(
        ofType(addShapeToStore),
        switchMap(({ data }) => {
          const { IRI, literals, relationships } = data;

          const { descriptor } = literals;

          function getScale(descriptor: GeneralShapeDescriptor) {
            switch (descriptor.type) {
              case 'rectangle-shape':
                const { width, height } =
                  descriptor as RectangleShapeDescriptor;
                return { x: width, y: height };
              case 'circle-shape':
                const { rx, ry } = descriptor as CircleShapeDescriptor;
                return { x: 2 * rx, y: 2 * ry };
              case 'imported-shape':
                return descriptor.position.scale;
            }
            return;
          }

          console.log('add-shape-to-store', data);

          return [
            setShape({ shape: data }),
            setRelationship({
              subject: IRI,
              relationship: 'file',
              object: relationships.file as string,
            }),
            setOriginalDescriptor({
              IRI,
              descriptor,
            }),
            setOriginalSVGAttributes({
              IRI: IRI,
              svgAttributes: descriptor.svgAttributes,
            }),
            setShapeOriginalTransform({
              IRI,
              transform: {
                translate:
                  descriptor.position.x && descriptor.position.y
                    ? (_pick(descriptor.position, 'x', 'y') as Translate)
                    : descriptor.position.translate,
                scale: getScale(descriptor),
              },
            }),
            descriptor._animationsByKey &&
              addAnimationsOfShapesToStoreBase({
                animations: [
                  {
                    IRI,
                    animationsById: descriptor._animationsByKey,
                  },
                ],
              }),
          ].filter(val => !!val);
        }),
      ),
    );

    this.deleteShape$ = createEffect(() =>
      this.actions$.pipe(
        ofType(deleteShapeAction),
        withLatestFrom(this.store.select(selectCurrentFileIRI)),
        switchMap(([{ IRI }, fileIRI]) => {
          return [
            setResourceDelete({
              resourceType: 'nw:Shape',
              IRI,
            }),
            removeRelationshipBase({
              subject: fileIRI,
              subjectType: 'nw:Shape',
              relationship: 'shapes',
              object: IRI,
            }),
            deselectShape({ IRI }),
          ];
        }),
      ),
    );

    // -- // -- //
    this.setRelationship$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setRelationship),
        withLatestFrom(
          this.store.select(selectShapes),
          this.store.select(selectFiles),
        ),
        switchMap(
          ([{ subject, relationship, object, objects }, shapes, files]) => {
            objects = objects ? objects : [object];
            let inverse: string;
            switch (relationship) {
              case 'file':
                const shapeIRI = subject;
                const fileIRI = object;
                const currentFileOfShape = shapes[shapeIRI].relationships
                  .file as string;
                return [
                  setRelationshipBase({
                    subject: shapeIRI,
                    subjectType: 'nw:Shape',
                    relationship,
                    object: fileIRI,
                  }),
                  setResourcePatch({
                    IRI: shapeIRI,
                    field: 'relationships',
                    key: relationship,
                    resourceType: 'nw:Shape',
                  }),
                  removeRelationshipBase({
                    subject: currentFileOfShape,
                    subjectType: 'nw:File',
                    relationship: 'shapes',
                    object: shapeIRI,
                  }),
                  setRelationshipBase({
                    subject: fileIRI,
                    subjectType: 'nw:File',
                    relationship: 'shapes',
                    object: shapeIRI,
                    oneToMany: true,
                  }),
                ];
              case 'maskedBy':
                return objects.reduce(
                  (array, object) => [
                    ...array,
                    setRelationshipBase({
                      subject,
                      subjectType: 'nw:Shape',
                      relationship,
                      object,
                    }),
                    saveChangedShapes({ IRI: subject }),
                    setRelationshipBase({
                      subject: object,
                      subjectType: 'nw:Shape',
                      relationship: 'maskChildren',
                      object: subject,
                    }),
                  ],
                  [],
                );
              case 'isInGroup':
                return [
                  setRelationshipBase({
                    subject,
                    subjectType: 'nw:Shape',
                    relationship,
                    object,
                  }),
                  setRelationshipBase({
                    subject: object,
                    subjectType: 'nw:File',
                    relationship: 'groupChildren',
                    object: subject,
                    oneToMany: true,
                  }),
                ];
              case 'layer':
                inverse = 'layerChildren';
                break;
            }
            return objects.reduce(
              (array, object) => [
                ...array,
                setRelationshipBase({
                  subject,
                  subjectType: 'nw:Shape',
                  relationship,
                  object,
                }),
                saveChangedShapes({ IRI: subject }),
                setRelationshipBase({
                  subject: object,
                  subjectType: 'nw:Shape',
                  relationship: 'layerChildren',
                  object: subject,
                }),
              ],
              [],
            );
          },
        ),
      ),
    );

    this.createNewComponent$ = createEffect(() =>
      this.actions$.pipe(
        ofType(createNewComponentAction),
        withLatestFrom(
          this.store.select(_selectedShapes),
          this.store.select(selectCurrentFileIRI),
        ),
        switchMap(([{ IRI, label, descriptor }, shapes, currentFileIRI]) => {
          console.log(
            'create-new-component',
            { IRI, label, descriptor },
            shapes,
          );
          return [
            addFile({
              file: {
                IRI,
                literals: {
                  label,
                  descriptor,
                },
                relationships: {
                  shapes,
                  componentOf: currentFileIRI,
                },
              },
            }),
            setResourcePost({
              IRI,
              resourceType: 'nw:File',
            }),
            ...Object.keys(shapes).map(shapeIRI =>
              setRelationship({
                subject: shapeIRI,
                relationship: 'file',
                object: IRI,
              }),
            ),
          ];
        }),
        catchError(error => {
          console.error('createNewComponentError', error.message);
          return of(error.message);
        }),
      ),
    );

    /**************  CANVAS STATE  ****************/

    this.moveBack$ = createEffect(() =>
      this.actions$.pipe(
        ofType(moveBack),
        withLatestFrom(this.store.select(selectVisitStack)),
        switchMap(([, visitStack]) => {
          return [
            popVisitStack(),
            openFile({ ID: visitStack[visitStack.length - 1] }),
          ];
        }),
      ),
    );

    this.openFile$ = createEffect(() =>
      this.actions$.pipe(
        ofType(openFile),
        withLatestFrom(
          this.store.select(selectFiles),
          this.store.select(selectShapes),
        ),
        switchMap(([{ IRI, ID, importedShape }, files, shapes]) => {
          IRI ||= IdToIRI(ID);
          if (files[IRI]?.relationships?.shape) {
            // This action sets the details of the current file into the store, indirectly to the animation service.
            return importedShape
              ? []
              : [
                  addFileToStore({
                    file: cloneDeep(files[IRI]),
                    shapes,
                  }),
                ];
          }

          return [
            loadFileAction({
              ID: ID || IRI.split('#')[1],
              mode: 'main-file',
            }),
          ];
        }),
      ),
    );

    this.bulkUpdateFinish$ = createEffect(() =>
      this.actions$.pipe(
        ofType(bulkUpdateFinish),
        withLatestFrom(this.store.select(selectBulkUpdates)),
        switchMap(([, data]) => {
          // this is where the undo/redo logic comes
          return [applyBulkShapeUpdates({ data })];
        }),
      ),
    );

    // this.resetBaseState$ = createEffect(() =>
    //   this.actions$.pipe(
    //     ofType(resetBaseState),
    //     withLatestFrom(
    //       this.store.select(baseShapeTransforms),
    //       this.store.select(baseSvgAttributes),
    //     ),
    //     switchMap(([, shapeTransforms]) => {
    //       // TODO - implement for other attributes like fill, stroke
    //       // console.log('resetBaseState', { shapeTransforms });
    //       const transforms = Object.entries(shapeTransforms)
    //         .filter(([shapeIRI]) => !shapeIRI.includes('_'))
    //         .map(([shapeIRI, transform]) =>
    //           setBaseShapeTransform({ IRI: shapeIRI, transform }),
    //         );
    //       return [...transforms, setCurrentAnimationIdBase({ id: undefined })];
    //     }),
    //   ),
    // );
  }
}
