import { BaseVector, Vector } from '../../../../../elements/base/vector/vector';
import { Coords } from '../../../../../elements/resource/types/shape.type';
import { PointController } from '../../../../../elements/util/point-controller/point-controller';
import {
  Easing,
  IncrementController,
} from '../../../../animation/frame/increment/controller/increment.controller';
import { PathElement } from '../../primitive/path-element';
import { HandShapeSectionDescriptorNew } from '../hand-shape-next/hand-shape-next';
import { ArcItem, PathItem } from '../path-shape.types';
import { HandShape } from './hand-shape';

export interface HandSectionNewConfig {
  x?: number;
  y?: number;
  r: number;

  flat?: boolean;
}

const { PI, sqrt, pow, asin } = Math;

export class HandSection {
  xIncrement: IncrementController;
  yIncrement: IncrementController;

  pc: PointController;

  nextAngle: number;

  prevAngle: number;

  topStroke: PathElement;
  sideStroke: PathElement;
  bottomStroke: PathElement;

  get amIFirst() {
    return this.index == 0;
  }

  get amILast() {
    return this.index == this.handShape.lastSectionIndex;
  }

  get x() {
    return this.config.x || 0;
  }

  get y() {
    return this.config.y || 0;
  }

  set x(val: number) {
    this.config.x = val;
  }

  set y(val: number) {
    this.config.y = val;
  }

  get r() {
    return this.config.r || 0;
  }
  set r(val: number) {
    this.config.r = val;
  }

  get flat() {
    return this.config.flat || 0;
  }

  get sections() {
    return this.handShape.handSections;
  }

  get prev(): HandSection {
    if (0 < this.index) {
      return this.sections[this.index - 1];
    }
    return this.handShape.isClosed
      ? this.sections[this.sections.length - 1]
      : this.next;
  }

  get prevStrict(): HandSection {
    if (0 < this.index) {
      return this.sections[this.index - 1];
    }
    return this.handShape.isClosed
      ? this.sections[this.sections.length - 1]
      : undefined;
  }

  get next(): HandSection {
    if (this.index < this.sections.length - 1) {
      return this.sections[this.index + 1];
    }
    return this.handShape.isClosed ? this.sections[0] : this.prev;
  }

  get nextStrict(): HandSection {
    if (this.index < this.sections.length - 1) {
      return this.sections[this.index + 1];
    }
    return this.handShape.isClosed ? this.sections[0] : undefined;
  }

  get id() {
    return `section.${this.index}`;
  }

  get coords(): Coords {
    return [this.x, this.y];
  }

  get isCW() {
    if (this.handShape.handSections.length == 2) {
      return false;
    }

    if (!this.prevStrict) {
      return this.next.isCW;
    }

    if (!this.nextStrict) {
      return this.prev.isCW;
    }

    const [px, py] = this.prev.coords;
    const [nx, ny] = this.next.coords;

    const a1 = this.handShape.getAngle(px - this.x, py - this.y);
    const a2 = this.handShape.getAngle(nx - this.x, ny - this.y);

    if (a1 < PI) {
      return a1 < a2 ? a2 < a1 + PI : false;
    } else {
      return a1 < a2 || a2 < a1 - PI;
    }
  }

  get curve() {
    return isNaN(this.config.curve) ? 0 : (this.config.curve * PI) / 4;
  }

  get prevVectorCoords(): Coords {
    return [this.prev.x - this.x, this.prev.y - this.y];
  }

  get prevVectorLength() {
    const [x, y] = this.prevVectorCoords;
    return sqrt(pow(x, 2) + pow(y, 2));
  }
  get nextVectorCoords(): Coords {
    return [this.next.x - this.x, this.next.y - this.y];
  }

  get nextVectorLength() {
    const [x, y] = this.nextVectorCoords;
    return sqrt(pow(x, 2) + pow(y, 2));
  }

  rPc: PointController;

  baseDragMode = false;
  constructor(
    private readonly handShape: HandShape,
    public config: HandShapeSectionDescriptorNew,
    public index: number,
  ) {
    this.baseDragMode = !this.handShape.isClosed && this.index == 0;
    this.pc = new PointController(
      this.handShape,
      {
        p: [config.x || 0, config.y || 0],
        pOffset: [0, 0],
        controlPoint: {
          shapeIRI: this.handShape.IRI,
          name: this.id,
        },
        start: () => this.handShape._startDrag(this.id),
        drag: ({ x, y, dx, dy }) => {
          if (this.amIFirst) this.pc.patch({ p: [0, 0] });
          this.handShape._drag(x, y, dx, dy);
        },
        end: () => {
          this.pc.patch({ p: [this.x, this.y] });
          this.handShape._endDrag();
        },
        dragOnto: params => {
          if (!params) {
            return;
          }
          const { name: point, shapeIRI } = params;
          this.handShape.addConstraint(this.id, shapeIRI, point);
        },
      },
      this.handShape.circleContainer,
      this.handShape.auxCircleContainer,
    );
    this.pc.hide();
  }

  unMovedR: number;

  showRC() {
    this.pc.show();
    this.rPc.show();
  }

  hideRC() {
    this.pc.hide();
    this.rPc.hide();
  }

  afterInit() {
    let vector: Vector;

    if (this.amIFirst) {
      vector = new BaseVector([this.next.x, this.next.y]);
      vector.rotate(Math.PI / 2);
    } else if (this.amILast) {
      vector = new BaseVector([this.x - this.prev.x, this.y - this.prev.y]);
      vector.rotate(Math.PI / 2);
    } else {
      vector = new BaseVector([-this.x, -this.y]).add(
        new BaseVector([this.next.x - this.x, this.next.y - this.y]),
      );
      vector.rotate(Math.PI);
    }

    vector.reScale(this.r);

    this.rPc = new PointController(
      this.handShape,
      {
        // p: [vector.x, vector.y],
        pOffset: [this.x, this.y],
        angle: vector.getAngle(),
        updateCircle: false,
        d: this.r,
        start: () => {
          this.unMovedR = this.r;
        },
        drag: ({ d }) => {
          this.r = d;
          this.handShape.refresh();
        },
        end: () => {
          this.rPc.patch({
            d: this.r,
          });
          this.handShape.saveSections();
        },
      },
      this.handShape.circleContainer,
      this.handShape.auxCircleContainer,
    );

    this.rPc.hide();
  }

  save() {
    this.handShape.updateSection(this.index, this.config);
  }

  refreshPC() {
    this.pc.patch({
      p: [this.x, this.y],
    });

    let vector: Vector;

    if (this.amIFirst) {
      vector = new BaseVector([this.next.x, this.next.y]);
      vector.rotate(Math.PI / 2);
    } else if (this.amILast) {
      vector = new BaseVector([this.x - this.prev.x, this.y - this.prev.y]);
      vector.rotate(Math.PI / 2);
    } else {
      vector = new BaseVector([-this.x, -this.y]).add(
        new BaseVector([this.next.x - this.x, this.next.y - this.y]),
      );
      vector.rotate(Math.PI);
    }

    vector.reScale(this.r);

    this.rPc.patch({
      pOffset: [this.x, this.y],
      angle: vector.getAngle(),
      d: this.r,
    });
  }

  offset: Coords;
  lineStart: Coords;
  lineEnd: Coords;

  prevV: Vector;
  nextV: Vector;
  prevDiffAngle: number;
  nextDiffAngle: number;

  calcAngles() {
    // -- // -- // -- // -- // -- // -- //

    const cc = !this.isCW;

    this.prevV = new BaseVector(this.prevVectorCoords).reScale(this.r);
    const prevAngle = asin((this.prev.r - this.r) / this.prevVectorLength);

    let prevCurve = this.prev.curve;

    if (this.amIFirst && !this.handShape.isClosed) {
      prevCurve = this.handShape.curve || 0;
    }

    cc
      ? this.prevV.rotate(Math.PI / 2 + prevAngle + prevCurve)
      : this.prevV.rotate(-Math.PI / 2 - prevAngle - prevCurve);

    this.prevAngle = this.prevV.getAngle();

    const nextL = this.nextVectorLength;

    this.nextV = new BaseVector(this.nextVectorCoords).reScale(this.r);
    const nextAngleDiff = Math.asin((this.next.r - this.r) / nextL);

    cc
      ? this.nextV.rotate(-Math.PI / 2 - nextAngleDiff - this.curve)
      : this.nextV.rotate(Math.PI / 2 + nextAngleDiff + this.curve);
    this.nextAngle = this.nextV.getAngle();
  }
  getElements(): PathItem[] {
    const cc = !this.isCW;

    const elements = [];

    let [ox, oy] = [0, 0];

    if (this.index == 0) {
      ox = this.prevV.x;
      oy = this.prevV.y;

      this.offset = [ox, oy];
      this.lineStart = [this.nextV.x, this.nextV.y];

      elements.push({
        type: 'arc',
        // a1: cc ? this.nextAngle : this.prevAngle,
        // a2: cc ? this.prevAngle : this.nextAngle,
        a1: this.prevAngle,
        a2: this.nextAngle,
        antiClockwise: !cc,
        offset: [ox, oy],
        CX: 0,
        CY: 0,
        x: this.nextV.x,
        y: this.nextV.y,
        r: this.r,
      } as ArcItem);
    } else {
      this.offset = this.prev.lineEnd;
      const [ex, ey] = this.prev.lineEnd;
      this.lineStart = [
        ex - this.prevV.x + this.r * Math.cos(this.nextAngle),
        ey - this.prevV.y + this.r * Math.sin(this.nextAngle),
      ];

      if (this.flat) {
        elements.push({
          type: 'line',
          x: -this.prevV.x + this.nextV.x,
          y: -this.prevV.y + this.nextV.y,
        });
      } else {
        elements.push({
          type: 'arc',
          a1: this.prevAngle,
          a2: this.nextAngle,
          cx: -this.prevV.x,
          cy: -this.prevV.y,
          x: -this.prevV.x + this.nextV.x,
          y: -this.prevV.y + this.nextV.y,
          r: this.r,
          antiClockwise: !cc,
        } as ArcItem);
      }
    }

    let x = -this.nextV.x;
    let y = -this.nextV.y;

    x += this.next.x - this.x;
    y += this.next.y - this.y;

    x += this.next.prevV.x;
    y += this.next.prevV.y;

    if (this.amILast && !this.handShape.isClosed) {
      const section = this.prev;

      const v1 = new BaseVector(this.prev.prevVectorCoords).reScale(
        this.prev.r,
      );
      const v2 = new BaseVector(this.prev.nextVectorCoords).reScale(
        this.prev.r,
      );

      this.lineEnd = [section.x + v1.x + v2.x, section.y + v1.y + v2.y];

      elements.push({
        type: 'line',
        X: this.lineEnd[0],
        Y: this.lineEnd[1],
      });
    } else {
      const [_x, _y] = this.lineStart;
      this.lineEnd = [x + _x, y + _y];

      // console.log('first-nextV-angle', this.nextV.getAngle());
      this.nextV.rotate(cc ? PI / 2 : -PI / 2);

      const vLength = this.nextVectorLength / 2;
      this.nextV.reScale(vLength);

      const vector = this.next.prevV.copy();
      vector.rotate(PI / 2);

      // console.log('_x', _x, '_y', _y);
      // console.log('this.nextV.x', this.nextV.x, 'this.nextV.y', this.nextV.y);

      elements.push({
        type: 'curve',
        // bx: this.nextV.x + (this.amIFirst ? -ox : 0),
        // by: this.nextV.y + (this.amIFirst ? -oy : 0),
        bx: -ox + this.nextV.x,
        by: -oy + this.nextV.y,
        cx: cc ? -vector.x : vector.x,
        cy: cc ? -vector.y : vector.y,
        x: x - ox,
        y: y - oy,
      });
    }

    return elements;
  }

  startAnimation(config: HandSectionNewConfig, division: number) {
    const { x, y } = config;
    this.xIncrement = new IncrementController(
      this.x,
      x,
      division,
      Easing.LINEAR,
    );

    this.yIncrement = new IncrementController(
      this.y,
      y,
      division,
      Easing.LINEAR,
    );
  }

  incrementAnimation(increment: number) {
    if (this.amIFirst) {
      return;
    }
    this.x = this.xIncrement.increment(increment);
    this.y = this.yIncrement.increment(increment);
  }

  remove() {
    this.pc?.remove();
    this.removeStrokeElements();
  }

  removeStrokeElements() {
    this.topStroke?.remove();
    this.sideStroke?.remove();
    this.bottomStroke?.remove();
  }

  initStrokeElements() {
    this.removeStrokeElements();
    let X: number, Y: number, offset: Coords;

    if (!this.config.stroke) {
      return;
    }

    const { color, width, top, side, bottom } = this.config.stroke;
    if (top) {
      const { start: st, end: et } = top;

      const vector = new Vector(
        {
          x: this.lineStart[0],
          y: this.lineStart[1],
        },
        {
          x: this.lineEnd[0],
          y: this.lineEnd[1],
        },
      );

      let [ox, oy] = this.lineStart;
      const [X, Y] = this.lineEnd;

      if (!isNaN(st) && !isNaN(et)) {
        // -- //
        if (st != 0) {
          const v = vector.copy();
          v.flip();
          v.scale(1 - st);
          ox = v.end.x;
          oy = v.end.y;
        }
      }

      this.topStroke = new PathElement(
        this.handShape,
        this.handShape.container,
        {
          elements: [
            {
              type: 'line',
              offset: [ox, oy],
              X,
              Y,
            },
          ],
          stroke: this.handShape.service.resolveColor(color),
          'stroke-width': width,
        },
      );
    }

    if (side) {
      const { start: ss, end: es } = side;
      // -- // -- //

      if (this.flat) {
        offset = this.offset;
        X = this.lineStart[0];
        Y = this.lineStart[1];
        if (ss != 0 || es != 1) {
          const vector = new Vector(
            {
              x: offset[0],
              y: offset[1],
            },
            {
              x: X,
              y: Y,
            },
          );
          vector.scale(es);
          X = vector.end.x;
          Y = vector.end.y;
        }

        this.sideStroke = new PathElement(
          this.handShape,
          this.handShape.container,
          {
            elements: [
              {
                type: 'line',
                offset,
                X,
                Y,
              },
            ],
            stroke: this.handShape.service.resolveColor(color),
            'stroke-width': width,
          },
        );
      }
    }

    if (bottom) {
      const { start: sb, end: eb } = bottom;

      offset = this.handShape.lastHandSection.lineEnd;

      let X = this.handShape.firstHandSection.offset[0];
      let Y = this.handShape.firstHandSection.offset[1];

      if (sb != 0 || eb != 1) {
        const vector = new Vector(
          {
            x: offset[0],
            y: offset[1],
          },
          {
            x: X,
            y: Y,
          },
        );
        vector.scale(eb);
        X = vector.end.x;
        Y = vector.end.y;
      }
      this.bottomStroke = new PathElement(
        this.handShape,
        this.handShape.container,
        {
          elements: [
            {
              type: 'line',
              offset,
              X,
              Y,
            },
          ],
          stroke: this.handShape.service.resolveColor(color),
          'stroke-width': width,
        },
      );
    }
  }
}
