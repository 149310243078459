import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  getCombinedColorPalette,
  getProjectColorPalette,
} from '../../../projects/project.selector';
import { ShapeService } from '../../../element-editor/shape/shape.service';

@Component({
  selector: 'nw-color-select',
  templateUrl: './color-select.component.html',
  styleUrls: ['./color-select.component.scss'],
})
export class ColorSelectComponent implements OnInit, OnDestroy {
  @Input()
  colors: string[] = [];

  @Input()
  noOptionSelect: boolean;

  @Input()
  noVariableSelect: boolean;

  colorPalette$: Observable<Array<{ label: string; color: string }>>;

  colorLibClosed: number;
  colorOptionsClosed: number;

  get isFormula() {
    return this.colors?.[0]?.startsWith('<');
  }

  get formula() {
    return this.colors?.[0]?.slice(1);
  }

  setFormula() {
    this.currentColor = '<random(#123, #456)';
  }

  get notNullColors() {
    return this.colors.filter(c => !!c && c !== 'undefined' && c !== 'null');
  }

  colorPalette: Record<string, string> = {};

  get currentColor() {
    if (this.colors?.length == 1) {
      const color = this.colors[0];
      if (color?.startsWith?.('$')) {
        return this.colorPalette?.[color.slice(2)];
      }
      return color;
    }
    return '';
  }

  currentColor$: Observable<string>;

  inputChange(event: Event) {
    this.currentColor = ('<' +
      (event.target as HTMLInputElement).value) as string;
  }

  set currentColor(val: string) {
    this.changed.emit(val);
  }

  colorLibSelectorOpen = false;
  colorSelectorOpen = false;

  @Output()
  changed = new EventEmitter<string>();

  sub: Subscription;
  subCb: Subscription;

  constructor(
    public readonly cs: CanvasService,
    public readonly shapeService: ShapeService,
    public readonly store: Store,
  ) {}

  ngOnInit(): void {
    if (!this.noOptionSelect) {
      this.colorPalette$ = this.store.select(getCombinedColorPalette);
      this.subCb = this.colorPalette$.subscribe(
        cb =>
          (this.colorPalette = cb.reduce((object, { label, color }) => {
            object[label] = color;
            return object;
          }, {})),
      );
    }

    this.sub = this.cs.generalEventSubscribe('globalClick', () => {
      this.colorLibSelectorOpen = false;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.subCb?.unsubscribe();
  }

  colorLibSelected({ label, color }: { label: string; color: string }) {
    this.changed.emit(`$.${label}`);
  }

  colorSelected(key: string) {
    this.changed.emit(key);
  }

  colorlibSelectorClicked() {
    this.colorOptionsClosed = Math.random();
  }

  colorOptionSelectorClicked() {
    this.colorLibClosed = Math.random();
  }
}
