<div class="animation-action-component editor-container">
  <div class="editor-container-header">Effects</div>
  <div
    class="editor-container-body"
    *ngIf="areThereSelectedShapes$ | async; else noFileSeelcted"
  >
    <iw-show-hide-animation></iw-show-hide-animation>
    <iw-float-effect-attribute></iw-float-effect-attribute>
    <nw-translate-effect></nw-translate-effect>
    <iw-rotate-effect-attribute></iw-rotate-effect-attribute>
    <iw-trajectory-animation></iw-trajectory-animation>
    <!-- <nw-path-animation *ngIf="onlyPathShapes$ | async"></nw-path-animation> -->
    <ng-container *ngIf="onlyPathShapes$ | async">
      <nw-path-fill-animation></nw-path-fill-animation>
      <nw-subpath-move-animation></nw-subpath-move-animation>
      <nw-dash-move-animation></nw-dash-move-animation>
    </ng-container>

    <iw-color-blink-animation></iw-color-blink-animation>
  </div>
  <ng-template #noFileSeelcted>
    <div class="fs-11 w-100 p-6 italic" style="text-align: center">
      Please select a shape
    </div>
  </ng-template>
</div>
