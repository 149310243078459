import { Container } from 'pixi.js';
import { ShapeService } from '../shape.service';
import { RectangleElement } from '../shapes/primitive/rectangle-element';
import { TextElement } from '../shapes/primitive/text-element';
import { GeneralShape } from '../shapes/general/general-shape';
import { _screenX, _screenY } from '../../../services/canvas/canvas.service';

export class SubtitleElement extends GeneralShape {
  // : TextElement;
  background: RectangleElement;

  _element: TextElement;

  constructor(service: ShapeService, text: string) {
    super(
      service,
      {
        IRI: Math.random().toString(),
      },
      {},
    );

    this.container = new Container();

    this.background = new RectangleElement(this, this.container, {
      width: 0,
      height: 0,
      fill: '#000000',
      r: 4,
    });

    this._element = new TextElement(this, this.container, {
      text,
      fontSize: 28,
      fontStyle: 'normal',
      position: { x: 300, y: 300 },
      stroke: '#fff',
      fill: '#fff',
      x: 6,
      y: 6,
    });

    let { width, height } = this._element.getBounds();

    width += 12;
    height += 12;

    this.background.patch({ width, height });

    // const [_w, _h] = [1470, 810].map(val => val * this.cs.canvasScale);
    const [_w, _h] = [_screenX, _screenY];
    this.container.setTransform(_w / 2 - width / 2, _h - height - 24);

    this.service.cs.app.stage.addChild(this.container);
  }

  delete() {
    this.container.destroy();
  }
}
