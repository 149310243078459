import { at, cloneDeep } from 'lodash';
import { BaseVector, Vector } from '../../../../../elements/base/vector/vector';
import {
  Coords,
  DragConstraintInstance,
} from '../../../../../elements/resource/types/shape.type';
import { PointController } from '../../../../../elements/util/point-controller/point-controller';
import {
  Easing,
  IncrementController,
} from '../../../../animation/frame/increment/controller/increment.controller';
import { Circle } from '../../primitive/circle-element';
import { PathElement } from '../../primitive/path-element';
import { ArcItem, CurveItem, PathItem } from '../path-shape.types';
import {
  HandShapeNext,
  HandShapeSectionDescriptorNew,
} from './hand-shape-next';
import { rotate } from 'mathjs';
import { GeneralShape } from '../../general/general-shape';

const { PI, sqrt, pow, asin } = Math;

export class HandSectionNext {
  xIncrement: IncrementController;
  yIncrement: IncrementController;

  pc: PointController;

  nextAngle: number;

  prevAngle: number;

  topStroke: PathElement;
  sideStroke: PathElement;
  bottomStroke: PathElement;

  startCircle: Circle;
  endCircle: Circle;
  cornerCircle: Circle;

  get amIFirst() {
    return this.index == 0;
  }

  get amILast() {
    return this.index == this.handShape.lastSectionIndex;
  }

  get x() {
    return this.config.x || 0;
  }

  get y() {
    return this.config.y || 0;
  }

  set x(val: number) {
    this.config.x = val;
  }

  set y(val: number) {
    this.config.y = val;
  }

  get r() {
    return this.config.r || 0;
  }
  set r(val: number) {
    this.config.r = val;
  }

  get flat() {
    if (!this.amIFirst && !this.amILast) {
      return false;
    }
    return this.config.end == 'flat';
  }

  get d1() {
    return ((this.config.d1 || 0) * PI) / 2;
  }

  get d2() {
    return this.config.d2 || 0;
  }

  get corner() {
    return !!this.config.corner;
  }

  get sections() {
    return this.handShape.handSections;
  }

  get prev(): HandSectionNext {
    if (0 < this.index) {
      return this.sections[this.index - 1];
    }
    return this.handShape.isClosed
      ? this.sections[this.sections.length - 1]
      : this.next;
  }

  get relX() {
    return this.x - this.prev.x;
  }

  get relY() {
    return this.y - this.prev.y;
  }

  get prevStrict(): HandSectionNext {
    if (0 < this.index) {
      return this.sections[this.index - 1];
    }
    return this.handShape.isClosed
      ? this.sections[this.sections.length - 1]
      : undefined;
  }

  get next(): HandSectionNext {
    if (this.index < this.sections.length - 1) {
      return this.sections[this.index + 1];
    }
    return this.handShape.isClosed ? this.sections[0] : this.prev;
  }

  get _next(): HandSectionNext {
    if (this.index < this.sections.length - 1) {
      return this.sections[this.index + 1];
    }
  }

  get nextStrict(): HandSectionNext {
    if (this.index < this.sections.length - 1) {
      return this.sections[this.index + 1];
    }
    return this.handShape.isClosed ? this.sections[0] : undefined;
  }

  get id() {
    return `section.${this.index}`;
  }

  get coords(): Coords {
    return [this.x, this.y];
  }

  get isCW() {
    if (this.handShape.handSections.length == 2) {
      return false;
    }

    if (!this.prevStrict) {
      return this.next.isCW;
    }

    if (!this.nextStrict) {
      return this.prev.isCW;
    }

    const [px, py] = this.prev.coords;
    const [nx, ny] = this.next.coords;

    const a1 = this.handShape.getAngle(px - this.x, py - this.y);
    const a2 = this.handShape.getAngle(nx - this.x, ny - this.y);

    if (a1 < PI) {
      return a1 < a2 ? a2 < a1 + PI : false;
    } else {
      return a1 < a2 || a2 < a1 - PI;
    }
  }

  get curve() {
    return isNaN(this.config.curve) ? 0 : (this.config.curve * PI) / 4;
  }

  get prevVectorCoords(): Coords {
    if (this.amIFirst) {
      return [this.next.x, this.next.y];
    }
    return [-this.x, -this.y];
  }

  get prevVectorLength() {
    const [x, y] = this.prevVectorCoords;
    return sqrt(pow(x, 2) + pow(y, 2));
  }
  get nextVectorCoords(): Coords {
    if (this.amILast) {
      return [-this.x, -this.y];
    }
    return [this.next.x, this.next.y];
  }

  get nextVectorLength() {
    const [x, y] = this.nextVectorCoords;
    return sqrt(pow(x, 2) + pow(y, 2));
  }

  rPc: PointController;

  baseDragMode = false;

  get cs() {
    return this.handShape.cs;
  }

  constructor(
    public readonly handShape: HandShapeNext,
    public config: HandShapeSectionDescriptorNew,
    public index: number,
  ) {
    this.baseDragMode = !this.handShape.isClosed && this.index == 0;
  }

  unMovedR: number;
  showRC() {
    this.pc.show();
    this.rPc.show();
  }

  hideRC() {
    this.pc.hide();
    this.rPc.hide();
  }

  get absoluteX() {
    return this.handShape.x + this.offsetX + this.x;
  }

  get absoluteY() {
    return this.handShape.y + this.offsetY + this.y;
  }

  get absX() {
    return this.offsetX + this.x;
  }

  get absY() {
    return this.offsetY + this.y;
  }

  get offsetX() {
    if (this.amIFirst) {
      return 0;
    }
    return this.prev.absX;
  }

  get offsetY() {
    if (this.amIFirst) {
      return 0;
    }
    return this.prev.absY;
  }

  afterInit() {
    let vector: Vector;

    if (this.amIFirst) {
      vector = new BaseVector([this.next.x, this.next.y]);
      vector.rotate(Math.PI / 2);
    } else if (this.amILast) {
      vector = new BaseVector([this.x, this.y]);
      vector.rotate(Math.PI / 2);
    } else {
      vector = new BaseVector([-this.x, -this.y]).add(
        new BaseVector([this.next.x - this.x, this.next.y - this.y]),
      );
      vector.rotate(Math.PI);
    }

    vector.reScale(this.r);

    this.rPc = new PointController(
      this.handShape,
      {
        // p: [vector.x, vector.y],
        pOffset: [this.absX, this.absY],
        angle: vector.getAngle(),
        updateCircle: true,
        d: this.r,
        start: () => {
          this.unMovedR = this.r;
        },
        drag: ({ d }) => {
          this.r = d;
          this.handShape.refresh();
        },
        end: () => {
          this.rPc.patch({
            d: this.r,
          });
          this.handShape.saveSections();
        },
      },
      this.handShape.circleContainer,
      this.handShape.auxCircleContainer,
    );

    this.rPc.hide();

    this.pc = new PointController(
      this.handShape,
      {
        p: [this.x, this.y],
        pOffset: [this.offsetX, this.offsetY],
        updateCircle: false,
        controlPoint: {
          shapeIRI: this.handShape.IRI,
          name: this.id,
        },
      },
      this.handShape.circleContainer,
      this.handShape.auxCircleContainer,
    );
    this.pc.hide();
  }

  save() {
    this.handShape.updateSection(this.index, this.config);
  }

  dragBase: Coords;
  absDragBase: Coords;
  dragBaseAngle: number;
  dragBaseLength: number;
  dragBaseVector: BaseVector;
  constraints: DragConstraintInstance[];

  rotateMode = false;
  startDrag(shapeStore: Record<string, boolean> = {}, rotateMode = false) {
    this.rotateMode = rotateMode;

    /* 
    if (this.index == 0) {
      console.log('hs-startDrag 0', { rotateMode });
    }
    if (this.index == 1) {
      console.log('hs-startDrag 1', { rotateMode });
    }
    */
    this.dragBase = [this.x, this.y];
    this.absDragBase = [this.absoluteX, this.absoluteY];
    this.dragBaseAngle = this.handShape.getAngle(this.x, this.y);
    this.dragBaseLength = this.l;
    this.dragBaseVector = new BaseVector([this.x, this.y]);

    this.constraints = this.handShape.getConstraintsByPoint(
      this.id,
      {
        [this.handShape.IRI]: true,
        ...shapeStore,
      },
      {
        rotate: true,
      },
    );

    this.constraints.map(({ shape, point }) => {
      shape.startDragBy(
        point,
        {
          [this.handShape.IRI]: true,
          ...shapeStore,
        },
        {
          rotate: true,
        },
      );
    });
    // -- // -- // -- // -- // -- // -- //
    this._next?.startDrag(shapeStore, rotateMode);
  }

  drag(dx: number, dy: number, dAngle?: number) {
    // -- // -- //
    this.x = this.dragBase[0] + dx;
    this.y = this.dragBase[1] + dy;
    this.refreshPCs();

    const angleDiff =
      dAngle !== undefined
        ? dAngle
        : this.handShape.getAngle(this.x, this.y) - this.dragBaseAngle;

    this.constraints.map(({ shape, point }) => {
      shape.dragBy(point, [dx, dy], angleDiff);
    });

    if (this.rotateMode) {
      // if (this.index == 0) {
      //   console.log('hs > drag 0', angleDiff);
      // }

      this._next?.dragByAngle(angleDiff);
    } else {
      this._next?.drag(dx, dy);
    }
  }

  dragByAngle(angleDiff: number) {
    // if (this.index == 1) {
    //   console.log('hs(1) > dragByAnggle', angleDiff);
    // }

    const currentAngle = this.dragBaseAngle + angleDiff;
    this.x = this.dragBaseLength * Math.cos(currentAngle);
    this.y = this.dragBaseLength * Math.sin(currentAngle);

    const [x0, y0] = this.absDragBase;
    this.constraints.map(({ shape, point }) => {
      // -- // -- //
      // this is the [dx, dy] of the i-th point

      shape.dragBy(
        point,
        [
          this.handShape.currentPosition.x + this.absX - x0,
          this.handShape.currentPosition.y + this.absY - y0,
        ],
        angleDiff,
      );
    });
    // console.log('hs > drag-by-angle', this.index, angleDiff);
    this._next?.dragByAngle(angleDiff);
    this.refreshPCs();
  }

  endDrag() {
    this.constraints?.map(({ shape, point }) => {
      shape.endDragBy(point);
    });
    this._next?.endDrag();
  }

  refreshPCs() {
    this.pc.patch({
      pOffset: [this.offsetX, this.offsetY],
      p: [this.x, this.y],
    });

    let vector: Vector;

    if (this.amIFirst) {
      vector = new BaseVector([this.next.x, this.next.y]);
      vector.rotate(Math.PI / 2);
    } else if (this.amILast) {
      vector = new BaseVector([this.x, this.y]);
      vector.rotate(Math.PI / 2);
    } else {
      vector = new BaseVector([-this.x, -this.y]).add(
        new BaseVector([this.next.x - this.x, this.next.y - this.y]),
      );
      vector.rotate(Math.PI);
    }

    vector.reScale(this.r);

    this.rPc.patch({
      pOffset: [this.absX, this.absY],
      angle: vector.getAngle(),
      d: this.r,
    });
  }

  offset: Coords;
  lineStart: Coords;
  lineEnd: Coords;

  prevV: Vector;
  nextV: Vector;
  prevDiffAngle: number;
  nextDiffAngle: number;

  startPoint: Coords;
  endPoint: Coords;
  cornerPoint: Coords;

  cornerLineStart: Coords;
  cornerLineEnd: Coords;

  d1StartVector: Vector;
  d1EndVector: Vector;
  d2StartVector: Vector;
  d2EndVector: Vector;

  // These variables are relevant by the inner sections
  forwardMode: 'arc' | 'corner';

  get backwardMode() {
    return this.forwardMode == 'arc' ? 'corner' : 'arc';
  }

  get end() {
    return this.config.end;
  }

  get joint() {
    return this.config.joint;
  }

  get l() {
    return Math.sqrt(Math.pow(this.x, 2) + Math.pow(this.y, 2));
  }

  calcAngles() {
    this.prevV = new BaseVector(this.prevVectorCoords);
    this.nextV = new BaseVector(this.nextVectorCoords);

    this.prevDiffAngle = asin((this.prev.r - this.r) / this.prevV.length);
    this.nextDiffAngle = asin((this.next.r - this.r) / this.nextV.length);

    this.prevV.reScale(this.r);
    this.nextV.reScale(this.r);

    const length = this.l;

    if (this.amIFirst) {
      // -- // -- //
      this.prevV.rotate(PI / 2);
      this.nextV.rotate(-PI / 2);

      switch (this.end) {
        case 'arc':
          // console.log('end.arc', this.index, this.prevDiffAngle);
          // this.prevV.rotate(this.prevDiffAngle + this.prev.d2);
          this.prevV.rotate(this.prevDiffAngle + this.next.d2);
          this.nextV.rotate(-this.prevDiffAngle - this.next.d1);
          break;
        case 'half-arc':
        case 'half-arc-inverse':
          break;
        case 'flat':
          break;
      }

      this.startPoint = [
        this.offsetX + this.x + this.prevV.x,
        this.offsetY + this.y + this.prevV.y,
      ];
      this.cornerPoint = this.startPoint;
      this.prevAngle = this.prevV.getAngle();
      this.endPoint = [
        this.offsetX + this.x + this.nextV.x,
        this.offsetY + this.y + this.nextV.y,
      ];
      this.nextAngle = this.nextV.getAngle();
    } else if (this.amILast) {
      this.prevV.rotate(PI / 2);
      this.nextV.rotate(-PI / 2);
      // -- // -- //
      switch (this.end) {
        case 'arc':
          // console.log('end.arc', this.index, this.prevDiffAngle);
          // this.prevV.rotate(this.prevDiffAngle + this.prev.d2);
          this.prevV.rotate(this.prevDiffAngle + this.d1);
          this.nextV.rotate(-this.prevDiffAngle - this.d2);
          break;
        case 'half-arc':
        case 'half-arc-inverse':
          break;
        case 'flat':
          break;
      }

      this.startPoint = [
        this.offsetX + this.x + this.prevV.x,
        this.offsetY + this.y + this.prevV.y,
      ];
      this.prevAngle = this.prevV.getAngle();
      this.endPoint = [
        this.offsetX + this.x + this.nextV.x,
        this.offsetY + this.y + this.nextV.y,
      ];
      this.nextAngle = this.nextV.getAngle();

      // The first cannot have currently a d1
    } else {
      // rescale is needed to make it uniform

      const a1 = this.prevV.getAngle();
      const a2 = this.nextV.getAngle();

      const v = new BaseVector(
        [this.prevV.x + this.nextV.x, this.prevV.y + this.nextV.y].map(
          v => v / 2,
        ) as Coords,
      ).reScale(this.r);

      this.cornerPoint = [
        this.offsetX + this.x + v.x,
        this.offsetY + this.y + v.y,
      ];

      let cw = false;
      if (a1 < PI) {
        cw = a1 < a2 ? a2 < a1 + PI : false;
      } else {
        cw = a1 < a2 || a2 < a1 - PI;
      }

      if (cw) {
        this.forwardMode = 'corner';

        this.prevV.rotate(-PI / 2 - this.prevDiffAngle - this.d2);
        this.nextV.rotate(PI / 2 + this.nextDiffAngle + this.next.d2);

        this.endPoint = [
          this.offsetX + this.x + this.prevV.x,
          this.offsetY + this.y + this.prevV.y,
        ];
        this.nextAngle = this.prevV.getAngle();

        switch (this.joint) {
          case 'symm':
            break;
          case 'assym':
            // -- // -- // -- /_
            // console.log('assym > corner');
            const _prevV = this.prevV.copy();
            const vector = _prevV.copy().rotate(-PI / 2);
            this.cornerLineStart = [
              this.offsetX + this.x + this.prevV.x + vector.x,
              this.offsetY + this.y + this.prevV.y + vector.y,
            ];
            this.cornerLineEnd = this.endPoint;
            this.nextAngle = vector.getAngle();

            // -- // -- //
            break;
          case 'assym-inverse':
            // -- //
            // const _prevV = this.prevV.copy();
            // const vector = _prevV.copy().rotate(-PI / 2);
            // this.cornerLineStart = [
            //   this.x + this.prevV.x + vector.x,
            //   this.y + this.prevV.y + vector.y,
            // ];
            // this.cornerLineEnd = this.endPoint;
            // this.nextAngle = vector.getAngle();

            // -- // -- //
            break;
        }

        this.startPoint = [
          this.offsetX + this.x + this.nextV.x,
          this.offsetY + this.y + this.nextV.y,
        ];
        this.prevAngle = this.nextV.getAngle();
      } else {
        // outer point with arc

        this.forwardMode = 'arc';

        this.prevV.rotate(PI / 2 + this.prevDiffAngle + this.d1);
        this.nextV.rotate(-PI / 2 - this.nextDiffAngle - this.next.d1);

        this.startPoint = [
          this.offsetX + this.x + this.prevV.x,
          this.offsetY + this.y + this.prevV.y,
        ];
        this.prevAngle = this.prevV.getAngle();

        let noEndPointUpdate = false;

        switch (this.joint) {
          case 'assym':
            // console.log('assym > arc');

            const _prevV = this.prevV.copy();
            const vector = _prevV.copy().rotate(PI / 2);

            const vYoo = this.prevV.copy().rotate(-this.prevDiffAngle);
            const [dx, dy] = [this.prevV.x - vYoo.x, this.prevV.y - vYoo.y];

            this.cornerLineStart = [
              this.offsetX + this.x + this.prevV.x + vector.x - dx,
              this.offsetY + this.y + this.prevV.y + vector.y - dy,
            ];

            this.prevV.rotate(PI / 2);
            this.prevAngle = this.prevV.getAngle();

            const [x, y] = [this.x, this.y];

            const _vector = new BaseVector([this.x, this.y]).reScale(
              2 * this.r,
            );

            this.cornerLineEnd = [
              this.x + _vector.x / 2,
              this.y + _vector.y / 2,
            ];

            // -- //
            const [_x, _y] = [
              this.next.x - (this.x + _vector.x),
              this.next.y - (this.y + _vector.y),
            ];

            const aThis = new BaseVector([x, y]).getAngle();
            const aNext = new BaseVector([_x, _y]).getAngle();

            const _aNext = new BaseVector([
              this.next.x - this.x,
              this.next.y - this.y,
            ]).getAngle();

            // console.log('aNext', aNext, 'aThis', aThis);
            if (aThis < _aNext && _aNext - aThis < PI / 2) {
              const d = Math.sqrt(Math.pow(_x, 2) + Math.pow(_y, 2));

              this.CX = this.offsetX + this.x + _vector.x;
              this.CY = this.offsetY + this.y + _vector.y;

              const diffAngle = Math.acos(
                this.r / (d / (this.next.r / this.r + 1)),
              );

              console.log(
                'demon',
                d / (this.next.r / this.r - 1),
                'aNext',
                aNext,
                'diffAngle',
                diffAngle,
              );
              // -- // -- //
              const prevAngle = aThis - PI;

              this.prevAngle = aThis - PI;

              this.nextAngle = aNext + diffAngle - 2 * PI;

              if (this.nextAngle < 0) {
                this.nextAngle += 2 * PI;
              }

              console.log('hands-up ............', this.nextAngle);

              // -- //

              noEndPointUpdate = true;
              this.antiClockwise = true;
            } else {
              this.antiClockwise = false;
              this.CX = this.offsetX + this.x;
              this.CY = this.offsetY + this.y;
            }
            this.X = this.CX + Math.cos(this.nextAngle) * this.r;
            this.Y = this.CY + Math.sin(this.nextAngle) * this.r;

            // -- // -- // -- //

            break;
        }
        // -- // -- //

        if (!noEndPointUpdate) {
          this.endPoint = [
            this.offsetX + this.x + this.nextV.x,
            this.offsetY + this.y + this.nextV.y,
          ];
          this.nextAngle = this.nextV.getAngle();
        }
      }
    }

    if (this.d1) {
      this.d1StartVector = new BaseVector([this.x, this.y])
        .rotate(this.prevDiffAngle - this.d1)
        .reScale(length / 3);

      this.d1EndVector = new BaseVector([-this.x, -this.y])
        .rotate(this.prevDiffAngle + this.d1)
        .reScale(length / 3);
    }

    if (this.d2) {
      this.d2StartVector = new BaseVector([-this.x, -this.y])
        .rotate(-this.prevDiffAngle - this.d2)
        .reScale(length / 3);

      this.d2EndVector = new BaseVector([this.x, this.y])
        .rotate(-this.prevDiffAngle + this.d2)
        .reScale(length / 3);
    }
  }

  CX: number;
  CY: number;

  X: number;
  Y: number;

  antiClockwise: boolean;

  getForwardElements() {
    if (this.amIFirst) {
      return [
        this.flat
          ? ({
              index: this.index,
              type: 'line',
              // offset: this.startPoint,
              X: this.endPoint[0],
              Y: this.endPoint[1],
            } as PathItem)
          : ({
              index: this.index,
              type: 'arc',
              CX: 0,
              CY: 0,
              // -- // -- //
              a1: this.prevAngle,
              a2: this.nextAngle,
              r: this.r,
              offset: this.startPoint,
              X: this.endPoint[0],
              Y: this.endPoint[1],
            } as ArcItem),
      ];
    } else if (this.amILast) {
      // console.log('last prevAngle', this.prevAngle, 'next', this.nextAngle);
      return [
        // this will change upon d1

        this.d1
          ? ({
              type: 'curve',
              bx: this.d1StartVector.x,
              by: this.d1StartVector.y,
              cx: this.d1EndVector.x,
              cy: this.d1EndVector.y,
              X: this.startPoint[0],
              Y: this.startPoint[1],
            } as CurveItem)
          : ({
              type: 'line',
              X: this.startPoint[0],
              Y: this.startPoint[1],
            } as PathItem),
        this.flat
          ? ({
              index: this.index,
              type: 'line',
              X: this.endPoint[0],
              Y: this.endPoint[1],
            } as PathItem)
          : ({
              index: this.index,
              type: 'arc',
              CX: this.absX,
              CY: this.absY,
              r: this.r,
              a1: this.prevAngle,
              a2: this.nextAngle,
              X: this.endPoint[0],
              Y: this.endPoint[1],
            } as ArcItem),
      ];
    } else {
      if (this.forwardMode == 'arc') {
        const array = [
          this.d1
            ? ({
                type: 'curve',
                bx: this.d1StartVector.x,
                by: this.d1StartVector.y,
                cx: this.d1EndVector.x,
                cy: this.d1EndVector.y,
                X: this.startPoint[0],
                Y: this.startPoint[1],
              } as CurveItem)
            : {
                type: 'line',
                X: this.startPoint[0],
                Y: this.startPoint[1],
              },
        ] as PathItem[];

        if (this.joint == 'assym') {
          array.push(
            ...[
              {
                type: 'line',
                X: this.cornerLineStart[0],
                Y: this.cornerLineStart[1],
              } as PathItem,
              {
                type: 'line',
                X: this.cornerLineEnd[0],
                Y: this.cornerLineEnd[1],
              } as PathItem,
            ],
          );

          // -- // -- //

          // const [CX, CY] = [this.x + vector.x, this.y + vector.y];
          array.push({
            index: this.index,
            type: 'arc',
            CX: this.CX,
            CY: this.CY,
            r: this.r,
            X: this.X,
            Y: this.Y,
            a1: this.prevAngle,
            a2: this.nextAngle,
            antiClockwise: this.antiClockwise,
          } as ArcItem);
        } else {
          array.push({
            index: this.index,
            type: 'arc',
            CX: this.absX,
            CY: this.absY,
            r: this.r,
            X: this.endPoint[0],
            Y: this.endPoint[1],
            a1: this.prevAngle,
            a2: this.nextAngle,
            antiClockwise: false,
          } as ArcItem);
        }
        return array;
      } else {
        return [
          this.d1
            ? ({
                type: 'curve',
                bx: this.d1StartVector.x,
                by: this.d1StartVector.y,
                cx: this.d1EndVector.x,
                cy: this.d1EndVector.y,
                X: this.cornerPoint[0],
                Y: this.cornerPoint[1],
              } as CurveItem)
            : {
                type: 'line',
                X: this.cornerPoint[0],
                Y: this.cornerPoint[1],
              },
        ];
      }
    }
  }

  getBackwardElements() {
    if (this.amIFirst) {
      if (this.flat) {
        return [
          {
            index: this.index,
            type: 'line',
            X: 0,
            Y: 0,
          },
        ];
      }
      return [];
    } else if (this.amILast) {
      return this.prev.forwardMode == 'arc'
        ? [
            this.d2
              ? {
                  type: 'curve',
                  bx: this.d2StartVector.x,
                  by: this.d2StartVector.y,
                  cx: this.d2EndVector.x,
                  cy: this.d2EndVector.y,
                  X: this.prev.cornerPoint[0],
                  Y: this.prev.cornerPoint[1],
                }
              : {
                  index: this.index,
                  type: 'line',
                  X: this.prev.cornerPoint[0],
                  Y: this.prev.cornerPoint[1],
                },
          ]
        : [
            this.d2
              ? {
                  type: 'curve',
                  bx: this.d2StartVector.x,
                  by: this.d2StartVector.y,
                  cx: this.d2EndVector.x,
                  cy: this.d2EndVector.y,
                  X: this.prev.startPoint[0],
                  Y: this.prev.startPoint[1],
                }
              : {},
          ];
    } else {
      if (this.forwardMode == 'corner') {
        const array = [];

        if (this.joint == 'assym') {
          array.push(
            ...[
              {
                index: this.index,
                type: 'arc',
                CX: this.absX,
                CY: this.absY,
                r: this.r,
                // X: this.endPoint[0],
                // Y: this.endPoint[1],
                a1: this.prevAngle,
                a2: this.nextAngle,
                antiClockwise: false,
              } as ArcItem,
              {
                type: 'line',
                X: this.cornerLineStart[0],
                Y: this.cornerLineStart[1],
              } as PathItem,
              {
                type: 'line',
                X: this.cornerLineEnd[0],
                Y: this.cornerLineEnd[1],
              } as PathItem,
            ],
          );
        } else {
          array.push({
            index: this.index,
            type: 'arc',
            CX: this.absX,
            CY: this.absY,
            r: this.r,
            X: this.endPoint[0],
            Y: this.endPoint[1],
            a1: this.prevAngle,
            a2: this.nextAngle,
            antiClockwise: false,
          } as ArcItem);
        }

        array.push(
          this.d2
            ? {
                type: 'curve',
                bx: this.d2StartVector.x,
                by: this.d2StartVector.y,
                cx: this.d2EndVector.x,
                cy: this.d2EndVector.y,
                // X: this.cornerPoint[0],
                // Y: this.cornerPoint[1],
                X:
                  this.prev.backwardMode == 'corner'
                    ? this.prev.cornerPoint?.[0]
                    : this.prev.startPoint[0],
                Y:
                  this.prev.backwardMode == 'corner'
                    ? this.prev.cornerPoint?.[1]
                    : this.prev.startPoint[1],
              }
            : {
                type: 'line',
                X: this.prev.startPoint[0],
                Y: this.prev.startPoint[1],
              },
        );

        return array;
      } else {
        // corner-mode
        return [
          this.d2
            ? {
                type: 'curve',
                bx: this.d2StartVector.x,
                by: this.d2StartVector.y,
                cx: this.d2EndVector.x,
                cy: this.d2EndVector.y,
                X:
                  this.prev.backwardMode == 'corner'
                    ? this.prev.cornerPoint?.[0]
                    : this.prev.startPoint[0],
                Y:
                  this.prev.backwardMode == 'corner'
                    ? this.prev.cornerPoint?.[1]
                    : this.prev.startPoint[1],
              }
            : {
                index: this.index,
                type: 'line',
                X: this.prev.cornerPoint[0],
                Y: this.prev.cornerPoint[1],
              },
        ];
      }
    }
  }

  startAnimation(config: HandShapeSectionDescriptorNew, division: number) {
    const { x, y } = config;
    this.xIncrement = new IncrementController(
      this.x,
      x,
      division,
      Easing.LINEAR,
    );

    this.yIncrement = new IncrementController(
      this.y,
      y,
      division,
      Easing.LINEAR,
    );
  }

  incrementAnimation(increment: number) {
    if (this.amIFirst) {
      return;
    }
    this.x = this.xIncrement.increment(increment);
    this.y = this.yIncrement.increment(increment);
  }

  remove() {
    this.pc?.remove();
    this.rPc?.remove();
    this.removeStrokeElements();
    this.startCircle?.remove();
    this.endCircle?.remove();
  }

  removeStrokeElements() {
    this.topStroke?.remove();
    this.sideStroke?.remove();
    this.bottomStroke?.remove();
  }

  initStrokeElements() {
    this.removeStrokeElements();
    let X: number, Y: number, offset: Coords;

    if (!this.config.stroke) {
      return;
    }

    const { color, width, top, side, bottom } = this.config.stroke;
    if (top) {
      const { start: st, end: et } = top;

      const vector = new Vector(
        {
          x: this.lineStart[0],
          y: this.lineStart[1],
        },
        {
          x: this.lineEnd[0],
          y: this.lineEnd[1],
        },
      );

      let [ox, oy] = this.lineStart;
      const [X, Y] = this.lineEnd;

      if (!isNaN(st) && !isNaN(et)) {
        // -- //
        if (st != 0) {
          const v = vector.copy();
          v.flip();
          v.scale(1 - st);
          ox = v.end.x;
          oy = v.end.y;
        }
      }

      this.topStroke = new PathElement(
        this.handShape,
        this.handShape.container,
        {
          elements: [
            {
              type: 'line',
              offset: [ox, oy],
              X,
              Y,
            },
          ],
          stroke: this.handShape.service.resolveColor(color),
          'stroke-width': width,
        },
      );
    }

    if (side) {
      const { start: ss, end: es } = side;
      // -- // -- //

      if (this.flat) {
        offset = this.offset;
        X = this.lineStart[0];
        Y = this.lineStart[1];
        if (ss != 0 || es != 1) {
          const vector = new Vector(
            {
              x: offset[0],
              y: offset[1],
            },
            {
              x: X,
              y: Y,
            },
          );
          vector.scale(es);
          X = vector.end.x;
          Y = vector.end.y;
        }

        this.sideStroke = new PathElement(
          this.handShape,
          this.handShape.container,
          {
            elements: [
              {
                type: 'line',
                offset,
                X,
                Y,
              },
            ],
            stroke: this.handShape.service.resolveColor(color),
            'stroke-width': width,
          },
        );
      }
    }

    if (bottom) {
      const { start: sb, end: eb } = bottom;

      offset = this.handShape.lastHandSection.lineEnd;

      let X = this.handShape.firstHandSection.offset[0];
      let Y = this.handShape.firstHandSection.offset[1];

      if (sb != 0 || eb != 1) {
        const vector = new Vector(
          {
            x: offset[0],
            y: offset[1],
          },
          {
            x: X,
            y: Y,
          },
        );
        vector.scale(eb);
        X = vector.end.x;
        Y = vector.end.y;
      }
      this.bottomStroke = new PathElement(
        this.handShape,
        this.handShape.container,
        {
          elements: [
            {
              type: 'line',
              offset,
              X,
              Y,
            },
          ],
          stroke: this.handShape.service.resolveColor(color),
          'stroke-width': width,
        },
      );
    }
  }
}
