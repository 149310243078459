<div
  class="drop-shadow-attribute-componen attribute-panel-delimiter-border"
  *ngIf="attributeState$ | async as attributeState"
>
  <nw-general-attribute-header
    title="Drop Shadow"
    [attributeState]="attributeState$ | async"
    key="dropShadow"
    [initialValue]="{ strength: 1, margin: 4, dx: 4, dy: 4, color: '#444444' }"
  ></nw-general-attribute-header>

  <div
    *ngIf="isDefined$ | async"
    class="attribute-value-section flex-column gap-4"
  >
    <nw-number-input
      [key]="'strength'"
      [value]="attributeState.value['strength']"
      (changed)="updateSubAttributeValue('strength', $event)"
    ></nw-number-input>
    <nw-number-input
      [key]="'margin'"
      [value]="attributeState.value['margin']"
      (changed)="updateSubAttributeValue('margin', $event)"
    ></nw-number-input>
    <div class="flex-row space-between">
      <nw-number-input
        [key]="'dx'"
        [value]="attributeState.value['dx']"
        (changed)="updateSubAttributeValue('dx', $event)"
      ></nw-number-input>

      <nw-number-input
        [key]="'dy'"
        [value]="attributeState.value['dy']"
        (changed)="updateSubAttributeValue('dy', $event)"
      ></nw-number-input>
    </div>
    <div class="row-space-between">
      <div class="label">color</div>
      <nw-color-select
        [colors]="attributeState.value['color']"
        (changed)="updateSubAttributeValue('color', $event)"
      >
      </nw-color-select>
    </div>

    <!-- <div class="row-space-between" *ngSwitchCase="'color'">
            <div class="attr-key">{{ field.title }}</div>
            <nw-color-select
              [colors]="attributeState.value[field.key]"
              (changed)="updateAnimationByValue($event, field.key)"
            ></nw-color-select>
          </div> -->
  </div>
  <!-- <div *ngSwitchCase="'function'">output</div>
    <div *ngSwitchCase="'mixed'">output</div>
    <div *ngSwitchCase="'undefined'">output</div> -->
</div>
