import { on } from '@ngrx/store';
import { ResourceData } from '../../../elements/resource/resource.types';
import {
  addNewState,
  deselectAllShapes,
  deselectShape,
  setNewShapeAction,
  removeState,
  resetPatchedShapes,
  selectAllShapes,
  selectShape,
  setCurrentState,
  setCurrentFile,
  setPatchLoading,
  setShapeLabel,
  toggleSelectAction,
  updateDescriptorOfShapeBaseAction,
  setOriginalDescriptor,
  setBBoxOfShape,
  setCurrentDescriptor,
  updateCurrentDescriptorBaseAction,
  setShapeTranslateBase,
  setShapeOriginalTransform,
  setCurrentShapeTranslate,
  setShowComponentSearch,
  setOriginalSVGAttributes,
  setCurrentSVGAttributesOfShape,
  setSVGAttributesOfShape,
  setBaseShapeTransform,
  setCurrentSVGSubAttribute,
  cleanFile,
  setDescriptorSubAttribute,
  setCurrentDescriptorSubAttribute,
  setCurrentScene,
  setFileChanged,
  addSubScene,
  setSubSceneName,
  saveSubScenePosition,
  setCanvasPosition,
  selectSubSceneBase,
  setCurrentScenes,
  setNoAnimationMode,
  setFontLoaded,
  setSelectedShapes,
  setCanvasBackgroundColor,
  setLoadedScenes,
  addLoadedScene,
  addState,
  selectState,
  setAttributeByState,
  saveScenePosition,
  resetBaseState,
  applyBulkShapeUpdates,
  setCanvasOrientation,
  setScenes,
  setFiles,
  setCanvasShapes,
  setShapes,
  setShape,
  setRelationshipBase,
  setShapeParent,
  setcompleteShapeDescriptor,
  addToFileVisitStack,
  popVisitStack,
  deleteComponent,
  loadFileAction,
  setSubsceneLoaded,
  setBulkUpdateItem,
  clearBulkUpdates,
  setShapeDescriptors,
  setNewFile,
  clearNewFiles,
  setResourcePatch,
  setResourceDelete,
  setResourcePost,
  removeRelationshipBase,
  addFile,
} from '../editor.actions';
import { createImmerReducer } from 'ngrx-immer/store';
import {
  BBox,
  CircleShapeDescriptor,
  GeneralShapeDescriptor,
  RectangleShapeDescriptor,
  SVGAttributes,
  Scene,
  ShapeIRI,
  ShapeTransform,
  AnimationKeys,
  AnimationValue,
  AnimationInnerKey,
} from '../../../elements/resource/types/shape.type';
import { set as _set, pick as _pick, set } from 'lodash';
import { setFile } from '../../../projects/project.actions';
import { deleteShapeFromFileAction } from '../editor.crud.actions';
export interface SceneState {
  current?: string[];
  scenes: Scene[];
}
export interface CanvasPosition {
  x: number;
  y: number;
  scale: {
    x: number;
    y: number;
  };
}
export type RelationshipPatches = Record<
  ShapeIRI,
  Record<string, string | Record<string, boolean>>
>;
export type UpdatesByState = Record<string, Record<string, any>>;
export type BulkUpdates = Record<string, BulkUpdateItems>;
export type BulkUpdateItems = Partial<
  Record<
    AnimationKeys,
    {
      innerKey?: AnimationInnerKey;
      value?: AnimationValue;
      base?: boolean;
    }
  >
>;
export interface ResourcePatch {
  type: string;
  literals?: {
    label?: boolean;
    descriptor?: boolean;
  };
  relationships?: {
    file?: boolean;
    instanceOf?: boolean;
    componentOf?: boolean;
  };
}

export interface EditorState {
  shapes?: Record<string, ResourceData>;
  descriptors?: Record<string, GeneralShapeDescriptor>;
  currentDescriptors?: Record<string, GeneralShapeDescriptor>;
  originalDescriptors?: Record<ShapeIRI, GeneralShapeDescriptor>;
  baseSvgAttributes?: Record<ShapeIRI, SVGAttributes>;
  originalSVGAttributes?: Record<ShapeIRI, SVGAttributes>;
  currentSVGAttributes?: Record<ShapeIRI, SVGAttributes>;
  baseShapeTransforms?: Record<ShapeIRI, ShapeTransform>;
  originalShapeTransforms?: Record<ShapeIRI, ShapeTransform>;
  currentShapeTransforms?: Record<ShapeIRI, ShapeTransform>;
  selectedShapes?: Record<string, boolean>;
  showComponentSearch?: boolean;
  loading?: boolean;
  isPatchLoading?: boolean;
  isProjectLoading?: boolean;
  isFileLoading?: boolean;
  canvasOffset?: { x: number; y: number; scale: number };
  // project?: ResourceData;
  currentFileIRI?: string;
  currentScene?: string[];
  currentMainScene?: string;
  scenes?: Scene[];
  bBoxes?: Record<string, BBox>;
  colorPalette?: Record<string, string>;
  fileChanged?: boolean;
  canvasPosition?: CanvasPosition;
  noAnimationMode?: boolean;
  fontLoaded?: boolean;
  loadedScenes?: Record<string, boolean>;
  states?: string[];
  selectedState?: string;
  updatesByState?: Record<ShapeIRI, UpdatesByState>;
  files?: Record<string, ResourceData>;
  visitStack?: string[];
  scenesAreLoaded: boolean;
  bulkUpdates: BulkUpdates;
  newFiles?: Record<string, boolean>;
  crudEvents: {
    patches: Record<string, ResourcePatch>;
    posts: Record<string, 'nw:Shape' | 'nw:File'>;
    deletes: Record<string, 'nw:Shape' | 'nw:File'>;
  };
}
export const initialEditorState: EditorState = {
  showComponentSearch: false,
  loading: false,
  descriptors: {},
  currentDescriptors: {},
  originalDescriptors: {},
  selectedShapes: {},
  isPatchLoading: false,
  bBoxes: {},
  baseShapeTransforms: {},
  originalShapeTransforms: {},
  currentShapeTransforms: {},
  baseSvgAttributes: {},
  originalSVGAttributes: {},
  currentSVGAttributes: {},
  noAnimationMode: false,
  fontLoaded: true,
  loadedScenes: null,
  updatesByState: {},
  files: {},
  shapes: {},
  visitStack: [],
  scenesAreLoaded: false,
  bulkUpdates: {},
  canvasOffset: {
    x: 0,
    y: 0,
    scale: 1,
  },
  newFiles: {},
  crudEvents: {
    patches: {},
    posts: {},
    deletes: {},
  },
};

// const getSVGAttributes = (descriptor: GeneralShapeDescriptor) => {
//   const strokeWidth = descriptor.svgAttributes['stroke-width'];

//   return {
//     ...descriptor.svgAttributes,
//     stroke: descriptor.svgAttributes?.stroke
//       ? ({
//           color: descriptor.svgAttributes.stroke,
//           width: isNaN(strokeWidth) ? 1 : strokeWidth,
//           dash: descriptor.svgAttributes.dash,
//         } as StrokeConfig)
//       : undefined,
//     fill: descriptor.svgAttributes?.fill
//       ? {
//           color: descriptor.svgAttributes.fill,
//           gradient: descriptor.svgAttributes.gradient,
//           gradientDirection: descriptor.svgAttributes.gradientDirection,
//         }
//       : undefined,
//   };
// };

const getScale = (descriptor: GeneralShapeDescriptor) => {
  switch (descriptor.type) {
    case 'rectangle-shape':
      const { width, height } = descriptor as RectangleShapeDescriptor;
      return { x: width, y: height };
    case 'circle-shape':
      const { rx, ry } = descriptor as CircleShapeDescriptor;
      return { x: 2 * rx, y: 2 * ry };
    case 'imported-shape':
      return descriptor.position.scale;
  }
  return;
};

export const editorReducer = createImmerReducer(
  initialEditorState,
  on(cleanFile, state => {
    return state;
  }),
  on(setCanvasBackgroundColor, (state, { color }) => {
    _set(state, ['file', 'literals', 'descriptor', 'backgroundColor'], color);
    state.fileChanged = true;
    return state;
  }),
  on(setCanvasOrientation, (state, { value }) => {
    _set(state, ['file', 'literals', 'descriptor', 'orientation'], value);
    state.fileChanged = true;
    return state;
  }),
  on(setCurrentFile, (state, { file }) => {
    state.currentFileIRI = file.IRI;
    const ID = file.IRI.split('#')[1];
    state.files[ID] = file;
    return state;
  }),
  on(addToFileVisitStack, (state, { ID }) => {
    console.log('add-file-visit-stack');
    state.visitStack.push(ID);
    return state;
  }),
  on(popVisitStack, state => {
    state.visitStack.pop();
    return state;
  }),
  on(setShape, (state, { shape }) => {
    const { IRI } = shape;
    state.shapes[IRI] = shape;
    return state;
  }),
  on(setShapeDescriptors, (state, { shapes }) => {
    shapes.map(shape => {
      _set(
        state.shapes,
        [shape.IRI, 'literals', 'descriptor'],
        shape.literals.descriptor,
      );
    });
    return state;
  }),
  on(setShapeParent, (state, { IRI, fileIRI: newParent }) => {
    const currentParent = state.shapes[IRI].relationships.parent as string;
    delete state.files[currentParent].relationships.shape[IRI];

    state.shapes[IRI].relationships.parent = newParent;
    state.files[newParent].relationships.shape[IRI] = true;

    return state;
  }),

  on(setNewFile, (state, { fileIRI }) => {
    state.newFiles[fileIRI] = true;
    return state;
  }),
  on(clearNewFiles, state => {
    state.newFiles = {};
    return state;
  }),
  on(deleteComponent, (state, { IRI }) => {
    delete state.files[IRI].relationships.parent;
    return state;
  }),
  on(
    setRelationshipBase,
    (state, { subject, subjectType, relationship, object, oneToMany }) => {
      if (oneToMany) {
        _set(
          subjectType == 'nw:Shape' ? state.shapes : state.files,
          [subject, 'relationships', relationship, object],
          true,
        );
      } else {
        _set(
          subjectType == 'nw:Shape' ? state.shapes : state.files,
          [subject, 'relationships', relationship],
          object,
        );
      }
      return state;
    },
  ),
  on(
    removeRelationshipBase,
    (state, { subject, subjectType, relationship, object }) => {
      if (object) {
        delete (subjectType == 'nw:Shape' ? state.shapes : state.files)[subject]
          .relationships[relationship][object];
      } else {
        delete (subjectType == 'nw:Shape' ? state.shapes : state.files)[subject]
          .relationships[relationship];
      }
      return state;
    },
  ),
  on(removeRelationshipBase, (state, { subject, relationship, object }) => {
    switch (relationship) {
      case 'shapes':
        delete state.files[subject].relationships.shapes[object];
        break;
    }
    return state;
  }),
  on(setCanvasShapes, (state, { shapes, merge }) => {
    if (!merge) {
      state.scenes = [];
      state.currentScene = null;
      state.descriptors = {};
      state.currentDescriptors = {};
      state.originalDescriptors = {};
      state.selectedShapes = {};
      state.updatesByState = {};
      state.isPatchLoading = false;
      state.bBoxes = {};
      state.baseShapeTransforms = {};
      state.originalShapeTransforms = {};
      state.currentShapeTransforms = {};
      state.baseSvgAttributes = {};
      state.originalSVGAttributes = {};
      state.currentSVGAttributes = {};
    }

    for (const shape of shapes) {
      const IRI = shape.IRI;

      state.shapes[IRI] = shape;
      const descriptor = shape.literals.descriptor;

      state.descriptors[IRI] = descriptor;
      state.currentDescriptors[IRI] = descriptor;
      state.originalDescriptors[IRI] = descriptor;
      state.updatesByState[IRI] = descriptor.updatesByState;

      // -- // -- // -- //

      const transform = descriptor.position;

      state.baseShapeTransforms[IRI] = transform;
      state.originalShapeTransforms[IRI] = transform;
      state.currentShapeTransforms[IRI] = transform;

      // const svgAttributes = getSVGAttributes(descriptor);
      state.baseSvgAttributes[IRI] = descriptor.svgAttributes;
      state.originalSVGAttributes[IRI] = descriptor.svgAttributes;
      state.currentSVGAttributes[IRI] = descriptor.svgAttributes;
    }
    return state;
  }),
  on(setBulkUpdateItem, (state, { item, items }) => {
    (item ? [item] : items).map(({ IRI, key, innerKey, value, base }) => {
      _set(state.bulkUpdates, [IRI, key], {
        innerKey,
        value,
        base,
      });
    });
    return state;
  }),
  on(clearBulkUpdates, state => {
    state.bulkUpdates = {};
    return state;
  }),
  on(applyBulkShapeUpdates, (state, { data: bulkData }) => {
    Object.entries(bulkData || {}).map(([shapeIRI, updates]) => {
      Object.entries(updates).map(([animationKey, updateValue]) => {
        const { innerKey, value, base } = updateValue;
        switch (animationKey) {
          case 'translate':
          case 'scale':
          case 'rotation':
            _set(
              state.currentShapeTransforms,
              [shapeIRI, animationKey, innerKey].filter(val => !!val),
              value,
            );

            if (base) {
              _set(
                state.baseShapeTransforms,
                [shapeIRI, animationKey, innerKey].filter(val => !!val),
                value,
              );
            }

            //   state.currentShapeTransforms[shapeIRI][animationKey] =
            //     animationValue as Translate;
            //   break;
            // case 'rotation':
            //   state.currentShapeTransforms[shapeIRI].rotation =
            //     animationValue as Rotation;
            break;
          case 'fill':
          case 'stroke':
            _set(
              state.currentSVGAttributes,
              [shapeIRI, animationKey, innerKey].filter(val => !!val),
              value,
            );
            if (base) {
              _set(
                state.baseSvgAttributes,
                [shapeIRI, animationKey, innerKey].filter(val => !!val),
                value,
              );
            }
            break;
          case 'constraint':
            // -- // -- //
            // In case of constraint there's no current descriptor setting
            _set(
              state.descriptors,
              [shapeIRI, animationKey, innerKey].filter(val => !!val),
              value,
            );
            break;
          default:
            _set(
              state.currentDescriptors,
              [shapeIRI, animationKey, innerKey].filter(val => !!val),
              value,
            );
            if (base) {
              _set(
                state.descriptors,
                [shapeIRI, animationKey, innerKey].filter(val => !!val),
                value,
              );
            }
            break;
        }
      });
    });
    return state;
  }),
  on(resetBaseState, state => {
    state.currentDescriptors = state.descriptors;
    state.currentShapeTransforms = state.baseShapeTransforms;
    state.currentSVGAttributes = state.baseSvgAttributes;
    return state;
  }),
  on(setFiles, (state, { files }) => {
    // Object.values(files).map(file => {
    //   const parentFileIRI = file.relationships.parentFile as string;
    //   if (parentFileIRI && files[parentFileIRI]) {
    //     set(
    //       files[parentFileIRI],
    //       ['relationships', 'components', file.IRI],
    //       true,
    //     );
    //   }
    // });
    state.files = {
      ...state.files,
      ...files,
    };
    return state;
  }),
  on(addFile, (state, { file }) => {
    state.files[file.IRI] = file;
    const parentFileIRI = file.relationships.componentOf as string;
    if (parentFileIRI) {
      set(
        state.files,
        [parentFileIRI, 'relationships', 'components', file.IRI],
        true,
      );
    }
    return state;
  }),
  on(setFile, (state, { file }) => {
    state.files[file.IRI] = file;
    const parentFileIRI = file.relationships.componentOf as string;
    if (parentFileIRI) {
      set(
        state.files,
        [parentFileIRI, 'relationships', 'components', file.IRI],
        true,
      );
    }
    return state;
  }),
  on(setShapes, (state, { shapes }) => {
    state.shapes = {
      ...state.shapes,
      ...shapes,
    };
    return state;
  }),
  on(setFontLoaded, (state, { value }) => {
    state.fontLoaded = value;
    return state;
  }),
  on(setNoAnimationMode, (state, { value }) => {
    state.noAnimationMode = value;
    return state;
  }),
  on(setFileChanged, (state, { value }) => {
    state.fileChanged = value;
    return state;
  }),
  on(saveScenePosition, (state, { scene, position }) => {
    const sceneIndex = state.scenes.findIndex(s => s.name == scene);

    state.scenes[sceneIndex].position = position;
    state.fileChanged = true;
    return state;
  }),
  on(saveSubScenePosition, (state, { scene, subScene, position }) => {
    const sceneIndex = state.scenes.findIndex(s => s.name == scene);
    const subSceneIndex = state.scenes[sceneIndex].subscenes.findIndex(
      s => s.name == subScene,
    );
    state.scenes[sceneIndex].subscenes[subSceneIndex].position = position;
    state.fileChanged = true;
    return state;
  }),
  on(setCurrentScene, (state, { scene }) => {
    state.currentScene = [scene];
    return state;
  }),
  on(setCurrentScenes, (state, { scenes }) => {
    console.log('set-current-scenes', scenes);
    state.currentScene = scenes;
    return state;
  }),
  on(selectSubSceneBase, (state, { name }) => {
    state.currentScene[1] = name;
    return state;
  }),
  on(setSubSceneName, (state, { parentIndex, index, name }) => {
    state.currentScene[1] = name;
    state.scenes[parentIndex].subscenes[index].name = name;
    state.fileChanged = true;
    return state;
  }),
  on(setScenes, (state, { scenes }) => {
    state.scenes = scenes;
    state.fileChanged = true;
    return state;
  }),
  on(addSubScene, (state, { parentIndex, name, index }) => {
    state.scenes[parentIndex].subscenes ||= [];
    state.scenes[parentIndex].subscenes.splice(index, 0, { name });
    state.fileChanged = true;
    return state;
  }),
  on(setCurrentDescriptor, (state, { IRI, descriptor }) => {
    state.currentDescriptors[IRI] = descriptor;
    return state;
  }),
  on(setOriginalDescriptor, (state, { IRI, descriptor }) => {
    state.originalDescriptors[IRI] = descriptor;
    state.currentDescriptors[IRI] = descriptor;
    state.descriptors[IRI] = descriptor;
    return state;
  }),
  on(
    setAttributeByState,
    (state, { IRIs, state: fileState, key, innerKey, value, keys }) => {
      IRIs.map(IRI => {
        _set(
          state.updatesByState,
          keys
            ? [IRI, fileState, ...keys]
            : [IRI, fileState, key, innerKey].filter(v => !!v),
          value,
        );
      });
      return state;
    },
  ),
  on(setLoadedScenes, (state, { value }) => {
    state.loadedScenes = value;
    return state;
  }),
  on(addLoadedScene, (state, { key }) => {
    state.loadedScenes ||= {};
    state.loadedScenes[key] = true;
    return state;
  }),
  on(setOriginalSVGAttributes, (state, { IRI: shapeIRI, svgAttributes }) => {
    state.originalSVGAttributes[shapeIRI] = svgAttributes;
    state.currentSVGAttributes[shapeIRI] = svgAttributes;
    state.baseSvgAttributes[shapeIRI] = svgAttributes;
    return state;
  }),
  on(setCurrentSVGAttributesOfShape, (state, { shapeIRI, svgAttributes }) => {
    state.currentSVGAttributes[shapeIRI] = {
      ...state.currentSVGAttributes[shapeIRI],
      ...svgAttributes,
    };
    return state;
  }),
  on(setSVGAttributesOfShape, (state, { shapeIRI, svgAttributes }) => {
    state.baseSvgAttributes[shapeIRI] = {
      ...state.baseSvgAttributes[shapeIRI],
      ...svgAttributes,
    };
    return state;
  }),
  on(
    setCurrentSVGSubAttribute,
    (state, { IRI: shapeIRI, key, innerKey, value }) => {
      _set(state.currentSVGAttributes, [shapeIRI, key, innerKey], value);
      return state;
    },
  ),
  on(setDescriptorSubAttribute, (state, { shapeIRI, key, innerKey, value }) => {
    console.log('set-transform-attribute');
    _set(
      state.currentDescriptors,
      [shapeIRI, key, innerKey].filter(v => !!v),
      value,
    );
    _set(state.descriptors, [shapeIRI, key, innerKey], value);
    return state;
  }),
  on(
    setCurrentDescriptorSubAttribute,
    (state, { shapeIRI, key, innerKey, value }) => {
      _set(
        state.currentDescriptors,
        [shapeIRI, key, innerKey].filter(v => !!v),
        value,
      );
      return state;
    },
  ),
  on(setSelectedShapes, (state, { shapes }) => {
    state.selectedShapes = shapes;
    return state;
  }),
  on(selectShape, (state, { IRI, shift }) => {
    if (shift) {
      state.selectedShapes[IRI] = true;
    } else {
      state.selectedShapes = {
        [IRI]: true,
      };
    }
    return state;
  }),
  on(addState, (state, { name }) => {
    state.states ||= [];
    state.states.push(name);
    state.fileChanged = true;
    return state;
  }),
  on(selectState, (state, { name }) => {
    state.selectedState = name;
    return state;
  }),
  on(deselectShape, (state, { IRI }) => {
    delete state.selectedShapes[IRI];
    return state;
  }),
  on(selectAllShapes, state => ({
    ...state,
    selectedShapes: Object.keys(state.shapes).reduce((prev, curr) => {
      prev[curr] = true;
      return prev;
    }, {}),
  })),
  on(deselectAllShapes, state => {
    state.selectedShapes = {};
    return state;
  }),
  on(setResourcePost, (state, { IRI, IRIs, resourceType }) => {
    const _IRIs = IRIs ? IRIs : [IRI];
    _IRIs.map(IRI => {
      set(state.crudEvents.posts, [IRI], resourceType);
    });
    return state;
  }),
  on(setResourcePatch, (state, { IRI, IRIs, resourceType, field, key }) => {
    const _IRIs = IRIs ? IRIs : [IRI];
    _IRIs.map(IRI => {
      if (state.crudEvents.posts[IRI]) {
        return;
      }
      set(state.crudEvents.patches, [IRI, 'type'], resourceType);
      set(state.crudEvents.patches, [IRI, field, key], true);
    });
    return state;
  }),
  on(setResourceDelete, (state, { IRI, IRIs }) => {
    const _IRIs = IRIs ? IRIs : [IRI];
    console.log('editor.reducer > setResourceDelete', IRI);
    _IRIs.map(IRI => {
      if (state.crudEvents.posts[IRI]) {
        delete state.crudEvents.posts[IRI];
        return;
      }
      if (state.crudEvents.patches[IRI]) {
        delete state.crudEvents.patches[IRI];
      }
      set(state.crudEvents.deletes, [IRI], 'nw:Shape');
    });
    return state;
  }),
  on(resetPatchedShapes, state => ({
    ...state,
    crudEvents: {
      patches: {},
      posts: {},
      deletes: {},
    },
  })),
  on(setSubsceneLoaded, state => {
    state.scenesAreLoaded = true;
    return state;
  }),
  on(updateDescriptorOfShapeBaseAction, (state, { IRI, descriptor }) => {
    // TDOOx - implement original
    state.descriptors[IRI] = {
      ...state.descriptors[IRI],
      ...descriptor,
    };
    state.currentDescriptors[IRI] = {
      ...state.currentDescriptors[IRI],
      ...descriptor,
    };
    return state;
  }),
  on(updateCurrentDescriptorBaseAction, (state, { IRI, descriptor }) => {
    // TDOOx - implement originally
    state.currentDescriptors[IRI] = {
      ...state.descriptors[IRI],
      ...descriptor,
    };
    return state;
  }),
  on(deleteShapeFromFileAction, (state, { IRI }) => {
    delete state.selectedShapes[IRI];
    delete state.descriptors[IRI];
    delete state.currentDescriptors[IRI];
    delete state.baseSvgAttributes[IRI];
    delete state.currentSVGAttributes[IRI];
    return state;
  }),
  on(setPatchLoading, (state, { value }) => {
    state.isPatchLoading = value;
    return state;
  }),
  on(loadFileAction, state => {
    state.isFileLoading = true;
    return state;
  }),
  // TODO - implement
  on(addNewState, (state, { name, index }) => {
    return state;
  }),
  // TODO - implement
  on(removeState, (state, { name }) => {
    return state;
  }),
  // TODO - implement
  on(setCurrentState, (state, { name }) => {
    return state;
  }),
  on(setNewShapeAction, (state, { data }) => {
    state.crudEvents.posts[data.IRI] = 'nw:Shape';
    return state;
  }),
  on(setShapeLabel, (state, { IRI, label }) => {
    state.shapes[IRI].literals.label = label;
    return state;
  }),
  on(toggleSelectAction, (state, { IRI }) => {
    state.selectedShapes[IRI] = !state.selectedShapes[IRI];
    return state;
  }),
  on(setBBoxOfShape, (state, { IRI, bBox }) => {
    state.bBoxes[IRI] = bBox;
    return state;
  }),
  on(setShapeTranslateBase, (state, { IRI, translate }) => {
    _set(state.baseShapeTransforms, [IRI, 'translate'], translate);
    _set(state.currentShapeTransforms, [IRI, 'translate'], translate);
    return state;
  }),
  on(setCurrentShapeTranslate, (state, { IRI, translate }) => {
    _set(state.currentShapeTransforms, [IRI, 'translate'], translate);
    return state;
  }),
  on(setBaseShapeTransform, (state, { IRI, transform }) => {
    console.log('set-shape-transform', IRI, transform);
    _set(state.baseShapeTransforms, IRI, transform);
    _set(state.currentShapeTransforms, IRI, transform);
    return state;
  }),
  on(setcompleteShapeDescriptor, (state, { IRI, descriptor }) => {
    _set(state.shapes, [IRI, 'literals', 'descriptor'], descriptor);
    return state;
  }),
  // this is called only once at the shape initialisation
  on(setShapeOriginalTransform, (state, { IRI, transform }) => {
    _set(state.originalShapeTransforms, [IRI], transform);
    _set(state.currentShapeTransforms, [IRI], transform);
    _set(state.baseShapeTransforms, [IRI], transform);
    return state;
  }),
  on(setShowComponentSearch, (state, { value }) => {
    state.showComponentSearch = value;
    return state;
  }),
  on(setCanvasPosition, (state, { position }) => {
    state.canvasPosition = position;
    return state;
  }),
);
