import { NgModule } from '@angular/core';
import { AnimationControllerComponent } from './animation-controller.component';
import { CanvasTransformComponent } from './canvas-transform/canvas-transform.component';
import { UtilComponentModule } from '../../../../components/util/util-component.module';
import { FunctionFrameComponent } from './function-frame/function-frame.component';
import { TextAnimationComponent } from './text-animation/text-animation.component';

@NgModule({
  imports: [UtilComponentModule],
  declarations: [
    AnimationControllerComponent,
    CanvasTransformComponent,
    TextAnimationComponent,
    FunctionFrameComponent,
  ],
  exports: [AnimationControllerComponent],
})
export class AnimationControllerComponentModule {}
