import { NgModule } from '@angular/core';
import { ColorBlinkAnimationComponent } from './color-blink-animation.component';
import { GeneralAttributeHeaderModule } from '../../../../general-attribute-header/general-attribute-header.module';
import { UtilComponentModule } from '../../../../../../components/util/util-component.module';

@NgModule({
  imports: [UtilComponentModule, GeneralAttributeHeaderModule],
  declarations: [ColorBlinkAnimationComponent],
  exports: [ColorBlinkAnimationComponent],
})
export class PrimitiveAnimationComponentModule {}
