import { Injectable } from '@angular/core';
import { HttpService } from '../store/http/http.service';
import { RequestType } from '../store/store.service';
import { map } from 'rxjs/operators';
import { Organisation } from '../organisation/organisation.interface';
import { OrganisationService } from '../organisation/organisation.service';
import { ProjectService } from '../projects/project.service';
import { Observable } from 'rxjs';

@Injectable()
export class HomeService {
  getHome(): Observable<Organisation[]> {
    return this.http
      .requestCall({ path: 'user/home', type: RequestType.GET })
      .pipe(
        map(orgs =>
          orgs.map(org =>
            this.organisationService.mapResourceToOrganisation(org),
          ),
        ),
      );
  }

  // mapResourceToHome({ organisations, projects }): HomeData {
  //   return {
  //     organisations: organisations.map(organisation => t),
  //     projects: projects.map(project =>
  //       this.projectService.mapResourceToProject(project),
  //     ),
  //   };
  // }

  constructor(
    private readonly http: HttpService,
    private readonly organisationService: OrganisationService,
    private readonly projectService: ProjectService,
  ) {}
}
