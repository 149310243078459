import { Container } from 'pixi.js';
import { RectangleController } from '../../../../elements/util/rectangle-controller/rectangle-controller';
import { GeneralShape } from '../general/general-shape';
import {
  ResourceData,
  ResourceType,
} from '../../../../elements/resource/resource.types';
import { ContainerShape } from '../container/container.shape';
import {
  ShapeConfig,
  ShapePosition,
  ShapeSelectParams,
} from '../../../../elements/resource/types/shape.type';
import { ShapeService } from '../../shape.service';
import { omit } from 'lodash';
import { setDescriptorValue } from '../../../store/editor.crud.actions';

export class LayerShape extends ContainerShape {
  childShapes: GeneralShape[] = [];
  groupRCContainer: Container;
  currentPosition: {
    x: number;
    y: number;
    width: number;
    height: number;
  } = { x: undefined, y: undefined, width: undefined, height: undefined };
  groupEdit = false;

  beforeDragPosition: {
    x: number;
    y: number;
    width: number;
    height: number;
  } = { x: undefined, y: undefined, width: undefined, height: undefined };

  getType(): string {
    return 'group-shape';
  }

  selectedShapes() {
    if (this.groupEdit) {
      return this._shapes.filter(shape => shape.selected);
    }
    return super.selectedShapes();
  }

  constructor(service: ShapeService, data: ResourceData, config: ShapeConfig) {
    super(service, data, config);
    this.type = ResourceType.GroupShape;

    this.init();
    this.subscriptions.push(
      this.cs.keyEventSubscribe('u', () => {
        if (this.selected && this.cs.selectedShapes.length == 1) {
          this.ungroup();
        }
      }),
    );

    this.subscriptions.push(
      this.cs.keyEventSubscribe(
        'Enter',
        () => {
          if (this.selected && this.cs.selectedShapes.length == 1) {
            this.startGroupEdit();
            this.cs.consumeKeyEvent('Enter');
          }
        },
        10,
      ),
    );

    this.initShapes();
    this.redraw();
    this.initRC();
    this.rc?.hide();
  }

  startGroupEdit() {
    this.selected = false;
    this.groupEdit = true;
    this.rc.hide();
    this.cs.generalEventEmit('opacity-set', 0.4);
  }

  endGroupEdit() {
    console.log('endGroupEdit');
    // return;
    this.selected = true;
    this.cs.generalEventEmit('opacity-set', 0);
    this.rc.show();
    this.groupEdit = false;
    this._shapes.map(shape => shape.deselect());
  }

  ungroup() {
    this._shapes
      .sort((s1, s2) => s1.index - s2.index)
      .map(shape => {
        shape.descriptor.if = this.if;
        shape.x += this.x;
        shape.y += this.y;
        shape.saveTranslate();
        shape.locked = false;
        shape.index += this.index;
        shape.descriptor.childOf = null;
        this.store.dispatch(
          setDescriptorValue({
            IRI: shape.IRI,
            key: 'childOf',
            value: undefined,
          }),
        );
        shape.relationships.parent = this.parent.IRI;
        // if (shape.animationsByKey && this.cs.currentAnimation) {
        //   delete shape.animationsByKey[this.cs.currentAnimation.id];
        // }
        this.containerForChildren.removeChild(shape.mainContainer);
        this.circleContainer?.removeChild(shape.circleContainer);
        this.auxCircleContainer?.removeChild(shape.auxCircleContainer);

        this.parent.containerForChildren.addChild(shape.mainContainer);
        this.parent.circleContainer.addChild(shape.circleContainer);
        this.parent.auxCircleContainer.addChild(shape.auxCircleContainer);
        shape.initRC();
        shape.select();
        shape._show();
        shape.redraw();
        shape.save();
      });
    this.delete();
  }

  deleteJustMe() {
    super.delete();
  }

  delete() {
    super.delete();
    this.shapes.map(shape => shape.delete());
  }

  active = false;
  initCount = 0;

  getChildren() {
    return this.asArray(this.relationships['children'])
      .map((k: string) => this.service.getResource(k))
      .filter(r => !!r) as GeneralShape[];
  }

  select(params: ShapeSelectParams = {}) {
    // this.initRC();
    super.select(params);
    this.opened = true;
  }

  deselect(params: ShapeSelectParams = {}) {
    // console.log('group-shape > deselect');
    if (this.groupEdit) {
      return;
      // return this.endGroupEdit();
    }

    super.deselect(params);
    this.opened = false;
  }
}
