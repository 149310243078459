import { NgModule } from '@angular/core';
import { ColorSelectorComponent } from './color-selector/color-selector.component';
import { UtilComponentModule } from '../../components/util/util-component.module';
import { OverlayPromptComponent } from './overlay-prompt/overlay-prompt.component';

@NgModule({
  imports: [UtilComponentModule],
  declarations: [ColorSelectorComponent, OverlayPromptComponent],
  exports: [ColorSelectorComponent, OverlayPromptComponent],
})
export class OverlayModule {}
