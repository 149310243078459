import { NgModule } from '@angular/core';
import { CanvasComponent } from './canvas/canvas.component';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { UtilComponentModule } from '../components/util/util-component.module';
import { ComponentSearchComponent } from './component-search/component-search.component';
import { ImageModule } from '../image-module/image.module';
import { DirectivesModule } from '../directives/directives.module';
import { CodeEditorComponent } from '../components/code-editor/code-editor.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { ShapeAttributePanelModule } from './shape-attribute-panel/shape-attribute-panel.module';
import { RightClickMenuComponent } from './right-click-menu/right-click-menu.component';
import { EditorComponent } from './component/editor.component';
import { ControlerPanelModule } from './control-panel/control-panel.module';
import { SidePanelModule } from '../components/side-panel/side-panel.module';
import { RecorderComponent } from '../components/recorder/recorder.component';
import { EffectsModule } from '@ngrx/effects';
import { EditorEffects } from './store/effect/editor.effect';
import { StoreModule } from '@ngrx/store';
import { EditorFeature } from './store/editor.actions';
import { editorReducer } from './store/reducer/editor.reducer';
import { EditorService } from './editor.service';
import { ShapeModule } from './shape/shape.module';
import { AnimationModule } from './animation/animation.module';
import { EditorRequestEffects } from './store/effect/editor.request.effect';
import { PlayComponent } from '../components/play/play.component';
import { TextEditComponent } from './canvas/scene-controller/text-edit/text-edit.component';
import { SpacingControllerComponent } from './canvas/align-controller/spacing-controller/spacing-controller.component';
import { ZoomPanelComponent } from './zoom-panel/zoom-panel.component';
import { ProjectHeaderComponent } from './side-panel/project-header/project-header.component';
import { ProjectModule } from '../projects/project.module';
import { ProjectEditModule } from './project/project-edit.module';
import { OverlayModule } from './overlay/overlay.module';
import { SVGParseService } from './svg/svg-parse.service';

@NgModule({
  imports: [
    CodemirrorModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    MaterialModule,
    UtilComponentModule,
    ImageModule,
    DirectivesModule,
    ShapeAttributePanelModule,
    ControlerPanelModule,
    UtilComponentModule,
    SidePanelModule,
    AnimationModule,
    ShapeModule,
    ProjectModule,
    ProjectEditModule,
    OverlayModule,
    EffectsModule.forFeature([EditorEffects, EditorRequestEffects]),
    StoreModule.forFeature(EditorFeature, editorReducer),
  ],
  providers: [EditorService, SVGParseService],
  declarations: [
    CodeEditorComponent,
    EditorComponent,
    CanvasComponent,
    ComponentSearchComponent,
    RightClickMenuComponent,
    PlayComponent,
    RecorderComponent,
    TextEditComponent,
    SpacingControllerComponent,
    ZoomPanelComponent,
    ProjectHeaderComponent,
  ],
  exports: [EditorComponent],
})
export class EditorModule {}
