import { Injectable } from '@angular/core';
import { GeneralShape } from './shapes/general/general-shape';
import { Container } from 'pixi.js';
import { RectangleController } from '../../elements/util/rectangle-controller/rectangle-controller';
import { _screenX, CanvasService } from '../../services/canvas/canvas.service';
import {
  IndividualRectangleShape,
  RectangleShape,
} from './shapes/base/rectangle/rectangle-shape';
import { PathShape } from './shapes/path-shape/path-shape';
import {
  _Scale,
  CircleShapeDescriptor,
  Coords,
  GeneralShapeDescriptor,
  ImportedShapeDescriptor,
  PathShapeDescriptor,
  Translate,
  RectangleShapeDescriptor,
  Scale,
  ShapeConfig,
  ShapeDragParams,
  TextShapeDescriptor,
  HandShapeNewDescriptor,
  BBox,
} from '../../elements/resource/types/shape.type';
import { CircleShape } from './shapes/base/circle-shape';
import {
  Relationships,
  ResourceData,
} from '../../elements/resource/resource.types';
import { cloneDeep, set as _set } from 'lodash';
import * as uuidv1 from 'uuid/v1';
import { Store } from '@ngrx/store';
import {
  largestIndex,
  scenesAreLoaded,
  selectCurrentScenes,
  selectFileByIRI,
  selectFiles,
  selectNoAnimationMode,
  selectShapes,
} from '../store/selector/editor.selector';
import {
  addNewShapeAction,
  bulkUpdateFinish,
  incrementRadius,
  loadFileAction,
  openFile,
  resetBaseState,
  createNewComponentAction,
  setSelectedShapes,
} from '../store/editor.actions';
import { setDescriptorValue } from '../store/editor.crud.actions';
import { RootShape } from './shapes/general/root/root-shape';
import { GroupShape } from './shapes/group/group-shape';
import { ImportedShape } from './shapes/general/imported/imported-shape';
import { ImageShape } from './shapes/base/image-shape';
import { TrajectoryShape } from './shapes/trajectory/trajectory-shape';
import { ShadowShape } from './shapes/path-shape/shadow/shadow-shape';
import { TextShape } from './shapes/text/text-shape';
import { AnimationService } from '../animation/animation.service';
import { Resource } from '../../elements/resource/resource';
import { OrientationService } from '../../services/orientation/orientation.service';
import { currentAnimationId } from '../animation/store/animation.selector';
import { HttpService } from '../../store/http/http.service';
import { addAnimationItemAction } from '../animation/store/animation.actions';
import { getCurrentProject } from '../../projects/project.selector';
import { Library, Project } from '../../projects/project.interface';
import { ActivatedRoute } from '@angular/router';
import { SubtitleElement } from './subtitle/subtitle-element';
import {
  setFile,
  setLibraryValue,
  stepLanguage,
} from '../../projects/project.actions';
import { currentOrganisation } from '../../organisation/organisation.selector';
import { Organisation } from '../../organisation/organisation.interface';
import { HandShape } from './shapes/path-shape/hand-shape/hand-shape';
import { LayerShape } from './shapes/layer/layer-shape';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { IRIToId } from '../../util/iri';
import {
  ArmShapeDescriptor,
  ArmShapeNext,
} from './shapes/path-shape/arm-section/arm-shape-new';
import { HandShapeNext } from './shapes/path-shape/hand-shape-next/hand-shape-next';
import { SceneTransitionType } from '../animation/components/animation-frame/animation.types';

@Injectable()
export class ShapeService {
  selectorRectangle: IndividualRectangleShape;
  // previewShape: RootShape;

  get rootShape() {
    return this.cs.previewShape;
  }

  get previewShape() {
    return this.cs.previewShape;
  }

  set previewShape(shape: RootShape) {
    this.cs.previewShape = shape;
  }

  get selectedShapes() {
    return Object.keys(this.selectedShapesStore)
      .map(IRI => this.resourceStore[IRI] as GeneralShape)
      .filter(shape => !!shape);
  }

  get currentScene() {
    return this.cs.currentScene;
  }

  lastFileData: ResourceData;

  appContainer: Container;
  previewShapeIsLoading = false;

  shapes: Record<string, ResourceData>;
  files: Record<string, ResourceData>;
  openRootShape(resourceData: ResourceData) {
    this.resourceStore = {};
    this.selectedShapesStore = {};
    this.cs.resetImage();

    this.lastFileData = resourceData;

    this.cs.canvasScale = 1;
    this.previewShape = new RootShape(this, resourceData);
    this.previewShape.afterInit();

    this.animationService.init();
    this.cs.addShapeToCanvas(this.previewShape);
    this.previewShapeIsLoading = false;
    // this.cs.generalEventEmit('set-shape-view', true);
    return this.previewShape;
  }

  setCanvas() {
    // -- //
  }

  currentSceneRootShape: RootShape;
  visitStack = [];
  currentAnimationId: string;
  noAnimationMode: boolean;
  currentProject: Project;
  currentScenes: ResourceData[];
  currentOrganisation: Organisation;
  currentFile: ResourceData;

  currentLargestIndex = 0;
  constructor(
    readonly store: Store,
    readonly cs: CanvasService,
    readonly animationService: AnimationService,
    readonly orientationService: OrientationService,
    readonly http: HttpService,
    private route: ActivatedRoute,
  ) {
    this.store.select(currentAnimationId).subscribe(id => {
      this.currentAnimationId = id;
      this.cs.currentAnimationId = id;
    });

    this.store.select(getCurrentProject).subscribe(project => {
      // console.log('------ currentProject -----', project);
    });

    this.store.select(largestIndex).subscribe(i => {
      this.currentLargestIndex = i;
    });

    this.store.select(selectNoAnimationMode).subscribe(val => {
      this.noAnimationMode = val;
    });

    this.store.select(selectCurrentScenes).subscribe(scenes => {
      this.currentScenes = scenes.sort(
        (s1, s2) => s1.literals.sceneIndex - s2.literals.sceneIndex,
      );
      // console.log('currentScenes', this.currentScenes);
    });

    this.store.select(currentOrganisation).subscribe(org => {
      this.currentOrganisation = org;
    });

    this.store.select(selectShapes).subscribe(shapes => {
      this.shapes = shapes;
    });

    this.store.select(selectFiles).subscribe(files => {
      // console.log('files', files);
      this.files = files;
    });

    this.cs.keyEventSubscribe(
      'Space+1',
      () => {
        this.cs.consumeKeyEvent('Space');
        this.store.dispatch(
          setDescriptorValue({
            key: 'show-hide',
            value: {
              value: true,
            },
          }),
        );
        this.finishShapeUpdate();
      },
      0,
    );

    this.cs.keyEventSubscribe('l+Tab', () => {
      this.cs.consumeKeyEvent('Tab');
      this.store.dispatch(stepLanguage());
    });

    this.cs.keyEventSubscribe('s+k', () => {
      console.log('-------------- heeey ------------');
      this.store.dispatch(
        setDescriptorValue({
          key: 'index',
          value: 50,
        }),
      );
    });

    this.cs.keyEventSubscribe('r+ArrowUp', () => {
      this.store.dispatch(incrementRadius({ increment: 1 }));
      this.store.dispatch(bulkUpdateFinish());
    });
    this.cs.keyEventSubscribe('r+ArrowDown', () => {
      this.store.dispatch(incrementRadius({ increment: -5 }));
      this.store.dispatch(bulkUpdateFinish());
    });
    this.cs.keyEventSubscribe('r+ArrowRight', () => {
      this.store.dispatch(incrementRadius({ increment: 5 }));
      this.store.dispatch(bulkUpdateFinish());
    });
    this.cs.keyEventSubscribe('r+ArrowLeft', () => {
      this.store.dispatch(incrementRadius({ increment: -5 }));
      this.store.dispatch(bulkUpdateFinish());
    });

    this.cs.keyEventSubscribe('s+Backspace', () => {
      console.log('s+backspace');
    });

    this.cs.generalEventSubscribe('clear-resource-store', () => {
      this.resourceStore = {};
    });

    this.cs.generalEventSubscribe('canvas-hover', () => {
      this.hoverSelectedShapes.map(shape => shape.deselect({ onHover: true }));
    });

    this.cs.generalEventSubscribe(
      'variable-changed',
      ({ key, varName, value }) => {
        console.log('variable-changed', { key, varName, value });
        this.currentProject.library[key][varName] = value;
        this.store.dispatch(
          setLibraryValue({
            key,
            varName,
            value,
          }),
        );
        this.cs.generalEventEmit(`variable.update.${key}.${varName}`);
      },
    );

    this.cs.keyEventSubscribe('r+c', () => {
      const [is] = this.selectedShapes;
      console.log('r+c > selectedShapes', this.selectedShapes);
      if (is?.getType() == 'is') {
        (is as ImportedShape).addRotationCenterPC();
      }
    });

    this.cs.keyEventSubscribe('Shift+r', () => {
      this.cs.consumeKeyEvent('r');
      console.log('------ reset-base-state -------');
      this.resetBaseState();
    });

    this.cs.keyEventSubscribe('Shift+g', () => {
      this.createImportedShapeFromSelection();
    });

    this.cs.keyEventSubscribe('x+m', () => {
      this.store.dispatch(
        setDescriptorValue({
          key: 'auxMode',
          value: true,
        }),
      );
    });

    this.cs.keyEventSubscribe('f+p', () => {
      this.store.dispatch(
        setDescriptorValue({
          key: 'fixPosition',
          value: true,
        }),
      );
    });

    this.cs.keyEventSubscribe('s+i', () => {
      if (!this.previewShape) {
        return;
      }

      // this.previewShape.shapes.map((shape, index) => {
      //   shape.index = index;
      this.store.dispatch(
        setDescriptorValue({
          IRI: this.selectedShapes[0].IRI,
          key: 'index',
          value: 90,
        }),
      );
      this.store.dispatch(bulkUpdateFinish());

      // });
    });

    this.cs.keyEventSubscribe('Space+c', () => {
      this.selectedShapes
        .filter(shape => shape.getType() == 'path-shape')
        .map((ps: PathShape) => ps.flipClosed());

      this.finishShapeUpdate();
    });

    this.cs.keyEventSubscribe('Shift+ArrowUp', () => {
      this.selectedShapes.map(shape => shape.incrementIndex());
    });

    this.cs.keyEventSubscribe('Shift+ArrowDown', () => {
      this.selectedShapes.map(shape => shape.decrementIndex());
    });

    this.cs.keyEventSubscribe('Shift+p', () => {
      if (this.currentAnimationId) {
        this.cs.consumeKeyEvent('a');
        this.store.dispatch(
          addAnimationItemAction({ item: { key: 'appear' } }),
        );
      }
    });

    this.cs.keyEventSubscribe('Shift+o', () => {
      if (this.currentAnimationId) {
        this.cs.consumeKeyEvent('a');
        this.store.dispatch(
          addAnimationItemAction({ item: { key: 'disappear' } }),
        );
      }
    });

    this.cs.keyEventSubscribe(
      'a',
      () => {
        this.cs.consumeKeyEvent('a');
        this.previewShape.__setPreAnimationState();
      },
      1,
    );

    this.cs.keyEventSubscribe(
      'Backspace',
      consume => {
        if (!this.previewShape) {
          return;
        }
        if (this.selectedShapes.length) {
          consume();
        }
        this.selectedShapes.map(shape => shape.delete());
        this.hideGroupRC();
      },
      10,
    );

    this.cs.keyEventSubscribe('Space+g', () => {
      const [groupShape] = this.selectedShapes;
      if (groupShape.getType() == 'group-shape') {
        (groupShape as GroupShape).ungroup();
      }
    });

    this.cs.keyEventSubscribe('Space+v', () => {
      this.cs.consumeKeyEvent('v');
      this.verticalAlign();
      this.resetGroupRC();
    });

    this.cs.keyEventSubscribe('Space+h', () => {
      this.horizontalAlign();
      this.cs.consumeKeyEvent('h');
    });

    this.cs.keyEventSubscribe('Shift+c', () => {
      if (!!this.previewShape && this.selectedShapes.length) {
        this.cs.copiedShapes = this.selectedShapes;
        this.cs.notify('Copied');
      }
    });

    this.cs.keyEventSubscribe('l+s', () => {
      this.store.dispatch(
        loadFileAction({
          ID: this.cs.currentFileID,
          mode: 'subscene-load',
        }),
      );
    });

    this.cs.keyEventSubscribe(
      'Space',
      () => {
        if (
          this.selectedShapes?.length == 1 &&
          this.selectedShapes[0].getType() == 'path-shape'
        ) {
          (this.selectedShapes[0] as PathShape).showRC();
          this.cs.consumeKeyEvent('Space');
          console.log('----- found --- selected-shape');
        }
      },
      19,
    );

    this.cs.keyEventSubscribe(
      'Shift+Space',
      async () => {
        console.log('Shift+Space');
        // this.cs.generalEventEmit('set-overlay', {
        //   type: 'prompt',
        //   callback: val => {
        //     console.log('overlay-response', val);

        this.cs.consumeKeyEvent('Space');

        // if (val) {
        this.store.dispatch(
          loadFileAction({ ID: this.cs.currentFileID, mode: 'subscene-load' }),
        );

        // -- // -- //
        this.store.select(scenesAreLoaded).subscribe(val => {
          if (!val) {
            return;
          }
          console.log('scenesAreLoaded', val);
          console.log('init-scene-root-shapes', this.currentScenes);
          let previousScene = this.cs.previewShape;
          this.currentSceneRootShape = this.cs.previewShape;
          this.currentScenes.map(data => {
            console.log('initializing scene root-shapes', data.literals.label);
            const sceneRootShape = new RootShape(this, data);
            sceneRootShape.afterInit();
            previousScene.nextSceneIRI = sceneRootShape.IRI;
            this.cs.previewShapesByScene[data.IRI] = sceneRootShape;
            previousScene = sceneRootShape;
          });
          this.animationService.playAnimations();
        });
        //   }, -- //
        // } as OverlayConfig);
        // -- // -- // -- //
      },
      19,
    );

    this.cs.keyEventSubscribe('Shift+v', () => {
      if (!!this.previewShape && this.cs.copiedShapes?.length) {
        const newShapes = this.cs.copiedShapes.map((shape, index) => {
          shape.deselect();
          return this.previewShape.addCopiedShape(shape, {
            // shape.containerIndex is the new position,
            // index represents the number of previously added copied shapes
            // containerIndex: shape.containerIndex + 1 + index,
            containerIndex: index,
          });
        });
        this.selectedShapesStore = {};
        this.cs.notify('Pasted');
        newShapes.map(shape => shape.select({ direct: true }));
        this.previewShape.setContainerIndexes();
        if (newShapes.length == 1) {
          this.hideGroupRC();
        } else {
          this.showGroupRC();
          newShapes.map(shape => shape.hideDragControllers());
        }
      }
    });

    this.cs.keyEventSubscribe('a+x', () => {
      if (this.cs.selectedShapes.length) {
        this.cs.consumeKeyEvent('a');
        this.cs.consumeKeyEvent('x');
      }
      this.cs.selectedShapes.map(shape => {
        shape.auxMode = !shape.auxMode;
        this.store.dispatch(
          setDescriptorValue({
            IRI: shape.IRI,
            key: 'auxMode',
            value: shape.auxMode,
          }),
        );
        this.finishShapeUpdate();
      });
    });
    // this.store.select;

    // setTimeout(() => this.addGradient(), 1_500);
  }

  subtitle: SubtitleElement;

  animationState: {
    canvasPosition: Coords;
    currentScene?: string;
  } = {
    canvasPosition: [0, 0],
    currentScene: null,
  };

  setAnimationState(shapeIRI: string, position: Coords) {
    this.animationState = {
      currentScene: shapeIRI,
      canvasPosition: position,
    };
  }

  addSubtitle(text: string) {
    this.subtitle = new SubtitleElement(this, text);
  }

  removeSubtitle() {
    this.subtitle.delete();
  }

  resolveScale(scale: Scale): _Scale {
    return {
      x: this.resolveNumber(scale?.x || 1),
      y: this.resolveNumber(scale?.y || 1),
    };
  }

  resolveTranslate(translate: Translate): Translate {
    return {
      x: this.resolveNumber(translate.x) || 0,
      y: this.resolveNumber(translate.y) || 0,
      relative: translate.relative,
    };
  }

  resolveNumber(variable: number | string) {
    return this.resolveLibaryValue<number>('number', variable);
  }

  resolveLibaryValue<T>(key: keyof Library, variable: any): T {
    if (typeof variable == 'string' && variable.startsWith('$')) {
      return this.currentProject.library?.[key]?.[variable.slice(2)] as T;
    }
    return variable;
  }

  getNextSceneShape(root: RootShape) {
    return this.cs.previewShapesByScene[root.nextSceneIRI];
  }

  resolveColor(key: string) {
    if (key?.startsWith?.('<')) {
      const formula = key.slice(1);

      if (formula.startsWith('random')) {
        const [c1, c2, c3] = formula
          .slice(7)
          .split(',')
          .map(v => v.trim());
        // console.log('random-color', c1, c2, c3.slice(0, 7));
        // const color = ColorIncrementController.getColor(c1, c2.trim(), Math.random())
        // console.log('color', color);
        const num = Math.random();

        if (num < 0.333) {
          return c1;
        }
        if (num < 0.666) {
          return c2;
        }
        return c3.slice(0, 7);
      }

      return key;
    }

    if (!key?.startsWith?.('$')) {
      return key;
    }
    const colorPalette = {
      ...(this.currentOrganisation?.colorPalette || {}),
      ...(this.currentProject?.colorPalette || {}),
    };

    if (!colorPalette) {
      console.warn('color-palette-could not be found');
    }
    return colorPalette?.[key.slice(2)];
  }

  hoverSelectedStore: Record<string, true> = {};

  get hoverSelectedShapes() {
    return Object.keys(this.hoverSelectedStore)
      .map(IRI => this.getShapeByIRI(IRI))
      .filter(shape => !!shape);
  }

  navigateToFile(fileID: string) {
    this.visitStack.push(this.cs.currentFileID);
    this.cs.router.navigate([
      'organisations',
      this.cs.currentOrganisationID,
      'projects',
      this.cs.currentProjectID,
      'file',
      fileID,
    ]);
  }

  goBack() {
    this.cs.router.navigate([
      'organisations',
      this.cs.currentOrganisationID,
      'projects',
      this.cs.currentProjectID,
      'file',
      IRIToId(this.currentFile.relationships.parent as string),
    ]);
  }

  hoverSelect(shape: GeneralShape) {
    if (!this.cs.isShiftPressed) {
      this.hoverDeselect(...this.hoverSelectedShapes);
    }

    this.hoverSelectedStore[shape.IRI] = true;
    shape.hoverSelect();
  }

  hoverDeselect(...shapes: GeneralShape[]) {
    shapes.map(shape => {
      delete this.hoverSelectedStore[shape.IRI];
      shape.hoverDeselect();
    });
  }

  canvasHover() {
    if (!this.cs.isShiftPressed) {
      this.hoverDeselect(...this.hoverSelectedShapes);
    }
  }

  selectedImportedShape: ImportedShape;

  select(shape: GeneralShape, childShapeSelect = false) {
    if (!this.cs.isShiftPressed && !childShapeSelect) {
      this.deselectAllShapes();
    }

    this.selectedShapesStore[shape.IRI] = true;

    if (this.selectedShapes.length > 1) {
      this.selectedShapes.map(shape => shape.setMultiSelectMode());
      this.showGroupRC();
    } else {
      shape.completeSelect();
    }

    this.saveSelectedShapes();
  }

  deselect(shape: GeneralShape) {
    this.saveSelectedShapes();
  }
  deselectAllShapes() {
    this.selectedShapes.map(shape => shape.hideDragControllers());
    this.selectedShapesStore = {};
    this.hideGroupRC();
  }
  resetBaseState() {
    this.store.dispatch(resetBaseState());
    this.previewShape.resetBaseState();
    this.animationService.resetBaseState();
  }
  areThereSelectedShapes() {
    return this.selectedShapes.length > 0;
  }
  shapesTobeMasked: GeneralShape[];
  setShapeToBeMasked() {
    this.shapesTobeMasked = this.selectedShapes;
    // -- // -- // -- //
  }

  initShape<T = GeneralShape<GeneralShapeDescriptor>>(
    data: ResourceData<GeneralShapeDescriptor>,
    parent?: GeneralShape,
    config?: ShapeConfig,
  ): T {
    if (parent) {
      _set(data, 'relationships.parent', parent.IRI);
    }

    const type: string = (data as ResourceData).literals.descriptor.type;
    let shapeClass;
    switch (type) {
      case 'image-shape':
        shapeClass = ImageShape;
        break;

      case 'imported-shape':
        shapeClass = ImportedShape;
        break;

      case 'imported-shape-preview':
        shapeClass = ImportedShape;
        break;

      case 'trajectory-shape':
        shapeClass = TrajectoryShape;
        break;

      case 'circle-shape':
        shapeClass = CircleShape;
        break;

      case 'rectangle-shape':
        shapeClass = RectangleShape;
        break;

      case 'layer-shape':
        shapeClass = LayerShape;
        break;

      case 'shadow-shape':
        shapeClass = ShadowShape;
        break;

      case 'text-shape':
        shapeClass = TextShape;
        break;

      case 'group-shape':
        // console.log('-new-group-shape-');
        shapeClass = GroupShape;
        break;
      case 'hand-shape':
        shapeClass = HandShape;
        break;
      case 'hand-shape-next':
        shapeClass = HandShapeNext;
        break;
      case 'arm-shape':
        shapeClass = ArmShapeNext;
        break;
      default:
        shapeClass = PathShape;
        break;
    }

    return new shapeClass(this, data, config) as T;
  }

  resourceStore: Record<string, Resource> = {};

  saveResource(resource: Resource) {
    this.resourceStore[resource.IRI] = resource;
  }

  deleteResource(resource: Resource) {
    delete this.resourceStore[resource.IRI];
  }

  getResource(IRI: string): Resource {
    return this.resourceStore[IRI];
  }

  getShapeByIRI(IRI: string): GeneralShape {
    return this.resourceStore[IRI] as GeneralShape;
  }

  dxAvg: number;
  xStartForHorizontalAlign: number;

  dyAvg: number;
  yStartForVerticalAlign: number;

  isColumnSelection() {
    if (this.selectedShapes.length < 3) {
      return false;
    }

    // -- //
    this.dyAvg = 0;
    const selectedShapesByY = this.selectedShapesByY;

    for (let i = 0; i < selectedShapesByY.length - 1; i++) {
      const [shape1, shape2] = [selectedShapesByY[i], selectedShapesByY[i + 1]];
      const { y, height } = shape1.getCurrentBBox();
      const shape1End = y + height;
      if (i == 0) {
        this.yStartForVerticalAlign = shape1End;
      }

      if (shape2.y < shape1End) {
        return false;
      }
      const diff = shape2.y - shape1End;
      this.dyAvg += diff;
    }

    this.dyAvg /= selectedShapesByY.length - 1;
    this.dyAvg = Math.floor(this.dyAvg);
    return true;
  }

  evenAlignColumn(gap?: number) {
    // -- //

    const overrideMode = !!gap;

    gap ||= this.dyAvg;

    let y = this.yStartForVerticalAlign;
    this.selectedShapesByY.map((shape, index) => {
      if (index == 0) {
        return;
      }
      if (index == this.selectedShapes.length - 1 && !overrideMode) {
        return;
      }
      y += gap;
      shape.y = y;
      shape.saveTranslate();
      shape.applyTranslate({
        x: shape.x,
        y: shape.y,
      });

      y += shape.getCurrentBBox().height;
    });
    this.resetGroupRC();
    this.finishShapeUpdate();
  }

  get selectedShapesByY() {
    return this.selectedShapes.sort((shape1, shape2) => shape1.y - shape2.y);
  }

  get selectedShapesByX() {
    return this.selectedShapes.sort((shape1, shape2) => shape1.x - shape2.x);
  }
  isRowSelection() {
    if (this.selectedShapes.length < 3) {
      return false;
    }

    // -- //
    this.dxAvg = 0;
    const selectedShapesByX = this.selectedShapesByX;

    for (let i = 0; i < selectedShapesByX.length - 1; i++) {
      const [shape1, shape2] = [selectedShapesByX[i], selectedShapesByX[i + 1]];
      const { x, width } = shape1.getCurrentBBox();
      const shape1End = x + width;
      if (i == 0) {
        this.xStartForHorizontalAlign = shape1End;
      }

      if (shape2.x < shape1End) {
        return false;
      }
      const diff = shape2.x - shape1End;
      this.dxAvg += diff;
    }

    this.dxAvg /= selectedShapesByX.length - 1;
    this.dxAvg = Math.floor(this.dxAvg);
    return true;
  }

  evenAlignRow(gap?: number) {
    // -- //

    const overrideMode = !!gap;

    gap ||= this.dxAvg;

    let x = this.xStartForHorizontalAlign;
    this.selectedShapesByX.map((shape, index) => {
      if (index == 0) {
        return;
      }
      if (index == this.selectedShapes.length - 1 && !overrideMode) {
        return;
      }
      x += gap;
      shape.x = x;
      shape.saveTranslate();
      shape.applyTranslate({
        x: shape.x,
        y: shape.y,
      });

      x += shape.getCurrentBBox().width;
    });
    this.resetGroupRC();
    this.finishShapeUpdate();
  }

  verticalAlign(pos: 'up' | 'center' | 'bottom' = 'center') {
    let ySum = 0;
    const shapes = this.cs.selectedShapes;
    const diffs = [];
    shapes.map(shape => {
      const { height } = shape.mainContainer.getBounds();
      const dy = height / 2;
      ySum += shape.y + dy;
      diffs.push(dy);
    });
    const mean = ySum / shapes.length;
    shapes.map((shape, i) => {
      shape.y = mean - diffs[i];
      shape.saveTranslate();
      shape.applyTranslate({
        x: shape.x,
        y: shape.y,
      });
    });
    this.finishShapeUpdate();
  }

  horizontalAlign(pos: 'left' | 'center' | 'right' = 'center') {
    let xSum = 0;
    const shapes = this.cs.selectedShapes;
    const diffs = [];
    shapes.map(shape => {
      const { width } = shape.mainContainer.getBounds();
      const dx = width / 2;
      // console.log('height', width);

      if (isNaN(+width)) {
        return;
      }

      xSum += shape.x + dx;
      diffs.push(dx);
    });
    const mean = xSum / shapes.length;
    shapes.map((shape, i) => {
      shape.x = mean - diffs[i];
      shape.saveTranslate();
      shape.redraw();
    });
    this.finishShapeUpdate();
  }

  finishShapeUpdate() {
    this.store.dispatch(bulkUpdateFinish());
  }

  concentricCircles() {
    let xSum = 0;
    let ySum = 0;
    const diffs = [];
    const shapes = this.cs.selectedShapes;
    shapes.map((shape: CircleShape) => {
      shape.makeSymmetric();
      const [ox, oy] = shape.origin;
      xSum += ox;
      ySum += oy;
    });

    const [x, y] = [xSum, ySum].map(v => v / shapes.length);
    shapes.map((shape: CircleShape) => {
      shape.origin = [x, y];
      shape.save();
      shape.redraw();
    });
  }

  setAsDarkShadow() {
    this.cs.selectedShapes.map(shape => {
      shape.patch('svgAttributes', {
        fill: '#000',
        opacity: 0.1,
        stroke: null,
      });
      // console.log('opacity', shape.opacity);
    });
  }

  setAsLightShadow() {
    this.cs.selectedShapes.map(shape => {
      shape.patch('svgAttributes', {
        fill: '#ffffff',
        opacity: 0.3,
        stroke: null,
      });
    });
  }

  dragState: 'started' | 'took-place' | 'idle' = 'idle';

  shapesToBeDragged: GeneralShape[];

  startDrag(shape?: GeneralShape) {
    if (shape && shape?.selected) {
      this.shapesToBeDragged = [shape];
    } else if (shape?.groupShapeParent) {
      this.shapesToBeDragged = this.selectedShapes.filter(
        _shape => _shape?.IRI !== shape.groupShapeParent.IRI,
      );
    } else {
      this.shapesToBeDragged = this.selectedShapes;
    }
    this.shapesToBeDragged.map(shape => shape?.startBaseDrag());
  }

  drag(dx: number, dy: number, trace?: string) {
    // console.log('shape.service > drag', dx, dy);
    // this.orientationService.clearOrientationLines();
    // const {
    //   xMin: xs,
    //   yMin: ys,
    //   xMax: xe,
    //   yMax: ye,
    // } = this.getSelectedBoundingRectangle();
    // -- // -- //
    //   this.orientationService._checkHorizontal('cs', [
    //     {
    //       coords: [xs, ys],
    //       left: false,
    //     },
    //     {
    //       coords: [xs, ye],
    //       left: false,
    //     },
    //     {
    //       coords: [xe, ys],
    //       left: true,
    //     },
    //     {
    //       coords: [xe, ye],
    //       left: true,
    //     },
    //   ]);
    //   this.orientationService._checkVertical('cs', [
    //     {
    //       coords: [xs, ys],
    //       top: false,
    //     },
    //     {
    //       coords: [xe, ys],
    //       top: false,
    //     },
    //     {
    //       coords: [xs, ye],
    //       top: true,
    //     },
    //     {
    //       coords: [xe, ye],
    //       top: true,
    //     },
    //   ]);

    this.shapesToBeDragged.map(shape => shape?.drag(dx, dy));
  }

  endDrag(_params?: ShapeDragParams) {
    this.shapesToBeDragged.map(shape => shape.endDrag());
  }

  addTrajectoryAppearAnimation() {
    // this.cs.selectedShapes
    //   .filter(shape => shape.getType() == 'ps')
    //   .map((ps: PathShape) => {
    //     ps.addAnimation('trajectory-appear', null);
    //     ps.save();
    //   });
  }

  startSelectorRectangle(x: number, y: number) {
    if (!this.cs.previewShape) {
      return;
    }
    if (this.selectorRectangle) {
      this.cs.app.stage.removeChild(this.selectorRectangle.container);
    }

    this.selectorRectangle?.remove();
    this.selectorRectangle = new IndividualRectangleShape(this, {
      position: { x, y },
      width: 0,
      height: 0,
      svgAttributes: {
        stroke: { color: '#000000', width: 1 },
      },
    });
    this.cs.addToCanvas(this.selectorRectangle);
  }

  selectorWidth = 0;
  selectorHeight = 0;
  strict = false;

  dragSelectorRectangle(x: number, y: number, width: number, height: number) {
    if (!this.cs.previewShape) {
      return;
    }
    if (!this.selectorRectangle) {
      return;
    }
    this.selectorWidth = Math.abs(width);
    this.selectorHeight = Math.abs(height);
    this.strict = width < 0;
    this.selectorRectangle.x = x;
    this.selectorRectangle.y = y;
    this.selectorRectangle._redraw({ x, y });
    this.selectorRectangle?._resize(this.selectorWidth, this.selectorHeight);

    // const shapeToSelect = this.cs.previewShape.shapes.filter(shape =>
    //   shape.selection({
    //     xLimits:
    //       this.selectorWidth > 0
    //         ? [x, x + this.selectorWidth]
    //         : [x + this.selectorWidth, x],
    //     yLimits:
    //       this.selectorHeight > 0
    //         ? [y, y + this.selectorHeight]
    //         : [y + this.selectorHeight, y],
    //     strict: this.strict,
    //   }),
    // );

    // const currentSelection = {};
    // shapeToSelect.map(shape => {
    //   currentSelection[shape.IRI] = shape;
    //   shape.rc?.show();
    //   // shape.rc?.hidePointControllers();
    // });

    // Object.keys(this.selectedShapesStore).map(IRI => {
    //   if (!currentSelection[IRI]) {
    //     this.getShapeByIRI(IRI)?.hideDragControllers();
    //   }
    // });

    // this.hoverSelectedShapes.map(shape => shape?.hideDragControllers());
    // this.hoverSelectedStore = {};

    // this.selectedShapesStore = currentSelection;
  }

  removeSelectorRectangle() {
    if (!this.selectorRectangle) {
      this.deselectAllShapes();
      return;
    }

    this.hideGroupRC();
    const { x, y } = this.selectorRectangle;

    // TODO - revise that //

    if (!this.cs.isShiftPressed) {
      this.selectedShapes.map(shape => shape.hideDragControllers());
      this.selectedShapesStore = {};
    }

    this.cs.previewShape.shapes
      .filter(shape => {
        return shape.selection({
          xLimits:
            this.selectorWidth > 0
              ? [x, x + this.selectorWidth]
              : [x + this.selectorWidth, x],
          yLimits:
            this.selectorHeight > 0
              ? [y, y + this.selectorHeight]
              : [y + this.selectorHeight, y],
          strict: this.strict,
        });
      })
      .map(shapeToSelect => {
        this.selectedShapesStore[shapeToSelect.IRI] = true;
      });

    switch (this.selectedShapes.length) {
      case 0:
        this.deselectAllShapes();
        break;
      case 1:
        this.selectedShapes[0].select();
        break;
      default:
        this.selectedShapes.map(shape => {
          shape.selected = true;
          shape.setMultiSelectMode();
        });
        this.showGroupRC();
    }

    this.saveSelectedShapes();

    this.selectorWidth = 0;
    this.selectorHeight = 0;
    this.strict = false;
    this.selectorRectangle?.remove();
    this.selectorRectangle = null;
  }

  saveSelectedShapes() {
    this.store.dispatch(
      setSelectedShapes({ shapes: cloneDeep(this.selectedShapesStore) }),
    );
  }

  checkSelectorRectangle() {
    // -- // -- // -- //
  }

  zoomUpdate() {
    this.selectedShapes.map(shape => shape.zoomUpdate());
    this.hoverSelectedShapes.map(shape => shape?.zoomUpdate());
    this.groupRC?.zoomUpdate();
  }

  getBoundingRectangle(shapes: GeneralShape[]) {
    let xMin = Infinity,
      yMin = Infinity,
      xMax = -Infinity,
      yMax = -Infinity;
    for (const shape of shapes) {
      const { x, y, width, height } = shape.container.getBounds();
      // const { x, y, width, height } = shape.getCurrentBBox();

      if (x < xMin) xMin = x;
      if (y < yMin) yMin = y;
      if (xMax < x + width) xMax = x + width;
      if (yMax < y + height) yMax = y + height;
    }

    this._x = xMin;
    this._y = yMin;

    return { x: xMin, y: yMin, width: xMax - xMin, height: yMax - yMin };
  }

  groupRC: RectangleController;
  groupRCContainer: Container;

  selectedShapesStore: Record<string, boolean> = {};

  closeAfterEndDrag = false;
  groupRCDragMode = false;

  currentGroupRCPostion: {
    x: number;
    y: number;
    width: number;
    height: number;
  };

  showVerticalEvenSpacing = false;
  verticalSpacingPosition: Coords;

  showHorizontalEvenSpacing = false;
  horizontalSpacingPosition: Coords;

  resetGroupRC() {
    if (!this.selectedShapes.length) {
      return;
    }
    this.hideGroupRC();
    this.showGroupRC();
  }
  showGroupRC(shape?: GeneralShape) {
    if (!this.groupRC) {
      // TODO: circle, RT, imported
      this.initGroupRC();
    }

    const { x, y, width, height } = shape
      ? shape.container.getBounds()
      : this.getBoundingRectangle(this.selectedShapes);

    this.showVerticalEvenSpacing = this.isColumnSelection();
    this.showHorizontalEvenSpacing = this.isRowSelection();
    this.verticalSpacingPosition = [x + width + 12, y + height / 2 - 4];
    this.horizontalSpacingPosition = [x + width / 2 - 4, y + height + 12];

    this.currentGroupRCPostion = { x, y, width, height };
    this.beforeDragPosition = {
      ...this.cs.getAbsoluteCoords(x, y),
      width,
      height,
    };

    this.groupRCContainer.visible = true;
    this.groupRC.show();
    return this.groupRC.patch(
      {
        offset: [x, y],
        width,
        height,
      },
      true,
    );
  }

  beforeDragPosition: BBox;

  initGroupRC() {
    this.closeAfterEndDrag = false;

    this.groupRCContainer = new Container();
    // this.cs.app.stage.addChild(this.groupRCContainer);
    // this.cs.previewShape.rootContainer.addChild(this.groupRCContainer);
    this.cs.previewShape.rootContainer.addChild(this.groupRCContainer);
    this.groupRC = new RectangleController(
      this.cs.previewShape,
      {
        width: 0,
        height: 0,
        noScale: true,
        offset: [0, 0],
        selectedContainerMode: true,
        dragParams: { noShow: true },
        startDrag: () => {
          this.groupRCDragMode = true;

          // TODO - migrate
          // this.selectedShapes = shape ? [shape] : this.cs.selectedShapes;
          this.selectedShapes.forEach(shape => {
            const dx = shape.x - shape.offsetX - this.beforeDragPosition.x;
            const dy = shape.y - shape.offsetY - this.beforeDragPosition.y;
            /* console.log('group-rc startDrag', {
              dx,
              dy,
              shape,
              shapeX: shape.x,
              offsetX: shape.offsetX,
            }); */
            shape.startTransformation(dx, dy);
            shape.hoverDeselect();
          });
        },
        drag: ({ x: cX, y: cY, width: newWidth, height: newHeight }) => {
          const scaleX = newWidth / this.beforeDragPosition.width;
          const scaleY = newHeight / this.beforeDragPosition.height;

          this.currentGroupRCPostion.x = this.beforeDragPosition.x + cX;
          this.currentGroupRCPostion.y = this.beforeDragPosition.y + cY;
          this.currentGroupRCPostion.width = newWidth;
          this.currentGroupRCPostion.height = newHeight;

          this.selectedShapes.forEach(shape => {
            shape.transformation(scaleX, scaleY, cX, cY);
            // shape.redraw();
            // shape.refresh();
          });
          // this.groupRCContainer.setTransform(
          //   this.currentGroupRCPostion.x,
          //   this.currentGroupRCPostion.y,
          // );
        },
        endDrag: () => {
          this.beforeDragPosition = this.currentGroupRCPostion;
          this.selectedShapes.forEach(shape => shape.endTransformation());
          this.selectedShapes.forEach(shape => shape.setMultiSelectMode());
        },
        _endDrag: () => {
          // this.updateGroupRC(shape);
        },
        _drag: (dx: number, dy: number) => {
          // -- // -- //
          this.moveGroupRc([
            // dx / this.cs.canvasScale,
            // dy / this.cs.canvasScale,
            dx,
            dy,
          ]);
        },
      },
      this.groupRCContainer,
      this.groupRCContainer,
      this.groupRCContainer,
    );

    this.groupRC.zoomUpdate();
  }

  _x: number;
  _y: number;

  updateGroupRC(shape?: GeneralShape) {
    if (!this.groupRC) {
      return;
    }

    console.log('---- update-groupRC ---', { shape });

    this.groupRC.show();

    const { x, y, width, height } = shape
      ? shape.container.getBounds()
      : this.getBoundingRectangle(this.cs.selectedShapes);

    this._x = x;
    this._y = y;

    this.groupRC.patch({
      offset: [x, y],
      width,
      height,
    });

    this.groupRC.refresh();

    if (!shape) {
      this.cs.selectedShapesStore.map(shape => {
        shape.hideDragControllers();
      });
    }
  }

  moveGroupRc([dx, dy]: Coords) {
    this.groupRCContainer?.setTransform(this._x + dx, this._y + dy);
  }

  hideGroupRC() {
    // console.log('hide-group-rc');
    this.showHorizontalEvenSpacing = false;
    this.showVerticalEvenSpacing = false;
    this.groupRC?.hide();
  }

  getResourceDataByDescriptor(
    label: string,
    descriptor: GeneralShapeDescriptor,
    IRI?: string,
  ) {
    descriptor.index = this.rootShape.getNewShapeIndex();
    descriptor.position ||= {};
    descriptor.position.translate = this.cs.getAbsoluteCoords(100, 100, true);
    descriptor._animationsByKey = this.cs.currentAnimation?.id
      ? {
          [this.cs.currentAnimation.id]: [{ key: 'appear' }],
        }
      : undefined;

    const resourceData = {
      IRI: IRI || `tmp-${Math.random()}`,
      type: 'nw:Shape',
      relationships: {
        parent: this.rootShape.IRI,
      },
      literals: {
        label,
        descriptor,
      },
    };
    return resourceData;
  }

  isLoading = false;
  isLoadSub: Subscription;
  async addImportedShape({ IRI }: ResourceData) {
    // -- // -- //
    this.isLoading = true;

    this.store.dispatch(openFile({ IRI, importedShape: true }));
    this.isLoadSub = this.store
      .select(selectFileByIRI(IRI))
      .pipe(filter(data => !!data?.relationships.shape))
      .subscribe(data => {
        const { x, y } = this.cs.getAbsoluteCoords(100, 100, true);
        this.addShape({
          label: `${data.literals.label}:1`,
          descriptor: {
            type: 'imported-shape',
            index: this.previewShape.getNewShapeIndex(),
            // Dirty but it will be used in the editor.request.effect
            shapeIRI: data.IRI,
            position: { x, y, scale: { x: 1, y: 1 } },
          } as ImportedShapeDescriptor,
          relationships: {
            shape: data.relationships?.shape,
            instanceOf: data.IRI,
          },
        }).select();
        this.isLoading = false;
        this.isLoadSub?.unsubscribe();
      });
  }

  addGroupShape(x: number, y: number) {
    return this.addShape({
      label: 'group-shape',
      descriptor: {
        type: 'group-shape',
        position: { x, y },
        if: { [this.cs.currentState]: true },
      },
    });
  }

  addArmShape(x: number, y: number) {
    return this.addShape({
      label: 'arm-shape',
      descriptor: {
        type: 'arm-shape',
        position: { x, y },
        if: { [this.cs.currentState]: true },
        svgAttributes: {
          fill: {
            color: '#000000',
          },
        },
        config: {
          r1: 20,
          r2: 30,
          x: 100,
          y: 120,
        },
      } as ArmShapeDescriptor,
    });
  }

  addHandShape(x: number, y: number) {
    // console.log('add > hand-shape next'); //
    return this.addShape({
      label: 'hand-shape-next',
      descriptor: {
        type: 'hand-shape-next',
        position: { x, y },
        if: { [this.cs.currentState]: true },
        svgAttributes: {
          fill: {
            color: '#000000',
          },
        },
        config: {
          closed: false,
          handSections: [
            {
              r: 20,
            },
            {
              x: 0,
              y: 100,
              r: 30,
            },
            {
              x: 100,
              y: 0,
              r: 30,
            },
          ],
        },
      } as HandShapeNewDescriptor,
    }) as HandShapeNext;
  }

  addRectangleShape(x: number, y: number) {
    // console.log('add-rectangle-shape', x, y);
    return this.addShape({
      label: 'rectangle-shape',
      descriptor: {
        type: 'rectangle-shape',
        position: { x, y },
        svgAttributes: { stroke: { color: '#000000', width: 1 } },
        width: 0,
        height: 0,
      } as RectangleShapeDescriptor,
    });
  }

  addCircleShape(x: number, y: number) {
    return this.addShape({
      label: 'circle-shape',
      descriptor: {
        type: 'circle-shape',
        position: { x, y },
        svgAttributes: { stroke: { color: '#000000', width: 1 } },
        rx: 0,
        ry: 0,
      } as CircleShapeDescriptor,
    });
  }

  addPathShape(x: number, y: number) {
    return this.addShape<PathShape>({
      label: 'path-shape',
      descriptor: {
        type: 'path-shape',
        position: { x, y },
        svgAttributes: { stroke: { color: '#000000', width: 1 } },
        newCurveAngle: true,
        sections: [
          {
            type: this.cs.isPressed(['s', 'd']) ? 'curve' : 'line',
            id: Math.random().toString(),
            x: 0,
            y: 0,
          },
          {
            type: 'line',
            id: Math.random().toString(),
            x: 0,
            y: 0,
          },
        ],
      } as PathShapeDescriptor,
    });
  }

  addTextShape(x: number, y: number) {
    return this.addShape({
      label: 'text-shape',
      descriptor: {
        type: 'text-shape',
        position: { x, y },
        svgAttributes: { fill: { color: '#000000' } },
        // TODO - clean this
        textConfig: {
          text: 'Text',
          fontSize: 28,
        },
        text: 'Text',
        fontSize: 28,
      } as TextShapeDescriptor,
    });
  }

  getNewIRI() {
    return `http://nowords.com#${uuidv1()}`;
  }

  addShape<T extends GeneralShape = GeneralShape>({
    label,
    descriptor,
    config,
    index,
    relationships,
  }: {
    label: string;
    descriptor: GeneralShapeDescriptor;
    config?: ShapeConfig;
    index?: number;
    relationships?: Relationships;
  }): T {
    // TOOO - this should be smarter if we are zoomed mode //

    const lastShape =
      this.rootShape.shapesByIndex[this.rootShape.shapeIndexes.length - 1];
    index = lastShape ? lastShape.index + 1 : 0;
    if (isNaN(index)) {
      index = this.rootShape.shapes.length;
      // throw new Error(`Invalid index was about to be set: ${index}`);
    }
    config ||= {
      isRoot: true,
    };

    descriptor.index = index;

    // same way it should be in the reducer

    if (this.cs.currentState) {
      descriptor.if = { [this.cs.currentState]: true };
    }

    const resourceData = {
      IRI: this.getNewIRI(),
      type: 'nw:Shape',
      relationships: {
        file: this.rootShape.IRI,
        ...(relationships || {}),
      },
      literals: {
        label,
        descriptor,
      },
      // This is not elegant but practical
    };

    // TODOx
    // This is because the path-shape offset is set and it would be readonly
    // This is not efficient
    // console.log('add-shape', resourceData);
    const shape = this.addShapeByResourceData<T>(resourceData, config);

    shape.select();

    return shape;
  }

  addShapeByDescriptor<T extends GeneralShape = GeneralShape>(
    descriptor: GeneralShapeDescriptor,
  ) {
    return this.addShapesByDescriptor<T>([descriptor])[0];
  }

  addShapesByDescriptor<T extends GeneralShape = GeneralShape>(
    descriptors: GeneralShapeDescriptor[],
    masks: Record<string, number> = {},
  ): GeneralShape[] {
    let resourceData = descriptors.map((descriptor, index) => ({
      type: 'nw:Shape',
      IRI: `http://nowords.com#${uuidv1()}`,
      literals: {
        label: 'shape-' + index?.toString(),
        descriptor,
      },
      relationships: {
        parent: this.previewShape.IRI,
      },
    }));

    resourceData = resourceData.map(data => {
      const descriptor = data.literals.descriptor;
      if (descriptor.maskedBy) {
        descriptor.maskedBy = resourceData[masks[descriptor.maskedBy]].IRI;
      }

      data.literals.descriptor = descriptor;

      return data;
    });

    const shapes = resourceData.map(resourceData =>
      this.addShapeByResourceData(resourceData),
    );

    // console.log('shapes', shapes); //

    shapes.forEach(shape => {
      if (shape.maskedBy) {
        const baseShape = (this.getResource(shape.maskedBy) ||
          this.getResource(
            this.previewShape.IRI + '_' + shape.maskedBy,
          )) as GeneralShape;
        baseShape.setMeAsMask(shape as GeneralShape, true);
      }
    });

    return shapes;
  }

  addShapeByResourceData<T extends GeneralShape = GeneralShape>(
    resourceData: ResourceData,
    config?: ShapeConfig,
  ): T {
    this.store.dispatch(addNewShapeAction({ data: cloneDeep(resourceData) }));
    const newShape = this.initShape<T>(resourceData, this.previewShape, config);
    this.rootShape._shapes.push(newShape);
    newShape.afterInit();
    return newShape;
  }

  copyShapes() {
    // -- //
  }

  pasteShapes() {
    // -- // -- // -- // -- //
  }

  addCopiedShape() {}

  saveImageToS3() {}

  createImportedShapeFromSelection() {
    if (!this.selectedShapes.length) {
      return alert('Please select at least one shape!');
    }

    const name = prompt('Please give a name to the new component');
    if (!name) {
      return;
    }
    const selectedShapes = this.selectedShapes;

    const IRI = this.getNewIRI();
    const { x, y, width, height } = this.getBoundingRectangle(selectedShapes);

    const offset = {
      ...this.cs.getAbsoluteCoords(x, y),
      width: width / this.cs.canvasScale,
      height: height / this.cs.canvasScale,
    };

    this.store.dispatch(
      createNewComponentAction({
        IRI,
        label: name,
        descriptor: {
          offset,
        },
      }),
    );
    //Its shapes are going to be set later

    // -- // -- //
    const isIRI = this.getNewIRI();
    const is = this.addShapeByResourceData(
      {
        IRI: isIRI,
        type: 'nw:Shape',
        literals: {
          label: `${name}-instance`,
          descriptor: {
            index: this.previewShape.getNewShapeIndex(),
            type: 'imported-shape',
            position: {
              x: offset.x,
              y: offset.y,
            },
          } as ImportedShapeDescriptor,
        },
        relationships: {
          instanceOf: IRI,
          file: 'http://nowords.com#' + this.cs.currentFileID,
        },
      },
      {
        isRoot: true,
      },
    );

    this.selectedShapes.map(shape => {
      // console.log('delete-shape', shape);
      // this.store.dispatch(
      //   setShapeParent({
      //     IRI: shape.IRI,
      //     fileIRI: file.IRI,
      //   }),
      // );
      console.log('they will be reinitialized by the new instance');
      shape.remove();
    });
    this.deselectAllShapes();
    is.select();
    // -- // -- //
  }
}
