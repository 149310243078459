import { ResourceData } from '../../../elements/resource/resource.types';
import { Component, OnInit, Input } from '@angular/core';
import { RootComponent } from '../../root/root.component';
import { HttpService } from '../../../store/http/http.service';
import { cloneDeep as _cloneDeep } from 'lodash';
import { StoreService } from '../../../store/store.service';
import { KeyHandlerService } from '../../../services/keyhandler/keyhandler.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CanvasService } from '../../../services/canvas/canvas.service';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { updateFileName } from '../../../projects/project.actions';
import { Store } from '@ngrx/store';
import {
  assignSceneToFile,
  createNewScene,
} from '../../../element-editor/store/editor.actions';
import { IRIToId } from '../../../util/iri';

@UntilDestroy()
@Component({
  selector: 'nw-scene-item',
  templateUrl: './scene-item.component.html',
  styleUrls: ['./scene-item.scss'],
})
export class SceneItemComponent extends RootComponent implements OnInit {
  @Input()
  index = 0;

  @Input()
  scene: ResourceData;

  // State
  isActive = false;
  loading = false;
  opened = false;
  hidden = false;
  editState: string;

  scenes$: Observable<ResourceData[]>;

  // New form
  newItemName: string;
  iconState = false;
  selected = false;

  get IRI() {
    return this.scene.IRI;
  }

  get ID() {
    return IRIToId(this.IRI);
  }

  constructor(
    readonly dialog: MatDialog,
    db: StoreService,
    http: HttpService,
    khs: KeyHandlerService,
    readonly cs: CanvasService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store,
  ) {
    super(db, http, khs);

    this.cs.generalEventSubscribe('globalClick', () => {
      this.addSceneMode = false;
    });
  }

  clicked() {
    if (this.cs.draggedResource?.IRI && this.cs.draggedResource?.IRI !== this.scene.IRI) {
      console.log('yoooo - drag', this.scene);

      this.store.dispatch(
        assignSceneToFile({
          mainFile: IRIToId(
            (this.scene.relationships.childOf as ResourceData).IRI as string,
          ),
          fileID: IRIToId(this.cs.draggedResource.IRI),
          index: +this.scene.literals.sceneIndex + 1,
        }),
      );
      // this.store.dispatch(createNewScene({}));
    }
  }

  hovered = false;
  mouseLeave() {
    this.hovered = false;
    // -- // -- //
  }

  mouseEnter() {
    this.hovered = true;
    // -- // -- //
  }

  clickEdit() {}

  addSceneMode = false;
  newSceneName = '';
  addScene() {
    this.addSceneMode = true;
  }

  saveNewScene() {
    if (!this.newSceneName) {
      return;
    }
    this.addSceneMode = false;
    this.store.dispatch(
      createNewScene({
        scene: this.newSceneName,
        index: 0,
        fileID: IRIToId(this.IRI),
      }),
    );
  }

  saveNewName(event: Event) {
    event.stopPropagation();
    this.cs.store.dispatch(
      updateFileName({
        IRI: this.scene.IRI,
        newName: this.scene.literals.label,
      }),
    );
    this.editState = null;
    this.isActive = true;
  }

  ngOnInit(): void {}

  goToFile(IRI: string) {
    this.cs.loadedItem =
      IRI.split('http://nowords.com#')?.[1] || IRI.split(':')?.[1];

    const { organisationID, projectID } = this.route.snapshot.params;
    this.router.navigate([
      'organisations',
      organisationID,
      'projects',
      projectID,
      'file',
      this.cs.loadedItem,
    ]);
  }

  keydown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      return this.globalClick();
    }
    event.stopPropagation();
  }

  async click(resetQueryParams = true, scene?: string) {
    // this.router.navigate(
  }
}
