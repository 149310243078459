<div class="floating-input-comp">
  <div class="flex-row gap-8">
    <input
      type="text"
      #input
      [(ngModel)]="text"
      (keydown)="$event.stopPropagation()"
      (keyup)="$event.stopPropagation()"
    />
    <nw-icon
      *ngIf="!isInvalid"
      type="check"
      [size]="12"
      (click)="clickCheck()"
    ></nw-icon>
    <nw-icon
      *ngIf="isInvalid"
      type="warning"
      matTooltip="Variable already exists"
      [outlined]="true"
      [size]="14"
      (click)="clickCheck()"
    ></nw-icon>
  </div>
</div>
