import { createSelector } from '@ngrx/store';
import { DataBag } from '../../../store/selectors';
import { EditorFeature } from '../editor.actions';
import { EditorState } from '../reducer/editor.reducer';
import { get as _get, pick } from 'lodash';
import {
  AnimationItem,
  AnimationKeys,
  AnimationValue,
  CircleShapeDescriptor,
  IfPanelState,
  PathShapeDescriptor,
  SVGAttributes,
  Scene,
  TextShapeDescriptor,
  HandShapeNewDescriptor,
  FillAnimation,
  ShapeTransform,
  GeneralShapeDescriptor,
  PrimitiveValue,
  RectangleShapeDescriptor,
} from '../../../elements/resource/types/shape.type';
import { selectAnimationFeature } from '../../animation/store/animation.reducer';
import {
  AttributePanelState,
  ShapeAttributeState,
} from '../../../services/animation/animation.types';
import { ArmShapeDescriptor } from '../../shape/shapes/path-shape/arm-section/arm-shape-new';
import { selectProjectFeature } from '../../../projects/project.selector';
import { ResourceData } from '../../../elements/resource/resource.types';

const asc = (v1, v2) => (v1 > v2 ? 1 : -1);

export const constraintsByIRI = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    ({ descriptors }) => descriptors[IRI]?.constraints,
  );

export const selectEditorFeature = (state: DataBag) =>
  state[EditorFeature] as EditorState;

export const selectFile = createSelector(
  selectEditorFeature,
  state => state.files[state.currentFileIRI],
);
export const selectFileByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state?.files[IRI]);

export const selectRectangleRadius = (IRI: string) =>
  createSelector(selectEditorFeature, ({ currentDescriptors }) => {
    // TODO - make sure that no coalescing is needed
    return (currentDescriptors[IRI] as RectangleShapeDescriptor)?.r;
  });

export const selectFiles = createSelector(
  selectEditorFeature,
  state => state?.files,
);

export const selectMaskChildren = (IRI: string) =>
  createSelector(selectEditorFeature, ({ shapes }) => {
    if (!shapes[IRI]) {
      return [];
    }
    const maskChildren = shapes[IRI].relationships.maskChildren;
    return Object.keys(maskChildren || {})
      .map(shapeIRI => shapes[shapeIRI])
      .filter(shape => !!shape);
  });

export const selectShapes = createSelector(
  selectEditorFeature,
  state => state.shapes,
);

export const selectBulkUpdates = createSelector(
  selectEditorFeature,
  state => state.bulkUpdates,
);

export const isAnimationMode = createSelector(
  selectEditorFeature,
  selectAnimationFeature,
  ({ noAnimationMode }, { currentAnimationId }) =>
    !!currentAnimationId && !noAnimationMode,
);

export const componentsList = createSelector(
  selectEditorFeature,
  ({ files, currentFileIRI }) => {
    return Object.keys(files[currentFileIRI].relationships.components || {})
      .filter(IRI => files[IRI])
      .map(IRI => files[IRI]);
  },
);

export const multiplicationByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => ({
    x: state.descriptors[IRI]?.multiplicationX,
    y: state.descriptors[IRI]?.multiplicationY,
  }));

export const dropShadowByIRI = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    state => state.descriptors[IRI]?.dropShadow,
  );

export const originalShapeTransforms = createSelector(
  selectEditorFeature,
  state => state.originalShapeTransforms,
);
export const currentShapeTransforms = createSelector(
  selectEditorFeature,
  state => state.currentShapeTransforms,
);
export const baseShapeTransforms = createSelector(
  selectEditorFeature,
  state => state.baseShapeTransforms,
);
export const originalDescriptors = createSelector(
  selectEditorFeature,
  state => state.originalDescriptors,
);
export const currentDescriptors = createSelector(
  selectEditorFeature,
  state => state.currentDescriptors,
);
export const currentSVGAttributes = createSelector(
  selectEditorFeature,
  state => state.currentSVGAttributes,
);
export const baseSvgAttributes = createSelector(
  selectEditorFeature,
  state => state.baseSvgAttributes,
);
export const canvasBackgroundColor = createSelector(
  selectEditorFeature,
  state =>
    state.files[state.currentFileIRI]?.literals?.descriptor?.backgroundColor ||
    '#ffffff',
);
export const canvasOrientation = createSelector(
  selectEditorFeature,
  state =>
    state.files[state.currentFileIRI]?.literals?.descriptor?.orientation ||
    'landscape',
);

export const baseSVGAttributesByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.baseSvgAttributes[IRI]);

export const baseShapeTransformByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.baseShapeTransforms[IRI]);

export const baseDescriptorByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.descriptors[IRI]);

export const currentSVGAttributesByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => state.currentSVGAttributes[IRI]);

export const selectDescriptors = createSelector(
  selectEditorFeature,
  state => state.descriptors,
);

export const colorPalette = createSelector(
  selectEditorFeature,
  state => state.colorPalette,
);

export const amISelected = (IRI: string) =>
  createSelector(selectEditorFeature, state => !state.selectedShapes[IRI]);

export const selectCrudEvents = createSelector(
  selectEditorFeature,
  state => state.crudEvents,
);

export const bBoxes = createSelector(
  selectEditorFeature,
  state => state.bBoxes,
);

export const getShapeResourceData = (
  IRI: string,
  transforms: Record<string, ShapeTransform>,
  descriptors: Record<string, GeneralShapeDescriptor>,
  svgAttributes: Record<string, SVGAttributes>,
  shapes: Record<string, ResourceData>,
  updatesByState: Record<string, any>,
  animations: any,
): ResourceData => {
  let override = {};
  // console.log('get.shape.resource.data', IRI);
  const relationships: Record<string, any> = {};
  if (!descriptors[IRI] || !transforms[IRI]) {
    return;
  }
  const { x, y } = transforms[IRI].scale || { x: 0, y: 0 };
  switch (descriptors[IRI].type) {
    case 'rectangle-shape':
      override = {
        width: x,
        height: y,
      };
      break;
    case 'circle-shape':
      override = {
        rx: (x as number) / 2,
        ry: (y as number) / 2,
      };
      break;
    // case 'imported-shape':
    //   relationships.instanceOf = (
    //     descriptors[IRI] as ImportedShapeDescriptor
    //   ).shapeIRI;
    //   break;
  }
  return {
    ...shapes[IRI],
    type: 'nw:Shape',
    literals: {
      ...shapes[IRI].literals,
      descriptor: {
        ...descriptors[IRI],
        position: {
          ...transforms[IRI].translate,
          scale: transforms[IRI]?.scale,
          rotation: transforms[IRI]?.rotation,
        },
        svgAttributes: svgAttributes[IRI],
        _animationsByKey: Object.entries(animations[IRI] || {}).reduce(
          (object, [animationId, animations]) => {
            object[animationId] = Object.entries(animations || {}).map(
              ([key, value]) => ({ ...value, key }),
            );
            return object;
          },
          {},
        ),
        updatesByState: updatesByState?.[IRI],
        ...override,
        // fixPosition: false,
        // 'show-hide': undefined,
      },
    },
    relationships: {
      ...relationships,
    },
  };
};

// export const getShapesToUpdate = createSelector(
//   selectEditorFeature,
//   selectAnimationFeature,
//   (
//     {
//       patchedShapes,
//       descriptors,
//       baseShapeTransforms,
//       baseSvgAttributes,
//       shapes,
//       updatesByState,
//       relationshipPatches,
//       deletedShapes,
//       newShapes,
//     },
//     { animationsByShape },
//   ) => {
//     // console.log('get-shapes-to-update', { patchedShapes });

//     const patches = Object.keys(patchedShapes).map(IRI =>
//       getShapeResourceData(
//         IRI,
//         baseShapeTransforms,
//         descriptors,
//         baseSvgAttributes,
//         shapes,
//         updatesByState,
//         animationsByShape,
//         relationshipPatches,
//       ),
//     );
//     const posts = Object.keys(newShapes).map(IRI =>
//       getShapeResourceData(
//         IRI,
//         baseShapeTransforms,
//         descriptors,
//         baseSvgAttributes,
//         shapes,
//         updatesByState,
//         animationsByShape,
//         relationshipPatches,
//       ),
//     );

//     return {
//       patches,
//       posts,
//       deletes: Object.keys(deletedShapes).map(IRI => ({ IRI })),
//     };
//   },
// );
export const getSelectedShapes = createSelector(
  selectEditorFeature,
  selectAnimationFeature,
  (
    {
      selectedShapes,
      descriptors,
      baseShapeTransforms,
      baseSvgAttributes,
      shapes,
      updatesByState,
    },
    { animationsByShape },
  ) => {
    return Object.keys(selectedShapes).map(IRI =>
      getShapeResourceData(
        IRI,
        baseShapeTransforms,
        descriptors,
        baseSvgAttributes,
        shapes,
        updatesByState,
        animationsByShape,
      ),
    );
  },
);

export const selectCurrentFileResourceData = createSelector(
  selectEditorFeature,
  selectAnimationFeature,
  ({ files, currentFileIRI, bBoxes, states }, { mainFrame, functions }) => {
    const file = files[currentFileIRI];
    if (!file) {
      return;
    }

    let xMin = Infinity,
      yMin = Infinity,
      xMax = -Infinity,
      yMax = -Infinity;

    if (Object.keys(bBoxes).length) {
      Object.values(bBoxes).map(({ x, y, width, height }) => {
        xMin = x < xMin ? x : xMin;
        yMin = y < yMin ? y : yMin;
        xMax = x + width > xMax ? x + width : xMax;
        yMax = y + height > yMax ? y + height : yMax;
      });
    }

    const offset = {
      x: xMin,
      y: yMin,
      width: xMax - xMin,
      height: yMax - yMin,
    };

    return {
      IRI: file.IRI,
      literals: {
        descriptor: {
          offset,
          animationFrame: mainFrame,
          animationFunctions: functions,
          states,
        },
      },
    };
  },
);
export const selectUpdatesByState = createSelector(
  selectEditorFeature,
  state => state.updatesByState,
);
export const deletedShapes = createSelector(
  selectEditorFeature,
  state => state.crudEvents.deletes,
);
export const isPatchLoading = createSelector(
  selectEditorFeature,
  state => state.isPatchLoading,
);
export const selectSelectedShapes = createSelector(
  selectEditorFeature,
  state => state.selectedShapes,
);
export const selectedShapeList = createSelector(selectEditorFeature, state =>
  Object.keys(state.selectedShapes || {}),
);
export const selectCurrentScenes = createSelector(
  selectProjectFeature,
  selectEditorFeature,
  ({ files: projectFiles }, { files, currentFileIRI }): ResourceData[] => {
    if (!currentFileIRI) {
      return [];
    }
    return Object.values(projectFiles)
      .filter(
        file =>
          (file.relationships?.childOf as ResourceData)?.IRI == currentFileIRI,
      )
      .map(file => files[file.IRI] || projectFiles[file.IRI]);
  },
);
export const selectScenes = createSelector(
  selectEditorFeature,
  ({ scenes }): Scene[] => {
    return scenes;
  },
);
export const selectStates = createSelector(
  selectEditorFeature,
  ({ states }) => states || [],
);

export const selectedSelectedState = createSelector(
  selectEditorFeature,
  ({ selectedState }) => selectedState,
);
export const isSaveActive = createSelector(
  selectEditorFeature,
  selectAnimationFeature,
  (state, animationState) => {
    return (
      !!Object.keys({
        ...state.crudEvents.posts,
        ...state.crudEvents.deletes,
        ...state.crudEvents.patches,
      }).length ||
      state.fileChanged ||
      animationState.animationChanged
    );
  },
);

export const isShapeSelected = (IRI: string) =>
  createSelector(selectEditorFeature, state => !!state.selectedShapes[IRI]);

export const selectedShapes = createSelector(selectEditorFeature, state =>
  Object.keys(state.selectedShapes),
);
export const _selectedShapes = createSelector(
  selectEditorFeature,
  state => state.selectedShapes,
);

export const showComponentSearch = createSelector(
  selectEditorFeature,
  state => state.showComponentSearch,
);

export const selectCurrentShapeTranslate = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    state => state.currentShapeTransforms[IRI]?.translate,

    // {
    // const { updatesByState, selectedState } = state;

    // if (!state.currentShapeTransforms[IRI]?.translate) {
    //   return;
    // }

    // let selectedPosition: Partial<ShapePosition> = {};

    // if (selectedState && updatesByState[IRI]?.[selectedState]?.position) {
    //   // console.log(
    //   //   '---- selectCurrentShapeTranslate -----', IRI,
    //   //   updatesByState[IRI]?.[selectedState]?.position,
    //   // );
    //   selectedPosition = updatesByState[IRI]?.[selectedState]?.position;
    //   // return updatesByState[IRI]?.[selectedState]?.position; //
    // }

    // return {
    //   x: selectedPosition.x || state.currentShapeTransforms[IRI]?.translate.x,
    //   y: selectedPosition.y || state.currentShapeTransforms[IRI]?.translate.y,
    // };
  );

export const selectCurrentShapeScale = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    const { updatesByState, selectedState } = state;
    if (
      selectedState &&
      (updatesByState[IRI]?.[selectedState]?.position as ShapeTransform)?.scale
    ) {
      return (updatesByState[IRI]?.[selectedState]?.position as ShapeTransform)
        ?.scale;
    }

    return state.currentShapeTransforms[IRI]?.scale;
    // const { type } = state.descriptors[IRI] || {};
    // switch (type) {
    //   case 'circle-shape':
    //     return {
    //       x: 2 * (descriptor as CircleShapeDescriptor).rx,
    //       y: 2 * (descriptor as CircleShapeDescriptor).ry,
    //     };
    //     default:
    //     return state.currentShapeTransforms[IRI]?.scale;
    // }
  });

export const selectCurrentShapeRotation = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    state => state.currentShapeTransforms[IRI]?.rotation,
  );

export const selectShapeTransforms = createSelector(
  selectEditorFeature,
  state => state.baseShapeTransforms,
);

export const selectVisitStack = createSelector(
  selectEditorFeature,
  state => state.visitStack,
);

const getDifferentAttribues = (svgAttributes: SVGAttributes[], key: string) => {
  const obj = {};

  try {
    svgAttributes
      .map(descriptor => _get(descriptor, key))
      .filter(val => !!val && val !== 'undefined' && val !== 'null')
      .map(val => {
        if (typeof val == 'object') {
          obj[JSON.stringify(val)] = true;
        } else {
          obj[val] = true;
        }
      });
  } catch (error) {
    console.log('error.getDifferentAttribues', error.message);
    return [];
  }

  return Object.keys(obj).map(key => {
    try {
      return JSON.parse(key);
    } catch (e) {
      return key;
    }
  });
};

// export const getProjectColors = createSelector(selectEditorFeature, state => {
//   return Object.entries(
//     state.project?.literals?.descriptor?.colorPalette || {},
//   ).map(([label, color]) => ({ label, color }));
// });

export const getFile = createSelector(
  selectEditorFeature,
  state => state.files[state.currentFileIRI],
);

// export const files = createSelector(selectEditorFeature, state =>
//   Object.values(state.files).sort((f1, f2) =>
//     f1.literals.label > f2.literals.label ? 1 : -1,
//   ),
// );

// export const selectCurrentScenes

export const isProjectLoading = createSelector(
  selectEditorFeature,
  state => state.isPatchLoading,
);

export const scenesAreLoaded = createSelector(
  selectEditorFeature,
  state => state.scenesAreLoaded,
);

export const isFileLoading = createSelector(
  selectEditorFeature,
  state => !!state.isFileLoading,
);

// export const currentProjectID = createSelector(selectEditorFeature, state => {
//   return state.project?.IRI?.split('#')?.[1];
// });

// export const currentProject = createSelector(selectEditorFeature, state => {
//   return state.project;
// });

export const isShapeSelect = (IRI: string) =>
  createSelector(selectEditorFeature, state => !!state.selectedShapes[IRI]);

export const rootShapeList = createSelector(selectEditorFeature, state => {});

export const shapeList = createSelector(
  selectEditorFeature,
  ({ shapes, files, currentFileIRI, crudEvents }) => {
    const file = files[currentFileIRI];
    return Object.keys(file.relationships.shapes)
      .filter(IRI => {
        // by the group shape children there's no relationship
        return !crudEvents.deletes[IRI];
      })
      .map(shapeIRI => shapes[shapeIRI])
      .sort(
        (s1, s2) =>
          s1.literals.descriptor?.index - s2.literals.descriptor.index,
      );
  },
);

export const shapeAttribute = <T>(IRI: string, attributeKey: string) =>
  createSelector(
    selectEditorFeature,
    state => _get(state.descriptors, [IRI, attributeKey]) as T,
  );

export const showByIf = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    // const { sceneState: editorState } = state;

    return true;
    // const { current } = editorState || {};
    // if (!current) {
    //   return true;
    // }

    // const ifDefinition = state.descriptors[IRI]?.if;

    // if (!ifDefinition) {
    //   return true;
    // }

    // return ifDefinition[current];
  });

export const ifPanelState = createSelector(
  selectEditorFeature,
  ({ scenes, descriptors, selectedShapes }): IfPanelState => {
    if (!scenes || scenes.length == 1) {
      return {
        type: 'hide',
      };
    }

    // console.log('scenes', scenes);

    const allValues: Record<number, boolean> = {};
    const sceneValues = {};
    scenes.map(({ name: scene }) => (sceneValues[scene] = {}));

    Object.keys(selectedShapes).map(IRI => {
      const ifValue = descriptors[IRI]?.if;

      if (!ifValue) {
        allValues[1] = true;
        scenes.map(({ name: scene }) => {
          sceneValues[scene][1] = true;
        });
      } else if (Array.isArray(ifValue)) {
        // TODO - avoid that in the editor.effect
        // scenes.map(scene => {
        //   if (ifValue.includes(scene)) {
        //     sceneValues[scene][1] = true;
        //   } else {
        //     sceneValues[scene][0] = true;
        //   }
        // });
        // allValues[0] = true;
      } else if (ifValue) {
        scenes.map(({ name: scene }) => {
          if (ifValue[scene]) {
            sceneValues[scene][1] = true;
          } else {
            sceneValues[scene][0] = true;
          }
        });
      }
      // console.log('if-value', ifValue);
    });

    // console.log('scene-values', sceneValues);

    return {
      type: 'show',
      all: Object.keys(allValues).map(val => !!val),
      scenes: scenes.map(({ name: key }) => ({
        key,
        values: Object.keys(sceneValues[key]).map(val => !!+val),
      })),
    };
  },
);

export const file = createSelector(
  selectEditorFeature,
  state => state.files[state.currentFileIRI],
);

export const getAttributeValueOfShape = (
  key: AnimationKeys,
  currentAnimationId: string,
  baseData: {
    transform?: ShapeTransform;
    svgAttributes?: SVGAttributes;
    descriptor?: GeneralShapeDescriptor;
  },
  animations: Record<string, Partial<Record<AnimationKeys, AnimationItem>>>,
): ShapeAttributeState => {
  if (currentAnimationId?.includes('_')) {
    const ids = currentAnimationId.split('_');
    currentAnimationId = ids[ids.length - 1];
  }
  if (animations?.[currentAnimationId]?.[key]) {
    const item = animations[currentAnimationId]?.[key];
    if (item.fcn) {
      return {
        type: 'function',
        value: item.fcn,
      };
    }
    return {
      type: 'animation',
      value: item.value,
    };
  }

  const { transform, svgAttributes, descriptor } = baseData;
  switch (key) {
    case 'translate':
    case 'scale':
    case 'rotation':
      return {
        type: 'base',
        value: transform[key],
      };

    case 'fill':
      return {
        type: svgAttributes?.fill ? 'base' : 'undefined',
        value: svgAttributes?.fill,
      };
    case 'stroke':
      return {
        type: svgAttributes?.stroke ? 'base' : 'undefined',
        value: svgAttributes?.stroke,
      };
    default:
      return {
        type: descriptor?.[key] ? 'base' : 'undefined',
        value: descriptor?.[key],
      };
  }
};

export const mergeObjects = (objects: AnimationValue[]) => {
  const returnObject: Record<string, PrimitiveValue[]> = {};

  objects.map(object => {
    Object.entries(object || {}).map(([key, value]) => {
      returnObject[key] ||= [];
      if (value !== undefined && !returnObject[key].includes(value)) {
        returnObject[key].push(value);
      }
    });
  });

  Object.entries(returnObject).map(([key, value]) => {
    returnObject[key] = value.sort((v1, v2) => (v1 > v2 ? 1 : -1));
  });

  return returnObject;
};

export const _attributeState = (attributeKey: AnimationKeys) =>
  createSelector(
    selectEditorFeature,
    selectAnimationFeature,
    (
      {
        selectedShapes,
        baseShapeTransforms,
        baseSvgAttributes,
        descriptors,
        noAnimationMode,
        selectedState,
        updatesByState,
      },
      { currentAnimationId, animationsByShape, functions: animationFunctions },
    ): AttributePanelState => {
      const values = Object.keys(selectedShapes).map(shapeIRI =>
        getAttributeValueOfShape(
          attributeKey,
          noAnimationMode ? '' : currentAnimationId,
          {
            transform: baseShapeTransforms[shapeIRI],
            svgAttributes: baseSvgAttributes[shapeIRI],
            descriptor: descriptors[shapeIRI],
          },
          animationsByShape[shapeIRI],
        ),
      );
      const isThereBase = !!values.find(({ type }) => type == 'base');
      const isThereAnimation = !!values.find(({ type }) => type == 'animation');
      const isThereFunction = !!values.find(({ type }) => type == 'function');
      const isThereUndefined = !!values.find(({ type }) => type == 'undefined');

      const optionCount = [
        isThereBase,
        isThereAnimation,
        isThereFunction,
        isThereUndefined,
      ].filter(val => val).length;

      if (optionCount > 1) {
        return { type: 'mixed' };
      }

      const functions = Object.keys(animationFunctions?.[attributeKey] || {});

      if (isThereFunction) {
        const selectedFunctions = values.reduce((prev, { value }) => {
          if (!prev.includes(value as string)) {
            prev.push(value as string);
          }
          return prev;
        }, [] as string[]);

        return {
          type: 'function',
          functions,
          selectedFunctions,
        };
      }

      if (isThereBase) {
        return {
          type: 'base',
          functions,
          value: mergeObjects(values.map(({ value }) => value)),
        };
      }

      if (isThereAnimation) {
        return {
          type: 'animation',
          functions,
          value: mergeObjects(values.map(({ value }) => value)),
        };
      }

      if (isThereUndefined) {
        return {
          type: 'undefined',
          functions,
          value: null,
        };
      }

      return null;
    },
  );

export const attributeState = (attributeKey: AnimationKeys) =>
  createSelector(
    selectEditorFeature,
    selectAnimationFeature,
    (
      {
        selectedShapes,
        baseShapeTransforms,
        baseSvgAttributes,
        descriptors,
        noAnimationMode,
        selectedState,
        updatesByState,
      },
      { currentAnimationId, animationsByShape, functions: animationFunctions },
    ): AttributePanelState => {
      const selectedShapeIRIs = Object.keys(selectedShapes);
      if (selectedShapeIRIs.length == 0) {
        return {
          type: currentAnimationId ? 'animation' : 'base',
          state: 'undefined',
        };
      }
      let type: 'base' | 'animation';

      function getReturnValuesAndState(
        objects: any[],
      ): ['defined' | 'mixed', Record<string, any[]> | any[]] {
        const returnValue = {};
        const primitiveValues = [];
        let state: 'defined' | 'mixed' = 'defined';
        let primitiveValue = false;

        objects.map(object => {
          if (typeof object == 'object') {
            Object.entries(object || {}).map(([key, val]) => {
              returnValue[key] ||= [];
              if (!returnValue[key].includes(val)) {
                returnValue[key].push(val);
              }
            });
          } else {
            primitiveValue = true;
            if (!primitiveValues.includes(object)) {
              primitiveValues.push(object);
            }
          }
        });

        if (primitiveValue) {
          state = primitiveValues.length > 1 ? 'mixed' : 'defined';
          return [state, primitiveValues];
        }

        const deleteKeys = [];
        Object.keys(returnValue).map(key => {
          switch (returnValue[key].length) {
            case 0:
              deleteKeys.push(key);
              break;
            case 1:
              if (!returnValue[key][0]) {
                deleteKeys.push(key);
              }
              break;
            default:
              returnValue[key] = returnValue[key].sort(asc);
              break;
          }
        });

        deleteKeys.map(key => delete returnValue[key]);

        return [state, returnValue];
      }

      const fcnNames = Object.keys(animationFunctions?.[attributeKey] || {});
      const functions = fcnNames.length ? fcnNames : undefined;

      if (currentAnimationId && !noAnimationMode) {
        type = 'animation';

        console.log('current-animation-id', currentAnimationId);
        if (currentAnimationId.includes('_')) {
          const ids = currentAnimationId.split('_');
          currentAnimationId = ids[ids.length - 1];
        }
        console.log('current-animation-id > after', currentAnimationId);

        const items: AnimationItem[] = selectedShapeIRIs.map(IRI => {
          if (attributeKey == 'fill') {
            if (!animationsByShape[IRI]?.[currentAnimationId]?.fill) {
              return;
            }

            return {
              key: attributeKey,
              value: {
                color: animationsByShape[IRI]?.[currentAnimationId]?.fill.value,
                gradient: false,
              } as FillAnimation,
            };
          }

          return animationsByShape[IRI]?.[currentAnimationId]?.[attributeKey];
        });

        console.log('items', items);

        const isThereDefined = !!items.find(value => !!value && !value.fcn);
        const isThereUndefined = !!items.filter(value => !value).length;
        const isThereFunction = !!items.find(value => !!value?.fcn);

        const cnt = [isThereDefined, isThereUndefined, isThereFunction].filter(
          v => v,
        ).length;

        if (cnt > 1) {
          return { type, state: 'mixed', functions };
        }

        if (isThereUndefined) {
          return { type, state: 'undefined', functions };
        }

        if (isThereFunction) {
          const fcnKeys = items.reduce((object, item) => {
            object[item.fcn] = true;
            return object;
          }, {});

          if (Object.keys(fcnKeys).length > 1) {
            return {
              type,
              state: 'mixed',
              selectedFunctions: Object.keys(fcnKeys).sort(asc),
              isFunction: true,
              functions,
            };
          } else {
            const [fcn] = Object.keys(fcnKeys);

            if (fcn == 'original') {
              if (Object.keys(selectedShapes).length > 1) {
                return {
                  type,
                  state: 'mixed',
                  selectedFunctions: Object.keys(fcnKeys).sort(asc),
                  isFunction: true,
                  functions,
                };
              }
              const [IRI] = Object.keys(selectedShapes);
              let value: AnimationValue;
              switch (attributeKey) {
                case 'scale':
                  value = baseShapeTransforms[IRI].scale;
                  break;
                case 'translate':
                  value = baseShapeTransforms[IRI].translate;
                  break;
                case 'rotation':
                  console.log(
                    'rotation-state',
                    baseShapeTransforms[IRI].rotation,
                  );
                  value = baseShapeTransforms[IRI].rotation || { angle: 0 };
                  break;
                case 'stroke':
                  value = baseSvgAttributes[IRI].stroke;
                  break;
                  // case 'fill':
                  //   value = baseSvgAttributes[IRI].fill;

                  break;
              }
              // -- //
              return {
                type,
                state: 'defined',
                isFunction: true,
                selectedFunctions: [fcn],
                functions,
                value,
              };
            }

            return {
              type,
              state: 'defined',
              isFunction: true,
              selectedFunctions: [fcn],
              functions,
              value: animationFunctions?.[attributeKey]?.[fcn].value as any,
            };
          }
        }

        // there is no function just values //
        const values = items.map(item => item.value);
        const [state, value] = getReturnValuesAndState(values);
        return { type, state, value, functions };
      } else {
        let values: AnimationValue[];
        type = 'base';

        switch (attributeKey) {
          // case 'fill':
          //   values = selectedShapeIRIs.map(IRI => ({
          //     color: baseSvgAttributes[IRI]?.fill,
          //     gradient: !!baseSvgAttributes[IRI]?.gradient,
          //     gradientColor: baseSvgAttributes[IRI]?.gradientColor,
          //     gradientDirection: baseSvgAttributes[IRI]?.gradientDirection,
          //   })) as FillAnimation[];
          //   break;
          case 'stroke':
          case 'stroke-width':
          case 'opacity':
            values = selectedShapeIRIs.map(
              IRI => baseSvgAttributes[IRI]?.[attributeKey],
            );
            break;
          case 'translate':
            values = selectedShapeIRIs.map(IRI => {
              if (
                selectedState &&
                updatesByState[IRI]?.[selectedState]?.position?.x
              ) {
                return pick(updatesByState[IRI]?.[selectedState]?.position, [
                  'x',
                  'y',
                ]);
              }
              return baseShapeTransforms[IRI]?.[attributeKey];
            });
            break;
          case 'scale':
          case 'rotation':
            values = selectedShapeIRIs.map(IRI => {
              if (
                selectedState &&
                updatesByState[IRI]?.[selectedState]?.position?.[attributeKey]
              ) {
                console.log('------ hey > scale ------');
                return updatesByState[IRI]?.[selectedState]?.position?.[
                  attributeKey
                ];
              }
              return baseShapeTransforms[IRI]?.[attributeKey];
            });
            break;
          case 'config':
            values = selectedShapeIRIs
              .filter(IRI => descriptors[IRI]?.type == 'hand-shape')
              .map(IRI => descriptors[IRI]?.[attributeKey]);
            break;

          // case 'env':
          //   // -- //
          //   // values = selectedShapeIRIs
          //   //   .map(IRI => descriptors[IRI])
          //   //   .filter(descriptor => {
          //   //     return (
          //   //       descriptor.type == 'imported-shape' &&
          //   //       (descriptor as ImportedShapeDescriptor).baseShapeDescriptor
          //   //         .inputs?.length
          //   //     );
          //   //   })
          //   //   .map(descriptor => descriptor.env);
          //   values = selectedShapeIRIs
          //     .map(IRI => descriptors[IRI])
          //     .filter(descriptor => {
          //       return (
          //         descriptor.type == 'imported-shape' &&
          //         (descriptor as ImportedShapeDescriptor).baseShapeDescriptor
          //           .inputs?.length
          //       );
          //     })
          //     .map(descriptor => descriptor.env);
          //   break;

          default:
            values = selectedShapeIRIs.map(IRI => {
              return descriptors[IRI]?.[attributeKey];
            });
            break;
        }

        if (!values.length) {
          return { type: 'base', state: 'undefined' };
        }

        if (attributeKey == 'stroke') {
          // console.log('values', values);
        }

        const isThereDefined = !!values.find(value => !!value);
        const isThereUndefined = !!values.filter(value => !value).length;

        if (isThereDefined && isThereUndefined) {
          return { type: 'base', state: 'mixed', functions };
        }

        if (isThereUndefined && !isThereDefined) {
          return { type: 'base', state: 'undefined', functions };
        }

        if (attributeKey == 'handSections') {
          return {
            type,
            state: values.length ? 'defined' : 'undefined',
            value: values[0],
          };
        }

        const [state, value] = getReturnValuesAndState(values);
        return {
          type,
          state,
          value,
          functions,
        };
      }
    },
  );

export const onlyShapeType = (
  type:
    | 'hand-shape'
    | 'hand-shape-next'
    | 'path-shape'
    | 'text-shape'
    | 'circle-shape'
    | 'arm-shape',
) =>
  createSelector(selectEditorFeature, ({ selectedShapes, descriptors }) => {
    return !Object.keys(selectedShapes).find(
      IRI => descriptors[IRI].type !== type,
    );
  });
export const canvasPosition = createSelector(
  selectEditorFeature,
  ({ scenes, currentScene }) => {
    if (!currentScene || currentScene.length < 2) {
      return;
    }
    const mainScene = currentScene[0];
    const subScene = currentScene[1];
    const mainSceneIndex = scenes.findIndex(s => s.name == mainScene);
    const subSceneIndex = scenes[mainSceneIndex].subscenes.findIndex(
      s => s.name == subScene,
    );
    return scenes[mainSceneIndex].subscenes[subSceneIndex].position;
  },
);

export const selectTextDescriptorByIRI = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    ({ descriptors }): TextShapeDescriptor =>
      (descriptors[IRI] as TextShapeDescriptor)?.textConfig,
  );

export const textAttributeState = createSelector(
  selectEditorFeature,
  ({ selectedShapes, descriptors }) => {
    const textShapes = Object.keys(selectedShapes)
      .map(IRI => descriptors[IRI])
      .filter(descriptor => descriptor?.type == 'text-shape');

    if (!textShapes.length) {
      return {
        type: 'hide',
      };
    }

    return {
      type: 'show',
      text: (textShapes[0] as TextShapeDescriptor).text,
      fontSize: (textShapes[0] as TextShapeDescriptor).fontSize,
      letterSpacing: (textShapes[0] as TextShapeDescriptor).letterSpacing,
    };
  },
);

export const selectStateOptions = createSelector(selectEditorFeature, state => {
  const { descriptors, selectedShapes } = state;
  const IRI = Object.keys(selectedShapes)[0];
  if (!IRI) {
    return [];
  }

  // TODO - migrate
  return [];
  // return (
  //   (descriptors[IRI] as ImportedShapeDescriptor)?.baseShapeDescriptor
  //     ?.states || []
  // );
});

export const boundingRectangle = createSelector(
  selectEditorFeature,
  ({ bBoxes }) => {
    if (Object.keys(bBoxes).length == 0) {
      return { x: 0, y: 0, width: 0, height: 0 };
    }

    let xMin = Infinity,
      yMin = Infinity,
      xMax = -Infinity,
      yMax = -Infinity;

    if (Object.keys(bBoxes).length) {
      Object.values(bBoxes).map(({ x, y, width, height }) => {
        xMin = x < xMin ? x : xMin;
        yMin = y < yMin ? y : yMin;
        xMax = x + width > xMax ? x + width : xMax;
        yMax = y + height > yMax ? y + height : yMax;
      });
    }

    return {
      x: xMin,
      y: yMin,
      width: xMax - xMin,
      height: yMax - yMin,
    };
  },
);

export const selectCurrentFileID = createSelector(
  selectEditorFeature,
  state => {
    const file = state.files[state.currentFileIRI];
    return file?.IRI.split('#')[1] || file?.IRI.split(':')[1];
  },
);

export const selectCurrentFileIRI = createSelector(
  selectEditorFeature,
  state => state.currentFileIRI,
);

export const selectNoAnimationMode = createSelector(
  selectEditorFeature,
  state => state.noAnimationMode,
);
export const selectFontLoaded = createSelector(
  selectEditorFeature,
  state => state.fontLoaded,
);

export const loadedScenes = createSelector(
  selectEditorFeature,
  state => state.loadedScenes,
);

export const selectOpacityByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    const opacity = state.currentDescriptors[IRI]?.opacity;
    return isNaN(opacity) ? 1 : opacity;
  });

export const selectFloatEffectByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    return state.currentDescriptors[IRI]?.floatEffect;
  });

export const selectRotateEffectByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    return state.currentDescriptors[IRI]?.rotateEffect;
  });
export const selectPathSectionsByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    return (state.currentDescriptors[IRI] as PathShapeDescriptor)?.sections;
  });

export const selectArmShapeConfig = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    return (state.currentDescriptors[IRI] as ArmShapeDescriptor)?.config;
  });

export const selectHandShapeConfig = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    return (state.currentDescriptors[IRI] as HandShapeNewDescriptor)?.config;
  });

export const selectConstraintByIRI = (IRI: string) =>
  createSelector(selectEditorFeature, state => {
    return state.currentDescriptors[IRI]?.constraints;
  });

export const largestIndex = createSelector(selectEditorFeature, state => {
  const { currentFileIRI } = state;
  return Math.max(
    ...Object.values(state.shapes)
      .filter(({ relationships }) => relationships.parent == currentFileIRI)
      .map(({ literals }) => (literals.descriptor.index || 0) as number),
  );
});

export const selectCanvasOffset = createSelector(
  selectEditorFeature,
  ({ canvasOffset }) => canvasOffset,
);

export const selectCurrentBackgroundColor = createSelector(
  selectEditorFeature,
  ({ files, currentFileIRI }) => {
    const file = files[currentFileIRI];
    if (!file) {
      return;
    }

    const descriptor = file.literals.descriptor;
    const { backgroundColor } = descriptor || {};
    return backgroundColor || '#ffffff';
  },
);
export const selectDescriptorValueByIRI = (IRI: string, key: string) =>
  createSelector(
    selectEditorFeature,
    ({ descriptors }) => descriptors[IRI]?.[key],
  );
export const selectArcIntervalByIRI = (IRI: string) =>
  createSelector(
    selectEditorFeature,
    ({ descriptors: currentDescriptors }) =>
      (currentDescriptors[IRI] as CircleShapeDescriptor)?.arcInterval,
  );
