<ng-container *ngIf="!recordMode">
  <nw-right-click-menu></nw-right-click-menu>
  <nw-spacing-controller
    *ngIf="shapeService.showVerticalEvenSpacing"
  ></nw-spacing-controller>
  <nw-spacing-controller
    *ngIf="shapeService.showHorizontalEvenSpacing"
    [isRow]="true"
  ></nw-spacing-controller>
</ng-container>

<div
  class="canvas-component"
  (contextmenu)="onRightClick($event)"
  class="canvas-container"
  [class.fix-canvas]="fixCanvas"
  #container
  appDnd
  (fileDropped)="onFileDropped($event)"
>
  <div #canvas id="_canvas" class="w-100 h-100" (mouseover)="mouseover()"></div>

  <ng-container *ngIf="!recordMode">
    <nw-component-search
      *ngIf="showComponentSearch$ | async"
    ></nw-component-search>
    <nw-image-processing-panel></nw-image-processing-panel>
  </ng-container>

  <ng-container *ngIf="!recordMode">
    <nw-general-selector *ngIf="cs.generalSelectorConfig"></nw-general-selector>
  </ng-container>
</div>
