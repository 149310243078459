import { omit, pick as _pick, isEqual, cloneDeep } from 'lodash';
import { Text } from 'pixi.js';
import { ResourceData } from '../../../../elements/resource/resource.types';
import {
  AnimationItem,
  Coords,
  ShapeConfig,
  TextConfig,
  TextShapeDescriptor,
  TypeDef,
} from '../../../../elements/resource/types/shape.type';
import { RectangleElement } from '../primitive/rectangle-element';
import { RectangleController } from '../../../../elements/util/rectangle-controller/rectangle-controller';
import { ShapeService } from '../../shape.service';
import { selectTextDescriptorByIRI } from '../../../store/selector/editor.selector';
import { TextElement } from '../primitive/text-element';
import { PrimitiveShape } from '../primitive/primitive-shape';
import { SVG } from '../primitive/svg-element';
import { addAnimationItemAction } from '../../../animation/store/animation.actions';
import { filter } from 'rxjs/operators';
import { setDescriptorValue } from '../../../store/editor.crud.actions';

export class TextShape extends PrimitiveShape<SVG, TextShapeDescriptor> {
  textElement: Text;

  rect: RectangleElement;

  getType() {
    return 'text-shape';
  }

  get textValue(): string {
    if (!this.text) {
      return '';
    }
    const text = this.text;
    if (text.startsWith('$.')) {
      const varName = text.split('.')[1];
      return this.parent.env?.[varName] || text;
    }

    return this.text;
  }

  get dim(): Coords {
    const { width, height } = this._textElement.getBounds();
    return [width, height].map(val => val) as Coords;
  }

  save() {
    this.saveTranslate();
  }

  // get text() {
  //   const text = this.descriptor.text;
  //   const variable = /(?<={{).*(?=}})/.exec(text);
  //   if (variable?.length) {
  //     return (this.parent as GeneralShape).getVariable(variable[0]);
  //   }
  //   return text;
  // }

  get text() {
    return this.textConfig.text;
  }

  set text(value: string) {
    this.textConfig.text = value;
  }
  textConfig: TextConfig;
  get fontSize() {
    return this.textConfig.fontSize;
  }

  set fontSize(value: number) {
    this.textConfig.fontSize = value;
  }

  get wordWrap() {
    return this.textConfig.wordWrap;
  }

  set wordWrap(value: boolean) {
    this.textConfig.wordWrap = value;
  }

  get wordWrapWidth() {
    return this.textConfig.wordWrapWidth;
  }

  set wordWrapWidth(value: number) {
    this.textConfig.wordWrapWidth = value;
  }

  get letterSpacing() {
    return this.textConfig.letterSpacing || 0;
  }

  set letterSpacing(value: number) {
    this.textConfig.letterSpacing = value;
  }

  get fontStyle() {
    return this.textConfig.fontStyle || 'normal';
  }

  set fontStyle(value: 'normal' | 'italic' | 'oblique') {
    this.textConfig.fontStyle = value;
  }

  get fontWeight() {
    return this.textConfig.fontWeight || 'normal';
  }

  set fontWeight(value: 'normal' | 'bold' | 'bolder' | 'lighter') {
    this.textConfig.fontWeight = value;
  }

  get containerForRc() {
    return this.container;
  }

  get hasFrameRectangle() {
    return true;
  }

  get frameRectangleBounds() {
    return this.textElement.getBounds();
  }

  get inputs(): TypeDef {
    return [
      ...super.inputs,
      {
        key: 'text',
        value: 'string',
      },
      {
        key: 'fontSize',
        value: 'string',
      },

      {
        key: 'fontStyle',
        value: 'string',
        options: ['normal', 'italic', 'oblique'],
      },
      {
        key: 'fontWeight',
        value: 'string',
        options: ['normal', 'bold', 'bolder', 'lighter'],
      },
    ];
  }

  get env() {
    return {
      ...super.env,
      ...{
        text: this.textValue || '',
        fontSize: this.fontSize || '',
        fontStyle: this.fontStyle || '',
        fontWeight: this.fontWeight || '',
      },
    };
  }

  constructor(
    service: ShapeService,
    data: ResourceData<TextShapeDescriptor>,
    config: ShapeConfig,
  ) {
    super(service, data, config);

    this.textConfig =
      this.descriptor.textConfig ||
      _pick(this.descriptor, [
        'text',
        'fontSize',
        'align',
        'fontStyle',
        'fontWeight',
        'wordWrap',
        'wordWrapWidth',
        'letterSpacing',
        'fontFamily',
      ]);

    if (this.config?.isRoot) {
      this.store
        .select(selectTextDescriptorByIRI(this.IRI))
        .pipe(filter(val => !!val))
        .subscribe(textConfig => {
          if (isEqual(textConfig, this.textConfig)) {
            return;
          }
          this.textConfig = cloneDeep(textConfig);
          this.refreshElement();
        });
    }

    this.keyEventSubscribe('t+a', () => {
      console.log('t+a', this.selected, this.text);
      if (!this.selected) {
        return;
      }
      console.log('dispatch-yo');
      this.store.dispatch(
        addAnimationItemAction({
          IRI: this.IRI,
          item: {
            key: 'type-animation',
            value: true,
          },
        }),
      );
    });
    this.init();
    if (this.descriptor.hidden) {
      this._hide();
    }
  }

  initRC() {
    console.log('');
  }

  get keys() {
    return ['text', 'fontSize', 'fontStyle', 'fontWeight'];
  }

  afterInit(): void {
    super.afterInit();

    Object.values(this.animationsById || {}).map(animations => {
      if (animations['type-animation']) {
        this.hide();
        this.hiddenByAnimation = true;
      }
    });
  }

  saveKey(innerKey: keyof TextConfig, value: number | string | boolean) {
    this.store.dispatch(
      setDescriptorValue({
        IRI: this.IRI,
        key: 'textConfig',
        innerKey,
        value,
      }),
    );
  }

  updateOpacity(opacity: number) {
    // this.textElement.opca
    this._textElement.element.alpha = opacity;
  }

  envChanged(env: Record<string, any>) {
    super.envChanged(omit(env, this.keys));
    if (!env) {
      return;
    }
    let changed = false;
    this.keys.map(key => {
      if (env[key] && env[key] !== this[key]) {
        this[key] = env[key];
        changed = true;
      }
    });
    if (changed) {
      this.refreshElement();
      this.save();
    }
  }

  typeAnimation: {
    originalText: string;
    current: number;
    division: number;
  };

  start: number;

  prepareKeyValueAnimation(
    animation: AnimationItem,
    division: number,
    duration: number,
  ) {
    super.prepareKeyValueAnimation(animation, division, duration);
    const { key, value } = animation;
    switch (key as string) {
      case 'fontWeight':
        this.fontWeight = value as 'normal' | 'bold' | 'bolder' | 'lighter';
        this.refreshElement();
        break;
    }
  }
  startAnimation(
    id: string,
    division: number,
    inverse?: boolean,
    duration?: number,
  ): void {
    Object.values(this.animationsById?.[id] || {}).map(({ key, value }) => {
      switch (key) {
        case 'text':
          this.text = value as string;
          this.refreshElement();
          break;
        case 'fontSize':
          this.fontSize = value as number;
          this.refreshElement();
          break;
        case 'fontWeight':
          this.fontWeight = value as 'normal' | 'bold' | 'bolder' | 'lighter';
          this.refreshElement();
          break;
        case 'type-animation':
          this._show();
          this.start = performance.now();
          this.typeAnimation = {
            originalText: `${this.textValue}`,
            current: 0,
            division: division / this.textValue.length,
          };
          this.text = '';
          this.refreshElement();
          break;
      }
    });
    // this.animationsById?.[id]; //
    super.startAnimation(id, division, inverse, duration);
  }

  incrementAnimation(
    increment: number,
    id?: string,
    maxIncrement?: number,
  ): void {
    super.incrementAnimation(increment, id, maxIncrement);

    if (this.typeAnimation) {
      this.typeAnimation.current += increment;
      const { current, division, originalText } = this.typeAnimation;
      const maxChars = Math.floor(current / division);
      this.text = originalText.slice(0, maxChars);
      this.refreshElement();
    }

    if (this.fillColorIncrement) {
      this._textElement.patch({
        fill: this.fillColorIncrement.increment(increment),
      });
    }
  }

  endAnimationByKeyValue(key: string, value: any): void {
    super.endAnimationByKeyValue(key, value);
    if (this.typeAnimation) {
      this.typeAnimation = null;
      this.text = this.descriptor.text;
      this.refreshElement();
    }
  }

  _textElement: TextElement;

  rcLength: number;
  unMovedFontSize: number;
  unMovedWordWrapWidth: number;
  unMovedDim: Coords;
  init() {
    super.init();

    this._textElement = new TextElement(this, this.container, {
      text: this.textValue,
      fontSize: this.fontSize,
    });

    this.refreshElement();

    this._textElement
      .click(() => this.clicked())
      .mouseover(() => {
        // if (!this.cs.shapeAddMode) {
        this.select({ onHover: true });
        // }
      })
      .mouseout(() => {
        // if (!this.cs.shapeAddMode) {
        this.deselect({ onHover: true });
        // }
        // handled at the rectangle controller appear
      })
      .drag(
        (dx, dy) => {
          this.service.drag(dx, dy);
        },
        () => this.localStartDrag(),
        () => {
          console.log('ps.element > endDrag');
          this.service.endDrag();
        },
      );

    // -- //

    if (this.editable) {
      const [width, height] = this.dim;
      this.rcLength = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2));
      this.rc = new RectangleController(
        this,
        {
          width,
          height,
          offset: [0, 0],
          showRectangle: true,
          skipRC: {
            'center-top': true,
            'center-bottom': true,
          },
          startDrag: () => {
            this.unMovedFontSize = this.fontSize;
            this.unMovedWordWrapWidth = this.wordWrapWidth;
            this.unMovedDim = this.dim as Coords;
            this.rcLength = Math.sqrt(
              Math.pow(this.unMovedDim[0], 2) + Math.pow(this.unMovedDim[1], 2),
            );
          },
          drag: ({ x, y, width, height, point }) => {
            // console.log('x', x, 'y', y, 'width', width, 'height', height); //
            this.x = x;
            this.y = y;

            switch (point) {
              case 'left-center':
              case 'right-center':
                this.wordWrap = true;
                this.wordWrapWidth = width;
                break;
              default:
                // -- //

                // if (width > w && height > h) {

                const length = Math.sqrt(
                  Math.pow(width, 2) + Math.pow(height, 2),
                );

                const ratio = length / this.rcLength;

                // console.log({ length, rcLength: this.rcLength });
                this.fontSize = Math.floor(ratio * this.unMovedFontSize);
                this.saveKey('fontSize', this.fontSize);

                if (this.wordWrap) {
                  this.wordWrapWidth = ratio * this.unMovedWordWrapWidth;
                  this.saveKey('wordWrapWidth', this.wordWrapWidth);
                }

                break;
            }

            // this.redraw();
            this.applyTranslate({ x, y });
            this.refreshElement(true);
          },
          endDrag: point => {
            switch (point) {
              case 'left-center':
              case 'right-center':
                break;
              default:
                this.saveKey('fontSize', this.fontSize);
                break;
            }

            const [w, h] = this.dim;
            this.rc?.patch({ width: w, height: h }, true);
            this.saveKey('wordWrap', this.wordWrap);
            this.saveKey('wordWrapWidth', this.wordWrapWidth);
            this.save();
          },
          clicked: () => this.clicked(),
          mouseout: () => {
            // console.log('text-shape rc > mouseout');
            if (!this.cs.shapeAddMode) {
              this.deselect({ onHover: true });
            }
          },
        },
        this.circleContainer,
        this.auxCircleContainer,
      );

      this.rc.hide();

      // this.rect = new Rectangle(this, this.circleContainer, {
      //   width,
      //   height,
      //   'stroke-width': 0,
      // })
      //   .drag(
      //     (dx, dy) => this.cs.drag(dx, dy),
      //     () => this.cs.startDrag(),
      //     () => this.cs.endDrag()
      //   )
      //   .click(() => this.clicked())
      //   .mouseover(() => {
      //     this.select({ onHover: true });
      //   })
      // .mouseout(() => {
      //     this.deselect({ onHover: true });
      // })
    }

    this.selectedKeyEventSubscribe('f+ArrowUp', () => {
      this.fontSize++;
      this.refreshElement();
    });
    this.selectedKeyEventSubscribe('f+ArrowDown', () => {
      this.fontSize--;
      this.refreshElement();
    });
    this.selectedKeyEventSubscribe('f+ArrowRight', () => {
      this.fontSize += 5;
      this.refreshElement();
      this.cs.consumeKeyEvent('ArrowRight');
    });
    this.selectedKeyEventSubscribe('f+ArrowLeft', () => {
      this.fontSize -= 5;
      this.refreshElement();
      this.cs.consumeKeyEvent('ArrowLeft');
    });
  }

  startTransformation(dx?: number, dy?: number): void {
    super.startTransformation(dx, dy);
    this.unMovedDim = this.dim;
    this.rcLength = Math.sqrt(
      Math.pow(this.unMovedDim[0], 2) + Math.pow(this.unMovedDim[1], 2),
    );
    console.log('ts - startWordWrapWidth', this.wordWrapWidth);
  }

  _scaleX: number;
  _scaleY: number;

  transformRatio: number;
  transformation(scaleX: number, scaleY: number, dx?: number, dy?: number) {
    const length = Math.sqrt(
      Math.pow(this.unMovedDim[0] * scaleX, 2) +
        Math.pow(this.unMovedDim[1] * scaleY, 2),
    );

    this.transformRatio = length / this.rcLength;

    this._textElement.patch({
      fontSize: this.transformRatio * this.fontSize,
      wordWrap: this.wordWrap,
      wordWrapWidth: this.wordWrap
        ? Math.floor(this.transformRatio * this.wordWrapWidth)
        : undefined,
    });

    super.transformation(scaleX, scaleY, dx, dy, true);
  }

  endTransformation(): void {
    super.endTransformation();
    this.fontSize *= this.transformRatio;
    this.saveKey('fontSize', this.fontSize);
    if (this.wordWrap) {
      this.wordWrapWidth *= this.transformRatio;
      this.saveKey('wordWrapWidth', this.wordWrapWidth);
    }
  }

  convertHexToNumber(value: string) {
    return value ? Number(`0x${value.substring(1)}`) : 0;
  }

  refresh() {
    super.refresh();
    this.refreshElement();
  }

  // updateText() {
  //   if (this.cs.currentAnimation) {
  //     // -- // -- //
  //   } else {
  //     this._updateText();
  //   }
  // }

  refreshElement(noRectUpdate = false) {
    if (!this._textElement) {
      return;
    }
    const { fill, stroke, opacity } = this.svgAttributes;

    const { color } = fill || {};

    this._textElement.patch({
      text: this.textValue,
      fontSize: this.fontSize,
      fontStyle: this.fontStyle,
      fill: color?.startsWith?.('$') ? this.service.resolveColor(color) : color,
      wordWrap: this.wordWrap,
      wordWrapWidth: this.wordWrapWidth,
      letterSpacing: this.letterSpacing,
      stroke: stroke ? this.service.resolveColor(stroke.color) : undefined,
      'stroke-width': stroke?.width,
    });

    if (noRectUpdate) {
      return;
    }

    this.updateRect();
  }

  updateRect() {
    const [width, height] = this.dim;
    this.rect?.patch({ width, height });
    this.rc?.patch({ width, height }, true);
  }

  contextIsUpdated(): void {
    this.refreshElement();
  }

  patch(key: string, value: any): void {
    super.patch(key, value);
    this.refreshElement();
  }
}
