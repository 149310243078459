import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  AnimationPanelConfig,
  AttributePanelState,
} from '../../../services/animation/animation.types';
import {
  AnimationItem,
  AnimationKeys,
  AnimationValue,
} from '../../../elements/resource/types/shape.type';
import { Store } from '@ngrx/store';
import { currentAnimationId } from '../../animation/store/animation.selector';
import {
  addAnimationFunction,
  addAnimationItemAction,
} from '../../animation/store/animation.actions';
import { bulkUpdateFinish } from '../../store/editor.actions';
import { setDescriptorValue } from '../../store/editor.crud.actions';

@Component({
  selector: 'nw-general-attribute-header',
  templateUrl: './general-attribute-header.component.html',
  styleUrls: ['./general-attribute-header.component.scss'],
})
export class GeneralAttributeHeaderComponent implements OnInit {
  @Input()
  icon: string;

  @Input()
  title: string;

  @Input()
  customIcon: string;

  @Input()
  noDelete = false;

  @Input()
  key: AnimationKeys;

  @Input()
  attributeState: AttributePanelState;

  @Input()
  initialValue: any;

  @Input()
  noFunction: boolean;

  functions$: Observable<Array<{ name: string; item: AnimationItem }>>;

  functionSaveMode = false;
  newFunctionName: string;

  config: AnimationPanelConfig = {
    icon: 'north_east',
    animationKey: 'translate',
    fields: [],
  };

  get functions() {
    return ['original', ...(this.attributeState?.functions || [])];
  }

  get value() {
    return this.attributeState.value;
  }

  get baseValue() {
    return this.value as Record<string, any[]>;
  }

  get newFunctionEnabled() {
    if (typeof this.value == 'object') {
      return !Object.values(this.value as Record<string, any[]>).find(
        val => val.length > 1,
      );
    }
    return true;
  }

  currentAnimationId: string;

  constructor(private readonly store: Store) {}

  ngOnInit(): void {
    this.store.select(currentAnimationId).subscribe(currentAnimationId => {
      this.currentAnimationId = currentAnimationId;
    });
  }

  discardNewAnimation() {
    // -- // -- //
  }

  addFunction() {}

  // TODO - move this new line
  saveAttribute() {
    const fcnName = prompt('Please define the name of the animation');

    const _value = Object.entries(this.baseValue).reduce(
      (object, [key, val]) => {
        object[key] = val[0];
        return object;
      },
      {},
    );

    console.log('_value', _value);

    this.store.dispatch(
      addAnimationFunction({
        name: fcnName,
        item: {
          key: this.key,
          value: _value as AnimationValue,
        },
      }),
    );

    this.store.dispatch(
      addAnimationItemAction({
        item: {
          key: this.key,
          fcn: fcnName,
        },
      }),
    );

    // this.store.dispatch(addAnimationItemAction({
    //   item: {
    //     key: this.key,
    //     value: this.initialValue,
    //   }
    // }))
  }

  functionSelectMode = false;

  openFunctionSelector() {
    this.functionSelectMode = true;
  }

  selectFunction(fcn: string) {
    this.store.dispatch(
      addAnimationItemAction({
        item: {
          key: this.key,
          fcn,
        },
      }),
    );
    this.functionSelectMode = false;
  }

  addInitialValue() {
    this.store.dispatch(
      setDescriptorValue({
        key: this.key,
        value: this.initialValue,
      }),
    );
    this.store.dispatch(bulkUpdateFinish());
  }

  deleteAttribute() {
    this.store.dispatch(
      setDescriptorValue({
        key: this.key,
        value: undefined,
      }),
    );
  }

  fcnSelectorOpen = false;
  functionSelected(name: any) {
    this.store.dispatch(
      addAnimationItemAction({
        item: {
          key: this.key,
          fcn: name,
        },
      }),
    );
    console.log('fcn-selected', name);
    this.fcnSelectorOpen = false;
  }

  functionEditMode = false;

  editFunction() {
    this.functionEditMode = true;
  }
}
