<div class="w-100 h-100 root flex-row" #editorContainer>
  <ng-container [ngSwitch]="projectState$ | async">
    <div *ngSwitchCase="'error'">output</div>
    <div
      *ngSwitchCase="'loading'"
      class="w-100 h-100 flex-row space-around m-12 editor-container"
    >
      <div class="blink">Project is being loaded</div>
    </div>
    <ng-container *ngSwitchCase="'loaded'">
      <div class="h-100 flex-column stretch">
        <nw-project-header
          (setOverlay)="setOverlayConfig($event)"
        ></nw-project-header>
        <nw-side-panel></nw-side-panel>
      </div>
      <div
        class="fill-available-width overflow-hidden flex-row h-100"
        *ngIf="
          !shapeService.previewShapeIsLoading && shapeService.previewShape;
          else noFileSelected
        "
      >
        <div
          class="overflow-hidden h-100"
          fxLayout="column"
          style="margin-bottom: 2px; display: flex"
        >
          <nw-control-panel></nw-control-panel>
          <div
            *ngIf="shapeService.previewShapeIsLoading; else canvas"
            class="w-100 h-100 editor-container flex-row space-around"
          >
            <div
              class="flex-column gap-16"
              [class.blink]="shapeService.previewShapeIsLoading"
            >
              <img src="assets/please_select.svg" style="width: 156px" />
              <div *ngIf="!shapeService.previewShapeIsLoading">
                Please select a file
              </div>
              <div *ngIf="shapeService.previewShapeIsLoading">
                File is being loaded
              </div>
            </div>
          </div>
          <ng-template #canvas>
            <div
              fxFlex="1 1 auto"
              class="editor-container"
              style="overflow: hidden; display: flex"
            >
              <div
                class="position-relative w-100 flex-row space-around"
                *ngIf="cs.shapeAddMode == 'path'"
              >
                <div class="path-shape-extend-mode-type">
                  <div class="flex-row gap-10">
                    <div
                      class="path-shape-extend-case"
                      [ngStyle]="{
                        opacity: cs.pathShapeExtendMode == 'line' ? 1 : 0.3,
                      }"
                    >
                      <nw-icon
                        type="pen_size_2"
                        [size]="18"
                        [outlined]="true"
                      ></nw-icon>
                    </div>
                    <div
                      class="path-shape-extend-case"
                      [ngStyle]="{
                        opacity: cs.pathShapeExtendMode == 'curve' ? 1 : 0.3,
                      }"
                    >
                      <nw-icon
                        type="line_curve"
                        [size]="18"
                        [outlined]="true"
                      ></nw-icon>
                    </div>
                    <div
                      class="path-shape-extend-case"
                      [ngStyle]="{
                        opacity:
                          cs.pathShapeExtendMode == 'anchor-curve' ? 1 : 0.3,
                      }"
                    >
                      <img
                        src="assets/curve.png"
                        style="height: 16px; width: 15px"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="position-top"
                fxLayout="column"
                fxLayoutAlign=" start"
              >
                <div
                  *ngIf="showBack"
                  class="cdk-content flex-row gap-6 pointer"
                  style="top: 30px; left: 4px; padding: 2px 8px"
                  (click)="moveBack()"
                >
                  <nw-icon type="keyboard_backspace" [size]="12"></nw-icon>
                  <div class="fs-11">parent</div>
                </div>
              </div>
              <iw-canvas> </iw-canvas>
            </div>
          </ng-template>

          <nw-animation-panel
            *ngIf="!shapeService.previewShapeIsLoading"
          ></nw-animation-panel>
        </div>

        <div class="fill-available-height" fxLayout="column">
          <nw-zoom-panel></nw-zoom-panel>
          <nw-shape-attribute-panel
            fxFlex="1 1 auto"
          ></nw-shape-attribute-panel>
        </div>
      </div>

      <ng-template #noFileSelected>
        <div
          fxFlex="1 1 auto"
          class="editor-container w-100 h-100 flex-row space-around"
        >
          <div
            class="flex-column gap-16"
            [class.blink]="shapeService.previewShapeIsLoading"
          >
            <img src="assets/please_select.svg" style="width: 156px" />
            <div *ngIf="!shapeService.previewShapeIsLoading">
              Please select a file
            </div>
            <div *ngIf="shapeService.previewShapeIsLoading">
              File is being loaded
            </div>
          </div>
        </div>
      </ng-template>
      <!-- </div> -->
    </ng-container>
  </ng-container>

  <div
    *ngIf="cs.notification"
    class="position-top"
    fxLayout="column"
    fxLayoutAlign=" center"
  >
    <div class="cdk-content">{{ cs.notification }}</div>
  </div>
</div>

<div *ngIf="overlayConfig" class="iw-overlay" (click)="overlayResponse()">
  <div class="layer"></div>
  <div
    [ngSwitch]="overlayConfig.type"
    class="content w-100 h-100 flex-row space-around"
    (click)="$event.stopPropagation()"
  >
    <nw-overlay-prompt
      *ngSwitchCase="'prompt'"
      (exit)="overlayResponse($event)"
    ></nw-overlay-prompt>
    <nw-project-languages-edit
      *ngSwitchCase="'languages'"
      (exit)="overlayResponse($event)"
    ></nw-project-languages-edit>
    <nw-color-palette-edit
      *ngSwitchCase="'color-palette'"
      (exit)="overlayResponse($event)"
    ></nw-color-palette-edit>
    <nw-project-users
      *ngSwitchCase="'users'"
      (exit)="overlayResponse($event)"
    ></nw-project-users>
    <nw-color-selector
      *ngSwitchCase="'color-selector'"
      (exit)="overlayResponse($event)"
    ></nw-color-selector>
  </div>
</div>
