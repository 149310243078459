import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAttributeComponent } from '../../../../base/base-attribute.component';
import { _attributeState } from '../../../../../store/selector/editor.selector';

@Component({
  selector: 'nw-subpath-move-animation',
  templateUrl: './subpath-move-animation.component.html',
  styleUrls: ['./subpath-move-animation.component.scss'],
})
export class SubPathMoveAnimationComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'subpath-move';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('subpath-move'));
    this.attributeState$.subscribe(attrState => {
      // console.log('path-attribute-start', attrState); //
    });
  }
}
