import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { _attributeState } from '../../store/selector/editor.selector';
import { BaseAttributeComponent } from '../base/base-attribute.component';
import { HandShapeSectionDescriptorNew } from '../../shape/shapes/path-shape/hand-shape-next/hand-shape-next';

@Component({
  selector: 'iw-hand-shape-attribute',
  templateUrl: './hand-shape-attribute.component.html',
  styleUrls: ['./hand-shape-attribute.component.scss'],
})
export class HandShapeAttributeComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'config';
  }

  sections: HandShapeSectionDescriptorNew[];

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('config'));

    this.attributeState$.subscribe(val => {
      this.sections = val?.value?.handSections?.[0];
    });
  }

  changeSection(index: number, section: HandShapeSectionDescriptorNew) {
    this.updateSubAttributeValue(
      'handSections',
      this.sections.map((s, i) => (i == index ? section : s)),
    );
  }

  addNewSection() {
    this.updateSubAttributeValue('handSections', [
      ...this.sections,
      {
        x: 300,
        y: 0,
        r: 20,
      },
    ]);
  }

  removeSection(index: number) {
    this.updateSubAttributeValue(
      'handSections',
      this.sections.filter((_section, i) => i !== index),
    );
  }
}
