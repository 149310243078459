<div
  #host
  class="editable-text"
  (click)="clickText($event)"
  [style.cursor]="editDisabled ? 'pointer' : 'text'"
>
  <div
    class="text-view no-select-x"
    [ngClass]="{ hidden: editMode, underline: underline }"
  >
    {{ text }}
  </div>

  <div
    *ngIf="editMode"
    [style.position]="'relative'"
    [style.top.px]="-18"
    [style.left.px]="-2"
  >
    <div [style.position]="'absolute'">
      <input
        #input
        [(ngModel)]="text"
        (keydown)="change($event)"
        (keyup)="$event.stopPropagation()"
      />
    </div>
  </div>

  <!-- <nw-floating [top]="-18" [left]="-2" *ngIf="editMode">
    <input #input [(ngModel)]="text" (keydown)="change($event)" />
  </nw-floating> -->
</div>
