import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'nw-overlay-prompt',
  templateUrl: './overlay-prompt.component.html',
  styleUrls: ['./overlay-prompt.component.scss'],
})
export class OverlayPromptComponent {
  @Output()
  exit = new EventEmitter();

  answer(value: boolean) {
    console.log('answer', value);
    this.exit.emit(value);
  }
}
