import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { getCombinedColorPalette } from '../../../projects/project.selector';
import { map } from 'rxjs/operators';
import { CanvasService } from '../../../services/canvas/canvas.service';

@Component({
  selector: 'nw-color-selector',
  templateUrl: './color-selector.component.html',
  styleUrls: ['./color-selector.component.scss'],
})
export class ColorSelectorComponent implements OnDestroy {
  @Output()
  exit = new EventEmitter();

  colorOptions$: Observable<
    {
      color: string;
      label: string;
    }[]
  >;

  selectedIndex = 2;
  get optionsLength() {
    return this.options?.length || 0;
  }

  subs: Subscription[];

  options: { label: string; color: string }[];

  constructor(
    private readonly store: Store,
    private readonly cs: CanvasService,
  ) {
    this.colorOptions$ = this.store.select(getCombinedColorPalette).pipe(
      map(colors => {
        return [
          {
            label: 'white',
            color: '#ffffff',
          },
          {
            label: 'black',
            color: '#000000',
          },
          ...colors,
        ];
      }),
    );

    this.subs = [
      this.colorOptions$.subscribe(options => (this.options = options)),
      this.cs.keyEventSubscribe('ArrowUp', () => {
        this.selectedIndex = Math.max(0, this.selectedIndex - 1);
      }),
      this.cs.keyEventSubscribe('ArrowDown', () => {
        this.selectedIndex++;
        if (this.selectedIndex >= this.optionsLength) {
          this.selectedIndex = 0;
        }
      }),
      this.cs.keyEventSubscribe(
        'Shift',
        () => {
          this.selectedIndex++;
          if (this.selectedIndex >= this.optionsLength) {
            this.selectedIndex = 0;
          }
        },
        1,
      ),
      this.cs.keyEventSubscribe('Enter', () => {
        if (this.selectedIndex <= 1) {
          this.exit.emit(this.options[this.selectedIndex].color);
        } else {
          this.exit.emit('$.' + this.options[this.selectedIndex].label);
        }
      }),
    ];
  }
  // resolveColor(color: string) {
  //   return this.shapeService.resolveColor(color);
  // }
  ngOnDestroy(): void {
    this.subs.map(sub => sub.unsubscribe());
  }
}
