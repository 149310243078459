import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { AnimationFrame, SoundAnimationFrame } from './animation.types';
import { AnimationFrameObject } from '../../frame/animation-frame-object';
import { CanvasService } from '../../../../services/canvas/canvas.service';
import { Subscription } from 'rxjs';
import {
  GeneralSelectorConfig,
  GeneralSelectorOptions,
} from '../../../../components/util/general-selector/general-selector.component';
import { ImportedShape } from '../../../shape/shapes/general/imported/imported-shape';
import { MainAnimationFrameObject } from '../../frame/main-animation-frame-object';
import { AnimationService } from '../../animation.service';
import { FunctionAnimationFrameObject } from '../../frame/function/function-animation-frame';
import { ShapeService } from '../../../shape/shape.service';

@Component({
  selector: 'nw-animation-frame',
  templateUrl: './animation-frame.component.html',
  styleUrls: ['./animation-frame.component.scss'],
})
export class AnimationFramePanelComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  opened = false;

  @ViewChild('duration')
  durationInput: ElementRef;

  @Input()
  parent?: FunctionAnimationFrameObject;

  _frame: MainAnimationFrameObject;

  @Input()
  set frame(val: MainAnimationFrameObject) {
    this._frame = val;
  }

  @Input()
  soundFrameMode = false;

  get frame() {
    return this._frame;
  }
  showFunctions = false;
  focusInput = false;

  get parentCnt() {
    return this.frame.parentCount;
  }

  get height() {
    // if (this.soundFrameMode) {
    //   return (this._frame as RootAnimationFrame).soundHeight;
    // }
    return this.frame.height;
  }
  get width() {
    return this.frame.width;
  }

  get amISelected() {
    if (this.soundFrameMode) {
      return this.frame.rootMainFrame.soundEditMode;
    }

    if (this.rootMainFrame.soundEditMode) {
      return false;
    }

    if (this.parent) {
      return this.parent.id === this.animationService.selectedFunctionId;
    }
    return !this.animationService.selectedFunctionId;
  }

  get frames() {
    if (this.soundFrameMode) {
      return Object.values(this.frame.rootMainFrame.soundStore);
    }

    return Object.entries(this.frame.store || {})
      .filter(([key]) => key !== '__selected__')
      .map(([, value]) => value)
      .sort((f1, f2) => (f1.isSelected2 && !f2.isSelected2 ? 1 : -1));
  }

  constructor(
    public readonly cs: CanvasService,
    public readonly shapeService: ShapeService,
    public readonly animationService: AnimationService,
  ) {}

  ngOnInit(): void {
    this.subscriptions = [
      ['Shift+Enter', 99],
      'ArrowUp',
      'ArrowDown',
      'ArrowLeft',
      'ArrowRight',
      'Enter',
      'Escape',
      'Shift+Backspace',
      'Shift+Tab',
      'Tab',
      'Alt', // duration decrement
      'Control', // duration increment
      'AltLeft',
      'Shift+F',
      'm+ArrowUp',
      'd',
      'x',
      'f',
      's',
    ].map(input => {
      let prio = 100;
      let key: string;
      if (Array.isArray(input)) {
        key = input[0] as string;
        prio = input[1] as number;
      } else {
        key = input as string;
      }
      return this.cs.keyEventSubscribe(
        key,
        () => {
          if (this.amISelected) {
            this.keyHandler(key);
          }
        },
        prio,
      );
    });

    if (!this.soundFrameMode) {
      this.cs.longKeyEventSubscribe('ArrowDown', () => {
        if (this.amISelected) {
          if (this.selectedObject?.soundAnimationFrame) {
            this.cs.consumeKeyEvent('ArrowDown');
            this.selectedObject.selectSoundAnimationFrame();
          }
          // console.log('arrow-down > animationFrame');
        }
      });
    }

    this.cs.keyEventSubscribe('r+p', () => {
      if (this.amISelected) {
        this.cs.openGeneralSelector(
          this.getAnimationSelectorConfig(['newFunction', 'inverse']),
          (finalResult: string, allResults?: string[]) => {
            const id = Math.random().toString();
            switch (finalResult) {
              case 'animation':
                this.selectedObject.frame = {
                  id,
                  duration: 1,
                };
                break;
              case 'delay':
                this.selectedObject.frame = {
                  id,
                  duration: 1,
                  delay: true,
                };
                break;
              case 'newFunction':
                alert('No new function!');
                break;
              case 'inverse':
                break;
              case 'state-transition':
                console.log('result', finalResult, allResults);
                break;
              default:
                let functionTarget: { IRI: string; label: string };
                if (finalResult.startsWith('__')) {
                  finalResult = finalResult.slice(2);
                  const [IRI, fcn] = finalResult.split('__');
                  functionTarget = {
                    IRI,
                    label: this.shapeService.getResource(IRI).label,
                  };
                  finalResult = fcn;
                }

                this.selectedObject.frame = {
                  id,
                  function: finalResult,
                  functionTarget,
                  duration: 1,
                };
            }
            this.calc();
          },
        );
      }
    });
  }

  max(...params: number[]) {
    return Math.max(...params);
  }

  functionIsActive: boolean;

  selectFunction(name: string) {
    if (name) {
      this.selectedFunction = name;
    }
  }

  isFunctionSelected(name: string) {
    return this.selectedFunction === name;
  }

  keyHandler(key: string) {
    // event.stopPropagation(); //
    switch (key) {
      case 'ArrowUp':
        this.selectedObject?.arrowUp();
        break;
      case 'ArrowDown':
        this.frame?.selectParalell();
        break;
      case 'ArrowLeft':
        this.selectedObject?.arrowLeft();
        break;
      case 'ArrowRight':
        this.frame.selectNext();
        break;

      case 'Escape':
        // TODO - implement close-frame

        if (this.parent) {
          this.parent.closeFrame();
        }
        /* (
          this.selectedObject?.mainFrame
            .fcnParent as FunctionAnimationFrameObject
        )?.closeFrame?.(); */

        break;
      case 'Enter':
        if (this.cs.isPressed('Space')) {
          this.cs.preConsumeKeyEvent('Space');
        }

        if (this.selectedObject) {
          if (this.focusInput) {
            this.focusInput = false;
            return;
          }

          this.selectedObject.edit = true;
          this.selectedObject.preDelayMode = this.cs.isSpacePressed;
          this.focusInput = true;

          this.update();
        }
        break;

      case 'Shift+Enter':
        if (this.selectedObject && this.selectedObject.function) {
          (this.selectedObject as FunctionAnimationFrameObject).openFrame();
          this.cs.consumeKeyEvent('Shift');
          this.cs.consumeKeyEvent('Enter');
        }
        break;
      // TODO - move it back
      case 'Shift+Backspace':
        this.cs.consumeKeyEvent('Shift+Backspace');
        if (this.selectedFunction) {
          this.frame.deleteFunction(this.selectedFunction);
        } else {
          this.selectedObject?.remove();
          this.frame?.calcPositions();
        }
        this.update();
        break;
      case 'm+ArrowUp':
        console.log('--------- m+ArrowUp -----------');
        this.selectedObject.moveUp();
        this.update();
        break;
      case 'Shift+Tab':
        const selectedShapes = this.shapeService.selectedShapes;
        if (!this.selectedObject) {
          return;
        }

        switch (selectedShapes.length) {
          case 0:
            return;
          case 1:
            const [shape] = selectedShapes;
            if (shape.getType() !== 'is') {
              return;
            }

            if (
              (shape as ImportedShape).getComponentFile().literals.descriptor
                .animationFrame
            ) {
              let frame: AnimationFrameObject;
              if (this.cs.isPressed('ArrowDown')) {
                console.log('fcn - insertParalell');
                frame = this.selectedObject.insertParalell({
                  function: 'main',
                  functionTarget: { IRI: shape.IRI, label: shape.label },
                  duration: 1,
                });
                // We need that because the shift is pressed
                frame.select({ uniqueSelect: true });
              } else {
                console.log('fcn - insertNext');
                if (!this.selectedObject) {
                  return;
                }
                // -- // -- //
                frame = this.selectedObject.insertNext({
                  function: 'main',
                  functionTarget: { IRI: shape.IRI, label: shape.label },
                  duration: 1,
                });
                // We need that because the shift is pressed
                frame.select({ uniqueSelect: true });
              }

              this.update();
              return;
            }
        }
        break;
      case 'Tab':
        const baseFrame = this.selectedObject;
        // const frames = selectedShapes
        //   .filter(shape => shape.getType() == 'is')
        //   .map((shape, i) => {
        //     if (i == 0) {
        //       baseFrame = baseFrame.insertNext({
        //         function: 'main',
        //         functionTarget: { IRI: shape.IRI, label: shape.label },
        //         duration: this.selectedObject.duration,
        //       });
        //     } else {
        //       baseFrame = baseFrame.insertParalell({
        //         function: 'main',
        //         functionTarget: { IRI: shape.IRI, label: shape.label },
        //         duration: 1,
        //       });
        //     }
        //     return baseFrame;
        //   });
        // frames[0].select({ uniqueSelect: true });
        // this.update();

        if (this.selectedObject) {
          if (this.cs.isPressed('ArrowDown')) {
            this.selectedObject.insertParalell().select();
          } else {
            if (this.cs.isPressed('ArrowLeft')) {
              console.log('insertPrev');
              this.selectedObject.insertPrev();
              this.cs.consumeKeyEvent('ArrowLeft');
            } else {
              this.selectedObject
                .insertNext({
                  duration: this.selectedObject.duration,
                })
                .select();
            }
          }
          this.selectedObject.save();
          this.update();
        }
        break;

      case 'd':
        if (this.selectedObject) {
          if (this.selectedObject.delay) {
            this.selectedObject.frame.delay = false;
            return;
          }
          this.selectedObject.frame.delay = true;
          this.calc();
          this.save();
        }
        break;

      case 'x':
        if (this.selectedObject) {
          if (this.selectedObject.delay) {
            this.selectedObject.frame.delay = null;
          }
          // else if (this.selectedObject.function) {
          //   this.selectedObject.frame.function = null;
          // }
          this.calc();
          this.save();
        }
        break;

      case 'Alt':
        if (!this.selectedObject) {
          return;
        }
        let d1 = this.selectedObject.duration;
        switch (true) {
          case d1 < 0.1:
            d1 = 0.1;
            break;
          case d1 < 0.2:
            d1 = 0.2;
            break;
          case d1 < 0.3:
            d1 = 0.3;
            break;
          case d1 < 0.5:
            d1 = 0.5;
            break;
          case d1 < 1:
            d1 = 1;
            break;
          case d1 < 2:
            d1 = 2;
            break;
          case d1 < 3:
            d1 = 3;
            break;
          case d1 < 5:
            d1 = 5;
            break;
        }
        this.selectedObject.duration = d1;
        this.update();
        break;

      case 'Control':
        if (!this.selectedObject) {
          return;
        }
        let d2 = this.selectedObject.duration;
        switch (true) {
          case d2 > 5:
            d2 = 5;
            break;
          case d2 > 3:
            d2 = 3;
            break;
          case d2 > 2:
            d2 = 2;
            break;
          case d2 > 1:
            d2 = 1;
            break;
          case d2 > 0.5:
            d2 = 0.5;
            break;
          case d2 > 0.3:
            d2 = 0.3;
            break;
          case d2 > 0.2:
            d2 = 0.2;
            break;
          case d2 > 0.1:
            d2 = 0.1;
            break;
        }
        this.selectedObject.duration = d2;
        this.update();
        break;
      case 'f':
        this.createFunction();
        break;
    }
  }

  selectedFunction: string;
  selectedFunctionIndex = 0;

  update() {
    this.calc();
    this.save();
  }

  clearSelectedFunction() {
    this.selectedFunctionIndex = -1;
    this.selectedFunction = null;
  }

  createFunction() {
    if (this.selectedObject) {
      if (this.selectedId === this.frame.id) {
        return alert('From the main step you cannot create a function!');
      }

      const nexts = [];
      const children = [];
      Object.keys(this.frame._selected).map(id => {
        const frameObject = this.getFrameObjectById(id);
        if (frameObject.next && !frameObject.next.isSelected2) {
          nexts.push(frameObject.next);
        }
        if (frameObject.child && !frameObject.child.isSelected2) {
          children.push(frameObject.child);
        }
      });

      if (nexts.length > 1 || children.length > 1) {
        this.cs.clearKeys();
        return alert(
          'The current selection is invalid for creating a function, because it has multiple next or child node!',
        );
      }

      const functionName = prompt('Please, give a name for the function', '');
      if (functionName === 'main') {
        return alert(`The name 'main' is reserved!`);
      }

      this.cs.clearKeys();
      if (functionName) {
        this.addFunction(functionName);
      }
    }
  }

  addFunction(name: string) {
    this.frame.createFunction(name);
    this.update();
  }

  deselect() {
    if (this.frame) {
      this.frame.store.__selected__ = null;
    }
  }

  calc() {
    // this.frame?.calcPositions();
    // this.parent?.calcPositions();
    // this.frame.calcPositions();
    this.rootMainFrame.calcPositions();
    this.selectedObject?.updated();
  }

  recalc() {
    if (!this.frame) {
      return;
    }
    this.frame.calcPositions();
  }

  inputKeyDown(event: KeyboardEvent) {
    if (event.code === 'Enter' && this.selectedObject?.edit) {
      this.selectedObject.edit = false;
      this.frame.calcPositions();
      this.save();
      console.log('event.code > save', this.selectedObject.preDelay);
    }

    // This should be done with event.stopPropagation || event.preventDefault //
    this.cs.consumeKeyEvent(event.code);
  }

  ngOnDestroy() {
    // console.log('animation-frame-panel > onDestroy');
    // this.animationService.selectedFunctionId = null;
    this.subscriptions?.map(s => s.unsubscribe());
  }

  get store() {
    return this.frame.store;
  }

  get selectedObject() {
    return this.frame?.store.__selected__;
  }

  get selectedId() {
    return this.selectedObject?.id;
  }

  isSelected(id: string) {
    return (
      this.amISelected && this.frame._selected[id] && !this.selectedFunction
    );
  }

  getRowElements(id: string): AnimationFrameObject[] {
    return this.frame.store[id]?.rowElements || [];
  }

  getFrameObjectById(id: string) {
    return this.frame.store[id];
  }

  getColumnElements(frame: AnimationFrame) {
    const elements = [frame];
    let currentFrame = frame;
    while (currentFrame.paralell) {
      elements.push(currentFrame.paralell);
      currentFrame = currentFrame.paralell;
    }
    return elements;
  }

  // getRowElements(frame: AnimationFrame) {
  //   const elements = [frame];
  //   let currentFrame = frame;
  //   while (currentFrame.next) {
  //     elements.push(currentFrame.next);
  //     currentFrame = currentFrame.next;
  //   }
  //   return elements;
  // }

  save() {
    this.selectedObject?.save();
    // if (this.parent) {
    //   // -- //
    //   this.parent.save();
    // } else {
    //   // -- //
    //   this.frame.save();
    // }

    // this.mainFrame.save();
  }

  nameChanged(newName: string, oldName: string) {
    // this.frame[name = oldName].name = newName; //
    // this.frame.functions.find(({ name }) => name === oldName).name =
    //   newName;
    this.save();
  }

  get rootMainFrame() {
    return this.frame?.rootMainFrame;
  }

  getAnimationSelectorConfig(exclude = []): GeneralSelectorConfig {
    const states = this.cs.previewShape.states;
    const options: GeneralSelectorOptions = [
      {
        label: 'Animation',
        id: 'animation',
      },
      {
        label: 'Sound',
        id: 'sound',
        children: [
          {
            label: 'Noise',
            id: 'noise',
          },
          {
            label: 'Beep',
            id: 'beep',
          },
          {
            label: 'Inwedio',
            id: 'inwedio',
          },
        ],
      },
      {
        label: 'State transition',
        id: 'state-transition',
        children: states.map(state => ({
          label: state,
          id: state,
          children: states.map(state => ({
            label: state,
            id: state,
          })),
        })),
      },
      {
        label: 'State transition transform',
        id: 'state-transition-transform',
        children: states.map(state => ({
          label: state,
          id: state,
          children: states.map(state => ({
            label: state,
            id: state,
          })),
        })),
      },
      {
        label: 'MS',
        id: 'ms',
        children: [
          {
            id: 'ms',
            text: true,
          },
        ],
      },
      { label: 'Delay', id: 'delay' },
      { label: 'Inverse', id: 'inverse' },
      { label: 'New function', id: 'newFunction' },
    ].filter(({ id }) => !exclude?.includes(id));

    const functions = [
      ...this.getLocalFunctions(),
      ...this.getImportedFunctions(),
    ];

    console.log('console.log()', { functions });
    if (functions.length) {
      options.unshift({
        label: 'Function',
        id: 'function',
        children: functions,
      });
    }

    return { options };
  }

  getLocalFunctions() {
    return (
      this.frame.frame.functions?.map(({ name }) => ({
        label: name,
        id: name,
      })) || []
    );
  }

  getImportedFunctions() {
    return this.cs.previewShape?.shapes
      ?.filter(
        shape =>
          shape.getType() === 'is' &&
          shape.selected &&
          !!(shape as ImportedShape).baseShapeDescriptor.animationFrame,
      )
      .map((shape: ImportedShape) => ({
        label: `@${shape.label}`,
        id: '',
        children: [
          {
            label: 'main',
            id: `__${shape.IRI}__main`,
          },
          ...(shape.baseShapeDescriptor.animationFrame?.functions?.map(
            ({ name }) => ({
              label: name,
              id: `__${shape.IRI}__${name}`,
            }),
          ) || []),
        ],
      }));
  }
}
