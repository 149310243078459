import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  canvasOrientation,
  selectCurrentBackgroundColor,
} from '../../../store/selector/editor.selector';
import {
  setCanvasBackgroundColor,
  setCanvasOrientation,
} from '../../../store/editor.actions';
import { map } from 'rxjs/operators';
import {
  CanvasOrientation,
  ShapeDescriptor,
} from '../../../../elements/resource/types/shape.type';
import { ShapeService } from '../../../shape/shape.service';

@Component({
  selector: 'nw-file-settings',
  templateUrl: './file-settings.component.html',
  styleUrls: ['./file-settings.component.scss'],
})
export class FileSettingsComponent {
  bgColor$: Observable<string>;

  isLandscapeOrientation$: Observable<boolean>;

  constructor(
    private readonly store: Store,
    private readonly shapeService: ShapeService,
  ) {
    this.bgColor$ = this.store
      .select(selectCurrentBackgroundColor)
      .pipe(map(val => this.shapeService.resolveColor(val)));

    this.bgColor$.subscribe(bgColor => {
      // console.log('bg-color > sub', bgColor);
    });
    this.isLandscapeOrientation$ = this.store
      .select(canvasOrientation)
      .pipe(map(val => val == 'landscape'));
  }

  colorChanged(color: string) {
    this.store.dispatch(setCanvasBackgroundColor({ color }));
  }

  setOrientation(orientation: CanvasOrientation) {
    this.store.dispatch(setCanvasOrientation({ value: orientation }));
  }
}
