import { Component } from '@angular/core';
import { BaseAttributeComponent } from '../../../base/base-attribute.component';
import { _attributeState } from '../../../../store/selector/editor.selector';
import { Store } from '@ngrx/store';

@Component({
  selector: 'nw-translate-effect',
  templateUrl: './translate-effect.component.html',
  styleUrls: ['./translate-effect.component.scss'],
})
export class TranslateEffectComponent extends BaseAttributeComponent {
  constructor(readonly store: Store) {
    super(store);
    this.key = 'translateEffect';
    this.attributeState$ = this.store.select(
      _attributeState('translateEffect'),
    );
    this.attributeState$.subscribe(state => {
      // -- // -- // -- //
    });
  }
}
