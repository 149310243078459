import { Component, OnInit } from '@angular/core';
import { CanvasService } from '../../services/canvas/canvas.service';

export interface RightClickMenu {
  x: number;
  y: number;
  options: Array<{
    label: string;
    shortcut?: string;
    id: string;
  }>;
  handler: (id: string) => void;
}

@Component({
  selector: 'nw-right-click-menu',
  templateUrl: './right-click-menu.component.html',
  styleUrls: ['./right-click-menu.component.scss'],
})
export class RightClickMenuComponent implements OnInit {
  get rightClickMenu() {
    return this.cs.rightClickMenu;
  }
  get x() {
    return this.rightClickMenu.x + 'px';
  }
  get y() {
    return this.rightClickMenu.y + 'px';
  }
  get options() {
    return this.rightClickMenu.options;
  }

  constructor(private readonly cs: CanvasService) {}
  ngOnInit(): void {
    // this.cs.generalEventSubscribe(
    //   'show-right-click-menu',
    //   (menu: RightClickMenu) => (this.rightClickMenu = menu),
    // );
  }

  select(event: MouseEvent, id: string) {
    event.stopPropagation();
    this.rightClickMenu.handler(id);
  }
}
