import _, { cloneDeep, drop } from 'lodash';
import { Container } from 'pixi.js';
import {
  Coords,
  DragParams,
  ShapeControlPointPosition,
  ShapeDragParams,
  ShapeSelectParams,
} from '../../resource/types/shape.type';
import { GeneralShape } from '../../../element-editor/shape/shapes/general/general-shape';
import { RectangleElement } from '../../../element-editor/shape/shapes/primitive/rectangle-element';
import {
  DragResponse,
  PointController,
} from '../point-controller/point-controller';
import { BaseVector, Vector } from '../../base/vector/vector';
import { ImportedShape } from '../../../element-editor/shape/shapes/general/imported/imported-shape';

export type RCPoint =
  | 'left-top'
  | 'left-bottom'
  | 'right-top'
  | 'right-bottom'
  | 'center-bottom'
  | 'center'
  | 'center-top'
  | 'left-center'
  | 'right-center';

export interface RectangleDragResponse {
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  dx?: number;
  dy?: number;
  dw?: number;
  dh?: number;
  _dx?: number;
  _dy?: number;
  dAngle?: number;
  point?: RCPoint;
  angle?: number;
}

export interface RectangleControllerConfig {
  offset?: Coords;
  position?: Coords;
  selectedContainerMode?: boolean;
  width: number;
  height: number;
  symmetric?: boolean;
  centerBased?: boolean;
  showRectangle?: boolean;
  noScale?: boolean;
  rotationCenter?: Coords;
  rotation?: {
    angle: number;
    origin?: Coords;
    point?: ShapeControlPointPosition;
  };

  startDrag?: (point?: ShapeControlPointPosition) => void;
  drag: (resp: RectangleDragResponse) => void;
  endDrag: (params?: DragParams, point?: RCPoint) => void;
  clicked?: () => void;
  mouseout?: () => void;
  mouseover?: () => void;
  noRect?: boolean;
  noDrag?: boolean;
  dragParams?: ShapeDragParams;
  _startDrag?: () => void;
  _drag?: (dx?: number, dy?: number) => void;
  _endDrag?: () => void;
  noFill?: boolean;
  skipRC?: Partial<Record<RCPoint, boolean>>;
}

export class RectangleController {
  pcLeftTop: PointController;
  pcLeftCenter: PointController;
  pcLeftBottom: PointController;

  pcCenterTop: PointController;
  pcCenter: PointController;
  pcCenterBottom: PointController;
  pcRightTop: PointController;
  pcRightCenter: PointController;
  pcRightBottom: PointController;
  rect: RectangleElement;
  unMovedLengths: Coords;
  diff: Coords;
  currentLengths: Coords;

  get cs() {
    return this.parent.cs;
  }

  get service() {
    return this.parent.service;
  }

  get os() {
    return this.parent.orientationService;
  }

  get rotation() {
    return this.config.rotation;
  }

  get rotationCenter() {
    return this.config.rotationCenter;
  }

  get width() {
    return this.config.width;
  }

  set width(val: number) {
    this.config.width = val;
  }

  get height() {
    return this.config.height;
  }

  set height(val: number) {
    this.config.height = val;
  }

  get position() {
    return [this.offsetX, this.offsetY];
  }

  get x() {
    return this.config.position[0];
  }

  set x(val: number) {
    this.config.position[0] = val;
  }

  get y() {
    return this.config.position[1];
  }
  set y(val: number) {
    this.config.position[1] = val;
  }

  get offsetX() {
    return this.config.offset?.[0] || 0;
  }

  get offsetY() {
    return this.config.offset?.[1] || 0;
  }

  set offsetX(val: number) {
    this.config.offset[0] = val;
  }

  set offsetY(val: number) {
    this.config.offset[1] = val;
  }

  get pcs() {
    return [
      this.pcLeftTop,
      this.pcLeftCenter,
      this.pcLeftBottom,
      this.pcCenterTop,
      this.pcCenter,
      this.pcCenterBottom,
      this.pcRightTop,
      this.pcRightCenter,
      this.pcRightBottom,
    ].filter(rc => !!rc);
  }

  get symmetric() {
    return this.config.symmetric;
  }

  get centerBased() {
    return this.config.centerBased || this.cs.isShiftPressed;
  }

  unMovedX: number;
  unMovedY: number;
  dx: number;
  dy: number;
  dw: number;
  dh: number;

  get noScale() {
    return this.config.noScale;
  }

  get skipRC() {
    return this.config.skipRC || {};
  }

  get canvasScale() {
    return this.noScale ? 1 : this.cs.canvasScale;
  }

  get inprogress() {
    return !!this.pcs.find(pc => pc.inProgress);
  }

  get selectedContainerMode() {
    return this.config.selectedContainerMode;
  }
  _dx: number;
  _dy: number;
  leftTop: Vector;
  leftBottom: Vector;
  rightTop: Vector;
  rightBottom: Vector;
  shapePosition: Vector;

  // The left-top vector's new position wrt. to the original origin through rotation-drag
  newLeftTop: Vector;

  newShapePosition: Vector;
  newOrigin: Coords;
  dLeftTop: Coords;

  unMovedAngle: number;
  unMoved: Coords;

  rotationMode = false;
  constructor(
    public parent: GeneralShape,
    public config: RectangleControllerConfig,
    private circleContainer: Container,
    private auxCircleContainer: Container,
    rectangleContainer?: Container,
  ) {
    // console.log('rectangle', this.width, this.height); // -- // -- //
    this.rect = new RectangleElement(
      this,
      circleContainer,
      //rectangleContainer || parent.containerForRc,
      // parent.containerForRc || rectangleContainer,
      {
        position: {
          x: this.offsetX,
          y: this.offsetY,
        },
        width: this.width,
        height: this.height,
        stroke: '#0191f3',
        // 'stroke-opacity': this.config.noRect ? 0 : 1,
        'stroke-width': 1,
        // noFill: this.config.noFill,
        noFill: true,
      },
    )
      .click(() => {
        this.config.clicked?.();
      })
      .mouseover(() => {
        this.isMouseOver = true;
        this.mouseover();
      })
      .mouseout(() => {
        this.isMouseOver = false;
        this.mouseout();
      });

    if (!this.config.noDrag) {
      // this.rect.drag(
      //   (dx, dy) => {
      //     this.service.drag(dx, dy);
      //     this.config._drag?.(dx, dy);
      //   },
      //   () =>
      //     this.service.startDrag(
      //       this.parent.getType() == 'root-shape' ? null : this.parent,
      //     ),
      //   () => {
      //     this.service.endDrag(this.config.dragParams);
      //   },
      // );
    }

    if (this.rotation) {
      this.leftTop = new BaseVector([-this.width / 2, -this.height / 2]);
      this.leftBottom = new BaseVector([-this.width / 2, this.height / 2]);

      this.rightTop = new BaseVector([this.width / 2, -this.height / 2]);
      this.rightBottom = new BaseVector([this.width / 2, this.height / 2]);

      [this.leftTop, this.leftBottom, this.rightTop, this.rightBottom].map(v =>
        v.rotate(this.rotation.angle),
      );

      this.shapePosition = this.leftTop.copy();
      this.shapePosition.rotate(-this.rotation.angle);
    }

    this.diff = [0, 0];
    this.currentLengths = cloneDeep([this.width, this.height]);

    this.pcLeftTop = this.initRC('left-top', 0, 0);
    this.pcLeftCenter = this.initRC('left-center', 0, this.height / 2);
    this.pcLeftBottom = this.initRC('left-bottom', 0, this.height);

    if (!this.skipRC['center']) {
      this.pcCenter = new PointController(
        this,
        {
          p: [this.offsetX + this.width / 2, this.offsetY + this.height / 2],
          showCircle: true,
          noScale: this.noScale,
          // rotation: this.rotation,
          noConstraint: this.selectedContainerMode,
          controlPoint: {
            shapeIRI: this.parent.IRI,
            name: 'center',
          },
          start: () => {
            if (this.selectedContainerMode) {
              this.unMovedX = this.service.groupRCContainer.x;
              this.unMovedY = this.service.groupRCContainer.y;
              this.service.startDrag();
            }
            // else {
            //   this.parent.startBaseDrag();
            // }
          },
          drag: ({ dx, dy }) => {
            if (this.selectedContainerMode) {
              this.service.drag(dx, dy);
              this.service.groupRCContainer.setTransform(
                this.unMovedX + dx * this.cs.canvasScale,
                this.unMovedY + dy * this.cs.canvasScale,
              );
            }
            //  else {
            //   this.parent.drag(dx, dy);
            // }
            // this.pcCenter.patch({
            //   p: [this.x + this.width / 2, this.y + this.height / 2],
            // });
          },
          end: () =>
            this.selectedContainerMode ? this.service.endDrag() : null,
          mouseout: () => this.pcMouseOut(),
          mouseover: () => this.mouseover(),
        },
        circleContainer,
        auxCircleContainer,
      );
    }

    this.pcCenterTop = this.initRC('center-top', this.width / 2, 0);
    this.pcCenterBottom = this.initRC(
      'center-bottom',
      this.width / 2,
      this.height,
    );

    this.pcRightTop = this.initRC('right-center', this.width, 0);
    this.pcRightCenter = this.initRC(
      'right-center',
      this.width,
      this.height / 2,
    );
    this.pcRightBottom = this.initRC('right-bottom', this.width, this.height);

    this.saveUnmoved();
    this.zoomUpdate();
  }

  startDrag(point?: ShapeControlPointPosition) {
    this.dx = 0;
    this.dy = 0;
    this.dw = 0;
    this.dh = 0;
    this.unMovedX = this.parent?.x || 0;
    this.unMovedY = this.parent?.y || 0;

    // -- // -- //
    if (this.rotation?.origin !== undefined || this.cs.isPressed('r')) {
      let end: Coords;
      this.cs.consumeKeyEvent('r');
      this.rotationMode = true;
      switch (point) {
        case 'right-top':
          end = [this.width, 0];
          break;
        case 'right-center':
          end = [this.width, this.height / 2];
          break;
        case 'right-bottom':
          end = [this.width, this.height];
          break;
        case 'left-top':
          end = [0, 0];
          break;
        case 'left-center':
          end = [0, this.height / 2];
          break;
        case 'left-bottom':
          end = [0, this.height];
          break;
        case 'center-top':
          end = [this.width / 2, 0];
          break;
        case 'center-bottom':
          end = [this.width / 2, this.height];
          break;
      }

      // const start = this.config.rot //
      let start: Coords;

      if (this.rotation?.origin) {
        start = this.rotation.origin;
        this.parent.prepareRotation(null, this.rotation.origin);
      } else if (this.parent.getType() == 'is') {
        const is = this.parent as ImportedShape;
        start = Object.values(is.controlPoints)[0].coords;
        is.prepareRotation(Object.keys(is.controlPoints)[0]);
      }

      const [sx, sy] = start;

      const [ex, ey] = end;
      const vector = new BaseVector([ex - sx, ey - sy]);
      vector.rotate(this.rotation.angle);

      this.unMoved = [vector.end.x, vector.end.y];
      this.unMovedAngle = vector.getAngle();

      console.log({ ex, ey, sx, sy });
      console.log('unMovedAngle', this.unMovedAngle);
      console.log('unMoved', this.unMoved);
      this.rotationMode = true;
    }

    this.config.startDrag?.(point);
  }
  initRC(point: RCPoint, w: number, h: number) {
    if (this.skipRC[point]) {
      return;
    }

    return new PointController(
      this,
      {
        p: [this.offsetX + w, this.offsetY + h],
        showCircle: true,
        // rotation: this.rotation,
        noScale: this.noScale,
        start: () => this.startDrag(point),
        drag: resp => this.drag(point, resp),
        end: () => this.endDrag(point),
        mouseout: () => this.mouseout(),
        mouseover: () => this.mouseover(),
      },
      this.circleContainer,
      this.auxCircleContainer,
    );
  }

  pcDrag(dx: number, dy: number, point: RCPoint) {
    let _dx: number, _dy: number;

    const [dw, dh] = this.getDwDhByRoation(dx, dy, point);

    const [dxOrigin, dyOrigin] = [dx, dy].map(val => val / 2);
    switch (point) {
      case 'left-top':
        this.newLeftTop = new BaseVector([
          this.leftTop.x + dx,
          this.leftTop.y + dy,
        ]);

        this.newShapePosition = new Vector(
          { x: dxOrigin, y: dyOrigin },
          {
            x: this.newLeftTop.x,
            y: this.newLeftTop.y,
          },
        ).rotate(-this.rotation);
        _dx = this.newShapePosition.end.x - this.shapePosition.x;
        _dy = this.newShapePosition.end.y - this.shapePosition.y;

        break;

      case 'left-bottom':
        this.newOrigin = [dxOrigin, dyOrigin];

        const [dLeftTopX, dLeftTopY] = [
          dw * Math.cos(this.rotation.angle),
          dw * Math.sin(this.rotation.angle),
        ];

        // The LEFT-TOP and the RIGHT-BOTTOM changes
        this.newLeftTop = new BaseVector([
          this.leftTop.x - dLeftTopX,
          this.leftTop.y - dLeftTopY,
        ]);

        this.newShapePosition = new Vector(
          { x: dxOrigin, y: dyOrigin },
          {
            x: this.newLeftTop.x,
            y: this.newLeftTop.y,
          },
        ).rotate(-this.rotation);

        _dx = this.newShapePosition.end.x - this.shapePosition.x;
        _dy = this.newShapePosition.end.y - this.shapePosition.y;

        break;
      case 'right-bottom':
        this.newOrigin = [dxOrigin, dyOrigin];

        this.newLeftTop = new BaseVector([
          this.leftTop.x - dxOrigin,
          this.leftTop.y - dyOrigin,
        ]);

        this.newLeftTop.rotate(-this.rotation);

        _dx = dxOrigin + this.newLeftTop.x - this.shapePosition.x;
        _dy = dyOrigin + this.newLeftTop.y - this.shapePosition.y;
        break;
      case 'right-top':
        // -- // -- //
        this.dLeftTop = [
          -dh * Math.cos(-this.rotation),
          -dh * Math.sin(-this.rotation),
        ];

        // console.log({ dxOrigin, dyOrigin });
        this.newLeftTop = new BaseVector([
          this.leftTop.x + this.dLeftTop[0],
          this.leftTop.y + this.dLeftTop[1],
        ]);

        this.newShapePosition = new Vector(
          { x: dxOrigin, y: dyOrigin },
          {
            x: this.newLeftTop.x,
            y: this.newLeftTop.y,
          },
        ).rotate(-this.rotation);

        _dx = this.newShapePosition.end.x - this.shapePosition.x;
        _dy = this.newShapePosition.end.y - this.shapePosition.y;

        break;
      default:
        break;
    }

    return [dw, dh, _dx, _dy];
  }

  getDwDhByRoation(dx: number, dy: number, point: RCPoint) {
    let angle = Math.atan(dy / dx);

    // -- // -- //
    if (dx < 0) {
      if (dy >= 0) {
        angle += Math.PI;
      } else {
        angle -= Math.PI;
      }
    }
    const diffAngle = angle - this.rotation.angle;
    const l = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

    // console.log('---------- getDwDh ------------', { l, diffAngle });
    const [dw, dh] = [Math.cos(diffAngle) * l, Math.sin(diffAngle) * l];

    switch (point) {
      case 'left-top':
        return [-dw, -dh];
      case 'left-bottom':
        return [-dw, dh];
      case 'right-bottom':
        return [dw, dh];
      case 'right-top':
        return [dw, -dh];
      default:
        return [0, 0];
    }
  }

  getDxDyByRotation(dx: number, dy: number, point: RCPoint) {
    // -- // -- // -- // -- // -- // -- //
  }

  hidePointControllers() {
    this.pcs.map(pc => pc.hide(true));
  }

  isMouseOver = false;

  mouseover() {
    if (this.inprogress) {
      return;
    }
    this.config.mouseover?.();
  }

  mouseout() {
    //setTimeout(() => {
    if (this.pcs.find(pc => pc.mouseIsOver)) {
      return;
    }

    if (this.inprogress) {
      return;
    }
    this.config.mouseout?.();
    // }, 5);
  }

  pcMouseOut() {
    // setTimeout(() => {
    // -- // -- // -- // -- //
    if (this.isMouseOver) {
      console.log('rc > isMouseOver yoooo!');
      return;
    }

    this.config.mouseout?.();
    //}, 5);
  }

  patch(config: Partial<RectangleControllerConfig>, refresh = false) {
    Object.keys(config).map(key => (this.config[key] = config[key]));

    // The width and height is updated in the case an ongoing animation
    if (config.width) {
      this.currentLengths[0] = this.width;
      this.saveUnmoved();
    }
    if (config.height) {
      this.currentLengths[1] = this.height;
      this.saveUnmoved();
    }

    if (config.width !== undefined && config.height !== undefined) {
      this.rect?.patch({
        width: config.width,
        height: config.height,
      });
    }

    if (refresh) {
      this.refresh();
    }
  }

  updateWH(w: number, h: number) {
    this.config.width = w;
    this.config.height = h;
    this.refresh();
  }

  hide(params: ShapeSelectParams = {}) {
    // Dirthy hack but works - maybe I can work with the fill: transparent somehow
    this.rect.hide();
    this.pcs.map(pc => pc.hide());
  }

  show({
    onHover,
    multipleSelected,
    withoutPCs,
    withoutRectangle,
  }: ShapeSelectParams = {}) {
    this.refresh();

    if (withoutRectangle) {
      this.rect.hide();
    } else {
      this.rect.show();
    }

    if (withoutPCs) {
      this.pcs.map(pc => pc.hide());
      return;
    }

    if (onHover || multipleSelected) {
      return;
    }
    this.pcs.map(pc => pc.show());
    this.zoomUpdate();
  }
  get visible() {
    return this.rect.visible;
  }
  remove() {
    this.rect.remove();
    this.pcs.map(pc => pc.remove());
  }

  drag(point: RCPoint, { dx, dy }: DragResponse) {
    if (this.rotationMode) {
      const [x0, y0] = this.unMoved;
      const newVectorAngle = this.getAngle(x0 + dx, y0 + dy);
      return this.config.drag({
        angle: this.rotation.angle + newVectorAngle - this.unMovedAngle,
      });
    }

    if (this.symmetric) {
      if (dx > dy) {
        dy = dx;
      } else {
        dx = dy;
      }
    }

    this._dx = dx;
    this._dy = dy;
    const [w, h] = this.unMovedLengths;

    const newDx = dx;
    const newDy = dy;

    // const [newDx, vLines] = this.parent.checkHorizontal(
    //   [this.parent._x, this.parent._y, 0],
    //   [this.parent._x, this.parent._y + h, h],
    //   [dx, dy],
    // );

    // const [newDy, hLines] = this.parent.checkVertical(
    //   [this.parent._x, this.parent._y, 0],
    //   [this.parent._x + w, this.parent._y, w],
    //   [dx, dy],
    // );

    // if (vLines) {
    //   this.os.showVerticalOrientationLines(
    //     this.parent,
    //     vLines,
    //     newDx,
    //     newDy,
    //   );
    // }

    // if (hLines) {
    //   this.os.showHorizontalOrientationLines(
    //     this.parent,
    //     hLines,
    //     newDx,
    //     newDy,
    //   );
    // }

    if (this.rotation) {
      const [dw, dh, dxPos, dyPos] = this.pcDrag(dx, dy, point);

      this.dw = dw;
      this.dh = dh;
      this.dx = dxPos;
      this.dy = dyPos;
    }

    switch (point) {
      case 'center-bottom':
        this.dh = dy;
        this.dy = 0;
        this.pcCenterTop.patch({
          p: [this.offsetX + this.width / 2, this.offsetY + this.height],
        });
        break;

      case 'right-top':
        this.diff = [0, dy];

        this.dx = 0;

        if (this.centerBased) {
          this.dx = -newDx;
        }

        this.dy = newDy;
        this.dw = newDx;
        this.dh = -newDy;
        break;
      case 'right-center':
        this.dw = dx;
        break;
      case 'right-bottom':
        this.dx = 0;
        this.dy = 0;

        if (this.centerBased) {
          this.dx = -newDx;
          this.dy = -newDy;
        }

        this.dw = newDx;
        this.dh = newDy;
        break;
      case 'left-top':
        this.dw = -newDx;
        this.dh = -newDy;
        this.dx = newDx;
        this.dy = newDy;
        break;
      case 'left-center':
        // -- // -- //
        this.dw = -dx;
        this.dh = 0;
        this.dx = dx;
        this.dy = 0;
        break;
      case 'left-bottom':
        this.dx = newDx;
        this.dy = 0;
        this.dw = -newDx;
        this.dh = newDy;
        break;
      case 'center-top':
        this.dh = -dy;
        this.dy = dy;
        break;
    }

    // -- // -- // -- // -- // -- //

    const multiplier = this.centerBased ? 2 : 1;

    this.currentLengths = [w + this.dw * multiplier, h + this.dh * multiplier];

    const [width, height] = this.currentLengths;
    this.refreshRectangle(width, height);

    // We need to update them because by their own they do not affect each other //

    // this.pcLeftTop.patch({ p: [this.x, this.y] });
    // this.pcRightTop.patch({ p: [this.x + width, this.y] });
    // this.pcRightBottom.patch({ p: [this.x + width, this.y + height] });
    // this.pcLeftBottom.patch({ p: [this.x, this.y + height] });

    this.updatePointControllers(width, height);
    console.log('unMovedX', this.unMovedX);
    return this.config.drag({
      x: this.unMovedX + this.dx,
      y: this.unMovedY + this.dy,
      width,
      height,
      dx: this.dx,
      dy: this.dy,
      dw: this.dw,
      dh: this.dh,
      _dx: this._dx,
      _dy: this._dy,
      point,
    });
  }

  getAngle(x: number, y: number) {
    if (!x && !y) {
      return 0;
    }
    const angle = Math.abs(Math.atan(y / x));
    if (x > 0 && y > 0) {
      return angle;
    }
    if (x < 0 && y > 0) {
      return Math.PI - angle;
    }
    if (x < 0 && y < 0) {
      return Math.PI + angle;
    }
    if (x > 0 && y < 0) {
      return 2 * Math.PI - angle;
    }
    if (Math.abs(x) === 0) {
      return y > 0 ? Math.PI / 2 : (3 * Math.PI) / 2;
    }
    if (Math.abs(y) === 0) {
      return x > 0 ? 0 : Math.PI;
    }
  }

  refresh() {
    const [width, height] = [this.width, this.height];

    this.refreshRectangle(width, height);

    this.updatePointControllers(width, height);

    // this.pcRightTop.patch({ p: [width, 0] }); //
    // this.pcRightBottom.patch({ p: [width, height] }); //
    // this.pcLeftBottom.patch({ p: [0, height] }); //

    this.pcs.map(pc => pc.refresh());
  }

  updatePointControllers(width: number, height: number) {
    // this.pcLeftTop.patch({ p: [this.x, this.y], pOffset: [-this.offsetX, -this.offsetY] });
    // this.pcRightTop.patch({ p: [this.x + width, this.y], pOffset: [-this.offsetX, -this.offsetY] });
    // this.pcRightBottom.patch({ p: [this.x + width, this.y + height], pOffset: [-this.offsetX, -this.offsetY] });
    // this.pcLeftBottom.patch({ p: [this.x, this.y + height], pOffset: [-this.offsetX, -this.offsetY] });

    const pOffset: Coords = [-this.offsetX, -this.offsetY];

    // --------- left --------- //
    this.pcLeftTop?.patch({
      p: [this.offsetX, this.offsetY],
      // pOffset,
    });

    this.pcLeftCenter?.patch({
      p: [this.offsetX, this.offsetY + height / 2],
      // pOffset,
    });

    this.pcLeftBottom?.patch({
      p: [this.offsetX, this.offsetY + height],
      // pOffset,
    });

    // --------- center --------- //

    this.pcCenterTop?.patch({
      p: [this.offsetX + width / 2, this.offsetY],
    });
    this.pcCenter?.patch({
      p: [this.offsetX + width / 2, this.offsetY + height / 2],
      pOffset,
    });
    this.pcCenterBottom?.patch({
      p: [this.offsetX + width / 2, this.offsetY + height],
    });

    // --------- right --------- //
    this.pcRightCenter?.patch({
      p: [this.offsetX + width, this.offsetY + height / 2],
      pOffset,
    });

    this.pcRightTop?.patch({
      p: [this.offsetX + width, this.offsetY],
      pOffset,
    });

    this.pcRightBottom?.patch({
      p: [this.offsetX + width, this.offsetY + height],
      pOffset,
    });
  }

  refreshRectangle(width: number, height: number) {
    let x = 0;
    let y = 0;
    if (width < 0) {
      x = width;
    }
    if (height < 0) {
      y = height;
    }

    this.rect.patch({
      // position: { x: this.x + x - this.dw, y: this.y + y - this.dh },
      position: { x: this.offsetX + x, y: this.offsetY + y },
      width: Math.abs(width) || 0,
      height: Math.abs(height) || 0,
      'stroke-width': 1 / this.canvasScale,
    });
  }

  saveUnmoved() {
    this.unMovedLengths = cloneDeep(this.currentLengths);
  }

  endDrag(point: RCPoint) {
    if (this.unMovedAngle) {
      // -- // -- // -- // -- // -- //
    }

    this.unMovedX += this._dx;
    this.unMovedY += this._dy;
    this.width = this.unMovedLengths[0] + this.dw;
    this.height = this.unMovedLengths[1] + this.dh;
    this._dx = undefined;
    this._dy = undefined;
    this.diff = [0, 0];
    this.saveUnmoved();
    // -- // -- //
    this.config.endDrag({ rotate: this.rotationMode });
    this.parent.hideOrientationLines();
  }

  zoomUpdate() {
    this.rect?.patch({
      'stroke-width': 1 / this.canvasScale,
    });
    this.pcs.map(pc => pc.zoomUpdate());
  }
}
