import { createSelector } from '@ngrx/store';
import { DataBag } from '../store/reducer';
import { Organisation } from './organisation.interface';
import { OrganisationFeature } from './organisation.actions';
import { myUserId, selectUserFeature } from '../user/user.selector';
import { UserWithRole } from '../user/user.interface';
import { RoleTypes } from './role.interface';

export const selectOrganisationFeature = (state: DataBag) =>
  state[OrganisationFeature];

export const organisationList = createSelector(
  selectOrganisationFeature,
  ({ allOrganisations }): Organisation[] => Object.values(allOrganisations),
);

export const allOrganisations = createSelector(
  selectOrganisationFeature,
  ({ allOrganisations }) => allOrganisations,
);

export const currentOrganisationID = createSelector(
  selectOrganisationFeature,
  ({ currentOrganisationID }): string => currentOrganisationID,
);
export const isOrgLoaded = createSelector(
  selectOrganisationFeature,
  ({ isOrganisationLoaded: orgIsLoaded }) => orgIsLoaded,
);

export const currentOrganisation = createSelector(
  selectOrganisationFeature,
  ({ allOrganisations, currentOrganisationID }): Organisation =>
    allOrganisations[currentOrganisationID],
);

export const organisationListLoading = createSelector(
  selectOrganisationFeature,
  ({ listLoading }): boolean => listLoading,
);

export const getCurrentColorPalette = createSelector(
  selectOrganisationFeature,
  ({ allOrganisations, currentOrganisationID }) =>
    allOrganisations[currentOrganisationID]?.colorPalette || {},
);

export const organisationById = (id: string) =>
  createSelector(
    selectOrganisationFeature,
    ({ allOrganisations }): Organisation => allOrganisations[id],
  );

const rolePriority: Record<RoleTypes, number> = {
  owner: 1,
  admin: 2,
  editor: 3,
  viewer: 4,
};
export const userListByOrganisationId = (id: string) =>
  createSelector(
    selectOrganisationFeature,
    selectUserFeature,
    ({ allOrganisations }, { allUsers, newlyCreatedUsers }): UserWithRole[] =>
      Object.values(allUsers)
        .filter(user => allOrganisations[id].roles[user.id])
        .map(user => ({ ...user, role: allOrganisations[id].roles[user.id] }))
        .sort((a, b) => rolePriority[a.role.type] - rolePriority[b.role.type])
        .sort(
          (u1, u2) =>
            (newlyCreatedUsers[u2.id] || 0) - (newlyCreatedUsers[u1.id] || 0),
        ),
  );

export const myRoleByOrganisationId = (id: string) =>
  createSelector(
    organisationById(id),
    myUserId,
    (organisation, myUserId): RoleTypes => {
      return organisation?.roles[myUserId]?.type;
    },
  );
