import { GeneralShapeDescriptor } from '../../../../elements/resource/types/shape.type';
import { bulkUpdateFinish } from '../../../store/editor.actions';
import { setDescriptorValue } from '../../../store/editor.crud.actions';
import { GeneralShape } from '../general/general-shape';

export class ContainerShape<
  T extends GeneralShapeDescriptor = GeneralShapeDescriptor,
> extends GeneralShape<T> {
  afterInit(): void {
    super.afterInit();
    this.shapes.map(shape => shape.afterInit());
    this.setChildIndexes();
  }

  resetBaseState(): void {
    super.resetBaseState();
    this.shapes.map(shape => shape.resetBaseState());
  }

  setPreAnimationState() {
    // TODO - check if it is the right thing
    this.shapes.map(shape => shape.setPreAnimationState());
    return super.setPreAnimationState();
  }

  setChildIndexes() {
    this.shapesByIndex.map((shape, index) => {
      if (index > 0) {
        const prevShape = this.shapes[index - 1];
        shape.prev = prevShape;
        prevShape.next = shape;
      }
      shape.childIndex = index;
    });
  }

  moveUp(shape: GeneralShape) {
    if (this._shapes.length == 1) {
      return;
    }

    if (shape.containerIndex == 0) {
      return;
    }

    this.setContainerIndexes();
    this.containerForChildren.removeChild(shape.mainContainer);

    this.containerForChildren.addChildAt(
      shape.mainContainer,
      shape.containerIndex - 1,
    );

    const prevPrev = this.shapesByIndex[shape.containerIndex - 2];
    const prev = this.shapesByIndex[shape.containerIndex - 1];

    if (!prevPrev || prevPrev.index == prev.index) {
      shape.index = prev.index - 2;
    } else {
      shape.index = (prevPrev.index + prev.index) / 2;
    }

    this.setContainerIndexes();
    this.saveShapeIndex(shape);
  }

  moveDown(shape: GeneralShape) {
    if (this._shapes.length == 1) {
      return;
    }

    if (shape.containerIndex == this.containerForChildren.children.length - 1) {
      return;
    }
    this.setContainerIndexes();

    this.containerForChildren.removeChild(shape.mainContainer);

    this.containerForChildren.addChildAt(
      shape.mainContainer,
      shape.containerIndex + 1,
    );

    const nextNext = this.shapesByIndex[shape.containerIndex + 2];
    const next = this.shapesByIndex[shape.containerIndex + 1];

    if (!nextNext || nextNext.index == next.index) {
      shape.index = next.index + 1;
    } else {
      shape.index = (nextNext.index + next.index) / 2;
    }

    this.setContainerIndexes();
    this.saveShapeIndex(shape);
  }

  setContainerIndexes() {
    this.shapesByIndex.map((shape, i) => (shape.containerIndex = i));
  }

  selectFrom(index: number) {
    for (let i = index; 0 < i; i--) {
      if (this.shapes[i].selected) {
        break;
      } else {
        console.log('select-shape');
        this.shapes[i].select();
      }
    }
    for (let i = index; i < this.shapes.length; i++) {
      if (this.shapes[i].selected) {
        break;
      } else {
        console.log('select-shape');
        this.shapes[i].select();
      }
    }
  }

  saveShapeIndex(shape: GeneralShape) {
    this.store.dispatch(
      setDescriptorValue({
        IRI: shape.IRI,
        key: 'index',
        value: shape.index,
      }),
    );
    this.store.dispatch(bulkUpdateFinish());
  }

  readdChildreContainer() {
    this.mainContainer.removeChildren();

    this.mainContainer.addChild(
      ...this._shapes.map(shape => shape.mainContainer).filter(c => !!c),
    );
  }

  moveUpwards(shape: GeneralShape) {
    // -- // -- // -- // -- //
    const indexes = this.shapeIndexes;
    this.fixUndefinedIndexes(indexes);

    const lastIndex = indexes[indexes.length - 1];
    this.setNewIndex(shape, lastIndex + 1);
  }

  setNewIndex(shape: GeneralShape, index: number) {
    console.log('set-new-index', index);
    try {
      shape.mainContainer.removeFromParent();
      // this.containerForChildren.removeChildAt(shape.childIndex);
    } catch (error) {
      console.warn('child-could not be removed', error.message);
    }

    shape.index = index;
    this.setChildIndexes();

    shape.save();
    console.log('up > add at', shape.childIndex);
    this.containerForChildren.addChildAt(shape.mainContainer, shape.childIndex);
  }

  moveDownwards(shape: GeneralShape) {
    console.log('move-downwards');
    return;
    const indexes = this.shapeIndexes;
    this.fixUndefinedIndexes(indexes);

    if (indexes.includes(null)) {
      for (const shape of this.shapes) {
        if (shape.index == null) {
          shape.index = 0;
          shape.save();
        }
      }
    }

    const firstIndex = indexes[0];
    this.containerForChildren.removeChildAt(shape.childIndex);

    if (firstIndex <= 0) {
      shape.index = firstIndex - 1;
    } else {
      shape.index = firstIndex / 2;
    }

    this.setChildIndexes();

    shape.save();
    this.containerForChildren.addChildAt(shape.mainContainer, 0);

    this._shapes = this._shapes.sort((c1, c2) => c1.index - c2.index);
  }

  fixUndefinedIndexes(indexes: number[]) {
    if (indexes.includes(null)) {
      for (const shape of this.shapes) {
        if (shape.index == null || shape.index == 0) {
          shape.index = 0;
          shape.save();
        }
      }
    }
  }
}
