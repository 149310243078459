<div class="canvas-transform-component">
  <div class="w-100 text-center">Canvas Transform</div>

  <div class="flex-column gap-4">
    <div class="flex-row space-between">
      x {{ x?.toFixed(2) }} y {{ y?.toFixed(2) }}
      <!-- <nw-number-input key="x" [value]="x"></nw-number-input>
      <nw-number-input key="y" [value]="y"></nw-number-input> -->
    </div>
    <div>
      <nw-number-input key="scale" [value]="scale"></nw-number-input>
    </div>
  </div>

  <div class="flex-row space-around pointer">
    <div (click)="setCurrent()">Set current</div>
  </div>
</div>
