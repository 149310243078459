import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAttributeComponent } from '../../../../base/base-attribute.component';
import { _attributeState } from '../../../../../store/selector/editor.selector';

@Component({
  selector: 'nw-path-fill-animation',
  templateUrl: './path-fill-animation.component.html',
  styleUrls: ['./path-fill-animation.component.scss'],
})
export class PathFillAnimationComponent
  extends BaseAttributeComponent
  implements OnInit
{
  constructor(readonly store: Store) {
    super(store);
    this.key = 'path-fill';
  }

  ngOnInit(): void {
    this.attributeState$ = this.store.select(_attributeState('path-fill'));
    this.attributeState$.subscribe(attrState => {
      // console.log('path-attribute-start', attrState); //
    });
  }
}
