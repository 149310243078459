import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  exhaustMap,
  map,
  catchError,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { ProjectService } from './project.service';
import {
  loadProjectById,
  setProject,
  loadProjects,
  setProjects,
  loadProjectWithFilesById,
  createProject,
  deleteProject,
  setDeleteIsPending,
  deleteProjectRequest,
  resetDeleteIsPending,
  deleteProjectRequestSuccess,
  deleteProjectRequestFailure,
  deleteProjectFromStore,
  saveProjectDescriptor,
  addFileToProject,
  addFileToProjectRequest,
  setNewFileLoading,
  setFile,
  saveProjectLanguages,
  patchProjectDescriptorSuccess,
  setFiles,
  setNewProjectLoading,
  setNewlyCreatedProject,
  loadProjectFailed,
  updateFileName,
  setLibraryValue,
  deleteFile,
} from './project.actions';
import { Action, Store } from '@ngrx/store';
import { setUsers } from '../user/user.actions';
import { setProjectLoaded } from '../element-editor/store/editor.actions';
import { currentOrganisationID } from '../organisation/organisation.selector';
import {
  getCurrentProject,
  getCurrentProjectID,
  selectFiles,
} from './project.selector';
import { ResourceData } from '../elements/resource/resource.types';
import { setOrganisationFromProject } from '../organisation/organisation.actions';
import { IRIToId } from '../util/iri';

@Injectable()
export class ProjectEffects {
  readonly loadProjects$: Observable<Action>;
  readonly loadProjectById$: Observable<Action>;

  readonly loadProjectWithFiles$: Observable<Action>;
  readonly loadProjectWithFilesRequest$: Observable<Action>;

  readonly addFileToProject$: Observable<Action>;
  readonly addFileToProjectRequest$: Observable<Action>;

  readonly createProject$: Observable<Action>;

  readonly deleteProject$: Observable<Action>;
  readonly deleteProjectRequest$: Observable<Action>;

  readonly saveProjectDescriptor$: Observable<Action>;
  readonly saveLanguages$: Observable<Action>;
  readonly setColorPalette$: Observable<Action>;
  readonly updateFileName$: Observable<Action>;
  readonly deleteFile$: Observable<Action>;
  readonly setLibraryValue$: Observable<Action>;

  constructor(
    private readonly actions$: Actions,
    private readonly projectService: ProjectService,
    private readonly store: Store,
  ) {
    this.deleteFile$ = createEffect(() =>
      this.actions$.pipe(
        ofType(deleteFile),
        withLatestFrom(
          this.store.select(getCurrentProjectID),
          this.store.select(currentOrganisationID),
        ),
        switchMap(([{ IRI }, projectID, orgID]) =>
          this.projectService.deleteFile(IRIToId(IRI), projectID, orgID),
        ),
        switchMap(response => [
          patchProjectDescriptorSuccess({ project: response }),
        ]),
        catchError(error => {
          console.log('error', error.message);
          return of(error.message);
        }),
      ),
    );

    this.saveLanguages$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveProjectLanguages),
        withLatestFrom(this.store.select(getCurrentProject)),
        switchMap(([{ languages }, project]) =>
          this.projectService.saveProjectDescriptor({
            ...project,
            languages,
          }),
        ),
        switchMap(response => [
          patchProjectDescriptorSuccess({ project: response }),
        ]),
        catchError(error => {
          console.log('error', error.message);
          return of(error.message);
        }),
      ),
    );

    this.setLibraryValue$ = createEffect(() =>
      this.actions$.pipe(
        ofType(setLibraryValue),
        withLatestFrom(
          this.store.select(getCurrentProject),
          this.store.select(currentOrganisationID),
        ),
        switchMap(([, project, orgID]) => {
          // console.log('library', project.library);
          return this.projectService.saveProjectDescriptor(project, orgID);
        }),
        switchMap(response => []),
        // catchError(error => {
        //   console.log('error', error.message);
        //   return of(error.message);
        // }),
      ),
    );

    this.saveProjectDescriptor$ = createEffect(() =>
      this.actions$.pipe(
        ofType(saveProjectDescriptor),
        withLatestFrom(
          this.store.select(getCurrentProject),
          this.store.select(currentOrganisationID),
        ),
        switchMap(([, project, orgID]) =>
          this.projectService.saveProjectDescriptor(project, orgID),
        ),
      ),
    );

    this.deleteProject$ = createEffect(() =>
      this.actions$.pipe(
        ofType(deleteProject),
        switchMap(({ id }) => {
          return [deleteProjectRequest({ id }), setDeleteIsPending({ id })];
        }),
      ),
    );

    this.deleteProjectRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(deleteProjectRequest),
        withLatestFrom(this.store.select(currentOrganisationID)),
        switchMap(([{ id }, orgID]) =>
          this.projectService.deleteProject(id, orgID),
        ),
        switchMap(id => [
          resetDeleteIsPending(),
          deleteProjectFromStore({ id }),
          deleteProjectRequestSuccess(),
        ]),
        catchError(error =>
          of(deleteProjectRequestFailure({ error: error.message })),
        ),
      ),
    );

    this.createProject$ = createEffect(() =>
      this.actions$.pipe(
        ofType(createProject),
        withLatestFrom(this.store.select(currentOrganisationID)),
        switchMap(([{ name }, currentOrganisationID]) => {
          return this.projectService.createProject(name, currentOrganisationID);
        }),
        switchMap(project => {
          console.log('create-project-succes', project);
          return [
            setProject({ project }),
            setNewProjectLoading({ value: false }),
            setNewlyCreatedProject({ id: project.id, time: performance.now() }),
          ];
        }),
        catchError(error => {
          console.error('error', error);
          return [];
        }),
      ),
    );

    this.loadProjects$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadProjects),
        exhaustMap(() => this.projectService.listProjects()),
        map(projects => setProjects({ projects })),
      ),
    );

    this.updateFileName$ = createEffect(() =>
      this.actions$.pipe(
        ofType(updateFileName),
        withLatestFrom(this.store.select(selectFiles)),
        exhaustMap(([{ IRI, newName }, files]) =>
          this.projectService.patchFile({
            IRI,
            literals: {
              ...(files[IRI].literals || {}),
              label: newName,
            },
          }),
        ),
        map(file => setFile({ file })),
      ),
    );

    this.loadProjectById$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadProjectById),
        exhaustMap(({ id }) => this.projectService.loadProjectById(id)),
        switchMap(({ project, users, files }) => {
          return [
            setProject({ project }),
            setFiles({ files }),
            setUsers({ users }),
          ].filter(v => !!v);
        }),
        catchError(error => of(loadProjectFailed({ message: error.message }))),
      ),
    );

    this.loadProjectWithFiles$ = createEffect(() =>
      this.actions$.pipe(
        ofType(loadProjectWithFilesById),
        withLatestFrom(this.store.select(currentOrganisationID)),
        exhaustMap(([{ id }, orgID]) =>
          this.projectService.loadProjectWithFiles(id, orgID),
        ),
        switchMap(project => [
          setOrganisationFromProject({
            organisation: project.relationships.organisation as ResourceData,
          }),
          setFiles({
            files: (project.relationships.files as ResourceData[]).reduce(
              (object, file) => {
                object[file.IRI] = file;
                return object;
              },
              {},
            ) as Record<string, ResourceData>,
          }),
          setProject({
            project: this.projectService.mapResourceToProject(project),
          }),
        ]),
        catchError(error => {
          console.error('load-project-error', error);
          return of(loadProjectFailed({ message: error.message }));
        }),
      ),
    );

    this.addFileToProject$ = createEffect(() =>
      this.actions$.pipe(
        ofType(addFileToProject),
        withLatestFrom(this.store.select(getCurrentProjectID)),
        switchMap(([{ label }, projectID]) => [
          addFileToProjectRequest({ projectID, label }),
          setNewFileLoading({ value: true }),
        ]),
      ),
    );

    this.addFileToProjectRequest$ = createEffect(() =>
      this.actions$.pipe(
        ofType(addFileToProjectRequest),
        exhaustMap(({ projectID, label }) =>
          this.projectService.addFileToProject(projectID, label),
        ),
        switchMap(file => {
          console.log('-------- request finished', file);
          return [setFile({ file }), setNewFileLoading({ value: false })];
        }),
        // catchError(error => of(loadProjectFailed({ message: error.message })))
      ),
    );
  }
}
