import { on } from '@ngrx/store';
import {
  loadOrganisations,
  setCurrentOrganisationID,
  setOrganisation,
  setOrganisationColorPalette,
  setOrganisations,
  setOrgIsLoaded,
  setUserRoleInOrganisation,
} from './organisation.actions';
import { Organisation } from './organisation.interface';
import { loadHome } from '../home/home.actions';
import { createImmerReducer } from 'ngrx-immer/store';
import { set } from 'lodash';

export interface OrganisationState {
  listLoading: boolean;
  allOrganisations: Record<string, Organisation>;
  currentOrganisationID?: string;

  isOrganisationLoaded?: boolean;
}

export const initialState: OrganisationState = {
  listLoading: false,
  allOrganisations: {},
};

export const organisationReducer = createImmerReducer(
  initialState,
  on(loadOrganisations, state => ({ ...state, listLoading: true })),
  on(loadHome, state => ({ ...state, listLoading: true })),
  on(setCurrentOrganisationID, (state, { ID }) => ({
    ...state,
    currentOrganisationID: ID,
  })),
  on(setOrganisationColorPalette, (state, { colorPalette }) => {
    state.allOrganisations[state.currentOrganisationID].colorPalette =
      colorPalette;
    return state;
  }),
  on(setOrganisation, (state, { organisation }) => ({
    ...state,
    allOrganisations: {
      ...state.allOrganisations,
      [organisation.id]: organisation,
    },
    isOrganisationLoaded: true,
    listLoading: false,
  })),
  on(setUserRoleInOrganisation, (state, { organisationId, userId, role }) => {
    set(state.allOrganisations, [organisationId, 'roles', userId], role);
    return state;
  }),
  on(setOrganisations, (state, { organisations }) => ({
    ...state,
    allOrganisations: organisations.reduce(
      (existing, organisation) => ({
        ...existing,
        [organisation.id]: organisation,
      }),
      { ...state.allOrganisations },
    ),
    listLoading: false,
  })),
  on(setOrgIsLoaded, (state, { value }) => {
    state.isOrganisationLoaded = value;
    return state;
  }),
);
